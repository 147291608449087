import { useEffect } from "react";

export function useSetWebpageBackgroundColor(cssColorString: string) {
  useEffect(() => {
    document.body.style.backgroundColor = cssColorString;

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [cssColorString]);
}
