import { compile } from "html-to-text";

// htmlToText converts `<br />` tags into `\n`
// new line characters
export const htmlToText = compile({
  wordwrap: false,
  formatters: {
    formatImage(elem, walk, builder, formatOptions) {
      const attrs = elem.attribs || {};

      const alt = attrs.alt || "";

      const src = !attrs.src
        ? ""
        : formatOptions.baseUrl && attrs.src.indexOf("/") === 0
          ? formatOptions.baseUrl + attrs.src
          : attrs.src;

      const text = alt ? withBrackets(alt, formatOptions.linkBrackets) : withBrackets(src, formatOptions.linkBrackets);

      builder.addInline(text);
    },
  },
  selectors: [
    { selector: "h1", options: { uppercase: false } },
    { selector: "h2", options: { uppercase: false } },
    { selector: "h3", options: { uppercase: false } },
    { selector: "h4", options: { uppercase: false } },
    { selector: "h5", options: { uppercase: false } },
    { selector: "h6", options: { uppercase: false } },
    { selector: "a", options: { ignoreHref: true } },
    { selector: "img", format: "formatImage" },
  ],
});

function withBrackets(str: string, brackets?: [string, string] | false) {
  if (!brackets) {
    return str;
  }

  const lbr = typeof brackets[0] === "string" ? brackets[0] : "[";
  const rbr = typeof brackets[1] === "string" ? brackets[1] : "]";
  return lbr + str + rbr;
}
