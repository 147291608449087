import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useGroupsUserHasAccessTo } from "./useGroupsUserHasAccessTo";
import { UseRecordLoaderResult } from "./useRecordLoader";

export type UseOrganizationGroupsResult = UseRecordLoaderResult<string[]>;

export function useOrganizationGroups(props: {
  organizationId: string | null | undefined;
  hideArchivedGroups?: boolean;
  orderBy?: "name";
}): UseOrganizationGroupsResult {
  const { currentUserId } = useAuthGuardContext();

  return useGroupsUserHasAccessTo({
    userId: currentUserId,
    ownerOrganizationId: props.organizationId,
    hideArchivedGroups: props.hideArchivedGroups,
    orderBy: props.orderBy,
  });
}
