import { wait } from "libs/promise-utils";
import { SharedQueryApi, SharedQueryApiKey, SharedQueryParams, getCacheKey } from "libs/database";
import { RecordMap, RecordPointer } from "libs/schema";
import { MS_IN_SECOND } from "libs/date-helpers";
import { Logger } from "libs/logger";
import { createClientSharedQueryApi } from "libs/database/client";
import { Simplify } from "type-fest";

const DelayMs = 10 * MS_IN_SECOND;

export type SubscriptionManagerApi = Simplify<SubscriptionManager>;

export class SubscriptionManager {
  logger: Logger;

  /**
   * A map of subscription key to QueryCache cache keys. This is
   * useful for garbage collection. Note that it's possible the set of
   * query cache keys contains stale queries which are no longer being
   * used. At the moment, this doesn't matter.
   */
  subscriptionKeyToQueryCacheKeyMap = new Map<string, Set<string>>();

  private subscriptionKeyToSubscriberCountMap = new Map<string, number>();

  private sharedQueryApi: SharedQueryApi;

  constructor(
    private props: {
      logger: Logger;
      onSubscribe(key: string): void;
      onUnsubscribe(key: string): void;
    },
  ) {
    this.logger = props.logger.child({ name: "SubscriptionManager" });
    this.sharedQueryApi = createClientSharedQueryApi({ logger: this.logger });
  }

  getSubscribeToQueryProps(query: SubscriptionQuery): {
    queryKey: string;
    subscriptionKeys: string[];
  } {
    // The queryKey must be the same as the cache key used by the QueryCache otherwise
    // garbage collection won't work properly. See the unloadQuery function in the
    // environment.
    const queryKey = getCacheKey(query);

    const subscriptionKeys =
      query.type === "getRecord"
        ? [`${query.params.table}:${query.params.id}`]
        : this.sharedQueryApi[query.type](query.params as any).subscriptionKeys(query.resultRecordMap);

    return {
      queryKey,
      subscriptionKeys,
    };
  }

  /**
   * This method receives an QueryCache cache key as well as an array of
   * subscription keys associated with that query. It subscribes to each of the
   * subscription keys and returns an array of `[subscriptionKey, unsubscribeFn]`
   * tuples.
   *
   * This function indexes the subscription key subscriptions and also associates
   * the queryKey with those subscriptions for garbage collection purposes.
   */
  subscribeToQuery(props: {
    queryKey: string;
    subscriptionKeys: string[];
  }): Array<[subscriptionKey: string, unsubscribeFn: () => void]> {
    const { queryKey, subscriptionKeys } = props;

    return subscriptionKeys.map((subKey) => {
      if (!this.subscriptionKeyToQueryCacheKeyMap.has(subKey)) {
        this.subscriptionKeyToQueryCacheKeyMap.set(subKey, new Set());
      }

      this.subscriptionKeyToQueryCacheKeyMap.get(subKey)!.add(queryKey);
      return [subKey, this._subscribe(subKey)];
    });
  }

  /**
   * Advanced use only.
   * In general you should use `subscribeToQuery` instead.
   */
  _subscribe(key: string): () => void {
    this.inc(key);
    let cleanedUp = false;

    return () => {
      if (cleanedUp) return;
      cleanedUp = true;
      wait(DelayMs).then(() => this.dec(key));
    };
  }

  keys() {
    return Array.from(this.subscriptionKeyToSubscriberCountMap.keys());
  }

  private inc(key: string) {
    const subscriptionCount = this.subscriptionKeyToSubscriberCountMap.get(key);

    if (subscriptionCount === undefined) {
      this.subscriptionKeyToSubscriberCountMap.set(key, 1);
      this.props.onSubscribe(key);
    } else {
      this.subscriptionKeyToSubscriberCountMap.set(key, subscriptionCount + 1);
    }
  }

  private dec(key: string) {
    const subscriptionCount = this.subscriptionKeyToSubscriberCountMap.get(key);

    if (subscriptionCount === undefined || subscriptionCount <= 0) {
      throw new Error("We shouldn't be decrementing right now.");
    }

    if (subscriptionCount === 1) {
      this.subscriptionKeyToSubscriberCountMap.delete(key);
      this.props.onUnsubscribe(key);
    } else {
      this.subscriptionKeyToSubscriberCountMap.set(key, subscriptionCount - 1);
    }
  }
}

export type SubscriptionQuery<T extends SubscriptionQueryType = SubscriptionQueryType> = SubscriptionQueryMap[T] & {
  resultRecordMap?: RecordMap;
};

export type SubscriptionQueryType = keyof SubscriptionQueryMap;

type SubscriptionQueryMap = {
  [K in SharedQueryApiKey]: {
    type: K;
    params: SharedQueryParams<K>;
  };
} & {
  getRecord: {
    type: "getRecord";
    params: RecordPointer;
  };
};
