import { router } from "./Routes";
import { PortalContainerContext } from "~/components/PortalContainerContext";
import useConstant from "use-constant";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { TooltipProvider } from "./components/Tooltip";
import { HintViewport } from "./environment/hint-service";
import { lazy, Suspense } from "react";
import { OfflineBanner } from "./components/OfflineBanner";
import { RouterProvider } from "react-router-dom";
import { LoadingModal } from "./dialogs/LoadingModal";
import { DarkModeProvider } from "./components/DarkMode";
import { CreateAndProvideClientEnvironment } from "./environment/ClientEnvironmentContext";
import { ToastViewport } from "./environment/toast-service";
import { ProvideRootCommandContext } from "./environment/command.service";
import { globalState } from "./state/global.state";
import { GlobalErrorView } from "./page-dialogs/GlobalErrorView";
import { UpdateAvailableBanner } from "./components/UpdateAvailableBanner";
import { AppLoadingBar } from "./components/AppLoadingBar";

const UnsupportedBrowserWarningBanner = lazy(() =>
  import("~/components/UnsupportedBrowserWarningBanner").then((m) => ({
    default: m.UnsupportedBrowserWarningBanner,
  })),
);

export default function App() {
  const portalContainer = useConstant(() => document.body);
  const globalError = globalState((s) => s.error);

  if (globalError) {
    return <GlobalErrorView message={globalError.message} code={globalError.code} />;
  }

  return (
    <CreateAndProvideClientEnvironment>
      <HelmetProvider>
        <ProvideRootCommandContext>
          <DarkModeProvider>
            <PortalContainerContext.Provider value={{ container: portalContainer }}>
              <TooltipProvider>
                <Helmet>
                  {/* 
                        This acts as the default page title for the app. It is expected that
                        this is overwritten by child components. It may be shown while a page
                        is loading. 
                      */}
                  <title>Comms</title>
                </Helmet>

                <Suspense fallback={null}>
                  <UnsupportedBrowserWarningBanner />
                </Suspense>

                <LoadingModal />
                <AppLoadingBar />
                <RouterProvider router={router} />
                <ToastViewport />
                <HintViewport />
                <OfflineBanner />
                <UpdateAvailableBanner />
              </TooltipProvider>
            </PortalContainerContext.Provider>
          </DarkModeProvider>
        </ProvideRootCommandContext>
      </HelmetProvider>
    </CreateAndProvideClientEnvironment>
  );
}
