import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { TextInput } from "~/components/forms/TextInput";
import { createFormControl, createFormGroup, useControl } from "solid-forms-react";
import { handleSubmit, onSubmitFn } from "~/components/forms/utils";
import { useRegisterCommands } from "~/environment/command.service";
import { Tooltip } from "~/components/Tooltip";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { OutlineButton } from "~/components/OutlineButtons";

export type IEditHyperlinkDialogData = { url?: string } | undefined;

export type IEditHyperlinkDialogReturnData = { url: string } | void;

export const EditHyperlinkDialogState = new DialogState<IEditHyperlinkDialogData, IEditHyperlinkDialogReturnData>();

interface IFormValue {
  url: string;
}

export const EditHyperlinkDialog = withModalDialog({
  dialogState: EditHyperlinkDialogState,
  Component: (props) => {
    const environment = useClientEnvironment();

    const control = useControl(() => {
      return createFormGroup({
        url: createFormControl(props.data?.url || ""),
      });
    });

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              EditHyperlinkDialogState.close();
            },
          },
          {
            label: "Submit form",
            hotkeys: ["Enter", "$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              handleSubmit({ control, environment, submit });
            },
          },
        ];
      },
      deps: [environment],
    });

    return (
      <div>
        <DialogTitle>
          <h2>Edit link</h2>
        </DialogTitle>

        <form onSubmit={onSubmitFn({ control, environment, submit })} className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="flex px-4">
            <div className="flex flex-1 py-2 mt-4 border-b border-mauve-5">
              <TextInput control={control.controls.url} name="url" placeholder="Enter url" id="url" />
            </div>
          </div>

          <div className="w-fit ml-auto m-2">
            <Tooltip side="left" content="Enter">
              <OutlineButton type="submit" className="flex-col text-sm px-3">
                Submit
              </OutlineButton>
            </Tooltip>
          </div>
        </form>
      </div>
    );
  },
});

const submit = onlyCallFnOnceWhilePreviousCallIsPending(async (_: ClientEnvironment, values: IFormValue) => {
  EditHyperlinkDialogState.close({ url: values.url });
});
