import { useObservableEagerState } from "observable-hooks";
import { map } from "rxjs";
import useConstant from "use-constant";
import { observeSyncProgress } from "~/observables/observeSyncProgress";

export function useSyncProgress() {
  const observable = useConstant(() => {
    const formatter = new Intl.NumberFormat();

    return observeSyncProgress().pipe(
      map((message) => {
        const current = formatter.format(message.current);

        if (!message.total) {
          return {
            ...message,
            text: current,
          };
        }

        return {
          ...message,
          text: `${current} / ${formatter.format(message.total)}`,
        };
      }),
    );
  });

  return useObservableEagerState(observable);
}
