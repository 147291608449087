import { useObservableEagerState } from "observable-hooks";
import { auth } from "~/environment/createEnvironment";

/**
 * **Important!**
 *
 * Most components should get the currentUserId from the AuthGuardContext
 * via useAuthGuardContext. Only use this hook if outside the
 * AuthGuardContext (rare).
 */
export function useCurrentUserId() {
  // We're importing the auth service directly here (rather than getting it from the environment)
  // because this hook is used by the DarkReader functionality inside the `LoadingText` component.
  // The `LoadingText` component is shown while the environment is being initialized.
  return useObservableEagerState(auth.currentUserId$);
}
