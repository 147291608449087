import { ComponentType, useEffect, useRef, useState } from "react";
import * as MainLayout from "~/page-layouts/main-layout";
import { MdEmail, MdCall } from "react-icons/md";
import { DialogState, withModalDialog, DialogTitle } from "~/dialogs/withModalDialog";
import { useRegisterCommands } from "~/environment/command.service";
import { isGoogleWorkspaceEmail } from "~/utils/email-utils";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { toast } from "~/environment/toast-service";
import { pluralize } from "~/utils/string-utils";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";

export const PreviewEmailState = new DialogState();

export const PreviewEmailDialog = withModalDialog({
  dialogState: PreviewEmailState,
  Component: () => {
    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              PreviewEmailState.close();
            },
          },
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>Email preview</h2>
        </DialogTitle>
        <div className="bg-white p-6 space-y-4">
          <h3>Hey there!</h3>
          <p>
            Our team has been using this incredible platform called Comms that's completely transformed how we manage
            our productivity, and I think your team will absolutely love it too! 🙌 The catch? You need an exclusive
            invite to join. 🤫
          </p>
          <p>
            Good news! I've got you covered. 🎉 I've cc'd Tony from the Comms team here. If you're up for a quick 1:1
            chat, just let him know, and he'll walk you through everything. Believe me, it's totally worth it!
          </p>
        </div>
      </>
    );
  },
});

const steps = [
  {
    id: "Step 1",
    name: "Invite a business to check out Comms.",
    icon: <MdEmail />,
  },
  {
    id: "Step 2",
    name: "They’ll need to book a call with our team via your email invitation.",
    icon: <MdCall />,
  },
];

function HowItWorksSteps() {
  return (
    <div>
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            <div className="group flex py-2 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 items-center">
              <div className="text-4xl mr-4">{step.icon}</div>

              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                <span className="text-sm max-w-64">{step.name}</span>
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}

export const ReferralsView: ComponentType<{}> = () => {
  const headerRef = useRef<HTMLElement>(null);
  const environment = useClientEnvironment();
  const { width, height } = useWindowSize();
  const { ownerOrganizationId } = useAuthGuardContext();

  const [invalidEmails, setInvalidEmails] = useState([]);
  const [validEmails, setValidEmails] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [sendingInvites, setSendingInvites] = useState(false);

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => setShowConfetti(false), 4000);
    }
  }, [showConfetti]);

  async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSendingInvites(true);
    const form = event.currentTarget;
    const emails = form.emails.value.split(",").map((email: string) => email.trim());

    const emailsValidity = await Promise.all(emails.map(isGoogleWorkspaceEmail));

    const validEmails = emails.filter((_: string, index: number) => emailsValidity[index]);
    const invalidEmails = emails.filter((_: string, index: number) => !emailsValidity[index]);

    setInvalidEmails(invalidEmails);

    if (validEmails.length > 0) {
      setValidEmails(validEmails);
      toast("vanilla", {
        subject: `${pluralize("Invitation", validEmails.length)} sent`,
      });

      setShowConfetti(true);

      await environment.api.referUsers({
        emails: validEmails,
        organizationId: ownerOrganizationId,
      });
    }

    form.reset();
    setSendingInvites(false);
  }

  return (
    <>
      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl mr-3">Referrals</h1>
      </MainLayout.Header>
      <div className="flex flex-col lg-w:flex-row mx-6">
        <div className="pt-4 lg-w:px-6 grow">
          <div className="bg-comms-tan shadow sm-w:rounded-lg">
            <div className="px-4 py-5 sm-w:p-6">
              <div className="flex justify-between">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Invite business via email</h3>
                <button
                  type="button"
                  onClick={() => PreviewEmailState.open()}
                  className="rounded-md px-2.5 py-1.5 text-sm  hover:bg-comms-green hover:text-comms-tan"
                >
                  Preview email
                </button>
              </div>

              <form className="mt-5" onSubmit={handleFormSubmit}>
                <div className="w-full flex flex-col space-y-2">
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  {validEmails.length > 0 && (
                    <small className="text-comms-green">
                      Invites successfully send to to the following {pluralize("email", validEmails.length)}{" "}
                      {validEmails.join(", ")}.
                    </small>
                  )}
                  <input
                    type="text"
                    name="emails"
                    id="emails"
                    required
                    className="block w-full rounded-md border-0 p-4 text-gray-900 shadow-sm ring-1 ring-inset ring-comms-green placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-comms-green sm-w:text-sm sm-w:leading-6"
                    placeholder="Emails"
                  />
                  <small>Separate multiple emails with a comma (,)</small>
                  {invalidEmails.length > 0 && (
                    <small className="text-red-9">
                      Unfortunately we where not able to send {pluralize("referral", invalidEmails.length)} to the
                      following {pluralize("email", invalidEmails.length)} {invalidEmails.join(", ")}. Comms only
                      supports Google Workspace emails.
                    </small>
                  )}
                </div>
                <button
                  type="submit"
                  className="w-full sm-w:w-fit px-6 py-2 hover:cursor-pointer bg-comms-green hover:opacity-90 text-comms-tan mr-auto block mt-2 rounded-md"
                >
                  {sendingInvites ? "Sending invites..." : "Invite"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="pt-4 ">
          <div className="bg-comms-tan shadow sm-w:rounded-lg">
            <div className="px-4 py-5 sm-w:p-6 space-y-4">
              <h3 className="text-base font-semibold leading-6 text-gray-900">How it works</h3>
              <HowItWorksSteps />
            </div>
          </div>
        </div>
      </div>
      <PreviewEmailDialog />
      {showConfetti && (
        <div className="pointer-events-none w-screen h-screen absolute top-0 left-0 z-[2000]">
          <Confetti width={width} height={height} recycle={false} tweenDuration={5000} gravity={0.1} />
        </div>
      )}
    </>
  );
};
