import { ComponentType, forwardRef, useEffect, useMemo, useState } from "react";
import { fuzzyMatchEntries } from "../../tiptap/suggestion-utils";
import { BaseEntry, BaseSuggestionsDropdown, ISuggestionEntryProps, WithIndexAndGroup } from "./dropdown";
import commandScore from "command-score";
import { Portal } from "~/components/Portal";
import { useAsRef } from "~/hooks/useAsRef";
import { useFloating, autoUpdate, ReferenceType } from "@floating-ui/react";
import {
  SuggestionDropdownContext,
  useSuggestionDropdownContext,
  useCreateSuggestionDropdownContext,
  TSuggestionDropdownContext,
} from "./context";

export const SuggestionsDropdownProvider: ComponentType = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const { refs, floatingStyles, update } = useFloating<HTMLDivElement>({
    placement: "right-start",
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const context = useCreateSuggestionDropdownContext({
    floatingElRef: refs.floating,
    isOpen,
    setIsOpen,
    setIsSearchFocused,
  });

  useUpdateReferenceDivPosition({ refs, context, updatePosition: update });

  return (
    <SuggestionDropdownContext.Provider value={context}>
      {children}
      <div ref={refs.setReference} id="com-test" />
      {isSearchFocused && (
        <Portal>
          <SuggestionsDropdown ref={refs.setFloating} isOpen={isOpen} style={floatingStyles} />
        </Portal>
      )}
    </SuggestionDropdownContext.Provider>
  );
};

function useUpdateReferenceDivPosition(props: {
  refs: {
    reference: React.MutableRefObject<ReferenceType | null>;
    floating: React.MutableRefObject<ReferenceType | null>;
  };
  context: TSuggestionDropdownContext;
  updatePosition: () => void;
}) {
  const { refs, context, updatePosition } = props;
  const updateRef = useAsRef(updatePosition);

  useEffect(() => {
    if (!refs.reference.current) return;
    const div = refs.reference.current as HTMLDivElement;

    div.style.position = "absolute";

    const subscription = context.position$.subscribe((position) => {
      const floatingRef = refs.floating.current;
      if (!floatingRef) return;

      const width = floatingRef.getBoundingClientRect().width;
      if (width === 0) return;

      let left = position.left;
      if (position.left + width > window.innerWidth) {
        left = window.innerWidth - width;
      }

      div.style.top = `${position.top + 10}px`;
      div.style.left = `${left}px`;
      updateRef.current();
    });

    return () => subscription.unsubscribe();
  }, [refs.reference, refs.floating, context.position$, updateRef]);
}

const SuggestionsDropdown = forwardRef<HTMLDivElement, { isOpen: boolean; style: React.CSSProperties }>(
  ({ isOpen, style }, forwardedRef) => {
    const context = useSuggestionDropdownContext();
    const props = context.useSuggestionDropdownProps();
    const word = props?.state.context?.word || "";

    const suggestions = useMemo(() => {
      if (word.length === 0) {
        return filterSuggestions.map((o, index) => ({
          ...o,
          id: o.name,
          index,
          group: "Filters",
        }));
      }

      return fuzzyMatchEntries({
        entries: filterSuggestions,
        query: word,
        entryScore: (o, query) => commandScore(o.name, query),
      }).map((o, index) => ({
        ...o,
        id: o.name,
        index,
        group: "Filters",
      }));
    }, [word]);

    return (
      <BaseSuggestionsDropdown
        ref={forwardedRef}
        items={suggestions}
        suggestionEntryComponentMap={{ Filters: FilterEntry }}
        onSelect={(item) => {
          props?.onSelect({
            name: item.name,
            hint: item.hint,
            receivesNestedQuery: item.receivesNestedQuery,
            selectHint: item.selectHint,
          });
        }}
        close={() => {
          context.setIsOpen(false);
        }}
        isOpen={isOpen && suggestions.length > 0 && word.length > 0}
        style={style}
      />
    );
  },
);

export type TFilterSuggestion = WithIndexAndGroup<{ id: string; name: string; description: string }, "Filters">;

const FilterEntry: ComponentType<ISuggestionEntryProps<TFilterSuggestion>> = (props) => {
  return (
    <BaseEntry
      item={props.entry}
      isSelected={props.selectedIndex === props.entry.index}
      onClick={() => {
        props.selectItem(props.entry.index);
      }}
    >
      <div className="flex flex-col">
        <span className="inline-flex items-center shrink whitespace-nowrap overflow-hidden px-3">
          <span className="truncate">{props.entry.name}</span>
        </span>

        <span className="inline-flex items-center shrink whitespace-nowrap overflow-hidden px-3">
          <span className="truncate text-slate-9">{props.entry.description}</span>
        </span>
      </div>
    </BaseEntry>
  );
};

export const filterSuggestions = [
  {
    name: "from:",
    description: "message sender",
    hint: "@",
  },
  {
    name: "to:",
    description: "message recipient",
    hint: "@",
  },
  {
    name: "group:",
    description: "group that the message is in",
    hint: "@",
  },
  // {
  //   name: "mentions:",
  //   description: "people or groups mentioned in the message",
  //   hint: "@",
  // },
  {
    name: "is:private",
    description: "private messages",
  },
  // {
  //   name: "is:shared",
  //   description: "shared messages (not private)",
  // },
  {
    name: "is:branch",
    description: "branched threads",
  },
  {
    name: "is:reply",
    description: "is reply",
  },
  {
    name: "after:",
    description: "sent at or after date",
    hint: `yyyy-mm-dd`,
    selectHint: true,
  },
  {
    name: "before:",
    description: "sent before date",
    hint: `yyyy-mm-dd`,
    selectHint: true,
  },
  {
    name: "has:attachments",
    description: "has attachments",
  },
  // {
  //   name: "body:",
  //   description: "message body content includes",
  //   hint: `"quoted text"`,
  //   selectHint: true,
  // },
  // {
  //   name: "subject:",
  //   description: "message subject includes",
  //   hint: `"quoted text"`,
  //   selectHint: true,
  // },
  // {
  //   name: "or",
  //   description: "OR nested query",
  //   receivesNestedQuery: true,
  //   hint: "replace with nested query",
  //   selectHint: true,
  // },
  // {
  //   name: "and",
  //   description: "AND nested query",
  //   receivesNestedQuery: true,
  //   hint: "replace with nested query",
  //   selectHint: true,
  // },
  {
    name: "not",
    description: "NOT nested query",
    receivesNestedQuery: true,
    hint: "replace with nested query",
    selectHint: true,
  },
];
