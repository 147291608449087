import { FetchStrategy } from "~/environment/RecordLoader";
import { useRecordLoader } from "./useRecordLoader";

export function useUsersOrganizationIds(props: { userId: string | null | undefined; fetchStrategy?: FetchStrategy }) {
  const { userId, fetchStrategy } = props;

  return useRecordLoader({
    name: "useUsersOrganizationIds",
    load({ loader, limit, deps: [userId, fetchStrategy] }) {
      if (!userId) {
        return loader.createObserveQueryResult<"organization_user_member">();
      }

      return loader.observeGetOrganizationUserMembers(
        {
          user_id: userId,
          limit,
        },
        { fetchStrategy },
      );
    },
    deps: [userId, fetchStrategy],
    initialLimit: 25,
    limitStep: 25,
    map: (records) => records.map((record) => record.organization_id),
  });
}
