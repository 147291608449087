import dayjs from "dayjs";
import { startWith } from "libs/rxjs-operators";
import { useObservable, useObservableState } from "observable-hooks";
import { distinctUntilChanged, interval, map, of, switchMap } from "rxjs";

/**
 * Returns a string indicating how far "from now" the provided timestamp is. This string
 * will be reevaluated every 10s by default.
 *
 * @returns string formatted with `dayjs(timestamp).fromNow()`
 */
export function useTimeFromNow(props: { timestamp: string | null | undefined; reevaluateMs?: number }) {
  const { timestamp, reevaluateMs = 10_000 } = props;

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([timestamp, reevaluateMs]) =>
          !timestamp
            ? of(null)
            : interval(reevaluateMs).pipe(
                startWith(() => null),
                map(() => (timestamp ? dayjs(timestamp).fromNow() : null)),
              ),
        ),
        distinctUntilChanged(),
      ),
    [timestamp, reevaluateMs],
  );

  return useObservableState(observable, () => (timestamp ? dayjs(timestamp).fromNow() : null));
}
