import { ClientEnvironment } from "./ClientEnvironment";
import { MessagePortService, Request } from "./MessagePortService";
import { PersistedDatabaseWorkerApi } from "./persisted-database-worker/PersistedDatabaseWorkerApi";
import { NEVER, catchError, from, interval, switchMap } from "rxjs";
import { promiseTimeout } from "libs/promise-utils";
import { TimeoutError } from "libs/errors";

export class PersistedDatabaseServiceProvider {
  static createService(
    env: Pick<ClientEnvironment, "logger">,
    props: {
      isConnectionActive: Promise<unknown>;
      connection: MessagePortService;
    },
  ) {
    const provider = new PersistedDatabaseServiceProvider(env, props);
    const service = provider.createService();
    return service;
  }

  private connection: MessagePortService;

  private constructor(
    protected env: Pick<ClientEnvironment, "logger">,
    props: {
      isConnectionActive: Promise<unknown>;
      connection: MessagePortService;
    },
  ) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "PersistedDatabaseService" }),
    };

    this.connection = props.connection;

    // Here we poll the persisted database and if we don't here back within a reasonable amount of time we
    // disconnect and then reconnect.
    from(props.isConnectionActive)
      .pipe(
        switchMap(() => this.connection.recipients$),
        switchMap((recipients) => {
          if (!recipients.has(MessagePortService.uniqueContexts.PERSISTED_DB_WORKER)) {
            return NEVER;
          }

          return interval(20_000).pipe(
            switchMap(() => promiseTimeout(15_000, this.getQuery({ prop: "getSchemaVersion", args: [] }))),
            catchError((error) => {
              if (error instanceof TimeoutError) {
                this.env.logger.error({ error }, "Failed to get schema version. Reconnecting to persisted db...");

                this.connection.disconnect({
                  recipientId: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
                });
              }

              return NEVER;
            }),
          );
        }),
      )
      .subscribe();
  }

  createService() {
    return new Proxy({} as PersistedDatabaseWorkerApi, {
      get: (_, prop: string) => {
        if (prop === "then") return undefined;
        if (prop === "toJSON") {
          return () => Promise.reject(new Error("Method toJSON called on PersistedDatabaseService"));
        }

        return (...args: any[]) => {
          if (prop.startsWith("observe")) {
            return this.observeQuery({ prop, args });
          }

          return this.getQuery({ prop, args });
        };
      },
    });
  }

  private getQuery(props: Request<"PERSISTED_DB_QUERY">["data"]) {
    return this.connection.sendRequest(
      "PERSISTED_DB_QUERY",
      {
        to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
        data: props,
      },
      { retryOnDisconnect: true },
    );
  }

  private observeQuery(props: Request<"PERSISTED_DB_OBSERVE_QUERY">["data"]) {
    return this.connection.observeRequest(
      "PERSISTED_DB_OBSERVE_QUERY",
      {
        to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
        data: props,
      },
      { retryOnDisconnect: true },
    );
  }
}

/* -----------------------------------------------------------------------------------------------*/
