import { op, Operation } from "libs/transaction";
import { generateRecordId, getPointer, RecordValue, ThreadType } from "../schema";
import { pick } from "lodash-comms";
import { isDefined } from "../predicates";

export type AddMinimalDoneNotificationForThreadIfOneDoesNotExistProps = {
  userId: string;
  threadId: string;
  threadType: ThreadType;
  messageId: string;
  messageSentAt: string;
  ownerOrganizationId: string;
};

/**
 * If we need a user to have a notification for a thread for technical purposes,
 * use this method. If conditionally creates a "done" notification for a user if
 * they don't already have one for the thread and gives the notification the lowest
 * priority possible. The notification is created with "is_deleted" set to false and
 * no tag_ids.
 */
export function addMinimalDoneNotificationForThreadIfOneDoesNotExist(
  props: AddMinimalDoneNotificationForThreadIfOneDoesNotExistProps,
): Operation[] {
  const pointer = getPointer("notification", {
    user_id: props.userId,
    thread_id: props.threadId,
  });

  return [
    op.upsert(pointer, {
      onCreate: [
        op.set(pointer.table, {
          id: pointer.id,
          thread_id: props.threadId,
          user_id: props.userId,
          thread_type: props.threadType,
          message_id: props.messageId,
          tag_ids: [],
          sent_at: props.messageSentAt,
          is_done: true,
          done_at: op.fieldValue.SERVER_TIMESTAMP(),
          priority: 500,
          done_last_modified_by: "delivery",
          oldest_message_not_marked_done_message_id: null,
          oldest_message_not_marked_done_sent_at: null,
          has_reminder: false,
          remind_at: null,
          is_starred: false,
          starred_at: null,
          owner_organization_id: props.ownerOrganizationId,
          is_delivered: false,
          delivered_at: null,
        }),
      ],
    }),
  ];
}

/* -----------------------------------------------------------------------------------------------*/

export type TriageNotificationProps = {
  notification: Pick<
    RecordValue<"notification">,
    | "id"
    | "user_id"
    | "thread_id"
    | "oldest_message_not_marked_done_message_id"
    | "oldest_message_not_marked_done_sent_at"
    | "sent_at"
    | "message_id"
    | "owner_organization_id"
  >;
  thread: Pick<
    RecordValue<"thread">,
    "last_message_id" | "last_message_timeline_order" | "last_message_sent_at"
  > | null;
  lastMessage: Pick<RecordValue<"message">, "id" | "timeline_order" | "sent_at"> | null;
  currentTimestamp: string;
  isDone?: boolean;
  remindAt?: Date | null;
  isStarred?: boolean;
};

export function triageNotification(props: TriageNotificationProps): Operation[] {
  if (!isDefined(props.isDone) && !isDefined(props.remindAt) && !isDefined(props.isStarred)) {
    throw new Error("[triageNotification] missing prop to triage.");
  }

  const { notification, thread, lastMessage } = props;

  const operations: Operation[] = [];

  const notificationUpdate: Partial<
    Pick<
      RecordValue<"notification">,
      | "is_done"
      | "done_at"
      | "done_last_modified_by"
      | "oldest_message_not_marked_done_message_id"
      | "oldest_message_not_marked_done_sent_at"
      | "has_reminder"
      | "remind_at"
      | "is_starred"
      | "starred_at"
    >
  > = {};

  if (isDefined(props.isDone)) {
    notificationUpdate.is_done = props.isDone;
    notificationUpdate.done_at = props.isDone ? props.currentTimestamp : null;
    notificationUpdate.done_last_modified_by = "user";

    notificationUpdate.oldest_message_not_marked_done_message_id = props.isDone
      ? null
      : notification.oldest_message_not_marked_done_message_id || notification.message_id;

    notificationUpdate.oldest_message_not_marked_done_sent_at = props.isDone
      ? null
      : notification.oldest_message_not_marked_done_sent_at || notification.sent_at;
  }

  if (isDefined(props.remindAt)) {
    notificationUpdate.has_reminder = !!props.remindAt;
    notificationUpdate.remind_at = props.remindAt?.toISOString() || null;
  }

  if (isDefined(props.isStarred)) {
    notificationUpdate.is_starred = props.isStarred;
    notificationUpdate.starred_at = props.isStarred ? props.currentTimestamp : null;
  }

  if (
    // If the user lost permission to access the thread, then thread and
    // lastMessage will be null. In this case, we don't mark the thread as
    // seen/read.
    thread &&
    notificationUpdate.is_done &&
    // setting a reminder shouldn't mark a thread as "read".
    !notificationUpdate.has_reminder
  ) {
    // When marking a notification as done we can expect to have the thread record already loaded
    // (i.e. performance will be snappy). We may or may not have thread messages loaded. There's an
    // edge case where someone sends a new message and then immediately marks the thread as done.
    // In this case the thread record will not have it's last_message_* values updated yet since
    // the server handles that. However, if the user just sent a message than we will have that
    // message in the local cache.
    const isThreadRecordMoreUpToDateThanLastMessage =
      !lastMessage || thread.last_message_timeline_order > lastMessage.timeline_order;

    const lastMessageData = isThreadRecordMoreUpToDateThanLastMessage
      ? pick(thread, ["last_message_id", "last_message_timeline_order", "last_message_sent_at"])
      : {
          last_message_id: lastMessage.id,
          last_message_timeline_order: lastMessage.timeline_order,
          last_message_sent_at: lastMessage.sent_at,
        };

    operations.push(
      op.set("thread_read_receipt", {
        id: generateRecordId("thread_read_receipt", {
          thread_id: notification.thread_id,
          user_id: notification.user_id,
        }),
        owner_organization_id: notification.owner_organization_id,
        thread_id: notification.thread_id,
        user_id: notification.user_id,
        read_to_timeline_id: lastMessageData.last_message_id,
        read_to_timeline_order: lastMessageData.last_message_timeline_order,
      }),

      op.set("thread_seen_receipt", {
        id: generateRecordId("thread_seen_receipt", {
          thread_id: notification.thread_id,
          user_id: notification.user_id,
        }),
        owner_organization_id: notification.owner_organization_id,
        thread_id: notification.thread_id,
        user_id: notification.user_id,
        seen_to_timeline_id: lastMessageData.last_message_id,
        seen_to_timeline_order: lastMessageData.last_message_timeline_order,
      }),
    );
  }

  operations.push(op.update({ table: "notification", id: notification.id }, notificationUpdate));

  return operations;
}

/* -----------------------------------------------------------------------------------------------*/
