import { htmlToText } from "libs/htmlToText";
import { PointerWithRecord } from "libs/schema";
import { ComponentType, useMemo, useRef } from "react";
import { RiGitBranchLine } from "react-icons/ri";
import { EntryTimestamp, PrivateEntryIcon, Recipients, Summary } from "~/components/content-list/layout";
import { IListOnEntryActionEvent, List } from "~/components/list";
import { isModKeyActive } from "~/environment/command.service";
import { useDraft } from "~/hooks/useDraft";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { collapsedTimelineEntryCSS } from "./util";
import { useTimelineEntryElementId } from "~/hooks/useTimelineEntryId";
import { cx } from "@emotion/css";

export const BranchedDraftEntry: ComponentType<{
  draftId: string;
  relativeOrder: number;
}> = (props) => {
  const [draft] = useDraft(props.draftId);

  const bodyText = useMemo(() => {
    return htmlToText(draft?.body_html || "");
  }, [draft?.body_html]);

  const entryData = useMemo(() => {
    if (!draft) return null;
    return { table: "draft" as const, id: draft.id, record: draft };
  }, [draft]);

  const listEntryRef = useRef<HTMLDivElement>(null);

  const elementId = useTimelineEntryElementId({
    id: draft?.id,
    thread_id: draft?.branched_from_thread_id,
  });

  const doNotRender = !draft || !entryData || !elementId;

  if (doNotRender) return null;

  const isPrivateThread = draft.new_thread_visibility === "PRIVATE";

  return (
    <List.Entry<PointerWithRecord<"draft">>
      id={draft.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
      onEntryAction={onBranchedDraftEntrySelectNavigateToDraft}
    >
      <div ref={listEntryRef} id={elementId} className={cx(collapsedTimelineEntryCSS, "BranchedDraft")}>
        <Recipients
          nonTruncatedPrefix={<RiGitBranchLine size="1.5rem" className="mr-2 shrink-0" />}
          nonTruncatedSuffix={<>{isPrivateThread && <PrivateEntryIcon />}</>}
          className="font-bold text-green-9"
        >
          Draft
        </Recipients>

        <Summary subject={draft.new_thread_subject} details={isPrivateThread ? "private message" : bodyText} />

        <EntryTimestamp datetime={draft.created_at} />
      </div>
    </List.Entry>
  );
};

function onBranchedDraftEntrySelectNavigateToDraft({
  entry,
  event,
}: IListOnEntryActionEvent<PointerWithRecord<"draft">>) {
  if (isModKeyActive(event)) {
    openComposeNewThreadDialog(entry.id, {
      inNewWindow: true,
    });

    return;
  }

  openComposeNewThreadDialog(entry.id);
}

/* -----------------------------------------------------------------------------------------------*/
