import { Logger } from "libs/logger";
import { RecordMap, RecordTable, RecordValue, assignToRecordMap, tableProps } from "libs/schema";
import { lowerFirst } from "lodash-comms";
import { ClientDatabaseApi, ObserveSharedQueryApiKey, OriginalSharedQueryApiKey } from "./ClientDatabaseApi";
import { Query, SharedQueryApi, SharedQueryApiKey } from "libs/database";
import { Sql, sql } from "libs/sql-statement";
import { areDecoderErrors } from "ts-decoders";
import { fromDatabaseDecoders } from "libs/schema/client/decoders";
import { ClientDatabaseAdapterApi } from "./ClientDatabaseAdapterApi";
import { ApiInput } from "../../ApiTypes";

export function createClientDatabase(props: { adapter: ClientDatabaseAdapterApi; logger: Logger }): ClientDatabaseApi {
  const { adapter, logger } = props;

  const sharedQueryApi = createClientSharedQueryApi({ logger });

  const observeRecord = adapter.observeRecord.bind(adapter);
  const observeRecords = adapter.observeRecords.bind(adapter);
  const transaction = <T>(fn: (tx: ClientDatabaseApi) => T): T => {
    return adapter.transaction((adapterTx) => {
      const tx = createClientDatabase({
        adapter: adapterTx,
        logger,
      });

      return fn(tx);
    });
  };

  return new Proxy(adapter as unknown as ClientDatabaseApi, {
    get(target, prop: keyof ClientDatabaseApi, receiver) {
      if (prop === "transaction") {
        return transaction;
      }

      if (prop in target) {
        return Reflect.get(target, prop, receiver);
      }

      if (prop in sharedQueryApi) {
        return (...params: Parameters<SharedQueryApi[SharedQueryApiKey]>) => {
          const method = sharedQueryApi[prop as SharedQueryApiKey] as any;

          const query = method.apply(sharedQueryApi, params) as Query<RecordTable>;

          return adapter.getQuery(query);
        };
      }

      if (prop === "getBatchedQueries") {
        return (batch: ApiInput<"getBatchedQueries">["batch"]) => {
          const recordMap = transaction((tx) => {
            const recordMap: RecordMap = {};

            for (const query of batch) {
              const method = tx[query.type];

              if (!method) {
                throw new Error(`[ClientDatabase] unknown method ${query.type}`);
              }

              const [_, { recordMap: resultRecordMap }] = method(query.params as any);

              assignToRecordMap(recordMap, resultRecordMap);
            }

            return recordMap;
          });

          return { recordMap };
        };
      }

      if (prop === "observeGetRecord") {
        return observeRecord;
      }

      if (prop === "observeGetRecords") {
        return observeRecords;
      }

      if (prop.startsWith("observe")) {
        const originalMethod = lowerFirst(prop.slice(7)) as OriginalSharedQueryApiKey<ObserveSharedQueryApiKey>;

        return (...params: Parameters<SharedQueryApi[typeof originalMethod]>) => {
          const method = sharedQueryApi[originalMethod] as any;

          if (!method) {
            logger.error({ prop, originalMethodName: originalMethod }, "ClientDatabase: unknown method");
            throw new Error(`ClientDatabase: unknown method "${prop}"`);
          }

          const query = method.apply(sharedQueryApi, params) as Query<RecordTable>;

          return adapter.observeQuery(query);
        };
      }
    },
  });
}

/* -----------------------------------------------------------------------------------------------*/

export function createClientSharedQueryApi(props: { logger: Logger }) {
  const { logger } = props;

  return new SharedQueryApi({
    engine: "SQLITE",
    logger,
    namespaceAllTableColumnsWithNullValues,
    decodeRecord: <T extends RecordTable>(table: T, row: Record<string, any>): RecordValue<T> => {
      const decoder = fromDatabaseDecoders[table];

      if (!decoder) {
        throw new Error(`No decoder found for table "${table}"`);
      }

      const result = decoder.decode(row);

      if (areDecoderErrors(result)) {
        logger.error(result, "Error parsing record from database");
        throw new Error(`Error parsing "${table}" record from database`);
      }

      return result.value as RecordValue<T>;
    },
  });
}

/* -----------------------------------------------------------------------------------------------*/

function namespaceAllTableColumnsWithNullValues<T extends RecordTable>(
  /**
   * An array of the table names in the result. If a table in the
   * result has been renamed, the entry here will be an object like
   * `{ table: T; as: string }`
   */
  ...tables: Array<T | { table: T; as: string }>
): Sql {
  return sql.join(
    tables.flatMap((arg) => {
      const select = typeof arg === "object" ? arg : { table: arg, as: arg };

      return tableProps[select.table].map((prop) => sql.raw(`null as "${select.as}__${prop}"`));
    }),
    ",\n",
  );
}

/* -----------------------------------------------------------------------------------------------*/
