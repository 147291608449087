import { ComponentType, useRef } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { showNotImplementedToastMsg, toast } from "~/environment/toast-service";
import { useRegisterCommands } from "~/environment/command.service";
import {
  deleteDraftCommand,
  ESCAPE_TO_INBOX_COMMAND,
  setThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { useDrafts } from "~/hooks/useDrafts";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { DraftEntry, onDraftsEntrySelect } from "~/components/content-list/DraftEntry";
import { PointerWithRecord } from "libs/schema";
import { deleteDraft } from "~/actions/draft";
import { EndOfListMsg } from "~/components/EndOfListMsg";
import { useListPaging } from "~/hooks/useListPaging";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { withOfflineFirstIfSynced } from "~/components/withOfflineFirstIfSynced";
import { GetOptions } from "~/environment/RecordLoader";

/* -------------------------------------------------------------------------------------------------
 * DraftsView
 * -----------------------------------------------------------------------------------------------*/

export const DraftsView: ComponentType<{}> = withOfflineFirstIfSynced(() => {
  const scrollboxRef = useRef<HTMLElement>(document.body);

  const [draftIds, { nextId, isLoading, fetchMore }] = useDrafts();

  const noMoreDrafts = !nextId && !isLoading;

  useListPaging({
    fetchMore,
    isLoading,
    isListEnd: noMoreDrafts,
    pagingScrollboxRef: scrollboxRef,
  });

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<PointerWithRecord<"draft">>();

  useRegisterDraftViewCommands(useFocusedEntry);

  const headerRef = useApplyScrollShadowToHeader();

  return (
    <>
      <Helmet>
        <title>Drafts | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl">Drafts</h1>
      </MainLayout.Header>

      <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
        {draftIds.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList onEntryFocused={setFocusedEntry} onEntryAction={onDraftsEntrySelect} className="mb-20" autoFocus>
            {draftIds.map((draftId, index) => (
              <DraftEntry key={draftId} draftId={draftId} relativeOrder={index} />
            ))}

            <EndOfListMsg isEnd={noMoreDrafts} />
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
});

/* -----------------------------------------------------------------------------------------------*/

function useApplyScrollShadowToHeader() {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return headerRef;
}

/* -------------------------------------------------------------------------------------------------
 * useRegisterDraftViewCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterDraftViewCommands(useFocusedDraft: () => PointerWithRecord<"draft"> | null) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const focusedDraft = useFocusedDraft();

  useRegisterCommands({
    commands: () => {
      return [
        ESCAPE_TO_INBOX_COMMAND,
        deleteDraftCommand({
          callback: () => {
            if (!focusedDraft) {
              // if the user has no draft focused, but try to delete,
              // just tell them what they need to do to delete.
              toast("vanilla", {
                subject: "Oops, no draft is focused.",
                description: `
                    You first need to focus a draft by hovering your mouse  
                    over it or by using the arrow keys on your keyboard.
                  `,
              });
            } else {
              deleteDraft(environment, {
                draftId: focusedDraft.id,
                currentUserId,
              });
            }
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the drafts page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star drafts.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star drafts.
            `);
          },
        }),
      ];
    },
    deps: [focusedDraft, currentUserId, environment],
  });
}

/* -----------------------------------------------------------------------------------------------*/
