import { FetchStrategy } from "~/environment/QueryCache";
import { useRecordLoader } from "./useRecordLoader";

export function useUserAuthTokens(props: { userId: string; fetchStrategy?: FetchStrategy }) {
  const { userId, fetchStrategy } = props;

  return useRecordLoader({
    name: "useUserAuthTokens",
    load({ loader, deps: [fetchStrategy] }) {
      return loader.observeGetUserAuthTokens(
        {
          userId,
        },
        { fetchStrategy },
      );
    },
    deps: [fetchStrategy],
    map: (records) => records.map((record) => record),
  });
}
