import { stripIndent } from "common-tags";
import {
  ForwardedRef,
  forwardRef,
  ReactElement,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { BehaviorSubject, debounceTime, filter, fromEvent, map, Observable, Subject, take, throttleTime } from "rxjs";
import useConstant from "use-constant";
import {
  EntryId,
  ListContext,
  IListContext,
  IListEntry,
  IListOnEntryActionEvent,
  IListOnEntryFocusEvent,
} from "./context";
import { Slot } from "@radix-ui/react-slot";
import { elementPositionInContainer, scrollContainerToTopOfElement, domNodeComparer } from "~/utils/dom-helpers";
import { Writable } from "type-fest";
import { getScrollTop, scrollElementTo } from "~/utils/dom-helpers";
import { UnreachableCaseError } from "libs/errors";
import { useAsRef } from "~/hooks/useAsRef";
import { IUpdatableBehaviorSubject, updatableBehaviorSubject } from "libs/updatableBehaviorSubject";

/* -------------------------------------------------------------------------------------------------
 * List
 * -----------------------------------------------------------------------------------------------*/

export interface IListProps<EntryData> {
  /**
   * In "focus" mode, the default, list entries are actually
   * focused as a user moves through the list. In
   * "active-descendent" mode, the `<List />` component tracks
   * which entry is "active" but that entry is not actually focused
   * in the DOM. This may be desirable if, for example, you want an
   * input component to remain focused.
   */
  mode?: IListContext<EntryData>["mode"];
  initiallyFocusableOrActiveEntryId?: EntryId;
  /**
   * Automatically focus `initiallyFocusableEntryId` on mount.
   * Does nothing in "active-descendent" mode.
   */
  autoFocus?: boolean;
  /**
   * Whether or not to focus an entry on mouse over. In
   * "active-descendent" mode the entry will be made active on
   * mouseover.
   */
  focusEntryOnMouseOver?: boolean;
  /**
   * Whether or not to try and maintain focus on the initially focused element for the
   * provided number of milliseconds or until the user interacts with the page (whichever
   * comes first). Useful for lists where content may load incrementally and out of order
   * so focusing the initial element too early threatens to be subject to "layout shift"
   * if additional content loads above that element in the list.
   */
  maintainInitialFocusForTimeMs?: number;
  /**
   * Called when the user presses the "ArrowUp" key when they
   * are already at the top of a list.
   *
   * If this is `undefined`, then pressing "ArrowUp" will focus
   * the last entry in the list. Providing a custom
   * onArrowUpOverflow fn overwrites that behavior. Providing
   * `null` just cancels that behavior.
   */
  onArrowUpOverflow?: ((e: KeyboardEvent) => void) | null;
  /**
   * Called when the user presses the "ArrowDown" key when they
   * are already at the bottom of a list.
   *
   * If this is `undefined`, then pressing "ArrowDown" will focus
   * the first entry in the list. Providing a custom
   * onArrowDownOverflow fn overwrites that behavior. Providing
   * `null` just cancels that behavior.
   */
  onArrowDownOverflow?: ((e: KeyboardEvent) => void) | null;
  onArrowLeft?(): void;
  onArrowRight?(): void;
  /**
   * Called when a user clicks on a list entry or when they focus
   * a list entry and press the "Enter" key.
   */
  onEntryAction?(args: IListOnEntryActionEvent<EntryData>): void;
  onEntryFocusIn?(args: IListOnEntryFocusEvent<EntryData>): void;
  onEntryFocusLeave?(args: IListOnEntryFocusEvent<EntryData>): void;

  children: ReactElement;
}

export interface IListRef<EntryData> {
  entries: ReadonlyArray<IListEntry<EntryData>>;
  entries$: Observable<ReadonlyArray<IListEntry<EntryData>>>;
  selectedEntryIds: ReadonlySet<EntryId>;
  /**
   * Not that while selectedEntryIds will emit when there are changes,
   * the object it emits is the same each time. For this reason,
   * distinctUntilChanged won't work since it compares the object references.
   */
  selectedEntryIds$: Observable<ReadonlySet<EntryId>>;
  /**
   * ### In "focus" mode,
   * This observable tracks which entry in the list is currently focusable.
   * If there are no entries or if all entries are disabled, returns `null`.
   *
   * The current "focusable" entry is marked with `tabindex="0"` in the DOM
   * while all other entries have `tabindex="-1"`. This allows the user to
   * tab away from this list and then tab back and have the correct element
   * focused.
   *
   * ### In "active-descendent" mode
   * This observable tracks which entry is active.
   */
  focusableOrActiveEntryId$: IListContext<EntryData>["focusableOrActiveEntryId$"];
  /**
   * ### In "focus" mode
   * This observable tracks which entry in the list is currently focused, if any.
   * This observable can also be used to move focus to the provided `EntryId`.
   *
   * _Note:_
   * _Doesn't support passing `null` in order to blur a currently focused entry._
   *
   * ### In "active-descendent" mode
   * This observable always returns `null`.
   */
  focusedEntryId$: IListContext<EntryData>["focusedEntryId$"];
  /**
   * ### In "focus" mode
   * Returns the currently focusable entry or `null`.
   *
   * ### In "active-descendent" mode
   * Returns the currently active entry or `null`.
   */
  focusableOrActiveEntry(): IListEntry<EntryData> | null;
  /**
   * ### In "focus" mode
   * Returns the currently focused entry or `null`.
   *
   * ### In "active-descendent" mode
   * Returns `null`.
   */
  focusedEntry(): IListEntry<EntryData> | null;
  /**
   * If called without an ID, will focus the current focusableEntry.
   * If called with an ID, will focus the entry with that ID.
   */
  focus(id?: EntryId): void;
  /** Adds the specified entryId to the selectedEntryIds set. */
  select(id: EntryId): void;
  /** Removes the specified entryId to the selectedEntryIds set. */
  deselect(id: EntryId): void;
  deselectAll(): void;
  /**
   * Re-sort the list entries by comparing their position in the dom.
   * Generally this is automatically handled as items are added/removed,
   * but this needs to be manually called when items in the list are
   * being reordered.
   */
  sort(): void;
}

/**
 * ### NOTE
 * The jsdoc comment which is displayed in VSCode for
 * consumers of this component is defined in the `./index.ts`
 * file. That comment is reproduced here for clarity. Changes
 * to this jsdoc comment should be made in both places.
 * ###
 *
 * Keyboard Accessible List component which allows focusing entries via
 * ArrowUp and ArrowDown. At any given time, only one entry in the list
 * can be focusable (`tabindex=0`) while other entries are not focusable
 * (`tabindex=-1`). As a user uses arrow keys to change focus, this
 * component handles updating the currently focusable element and
 * transitioning focus from one element to the next. Because only one
 * element in the list has `tabindex=0`, we get tab-to-focus functionality
 * for free.
 *
 * Example:
 *
 * ```ts
 * <List<IPostDoc>>
 *   <ul>
 *     {posts.map((post) => (
 *       <li>
 *         <List.Entry<IPostDoc>
 *           key={post.id}
 *           id={post.id}
 *           data={post}
 *         >
 *           <Post post={post} />
 *         </List.Entry>
 *       </li>
 *     ))}
 *   </ul>
 * </List>
 * ```
 */
// This is a type hack to support a generic component while using `forwardRef`.
// See https://stackoverflow.com/a/58473012/5490505
export const List = forwardRef(_List) as <EntryData>(
  props: IListProps<EntryData> & { ref?: Ref<IListRef<EntryData>> },
) => ReactElement;

function _List<EntryData>(props: IListProps<EntryData>, ref: ForwardedRef<IListRef<EntryData>>) {
  const listMode = props.mode || "focus";

  const containerRef = useRef<HTMLElement>(null);

  const entries$ = useConstant(() => updatableBehaviorSubject<IListEntry<EntryData>[]>([]));

  const selectedEntryIds$ = useConstant(() => updatableBehaviorSubject<Set<EntryId>>(new Set()));

  const focusableOrActiveEntryId$: IListContext<EntryData>["focusableOrActiveEntryId$"] = useConstant(
    () => new BehaviorSubject<EntryId | null>(null),
  );

  const focusedEntryId$: IListContext<EntryData>["focusedEntryId$"] = useConstant(
    () => new BehaviorSubject<EntryId | null>(null),
  );

  const resortEntriesEvents$ = useConstant(() => new Subject<void>());

  const { focusEntry, mergeEntry, removeEntry } = useListEntryCallbacks({
    entries$,
    selectedEntryIds$,
    focusableOrActiveEntryId$,
    focusedEntryId$,
  });

  useImperativeListHandle({
    ref,
    entries$,
    selectedEntryIds$,
    focusableOrActiveEntryId$,
    focusedEntryId$,
    focusEntry,
  });

  useHandleResortEntriesEvents({
    resortEntriesEvents$,
    entries$,
  });

  // useFocusInitialEntryOnMount({
  //   mode: props.mode,
  //   entries$,
  //   focusableOrActiveEntryId$,
  //   focusedEntryId$,
  //   initiallyFocusableOrActiveEntryId: props.initiallyFocusableOrActiveEntryId,
  //   autoFocus: props.autoFocus,
  // });

  useHandleListKeyboardEvents({
    containerRef,
    entries$,
    selectedEntryIds$,
    focusableOrActiveEntryId$,
    focusedEntryId$,
    onArrowLeft: props.onArrowLeft,
    onArrowRight: props.onArrowRight,
    onArrowUpOverflow: props.onArrowUpOverflow,
    onArrowDownOverflow: props.onArrowDownOverflow,
  });

  // As a performance optimization, we convert these props
  // to refs so that they don't cause the ListContext to change
  // if they are updated.
  const onEntryActionRef = useAsRef(props.onEntryAction);
  const onEntryFocusInRef = useAsRef(props.onEntryFocusIn);
  const onEntryFocusLeaveRef = useAsRef(props.onEntryFocusLeave);

  const context = useMemo<IListContext<EntryData>>(() => {
    return {
      mode: listMode,
      autoFocus: props.autoFocus || false,
      maintainInitialFocusForTimeMs: props.maintainInitialFocusForTimeMs,
      get entries() {
        return entries$.getValue();
      },
      entries$,
      get selectedEntryIds() {
        return selectedEntryIds$.getValue();
      },
      selectedEntryIds$,
      focusableOrActiveEntryId$,
      focusedEntryId$,
      initiallyFocusableOrActiveEntryId: props.initiallyFocusableOrActiveEntryId || null,
      focusEntryOnMouseOver: props.focusEntryOnMouseOver || false,
      focus: focusEntry,
      mergeEntry,
      removeEntry,
      onEntryAction: onEntryActionRef,
      onEntryFocusIn: onEntryFocusInRef,
      onEntryFocusLeave: onEntryFocusLeaveRef,
      sortEntries() {
        resortEntriesEvents$.next();
      },
      select(id) {
        selectedEntryIds$.update((ids) => ids.add(id));
      },
      deselect(id) {
        selectedEntryIds$.update((ids) => {
          ids.delete(id);
          return ids;
        });
      },
    };
  }, [
    listMode,
    props.autoFocus,
    entries$,
    selectedEntryIds$,
    focusableOrActiveEntryId$,
    focusedEntryId$,
    resortEntriesEvents$,
    props.focusEntryOnMouseOver,
    props.initiallyFocusableOrActiveEntryId,
    focusEntry,
    mergeEntry,
    removeEntry,
    onEntryActionRef,
    onEntryFocusInRef,
    onEntryFocusLeaveRef,
    props.maintainInitialFocusForTimeMs,
  ]);

  return (
    <ListContext.Provider value={context}>
      <Slot ref={containerRef}>{props.children}</Slot>
    </ListContext.Provider>
  );
}

/* -----------------------------------------------------------------------------------------------*/

function useListEntryCallbacks<EntryData>(args: {
  entries$: IUpdatableBehaviorSubject<Array<IListEntry<EntryData>>>;
  selectedEntryIds$: IUpdatableBehaviorSubject<Set<EntryId>>;
  focusableOrActiveEntryId$: IListContext<EntryData>["focusableOrActiveEntryId$"];
  focusedEntryId$: IListContext<EntryData>["focusedEntryId$"];
}) {
  const { entries$, selectedEntryIds$, focusableOrActiveEntryId$, focusedEntryId$ } = args;

  const focusEntry = useCallback(
    (id?: EntryId) => {
      if (focusableOrActiveEntryId$.getValue() === null) return;

      const entries = entries$.getValue();

      if (id !== undefined && id !== focusableOrActiveEntryId$.getValue()) {
        if (!entries.some((entry) => entry.id === id)) return;

        focusableOrActiveEntryId$.next(id);
      }

      focusedEntryId$.next(focusableOrActiveEntryId$.getValue());

      const focusedEntry = entries.find((entry) => entry.id === focusedEntryId$.getValue());

      if (!focusedEntry) return;

      const scrollboxEl = focusedEntry.scrollboxContext.scrollboxRef.current;

      const scrollHeaderOffset = focusedEntry.scrollboxContext.offsetHeaderEl?.current?.offsetHeight || 0;

      if (!scrollboxEl) return;

      scrollContainerToTopOfElement({
        container: scrollboxEl,
        element: focusedEntry.node,
        offset: -scrollHeaderOffset - 60,
      });
    },
    [entries$, focusedEntryId$, focusableOrActiveEntryId$],
  );

  const mergeEntry: IListContext<EntryData>["mergeEntry"] = useCallback(
    (args) => {
      const entries = entries$.getValue();

      const index = entries.findIndex((entry) => entry.id === args.id);

      if (index >= 0) {
        entries[index] = args;
      } else {
        entries.push(args);
        entries.sort((a, b) => domNodeComparer(a.node, b.node));
      }

      if (focusableOrActiveEntryId$.getValue() === null && !args.disabled) {
        focusableOrActiveEntryId$.next(args.id);
      }

      entries$.next(entries);
    },
    [entries$, focusableOrActiveEntryId$],
  );

  const removeEntry: IListContext<EntryData>["removeEntry"] = useCallback(
    (removedEntryId) => {
      const entries = entries$.getValue();

      const index = entries.findIndex((entry) => entry.id === removedEntryId);

      entries.splice(index, 1);

      if (focusableOrActiveEntryId$.getValue() !== removedEntryId) {
        entries$.next(entries);

        selectedEntryIds$.update((ids) => {
          ids.delete(removedEntryId);
          return ids;
        });

        return;
      }

      // Since we just removed the current focusable entry, we
      // try to make the next entry focusable if one exists.

      // using helper function to properly type `entries[index]`
      const getEntryForIndex = (index: number) => entries[index] as (typeof entries)[number] | undefined;

      // The "next entry" is now at the index of the entry we just removed
      let nextFocusableEntryIndex = index;

      let nextFocusableEntry = getEntryForIndex(nextFocusableEntryIndex);

      // skip disabled entries
      while (nextFocusableEntry?.disabled) {
        nextFocusableEntryIndex += 1;
        nextFocusableEntry = getEntryForIndex(nextFocusableEntryIndex);
      }

      if (!nextFocusableEntry) {
        // If there is no focusable "next" entry, try to make a previous
        // entry focusable.

        nextFocusableEntryIndex = index - 1;
        nextFocusableEntry = getEntryForIndex(nextFocusableEntryIndex);

        // skip disabled entries
        while (nextFocusableEntry?.disabled) {
          nextFocusableEntryIndex -= 1;
          nextFocusableEntry = getEntryForIndex(nextFocusableEntryIndex);
        }
      }

      const nextFocusableEntryId = nextFocusableEntry?.id ?? null;

      focusableOrActiveEntryId$.next(nextFocusableEntryId);

      if (focusedEntryId$.getValue() === removedEntryId) {
        focusedEntryId$.next(nextFocusableEntryId);
      }

      entries$.next(entries);

      selectedEntryIds$.update((ids) => {
        ids.delete(removedEntryId);
        return ids;
      });
    },
    [entries$, selectedEntryIds$, focusableOrActiveEntryId$, focusedEntryId$],
  );

  return { focusEntry, mergeEntry, removeEntry };
}

/* -----------------------------------------------------------------------------------------------*/

function useImperativeListHandle<EntryData>(args: {
  ref: ForwardedRef<IListRef<EntryData>>;
  entries$: IUpdatableBehaviorSubject<Array<IListEntry<EntryData>>>;
  selectedEntryIds$: IUpdatableBehaviorSubject<Set<EntryId>>;
  focusableOrActiveEntryId$: IListContext<EntryData>["focusableOrActiveEntryId$"];
  focusedEntryId$: IListContext<EntryData>["focusedEntryId$"];
  focusEntry: (id?: EntryId) => void;
}) {
  useImperativeHandle(args.ref, () => ({
    get entries() {
      return args.entries$.getValue();
    },
    entries$: args.entries$,
    get selectedEntryIds() {
      return args.selectedEntryIds$.getValue();
    },
    selectedEntryIds$: args.selectedEntryIds$,
    focusableOrActiveEntryId$: args.focusableOrActiveEntryId$,
    focusedEntryId$: args.focusedEntryId$,
    focusableOrActiveEntry() {
      const focusableEntryId = args.focusableOrActiveEntryId$.getValue();

      if (focusableEntryId === null) return null;

      return args.entries$.getValue().find((e) => e.id === focusableEntryId) || null;
    },
    focusedEntry() {
      const focusedEntryId = args.focusedEntryId$.getValue();

      if (focusedEntryId === null) return null;

      return args.entries$.getValue().find((e) => e.id === focusedEntryId) || null;
    },
    focus: args.focusEntry,
    select(id) {
      args.selectedEntryIds$.update((ids) => ids.add(id));
    },
    deselect(id) {
      args.selectedEntryIds$.update((ids) => {
        ids.delete(id);
        return ids;
      });
    },
    deselectAll() {
      args.selectedEntryIds$.update((ids) => {
        ids.clear();
        return ids;
      });
    },
    sort() {
      args.entries$.update((entries) => {
        entries.sort((a, b) => domNodeComparer(a.node, b.node));
        return entries;
      });
    },
  }));
}

/* -----------------------------------------------------------------------------------------------*/

function useHandleResortEntriesEvents<EntryData>(args: {
  resortEntriesEvents$: Observable<void>;
  entries$: IUpdatableBehaviorSubject<Array<IListEntry<EntryData>>>;
}) {
  useEffect(() => {
    const sub = args.resortEntriesEvents$.pipe(debounceTime(1)).subscribe(() => {
      args.entries$.update((entries) => {
        entries.sort((a, b) => domNodeComparer(a.node, b.node));
        return entries;
      });
    });

    return () => sub.unsubscribe();
  }, [args.resortEntriesEvents$, args.entries$]);
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * When the list is initially rendered in the DOM, we want
 * to set `focusableOrActiveEntryId$` and also focus that entry if
 * appropriate.
 */
function useFocusInitialEntryOnMount<EntryData>(args: {
  entries$: BehaviorSubject<Array<IListEntry<EntryData>>>;
  focusableOrActiveEntryId$: IListContext<EntryData>["focusableOrActiveEntryId$"];
  focusedEntryId$: IListContext<EntryData>["focusedEntryId$"];
  initiallyFocusableOrActiveEntryId: IListProps<EntryData>["initiallyFocusableOrActiveEntryId"];
  autoFocus: IListProps<EntryData>["autoFocus"];
  mode: IListProps<EntryData>["mode"];
}) {
  useEffect(
    () => {
      let unmounted = false;

      const sub = args.entries$.pipe(throttleTime(10, undefined, { trailing: true }), take(1)).subscribe((entries) => {
        const focusedEntry =
          args.initiallyFocusableOrActiveEntryId === undefined
            ? entries.find((entry) => !entry.disabled)
            : entries.find((entry) => !entry.disabled && entry.id === args.initiallyFocusableOrActiveEntryId) ||
              entries.find((entry) => !entry.disabled);

        if (!focusedEntry) return;

        args.focusableOrActiveEntryId$.next(focusedEntry.id);

        if (args.mode === "active-descendent" || !args.autoFocus) return;

        args.focusedEntryId$.next(focusedEntry.id);

        // If we're just automatically focusing the first entry, then we should
        // never bother scrolling.
        if (focusedEntry === entries[0]) return;

        // Without this setTimeout, if you attempt to focus an entry in a long list
        // where the entry is off the screen sometimes that entry will focus but
        // the entry isn't automatically scrolled into view.
        setTimeout(() => {
          if (unmounted) return;

          const scrollboxEl = focusedEntry.scrollboxContext.scrollboxRef.current;

          const scrollHeaderOffset = focusedEntry.scrollboxContext.offsetHeaderEl?.current?.offsetHeight || 0;

          if (!scrollboxEl) return;

          scrollContainerToTopOfElement({
            container: scrollboxEl,
            element: focusedEntry.node,
            offset: -scrollHeaderOffset - 60,
          });
        }, 0);
      });

      return () => {
        unmounted = true;
        sub.unsubscribe();
      };
    },
    // We only want to run this setup code on mount and not
    // when the deps change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

/* -------------------------------------------------------------------------------------------------
 * useHandleListKeyboardCommands
 * -----------------------------------------------------------------------------------------------*/

function useHandleListKeyboardEvents<EntryData>(args: {
  containerRef: RefObject<HTMLElement>;
  entries$: IUpdatableBehaviorSubject<Array<IListEntry<EntryData>>>;
  selectedEntryIds$: IUpdatableBehaviorSubject<Set<EntryId>>;
  focusableOrActiveEntryId$: IListContext<EntryData>["focusableOrActiveEntryId$"];
  focusedEntryId$: IListContext<EntryData>["focusedEntryId$"];
  onArrowLeft: IListProps<EntryData>["onArrowLeft"];
  onArrowRight: IListProps<EntryData>["onArrowRight"];
  onArrowUpOverflow: IListProps<EntryData>["onArrowUpOverflow"];
  onArrowDownOverflow: IListProps<EntryData>["onArrowDownOverflow"];
}) {
  useEffect(
    () => {
      const sub = fromEvent<KeyboardEvent>(
        // refs are set by react before calling useEffect hooks

        args.containerRef.current as unknown as HTMLElement | SVGElement,
        "keydown",
      )
        .pipe(
          filter((e) => !e.defaultPrevented),
          filter(
            (e) =>
              e.key === "ArrowUp" ||
              e.key === "ArrowDown" ||
              (!!args.onArrowLeft && e.key === "ArrowLeft") ||
              (!!args.onArrowRight && e.key === "ArrowRight"),
          ),
          filter(() => args.entries$.getValue().length > 0),
          map((e) => {
            const keyPressed = e.key as "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight";

            if (keyPressed === "ArrowLeft") {
              if (args.onArrowLeft) {
                args.onArrowLeft();
                return e;
              }

              return;
            } else if (keyPressed === "ArrowRight") {
              if (args.onArrowRight) {
                args.onArrowRight();
                return e;
              }

              return;
            }

            const currentFocusableId = args.focusableOrActiveEntryId$.getValue();

            if (currentFocusableId === null) {
              console.error(
                stripIndent(`
                  List: entries exist but focusableEntryId$ value was null. 
                  This should never happen.
                `),
              );

              return;
            }

            const entries = args.entries$.getValue();

            const currentFocusableIndex = entries.findIndex((entry) => entry.id === currentFocusableId);

            if (currentFocusableIndex < 0) {
              console.error(
                stripIndent(`
                  List: entry for currentFocusableId does not exist
                `),
              );

              return;
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const currentEntry = entries[currentFocusableIndex]!;

            const wasViewScrolled = maybeScrollViewInsteadOfFocusingNextEntry(keyPressed, currentEntry);

            if (wasViewScrolled) return e;

            const indexOfLastEntry = entries.length - 1;
            const indexChange = keyPressed === "ArrowUp" ? -1 : 1;

            let entryIndex = currentFocusableIndex;
            let didListFocusOverflow = false;

            function incrementEntryIndex(e: KeyboardEvent) {
              entryIndex += indexChange;

              if (entryIndex < 0) {
                // here we're trying to move to the previous entry in the
                // list but we are already at the first entry

                if (args.onArrowUpOverflow !== undefined) {
                  args.onArrowUpOverflow?.(e);
                  return false;
                }

                entryIndex = indexOfLastEntry;
                didListFocusOverflow = true;
              } else if (entryIndex > indexOfLastEntry) {
                // here we're trying to move to the next entry in the
                // list but we're already at the last entry

                if (args.onArrowDownOverflow !== undefined) {
                  args.onArrowDownOverflow?.(e);
                  return false;
                }

                entryIndex = 0;
                didListFocusOverflow = true;
              } else if (entryIndex === currentFocusableIndex) {
                // This indicates that we've iterated through the entire list
                // and are back where we started. I.e. we're in an infinite loop.
                return false;
              }
            }

            if (incrementEntryIndex(e) === false) {
              return e;
            }

            let nextEntry = entries.at(entryIndex);

            // skip disabled entries
            while (nextEntry?.disabled) {
              if (incrementEntryIndex(e) === false) return e;
              nextEntry = entries.at(entryIndex);
            }

            if (e.shiftKey) {
              args.selectedEntryIds$.update((ids) => {
                if (nextEntry) {
                  if (ids.has(nextEntry.id)) {
                    ids.delete(currentEntry.id);
                  } else {
                    ids.add(nextEntry.id);
                    ids.add(currentEntry.id);
                  }
                }

                return ids;
              });
            }

            if (!nextEntry) return;

            args.focusableOrActiveEntryId$.next(nextEntry.id);
            args.focusedEntryId$.next(nextEntry.id);

            scrollViewToNextEntryIfAppropriate(keyPressed, nextEntry, didListFocusOverflow);

            return e;
          }),
        )
        .subscribe((event) => {
          event?.preventDefault();
        });

      return () => sub.unsubscribe();
    },
    // exhaustive-deps is incorrectly complaining that the `args`
    // object is a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      args.containerRef,
      args.entries$,
      args.focusableOrActiveEntryId$,
      args.focusedEntryId$,
      args.onArrowUpOverflow,
      args.onArrowDownOverflow,
      args.onArrowLeft,
      args.onArrowRight,
    ],
  );
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * Before attempting to focus the next entry, we
 * need to check to see if the current entry's top
 * (for navigating up) or bottom (for navigating down)
 * is in the view. If it isn't, we need to scroll the
 * view. If it is, then we can focus the next entry.
 */
function maybeScrollViewInsteadOfFocusingNextEntry(
  keyPressed: "ArrowUp" | "ArrowDown",
  currentEntry: Writable<IListEntry<unknown>>,
) {
  const scrollboxEl = currentEntry.scrollboxContext.scrollboxRef.current;

  const headerElOffsetHeight = currentEntry.scrollboxContext.offsetHeaderEl?.current?.offsetHeight || 0;

  const containerPosOffset = {
    top: headerElOffsetHeight + currentEntry.scrollboxContext.offsetTopPx,
  };

  if (!scrollboxEl) {
    throw new Error("maybeScrollViewInsteadOfFocusingNextEntry: scrollboxEl expected to be non-null");
  }

  const { top: currentEntryTopPlacementInView, bottom: currentEntryBottomPlacementInView } = elementPositionInContainer(
    {
      container: scrollboxEl,
      element: currentEntry.node,
      containerPosOffset,
    },
  );

  const scrollContainerToTopOfElementOffset = -containerPosOffset.top;

  const originalScrollTop = getScrollTop(scrollboxEl);

  // because we might have a offset hight for the "top" of our
  // scroll container, it's possible for one or more of the first
  // entries to always be considered partially out of view. We detect this
  // by determining if our scroll attempt did anything.
  const didScrollTopChange = () => getScrollTop(scrollboxEl) !== originalScrollTop;

  if (keyPressed === "ArrowUp") {
    if (currentEntryBottomPlacementInView === "above") {
      scrollContainerToTopOfElement({
        container: scrollboxEl,
        element: currentEntry.node,
        offset: scrollContainerToTopOfElementOffset,
      });

      return didScrollTopChange();
    } else if (currentEntryTopPlacementInView === "above") {
      scrollElementTo(scrollboxEl, {
        top: originalScrollTop - 100,
      });

      return didScrollTopChange();
    } else if (currentEntryTopPlacementInView === "below") {
      scrollContainerToTopOfElement({
        container: scrollboxEl,
        element: currentEntry.node,
        offset: scrollContainerToTopOfElementOffset,
      });

      return didScrollTopChange();
    }
  } else if (keyPressed === "ArrowDown") {
    if (currentEntryTopPlacementInView === "below") {
      scrollContainerToTopOfElement({
        container: scrollboxEl,
        element: currentEntry.node,
        offset: scrollContainerToTopOfElementOffset,
      });

      return didScrollTopChange();
    } else if (currentEntryBottomPlacementInView === "below") {
      scrollElementTo(scrollboxEl, {
        top: originalScrollTop + 100,
      });

      return didScrollTopChange();
    } else if (currentEntryBottomPlacementInView === "above") {
      scrollContainerToTopOfElement({
        container: scrollboxEl,
        element: currentEntry.node,
        offset: scrollContainerToTopOfElementOffset,
      });

      return didScrollTopChange();
    }
  } else {
    throw new UnreachableCaseError(keyPressed);
  }

  return false;
}

/* -----------------------------------------------------------------------------------------------*/

function scrollViewToNextEntryIfAppropriate(
  keyPressed: "ArrowUp" | "ArrowDown",
  nextEntry: Writable<IListEntry<unknown>>,
  /**
   * e.g. did the user press ArrowDown when the last
   * entry was already focused causing focus to wrap
   * around to the first entry again.
   */
  didListFocusOverflow: boolean,
) {
  const scrollboxEl = nextEntry.scrollboxContext.scrollboxRef.current;
  const containerPosOffset = {
    top: nextEntry.scrollboxContext.offsetHeaderEl?.current?.offsetHeight,
  };

  if (!scrollboxEl) {
    throw new Error("scrollViewToNextEntryIfAppropriate: scrollboxEl expected to be non-null");
  }

  const { top: nextEntryTopPlacementInView, bottom: nextEntryBottomPlacementInView } = elementPositionInContainer({
    container: scrollboxEl,
    element: nextEntry.node,
    containerPosOffset,
  });

  const scrollContainerToTopOfElementOffset = -(containerPosOffset.top || 0) - nextEntry.scrollboxContext.offsetTopPx;

  if (keyPressed === "ArrowUp") {
    if (didListFocusOverflow) {
      if (nextEntryBottomPlacementInView === "below") {
        scrollContainerToTopOfElement({
          container: scrollboxEl,
          element: nextEntry.node,
          offset: scrollContainerToTopOfElementOffset,
        });

        return true;
      }
    } else if (nextEntryBottomPlacementInView !== "visible") {
      scrollContainerToTopOfElement({
        container: scrollboxEl,
        element: nextEntry.node,
        offset: scrollContainerToTopOfElementOffset,
      });

      return true;
    }
  } else if (keyPressed === "ArrowDown") {
    if (didListFocusOverflow) {
      if (nextEntryTopPlacementInView === "above") {
        scrollContainerToTopOfElement({
          container: scrollboxEl,
          element: nextEntry.node,
          offset: scrollContainerToTopOfElementOffset,
        });

        return true;
      }
    } else if (nextEntryTopPlacementInView !== "visible") {
      scrollContainerToTopOfElement({
        container: scrollboxEl,
        element: nextEntry.node,
        offset: scrollContainerToTopOfElementOffset,
      });

      return true;
    }
  } else {
    throw new UnreachableCaseError(keyPressed);
  }

  return false;
}

/* -----------------------------------------------------------------------------------------------*/
