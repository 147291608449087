import { RecordValue } from "libs/schema";
import { isNonNullable } from "libs/predicates";
import { getTagFolderAncestorIdPaths } from "./getTagFolderAncestorIdPaths";
import { GetOptions } from "~/environment/RecordLoader";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export type GetTagFolderAncestorRecordResult = {
  folderPaths: RecordValue<"tag">[][];
};

/** Like `observeTagFolderAncestors` but returns whole records */
export async function getTagFolderAncestors(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    tagId: string;
    maxDepth?: number;
  },
  options?: GetOptions,
) {
  const { tagId, maxDepth = Infinity } = props;
  const { recordLoader } = environment;

  const { folderPaths } = await getTagFolderAncestorIdPaths(
    environment,
    {
      tagId,
      maxDepth,
    },
    options,
  );

  if (folderPaths.length === 0) return { folderPaths: [] };

  const paths = await Promise.all(
    folderPaths.map(async (path) => {
      const records = await Promise.all(
        path.map(async (folderId) => {
          const [tag] = await recordLoader.getRecord({ table: "tag", id: folderId }, options);

          return tag;
        }),
      );

      return records.filter(isNonNullable);
    }),
  );

  return {
    folderPaths: paths.filter((path) => path.length > 0),
  };
}
