import { RecordPointer, RecordTable, RecordValue, getPointer } from "libs/schema";
import { distinctUntilChanged, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useMemo } from "react";
import { ClientRecordLoaderObserveGetRecordResultMeta, FetchStrategy } from "~/environment/RecordLoader";
import { isEqual } from "libs/predicates";
import { useDistinctUntilChanged } from "./useDistinctUntilChanged";
import { rxIsLoading } from "~/observables/operators";

export type UseRecordResult<T extends RecordTable> = [RecordValue<T> | null, UseRecordResultMeta];

export type UseRecordResultMeta = ClientRecordLoaderObserveGetRecordResultMeta;

export type UseRecordOptions = {
  fetchStrategy?: FetchStrategy;
  includeSoftDeletes?: boolean;
  /** Hook name. Used for debugging purposes. */
  name?: string;
};

const DEFAULT_VALUE_TRUE = Object.freeze([null, Object.freeze({ isLoading: true })]);

const DEFAULT_VALUE_FALSE = Object.freeze([null, Object.freeze({ isLoading: false })]);

export function useRecord<T extends RecordTable>(
  table: T,
  id?: string | null,
  options?: UseRecordOptions,
): UseRecordResult<T>;
export function useRecord<T extends RecordTable>(
  pointer?: RecordPointer<T> | null,
  options?: UseRecordOptions,
): UseRecordResult<T>;
export function useRecord<T extends RecordTable>(
  a?: RecordPointer<T> | T | null,
  b?: string | null | UseRecordOptions,
  c?: UseRecordOptions,
) {
  const environment = useClientEnvironment();
  const stablePointer = useDistinctUntilChanged(getPointerFromInput(a, b));
  const options = getOptionsFromInput(b, c);

  const initialValue = useMemo((): UseRecordResult<T> => {
    if (!stablePointer) return DEFAULT_VALUE_FALSE as UseRecordResult<T>;
    const [record] = environment.db.getRecord(stablePointer, { includeSoftDeletes: options?.includeSoftDeletes });
    if (record) return [record, { isLoading: true }];
    return DEFAULT_VALUE_TRUE as UseRecordResult<T>;
  }, [stablePointer, environment, options?.includeSoftDeletes]);

  const result = useLoadingObservable({
    initialValue,
    deps: [environment, stablePointer, options?.fetchStrategy, options?.includeSoftDeletes, options?.name],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, pointer, fetchStrategy, includeDeleted, name]) => {
          const observable =
            pointer ?
              environment.recordLoader.observeGetRecord(pointer, { fetchStrategy, includeSoftDeletes: includeDeleted })
            : environment.recordLoader.createObserveGetResult<T>();

          return observable.pipe(distinctUntilChanged(isEqual), rxIsLoading(environment, { label: name }));
        }),
      );
    },
  });

  return result;
}

function getPointerFromInput<T extends RecordTable>(
  a?: RecordPointer<T> | T | null,
  b?: string | null | { fetchStrategy?: FetchStrategy },
): RecordPointer<T> | undefined {
  if (!a) return;
  if (typeof a === "string") {
    return typeof b === "string" ? getPointer(a, b) : undefined;
  }

  return a;
}

function getOptionsFromInput(a?: string | null | UseRecordOptions, b?: UseRecordOptions): UseRecordOptions | undefined {
  if (typeof a === "object" && a) return a;
  if (b) return b;
}
