import { createContext } from "react";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { useAsRef } from "~/hooks/useAsRef";
import useConstant from "use-constant";
import { BehaviorSubject } from "rxjs";
import { useObservableEagerState } from "observable-hooks";

export interface SuggestPluginState {
  isOpen: boolean;
  /**
   * State changes can come from tiptap/prosemirror, or they can come from
   * us external sources such as running out of suggestions to show, or
   * they can come from the user explicitely opening or closing the dropdown.
   * If the user explicitely opens or closes the dropdown, we note this
   * by setting this value to "direct".
   */
  isOpenChangedBy?: "direct";
  context?: {
    word: string;
    from: number;
    to: number;
  };
}

export interface SuggestPluginActionMeta {
  type: "open" | "close";
  isOpenChangedBy?: SuggestPluginState["isOpenChangedBy"];
}

export type TSuggestionDropdownContext = {
  position$: BehaviorSubject<Position>;
  floatingElRef: React.MutableRefObject<HTMLElement | null>;
  setIsSearchFocused: (value: boolean) => void;
  setPosition(position: Position): void;
  setIsOpen(isOpen: boolean): void;
  getIsOpen(): boolean;
  setSuggestionDropdownProps(props: SuggestionDropdownProps | null): void;
  useSuggestionDropdownProps(): SuggestionDropdownProps | null;
};

export type SuggestionDropdownProps = {
  state: SuggestPluginState;
  onSelect: (args: { name: string; hint?: string; receivesNestedQuery?: boolean; selectHint?: boolean }) => void;
};

export type Position = { left: number; top: number };

export const SuggestionDropdownContext = createContext<TSuggestionDropdownContext | null>(null);

export const useSuggestionDropdownContext = createUseContextHook(
  SuggestionDropdownContext,
  "SuggestionDropdownContext",
);

export function useCreateSuggestionDropdownContext(props: {
  floatingElRef: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setIsSearchFocused: (value: boolean) => void;
}): TSuggestionDropdownContext {
  const { floatingElRef, isOpen, setIsOpen, setIsSearchFocused } = props;

  const isOpenRef = useAsRef(isOpen);

  return useConstant((): TSuggestionDropdownContext => {
    const position$ = new BehaviorSubject<Position>({ left: 0, top: 0 });
    const props$ = new BehaviorSubject<SuggestionDropdownProps | null>(null);

    return {
      position$,
      floatingElRef,
      setIsSearchFocused,
      setPosition(position: Position) {
        position$.next(position);
      },
      setIsOpen(value: boolean) {
        setIsOpen(value);
      },
      getIsOpen() {
        return isOpenRef.current;
      },
      setSuggestionDropdownProps(props: SuggestionDropdownProps | null) {
        props$.next(props);
      },
      useSuggestionDropdownProps() {
        return useObservableEagerState(props$);
      },
    };
  });
}
