export type TRegExpHelperInput = string | RegExpHelper | ((helper: RegExpHelper) => RegExpHelper);

export interface IRegExpHelperOptions {
  fromStartOfString?: boolean;
  toEndOfString?: boolean;
  caseInsensitive?: boolean;
  global?: boolean;
}

export class RegExpHelper {
  /**
   * **`\w`**
   * Matches any alphanumeric character from the basic Latin alphabet,
   * including the underscore. Equivalent to `[A-Za-z0-9_]`. For example,
   * `/\w/` matches "a" in "apple", "5" in "$5.28", "3" in "3D" and "m"
   * in "Émanuel".
   *
   * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes#types
   */
  static readonly CHARACTER = "\\w";
  /**
   * Matches any character that is not a word character from the basic
   * Latin alphabet. Equivalent to `[^A-Za-z0-9_]`. For example, `/\W/`
   * or `/[^A-Za-z0-9_]/` matches "%" in "50%" and "É" in "Émanuel".
   *
   * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes#types
   */
  static readonly NOT_CHARACTER = "\\W";

  static readonly LETTER = "[A-Za-z]";
  static readonly LETTER_LOWERCASE = "[a-z]";
  static readonly LETTER_UPPERCASE = "[A-Z]";

  /**
   * `\b` Matches a word boundary. This is the position where a word character
   * is not followed or preceded by another word-character, such as
   * between a letter and a space. Note that a matched word boundary
   * is not included in the match. In other words, the length of a
   * matched word boundary is zero.
   *
   * Examples:
   * - `/\bm/` matches the "m" in "moon".
   * - `/oo\b/` does not match the "oo" in "moon", because "oo" is followed
   *   by "n" which is a word character.
   * - `/oon\b/` matches the "oon" in "moon", because "oon" is the end of the
   *   string, thus not followed by a word character.
   * - `/\w\b\w/` will never match anything, because a word character can
   *   never be followed by both a non-word and a word character.
   *
   * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes#types
   */
  static readonly WORD_BOUNDARY = "\\b";
  /**
   * Matches any digit (Arabic numeral). Equivalent to `[0-9]`. For example,
   * `/\d/` or `/[0-9]/` matches "2" in "B2 is the suite number".
   *
   * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes#types
   */
  static readonly NUMBER = "\\d";
  static readonly NOT_NUMBER = "\\D";
  static readonly SPACE = "\\s";
  static readonly NOT_SPACE = "\\S";
  static readonly ANYTHING_EXCEPT_NEWLINE = ".";
  static readonly TAB = "\\t";
  static readonly LINEFEED = "\\n";
  static readonly CARRIAGE_RETURN = "\\r";

  static from(input: RegExp) {
    const content = input.toString().match(/^\/(.*)\/[gimuy]*$/)?.[1];

    if (!content) {
      throw new Error(`
        Oops, for some reason we couldn't process the provided RegExp.
        This is a bug.
      `);
    }

    const helper = new RegExpHelper({
      caseInsensitive: input.ignoreCase,
      global: input.global,
    });

    helper.content = content;

    return helper;
  }

  fromStartOfString: boolean;
  toEndOfString: boolean;
  caseInsensitive: boolean;
  global: boolean;

  /**
   * This textual representation of the current helper state,
   * minus any flags (e.g. "i", "g") and also not including
   * the "start of string" or "end of strong" symbols (i.e.
   * "^" or "$"). It's expected that you use the provided
   * methods to alter this helper's content, but you are
   * also free to modify the content directly.
   */
  content = "";

  constructor(options: IRegExpHelperOptions = {}) {
    this.fromStartOfString = options.fromStartOfString ?? false;
    this.toEndOfString = options.toEndOfString ?? false;
    this.caseInsensitive = options.caseInsensitive ?? false;
    this.global = options.global ?? false;
  }

  /**
   * When called without an argument, this method does nothing
   * and simply returns itself--providing a way to make code
   * more readable.
   *
   * Example
   *
   * ```ts
   * createRegExp({ caseInsensitive: true })
   *   .startOfString()
   *   .then()
   *   .capture((a) => a.optional((b) => b.oneOrMore(RegExpHelper.NUMBER)))
   *
   * // equivalent to
   *
   * createRegExp({ caseInsensitive: true })
   *   .startOfString()
   *   .capture((a) => a.optional((b) => b.oneOrMore(RegExpHelper.NUMBER)))
   * ```
   *
   * When called with an argument, this method does what you
   * might expect and simply appends the provided input to the RegExp.
   */
  then(input?: TRegExpHelperInput) {
    if (input) {
      this.content += this.normalize(input);
    }

    return this;
  }

  /**
   * Translates to:
   * ```ts
   * `(${input})`
   * ```
   */
  capture(input: TRegExpHelperInput) {
    this.content += `(${this.normalize(input)})`;
    return this;
  }

  /**
   * Translates to:
   * ```ts
   * `(?:${input})`
   * ```
   */
  group(input: TRegExpHelperInput) {
    this.content += `(?:${this.normalize(input)})`;
    return this;
  }

  startOfString(value = true) {
    this.fromStartOfString = value;
    return this;
  }

  endOfString(value = true) {
    this.toEndOfString = value;
    return this;
  }

  /**
   * Translates to:
   * ```ts
   * `${input}*`
   * ```
   */
  zeroOrMore(input: TRegExpHelperInput) {
    this.content += `${this.normalize(input)}*`;
    return this;
  }

  /**
   * Translates to:
   * ```ts
   * `${input}+`
   * ```
   */
  oneOrMore(input: TRegExpHelperInput) {
    this.content += `${this.normalize(input)}+`;
    return this;
  }

  /**
   * Translates to:
   * ```ts
   * `(?:${input})?`
   * ```
   */
  optional(input: TRegExpHelperInput) {
    this.content += `(?:${this.normalize(input)})?`;
    return this;
  }

  /**
   * We aware that the *first* match will be the one used.
   * Translates to:
   * ```ts
   * `(?:${inputs.join("|")})`
   * ```
   */
  anyOf(...inputs: TRegExpHelperInput[]) {
    this.content += `(?:${inputs.map((i) => this.normalize(i)).join("|")})`;
    return this;
  }

  /**
   * Translates to:
   * ```ts
   * `(?!${input})`
   * ```
   */
  not(input: TRegExpHelperInput) {
    this.content += `(?!${this.normalize(input)})`;
    return this;
  }

  toString() {
    return this.toRegExp().toString();
  }

  toRegExp() {
    let flags = "";
    if (this.global) flags += "g";
    if (this.caseInsensitive) flags += "i";

    const content = [this.content];
    if (this.fromStartOfString) content.unshift("^");
    if (this.toEndOfString) content.push("$");

    return new RegExp(content.join(""), flags);
  }

  protected normalize(input: TRegExpHelperInput) {
    return typeof input === "string"
      ? input
      : input instanceof RegExpHelper
        ? input.content
        : input(new RegExpHelper()).content;
  }
}

export function createRegExp(options?: IRegExpHelperOptions) {
  return new RegExpHelper(options);
}
