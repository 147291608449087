import { ClientRecordLoaderObserveGetRecordResultMeta } from "~/environment/RecordLoader";
import { useOrganizationUserMember } from "./useOrganizationUserMember";

export type UseIsUserOrganizationAdminResult = [boolean, ClientRecordLoaderObserveGetRecordResultMeta];

export function useIsUserOrganizationAdmin(props: {
  userId: string | null | undefined;
  organizationId: string | null | undefined;
}): UseIsUserOrganizationAdminResult {
  const [organizationUserMember, meta] = useOrganizationUserMember(props);

  return [!!organizationUserMember?.is_admin, meta];
}
