import { ComponentType } from "react";
import { ContentList } from "~/components/content-list/ContentList";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { QuoteParentThreadTimeline } from "~/components/QuoteParentThreadTimeline";
import { ThreadTimelineEntry } from "~/components/thread-timeline-entry/ThreadTimelineEntry";
import { Observable } from "rxjs";
import { ParentThreadTimelineContextProvider } from "~/components/thread-timeline-entry/context";

export const ParentThreadTimeline: ComponentType<{
  parentThreadId: string;
  parentMessageId: string;
  onArrowDownOverflow: () => void;
  loadMoreMessagesButtonFocusEvents: Observable<void>;
}> = (props) => {
  return (
    <ContentList<TThreadTimelineEntry>
      // onArrowUpOverflow={onArrowUpOverflow}
      onArrowDownOverflow={props.onArrowDownOverflow}
      focusOnMouseOver={false}
    >
      <ParentThreadTimelineContextProvider>
        <QuoteParentThreadTimeline
          isFirstBranch
          parentThreadId={props.parentThreadId}
          parentMessageId={props.parentMessageId}
          branchCreatedAt="now"
          loadMoreMessagesButtonFocusEvents={props.loadMoreMessagesButtonFocusEvents}
        >
          {({ id, relativeIndex }) => {
            return <ThreadTimelineEntry key={id} timelineId={id} relativeOrder={relativeIndex} />;
          }}
        </QuoteParentThreadTimeline>
      </ParentThreadTimelineContextProvider>
    </ContentList>
  );
};
