import { useState, useRef, useEffect, ComponentType } from "react";
import { PWAInstallElement } from "@khmyznikov/pwa-install";
import PWAInstall from "@khmyznikov/pwa-install/react-legacy";
import { Portal } from "~/components/Portal";
import { beforeInstallPrompt$ } from "~/utils/beforeInstallPrompt";
import { alertUserIfPersistenceIsNotSupported } from "~/hooks/useWarnIfPersistenceIsNotSupported";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { isDesktopBrowser } from "~/utils/dom-helpers";

export const PWAInstallDialog: ComponentType = () => {
  const environment = useClientEnvironment();
  const [promptEvent, setPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);
  const pwaInstallRef = useRef<PWAInstallElement>(null);

  // externalPromptEvent is only if your app is big and slow to start
  // check index.html for additional code.
  // https://github.com/khmyznikov/pwa-install?tab=readme-ov-file#async-mode
  useEffect(() => {
    if (isDesktopBrowser() && localStorage.getItem(DISMISSED_STORAGE_KEY)) {
      return;
    }

    // This will trigger the dialog for browsers which support the beforeinstallprompt event
    const sub = beforeInstallPrompt$.subscribe((e) => {
      setPromptEvent(e);
    });

    // Trigger the dialog for Safari browsers
    //
    // Safari doesn't support the beforeinstallprompt event so we need to try and manually trigger
    // the dialog. The PWAInstall element contains logic so that `showDialog()` will be ignored if
    // the browser doesn't support it.
    pwaInstallRef.current?.showDialog();

    return () => sub.unsubscribe();
  }, []);

  return (
    <Portal>
      <PWAInstall
        ref={pwaInstallRef}
        manifestUrl="/manifest.webmanifest"
        externalPromptEvent={promptEvent}
        installDescription="Install Comms to your device for a better experience, including offline support."
        onPwaUserChoiceResultEvent={(e) => {
          const wasDismissed = (e as any).detail.message === "dismissed";
          if (!wasDismissed) return;
          localStorage.setItem(DISMISSED_STORAGE_KEY, new Date().toISOString());
          alertUserIfPersistenceIsNotSupported(environment);
        }}
      />
    </Portal>
  );
};

const DISMISSED_STORAGE_KEY = "pwa-install-dialog-dismissed";

export function showPWAInstallDialog(force?: boolean) {
  const el = document.querySelector<PWAInstallElement>("pwa-install");
  el?.showDialog(force);
}
