import { getPointer, RecordValue } from "libs/schema";
import { debounce } from "lodash-comms";
import { useMemo } from "react";
import { useAsync, usePrevious } from "react-use";
import { markThreadSeen } from "~/actions/thread";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";

export function useMarkThreadSeen(threadId: string | null | undefined): (message: RecordValue<"message">) => void {
  const environment = useClientEnvironment();

  const { threadSeenReceiptSnapshot, isLoading } = useThreadSeenReceiptSnapshot(threadId);

  const updateThreadSeen = useMemo(() => {
    if (!threadId || isLoading || threadSeenReceiptSnapshot === undefined) {
      return () => {};
    }

    return getThreadSeenUpdaterFn({
      environment,
      threadId,
      initialThreadSeenReceipt: threadSeenReceiptSnapshot,
    });
    // Because we only want the initialThreadReadStatus of the
    // thread, we only want to rerun this memo when the thread changes.
  }, [threadId, isLoading, threadSeenReceiptSnapshot, environment]);

  return updateThreadSeen;
}

/**
 * Gets the current value of the given thread_seen_receipt
 * without subscribing to updates.
 */
function useThreadSeenReceiptSnapshot(threadId: string | null | undefined) {
  const { currentUserId } = useAuthGuardContext();
  const environment = useClientEnvironment();
  const prevThreadId = usePrevious(threadId);

  const initialThreadSeenReceipt = useAsync(async () => {
    if (!threadId) return;

    const [record] = await environment.recordLoader.getRecord(
      getPointer("thread_seen_receipt", {
        thread_id: threadId,
        user_id: currentUserId,
      }),
    );

    return record;
  }, [threadId, currentUserId, environment]);

  const isThreadSeenReceiptLoading = prevThreadId !== threadId || initialThreadSeenReceipt.loading;

  return {
    threadSeenReceiptSnapshot: initialThreadSeenReceipt.value,
    isLoading: isThreadSeenReceiptLoading,
  };
}

/**
 * Returns a function that can be used to indicate that a particular
 * message has scrolled into view and was "read". Will only actually
 * update the "read" status of the thread if the message that scrolled
 * into view hadn't previously been read.
 */
function getThreadSeenUpdaterFn(props: {
  environment: ClientEnvironment;
  threadId: string;
  initialThreadSeenReceipt: RecordValue<"thread_seen_receipt"> | null;
}): (message: RecordValue<"message">) => Promise<void> | undefined {
  const { environment, threadId, initialThreadSeenReceipt } = props;

  if (initialThreadSeenReceipt && initialThreadSeenReceipt.thread_id !== threadId) {
    const msg = `getThreadSeenUpdaterFn: Provided an initialThreadSeenReceipt for the wrong thread`;
    console.error(msg, threadId, initialThreadSeenReceipt);
    throw new Error(msg);
  }

  let seenToTimelineId = initialThreadSeenReceipt?.seen_to_timeline_id;
  let seenToTimelineOrder = initialThreadSeenReceipt?.seen_to_timeline_order;

  const updThreadSeen = debounce(async () => {
    if (!seenToTimelineId || !seenToTimelineOrder) return;

    return markThreadSeen(environment, {
      threadId: props.threadId,
      seen_to_timeline_id: seenToTimelineId,
      seen_to_timeline_order: seenToTimelineOrder,
    });
  }, 1500);

  return (message: RecordValue<"message">) => {
    if (!seenToTimelineId || !seenToTimelineOrder) {
      seenToTimelineId = message.id;
      seenToTimelineOrder = message.timeline_order;
      return updThreadSeen();
    }

    if (seenToTimelineOrder >= message.timeline_order) return;

    seenToTimelineId = message.id;
    seenToTimelineOrder = message.timeline_order;

    return updThreadSeen();
  };
}
