import { ComponentType, useState } from "react";
import { IListOnEntryActionEvent, List, useListContext } from "../list";
import { navigateToEntry } from "./ContentList";
import {
  EntryActions,
  entryCSSClasses,
  EntryTimestamp,
  MarkDoneEntryAction,
  OtherCommandEntryAction,
  PrivateEntryIcon,
  Recipients,
  SetReminderEntryAction,
  StarredEntryIcon,
  Summary,
  useShouldShowChannelLabels,
} from "./layout";
import { isModKeyActive } from "~/environment/command.service";
import { INavigateServiceOptions, openLinkInNewTabOrWindow } from "~/environment/navigate.service";
import { Avatar } from "../Avatar";
import { useMessage } from "~/hooks/useMessage";
import { useThread } from "~/hooks/useThread";
import { PointerWithRecord } from "libs/schema";
import { LabelContainer, PermittedGroupChips, ThreadResolvedLabel } from "../LabelChip";
import { useMessageSender } from "~/hooks/useMessageSender";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { onMessageSelectNavigateToThread } from "./MessageEntry";
import { cx } from "@emotion/css";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { useNotification } from "~/hooks/useNotification";
import { NotificationTimestamp } from "./NotificationEntry";

export function onThreadSelectNavigateToThread(
  { entry, event }: IListOnEntryActionEvent<PointerWithRecord<"thread">>,
  options?: INavigateServiceOptions,
) {
  const url = `/threads/${entry.id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(url);
  } else {
    navigateToEntry(entry.id, url, options);
  }
}

export const ThreadEntry: ComponentType<{
  threadId: string;
  relativeOrder: number;
}> = (props) => {
  const [thread] = useThread(props.threadId, { includeSoftDeletes: true });
  const [message] = useMessage(thread?.last_message_id);
  const [notification] = useNotification({ threadId: props.threadId });
  const sender = useMessageSender(thread?.last_message_id);
  const showChannelLabels = useShouldShowChannelLabels();
  const [isChecked, setIsChecked] = useState(false);
  const listContext = useListContext();
  const entryData = useAsPointerWithRecord("thread", thread);

  if (!thread || !message || !entryData) return null;

  const senderLabel = sender?.label || (sender.isLoading ? "loading..." : "unknown");
  const isPrivateThread = thread.visibility === "PRIVATE";

  return (
    <List.Entry<PointerWithRecord<"thread">>
      id={props.threadId}
      data={entryData}
      relativeOrder={props.relativeOrder}
      onEntrySelectionChange={(event) => {
        setIsChecked(event.isSelected);
      }}
    >
      <div role="listitem" className={cx(entryCSSClasses, isChecked && "is-checked", "ThreadEntry")}>
        <div className="pr-2 sm-w:pr-3">
          {isChecked ?
            <MdOutlineCheckBox
              size={30}
              className="p-1"
              onClick={(e) => {
                e.stopPropagation();
                listContext.deselect(thread.id);
              }}
            />
          : <>
              <Avatar label={senderLabel} photoURL={sender?.photoUrl} width="30px" className="group-hover:hidden" />

              <MdOutlineCheckBoxOutlineBlank
                size={30}
                className="hidden group-hover:block p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  listContext.select(thread.id);
                }}
              />
            </>
          }
        </div>

        <div className="flex flex-col md-w:flex-row flex-1 min-w-0">
          <Recipients
            nonTruncatedSuffix={
              <>
                {isPrivateThread && <PrivateEntryIcon />}
                {notification?.is_starred && <StarredEntryIcon />}
              </>
            }
          >
            <span className="truncate">{senderLabel}</span>
          </Recipients>

          <Summary
            subject={message.subject}
            formatAsReply={message.is_reply && message.type === "COMMS"}
            details={isPrivateThread ? "private message" : message.body_text}
          />
        </div>

        <LabelContainer>
          {showChannelLabels && <PermittedGroupChips threadId={props.threadId} />}

          <ThreadResolvedLabel
            threadId={thread.id}
            onClick={({ event, threadTag }) => {
              event.preventDefault();

              onMessageSelectNavigateToThread({
                event: event.nativeEvent,
                id: threadTag.data.message_id,
                entry: {
                  table: "message",
                  id: threadTag.data.message_id,
                  record: { thread_id: threadTag.thread_id },
                },
              });
            }}
          />
        </LabelContainer>

        <EntryActions
          defaultComponent={
            notification?.has_reminder ?
              <div className="ml-2">
                <NotificationTimestamp notification={notification} sentAt={message.sent_at} showReminder />
              </div>
            : <EntryTimestamp datetime={message.sent_at} />
          }
        >
          <MarkDoneEntryAction />
          <SetReminderEntryAction />
          <OtherCommandEntryAction />
        </EntryActions>
      </div>
    </List.Entry>
  );
};
