import { render, flushSync } from "react-dom";

// See the following for why we aren't simply using renderToStaticMarkup
// https://react.dev/reference/react-dom/server/renderToStaticMarkup#caveats
export function renderJsxToString(jsx: JSX.Element) {
  const div = document.createElement("div");

  flushSync(() => {
    render(jsx, div);
  });

  return div.innerHTML;
}
