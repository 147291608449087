import { ComponentType, useMemo } from "react";
import { Subject } from "rxjs";
import { useMarkThreadSeen } from "~/hooks/useMarkThreadSeen";
import { TThreadTimelineContext, ThreadTimelineContext } from "~/components/thread-timeline-entry/context";

/* -------------------------------------------------------------------------------------------------
 * ThreadTimelineContextProvider
 * -----------------------------------------------------------------------------------------------*/

export const ThreadTimelineContextProvider: ComponentType<{
  threadId: string;
}> = (props) => {
  const markThreadSeen = useMarkThreadSeen(props.threadId);

  const context = useMemo<TThreadTimelineContext>(() => {
    return {
      isQuoted: false,
      collapseMessageEvents: new Subject<"expand" | "collapse" | string>(),
      onMessageInView: markThreadSeen,
    };
  }, [markThreadSeen]);

  return <ThreadTimelineContext.Provider value={context}>{props.children}</ThreadTimelineContext.Provider>;
};
