import { UnreachableCaseError } from "libs/errors";
import { useRecordLoader, UseRecordLoaderResult } from "./useRecordLoader";
import { ClientRecordLoaderObserveQueryResult } from "~/environment/RecordLoader";
import { distinctUntilChanged, map, Observable, of, switchMap } from "rxjs";
import { omit } from "lodash-comms";
import { isEqual } from "libs/predicates";
import { useInitialQueryLimit } from "./useInitialQueryLimit";

export type UseTagViewThreadsResult = UseRecordLoaderResult<string[]>;

export function useTagViewThreads(props: {
  tagId: string | null | undefined;
  type: "tag" | "group";
}): UseTagViewThreadsResult {
  const initialLimit = useInitialQueryLimit(50);

  return useRecordLoader({
    name: "useTagViewThreads",
    load({ loader, limit, currentUserId, deps: [tagId, type] }) {
      let observable: Observable<ClientRecordLoaderObserveQueryResult<"thread">>;

      if (!tagId) {
        observable = loader.createObserveQueryResult<"thread">();
      } else {
        switch (type) {
          case "tag": {
            observable = loader.observeGetTagViewThreads({
              currentUserId,
              tagId,
              sortDirection: "DESC",
              limit,
            });

            break;
          }
          case "group": {
            observable = loader.observeGetGroupViewThreads({
              currentUserId,
              groupId: tagId,
              sortDirection: "DESC",
              limit,
            });

            break;
          }
          default: {
            throw new UnreachableCaseError(type);
          }
        }
      }

      return observable.pipe(
        map(([threads, meta]) => {
          const threadIds = threads.map((thread) => thread.id);
          return [threadIds, omit(meta, "recordMap")] as const;
        }),
        distinctUntilChanged(isEqual),
        switchMap(([threadIds, meta]) => {
          if (threadIds.length === 0) return of([threadIds, meta] as const);

          return loader.observeGetThreadsGroupPermissions({ threadIds }, { isLoading: meta.isLoading }).pipe(
            map(([, { isLoading }]) => {
              return [threadIds, { ...meta, isLoading }] as const;
            }),
          );
        }),
      );
    },
    initialLimit,
    limitStep: Math.ceil(initialLimit / 2),
    deps: [props.tagId, props.type],
  });
}
