import { uuid } from "libs/uuid";
import { MonoTypeOperatorFunction, Observable } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { IAddLoadingOptions } from "~/environment/is-loading.service";

/* -------------------------------------------------------------------------------------------------
 * rxIsLoading
 * -------------------------------------------------------------------------------------------------
 */

/**
 * An operator that triggers the app loading bar when the emission indicates
 * `isLoading === true`. Intended to be used with record loader observables.
 */
// Note that previously we used a hook to trigger the app loading bar but found that,
// in one specific instance, sometimes the hook wouldn't trigger cleanup and the app
// loading bar would stay on the screen indefinitely. It really *looked* like a react bug
// but I can't be sure. Regardless, this operator does the same thing but is more reliable.
export function rxIsLoading<T extends [any, { isLoading: boolean }]>(
  environment: Pick<ClientEnvironment, "isLoading">,
  props: {
    label?: string;
    options?: Omit<IAddLoadingOptions, "unique">;
  } = {},
): MonoTypeOperatorFunction<T> {
  const { label, options } = props;

  return (source) => {
    const id = uuid();
    let isLoading = false;

    const markLoading = () => {
      if (isLoading) return;
      isLoading = true;
      environment.isLoading.add({ ...options, unique: id });

      if (label) {
        const count = appLoadingBarState.get(label) ?? 0;
        appLoadingBarState.set(label, count + 1);
      }
    };

    const removeLoading = () => {
      if (!isLoading) return;
      isLoading = false;
      environment.isLoading.remove({ ...options, unique: id });

      if (label) {
        const count = appLoadingBarState.get(label) ?? 0;
        const newCount = count - 1;

        if (newCount < 1) {
          appLoadingBarState.delete(label);
        } else {
          appLoadingBarState.set(label, newCount);
        }
      }
    };

    return new Observable((subscriber) => {
      const sub = source.subscribe({
        next(value) {
          if (value[1].isLoading) {
            markLoading();
          } else {
            removeLoading();
          }

          subscriber.next(value);
        },
        error(error) {
          removeLoading();
          subscriber.error(error);
        },
        complete() {
          removeLoading();
          subscriber.complete();
        },
      });

      return () => {
        removeLoading();
        sub.unsubscribe();
      };
    });
  };
}

// We just track the state for debugging purposes (so that, if the pending bar won't go away,
// we can get an idea of what's causing it).
const appLoadingBarState = new Map<string, number>();

// Added to global state for debugging purposes in the console
(globalThis as any).appLoadingBarState = appLoadingBarState;
