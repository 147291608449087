import type { ApiTypes, ApiResponse, ApiResponseBody, ErrorResponse, StatusCode } from "@apps/api-server/src/api";
import { Simplify } from "type-fest";

export { ApiTypes, ApiResponse, ApiResponseBody, ErrorResponse };

export type ApiInput<T extends keyof ApiTypes = keyof ApiTypes> = ApiTypes[T]["input"];

export type ApiOutput<T extends keyof ApiTypes = keyof ApiTypes> = ApiTypes[T]["output"];

// We're jumping through some hoops here for added type safety.
const ReverseStatusCodeEnum = {
  "-1": "ABORTED",
  "0": "OFFLINE",
  "200": "SUCCESS",
  "400": "400",
  "401": "UNAUTHENTICATED",
  "403": "UNAUTHORIZED",
  "406": "VERSION_MISMATCH",
  "409": "409",
  "424": "424",
  "426": "426",
  "500": "INTERNAL_SERVER_ERROR",
} as const satisfies {
  [code in StatusCode]: string;
};

// Reverses the provided interface and also coerces the keys to numbers.
type ReverseInterface<T extends Record<string, string>> = {
  [K in keyof T as T[K]]: K extends `${infer N extends number}` ? N : never;
};

type TStatusCodeEnum = Simplify<ReverseInterface<typeof ReverseStatusCodeEnum>>;

// This result is type safe in that, if additional StatusCode values are added in the future, typescript
// will ensure that this enum covers them.
export const StatusCodeEnum = Object.fromEntries(
  Object.entries(ReverseStatusCodeEnum).map(([key, value]) => [value, Number(key)] as const),
) as TStatusCodeEnum;
