import { RecordValue } from "libs/schema";
import { combineLatest, debounceTime, map, Observable, of, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ObserveOptions } from "~/environment/RecordLoader";

/**
 * Returns an array of folderId arrays. Each folderId array represents a
 * possible path from the root folder to the current folder.
 */
export type ObserveGroupFoldersResult = [folderPaths: string[][], meta: { isLoading: boolean }];

export function observeTagFolderAncestorIds(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    tagId: string;
    maxDepth?: number;
  },
  options?: ObserveOptions,
) {
  const { tagId, maxDepth = Infinity } = props;

  return observeTagFolderAncestorsInner(
    environment,
    {
      tagId,
      currentDepth: 0,
      maxDepth,
    },
    options,
  );
}

function observeTagFolderAncestorsInner(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    tagId: string;
    currentDepth: number;
    maxDepth: number;
  },
  options?: ObserveOptions,
): Observable<ObserveGroupFoldersResult> {
  const { tagId, currentDepth, maxDepth } = props;
  const { recordLoader } = environment;

  return recordLoader.observeGetTagFolderMembers({ tag_id: tagId }, options).pipe(
    switchMap(([parentFolders, { isLoading: isParentsLoading }]) => {
      const nextDepth = currentDepth + 1;
      const isComplete = parentFolders.length === 0 || nextDepth === maxDepth;

      if (isComplete) {
        const folderPaths = parentFolders.map(({ folder_id }) => [folder_id]);

        return of<ObserveGroupFoldersResult>([folderPaths, { isLoading: isParentsLoading }]);
      }

      const observeNext = (folder: RecordValue<"tag_folder_member">) =>
        observeTagFolderAncestorsInner(
          environment,
          {
            tagId: folder.folder_id,
            currentDepth: nextDepth,
            maxDepth,
          },
          {
            fetchStrategy: options?.fetchStrategy,
            isLoading: isParentsLoading,
          },
        );

      const observables = parentFolders.map(observeNext);

      return combineLatest(observables).pipe(
        map((ancestorsArray): ObserveGroupFoldersResult => {
          let isSomeAncestorLoading = false;

          const folderPaths = parentFolders.flatMap(({ folder_id: parentFolderId }, index) => {
            const [ancestorFolders, { isLoading: isAncestorsLoading }] = ancestorsArray[index]!;

            if (isAncestorsLoading) isSomeAncestorLoading = true;

            if (ancestorFolders.length === 0) {
              return [[parentFolderId]];
            }

            return ancestorFolders.map((folderIds) => {
              return [parentFolderId, ...folderIds];
            });
          });

          return [folderPaths, { isLoading: isSomeAncestorLoading || isParentsLoading }];
        }),
      );
    }),
    // This observable tends to emit a lot of values because of the number of subscriptions
    // it produces. Even reducing emissions to only distinct changes, there are still 100s
    // of emissions in rapid succession. Debouncing helps improve performance tremendously.
    debounceTime(100),
  );
}
