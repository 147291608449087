import { IconButton } from "@mui/material";
import { capitalize } from "lodash-comms";
import { ComponentType, forwardRef, PropsWithChildren } from "react";
import { MdClose } from "react-icons/md";
import { IComposeMessageForm } from "~/components/ComposeMessageContext";
import { Tooltip } from "~/components/Tooltip";
import { AvailableLessonBadge } from "~/environment/lesson-service";
import {
  getClassForPrivateMessageTourStep,
  tour,
} from "~/environment/lesson-service/lessons/private-message-walkthrough";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { useControlState } from "~/components/forms/utils";
import { cx } from "@emotion/css";
import { Link } from "react-router-dom";
import { RiGitBranchLine } from "react-icons/ri";
import { BsLockFill } from "react-icons/bs";
import { SwitchCase } from "~/components/SwitchCase";
import { UnreachableCaseError } from "libs/errors";
import { ThreadVisibility } from "libs/schema";
import { useThreadVisibility } from "~/hooks/useThreadVisibility";
import { useThreadSubject } from "~/hooks/useThreadSubject";
import { closeDraftCommand } from "~/utils/common-commands";

export const Header = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    control: IComposeMessageForm;
    isLessonComplete?: boolean;
    parentThreadId?: string;
  }>
>((props, ref) => {
  const threadType = useControlState(() => props.control.rawValue.type, [props.control]);

  const visibility = useControlState(() => props.control.rawValue.visibility, [props.control]);

  const { visibility: parentThreadVisibility } = useThreadVisibility(props.parentThreadId);

  const { subject: parentThreadSubject } = useThreadSubject(props.parentThreadId);

  return (
    <ThreadLayout.Header ref={ref} theme={visibility === "PRIVATE" ? "dark" : "light"}>
      <ThreadLayout.ContentPanel className={cx("mx-auto flex pt-7 pb-6", getClassForPrivateMessageTourStep(7))}>
        <div className="flex-1 flex flex-col px-6 sm-w:px-8">
          <div className="flex">
            <h1 className="compose-new-post-header text-2xl">
              New <VisibilityLabel visibility={visibility} isLessonComplete={props.isLessonComplete} />{" "}
              <SwitchCase deps={[threadType, props.parentThreadId]}>
                {() => {
                  switch (threadType) {
                    case "COMMS": {
                      return props.parentThreadId ? <>Branch</> : <>Message</>;
                    }
                    case "EMAIL": {
                      return props.parentThreadId ? <>Email Branch</> : <>Email</>;
                    }
                    default: {
                      throw new UnreachableCaseError(threadType);
                    }
                  }
                }}
              </SwitchCase>
            </h1>

            <span className="flex-1" />

            <div className="flex justify-center items-center">
              <Tooltip side="bottom" content="Close">
                <span className="rounded-full group">
                  {/* Doesn't need to be tabbable because of hotkeys */}
                  <IconButton
                    tabIndex={-1}
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      closeDraftCommand.trigger();
                    }}
                    className="scale-125"
                  >
                    <span className="text-slate-9 group-hover:text-black">
                      <MdClose />
                    </span>
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>

          {parentThreadSubject && props.parentThreadId && (
            <div className="mt-2 flex items-center">
              <RiGitBranchLine size="1.5rem" className="mr-2" />

              {parentThreadVisibility === "PRIVATE" && <BsLockFill size="1.2rem" className="mr-2" />}

              <Link to={`/threads/${props.parentThreadId}`} className="italic hover:underline truncate">
                {parentThreadSubject}
              </Link>
            </div>
          )}
        </div>
      </ThreadLayout.ContentPanel>
    </ThreadLayout.Header>
  );
});

const VisibilityLabel: ComponentType<{
  visibility?: null | ThreadVisibility;
  isLessonComplete?: boolean;
}> = (props) => {
  if (!props.visibility) return null;
  if (props.isLessonComplete) {
    return <>{capitalize(props.visibility)}</>;
  }

  if (props.visibility === "PRIVATE") {
    return (
      <Tooltip side="bottom" content="Click to learn about Shared and Private messages.">
        <span className="post-visibility-header text-violet-8 inline-flex" onClick={() => tour.start()}>
          {capitalize(props.visibility)}{" "}
          <AvailableLessonBadge className="relative ml-1 align-super text-[60%] pointer-events-none" inline />
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip side="bottom" content="Click to learn about Shared and Private messages.">
      <span className="text-violet-9" onClick={() => tour.start()}>
        {capitalize(props.visibility)}
        <AvailableLessonBadge className="relative ml-1 align-super text-[60%] pointer-events-none" inline />
      </span>
    </Tooltip>
  );
};
