import { ComponentType, ReactElement, memo } from "react";
import { IListOnEntryActionEvent, List } from "../list";
import { isEqual } from "libs/predicates";
import { navigateToEntry } from "./ContentList";
import {
  EntryActions,
  entryCSSClasses,
  EntryTimestamp,
  OtherCommandEntryAction,
  PrivateEntryIcon,
  Recipients,
  StarredEntryIcon,
  Summary,
  useShouldShowChannelLabels,
} from "./layout";
import { NotificationTimestamp } from "./NotificationEntry";
import { isModKeyActive } from "~/environment/command.service";
import { openLinkInNewTabOrWindow } from "~/environment/navigate.service";
import { Avatar } from "../Avatar";
import { useMessage } from "~/hooks/useMessage";
import { useThread } from "~/hooks/useThread";
import { PointerWithRecord, RecordValue } from "libs/schema";
import { useNotification } from "~/hooks/useNotification";
import { LabelContainer, PermittedGroupChips } from "../LabelChip";
import { useMessageRecipientNames } from "~/hooks/useMessageRecipientNames";
import { useMessageSender } from "~/hooks/useMessageSender";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { Tooltip } from "../Tooltip";
import { MdScheduleSend, MdSync, MdSyncProblem } from "react-icons/md";
import { cx } from "@emotion/css";
import { useIsMessageSent } from "~/hooks/useIsMessageSent";
import { useIsOnline } from "~/hooks/useIsOnline";
import { useTimeFromNow } from "~/hooks/useTimeFromNow";
import { useSoonestScheduledMessageForThread } from "~/hooks/useSoonestScheduledMessageForThread";
import { throwUnreachableCaseError } from "libs/errors";

export function onMessageSelectNavigateToThread({
  entry,
  event,
}: IListOnEntryActionEvent<{
  table: "message";
  id: string;
  record: { thread_id: string };
}>) {
  const url = `/threads/${entry.record.thread_id}?message=${entry.id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(url);
  } else {
    navigateToEntry(entry.id, url);
  }
}

export const MessageEntry: ComponentType<{
  messageId: string;
  relativeOrder: number;
  /**
   * Whether the "scheduled to be sent icon" should be shown only if a message matching the
   * provided messageId is scheduled to be sent or if a thread matching the provided messageId
   * has any messages which are scheduled to be sent.
   */
  showScheduledToBeSentIconFor: "messageId" | "threadId";
  showRecipientNames?: boolean;
  overrideSubject?: string | ReactElement;
  overrideBody?: string | ReactElement;
}> = memo(
  ({
    messageId,
    showRecipientNames = false,
    relativeOrder,
    overrideSubject,
    overrideBody,
    showScheduledToBeSentIconFor,
  }) => {
    const shouldShowChannelLabels = useShouldShowChannelLabels();
    const [message] = useMessage(messageId);
    const [thread] = useThread(message?.thread_id);
    const [notification] = useNotification({ threadId: message?.thread_id });
    const sender = useMessageSender(messageId);
    const entryData = useAsPointerWithRecord("message", message);

    if (!message || !thread || !entryData) return null;

    const isPrivateThread = thread.visibility === "PRIVATE";
    const senderLabel = sender?.label || (sender.isLoading ? "loading..." : "unknown");

    return (
      <List.Entry<PointerWithRecord<"message">> id={messageId} data={entryData} relativeOrder={relativeOrder}>
        <div role="listitem" className={cx(entryCSSClasses, "MessageEntry")}>
          <div className="pr-2 sm-w:pr-3">
            <Avatar label={senderLabel} photoURL={sender?.photoUrl} width="30px" />
          </div>

          <div className="flex flex-col md-w:flex-row flex-1 min-w-0">
            <Recipients
              nonTruncatedSuffix={
                <>
                  {isPrivateThread && <PrivateEntryIcon />}
                  {notification?.is_starred && <StarredEntryIcon />}
                </>
              }
            >
              {showRecipientNames ?
                <div className="truncate">
                  <RecipientNames messageId={messageId} />
                </div>
              : <>
                  <span className="mr-3 truncate">{senderLabel}</span>
                </>
              }
            </Recipients>

            <Summary
              subject={overrideSubject ?? message.subject}
              formatAsReply={message.is_reply && message.type === "COMMS"}
              details={isPrivateThread ? "private message" : (overrideBody ?? message.body_text)}
            />
          </div>

          <div className="mx-4 flex space-x-3">
            <ScheduledToBeSentIcons message={message} showScheduledToBeSentIconFor={showScheduledToBeSentIconFor} />
          </div>

          <LabelContainer>
            {shouldShowChannelLabels && <PermittedGroupChips threadId={message.thread_id} />}
          </LabelContainer>

          <EntryActions
            defaultComponent={
              notification?.has_reminder ?
                <NotificationTimestamp notification={notification} sentAt={message.sent_at} />
              : <EntryTimestamp datetime={message.sent_at} />
            }
          >
            <OtherCommandEntryAction />
          </EntryActions>
        </div>
      </List.Entry>
    );
  },
  isEqual,
);

/* -----------------------------------------------------------------------------------------------*/

const RecipientNames: ComponentType<{ messageId: string }> = (props) => {
  const [names, { isLoading }] = useMessageRecipientNames(props.messageId);

  return <>{names.join(", ") || (isLoading ? "loading..." : "unknown")}</>;
};

/* -------------------------------------------------------------------------------------------------
 *  ScheduledToBeSentIcons
 * -------------------------------------------------------------------------------------------------
 */

export const ScheduledToBeSentIcons: ComponentType<{
  message: Pick<RecordValue<"message">, "thread_id" | "sent_at" | "last_edited_at" | "server_updated_at">;
  /**
   * Whether the "scheduled to be sent icon" should be shown only if a message matching the
   * provided messageId is scheduled to be sent or if a thread matching the provided messageId
   * has any messages which are scheduled to be sent.
   */
  showScheduledToBeSentIconFor: "messageId" | "threadId";
}> = (props) => {
  const isOnline = useIsOnline();

  const [soonestScheduledMessageForThread] = useSoonestScheduledMessageForThread({
    threadId: props.showScheduledToBeSentIconFor === "threadId" ? props.message.thread_id : undefined,
  });

  const message =
    props.showScheduledToBeSentIconFor === "messageId" ? props.message
    : props.showScheduledToBeSentIconFor === "threadId" ? soonestScheduledMessageForThread
    : throwUnreachableCaseError(props.showScheduledToBeSentIconFor);

  const scheduledToBeSentAt = useTimeFromNow({ timestamp: message?.sent_at });

  const canCancelSendingMessage = !useIsMessageSent(message);

  if (!message) return null;

  const isCreationSyncedWithServer = isMessageCreationSyncedWithServer(message);
  const isEditSyncedWithServer = isMessageEditSyncedWithServer(message);

  return (
    <>
      {!isCreationSyncedWithServer && (
        <Tooltip
          side="bottom"
          // We're choosing to use the language "uploading message" instead of "sending message" because a message
          // isn't actually "sent" until it's "scheduled to be sent" time has passed.
          content={isOnline ? `Uploading message ...` : `Waiting for network connection to upload this message`}
        >
          {isOnline ? onlineIcon : offlineIcon}
        </Tooltip>
      )}

      {isCreationSyncedWithServer && !isEditSyncedWithServer && (
        <Tooltip
          side="bottom"
          content={isOnline ? `Uploading edits...` : `Waiting for network connection to upload edits`}
        >
          {isOnline ? onlineIcon : offlineIcon}
        </Tooltip>
      )}

      {canCancelSendingMessage && (
        <Tooltip
          side="bottom"
          content={`This message is scheduled to be sent ${scheduledToBeSentAt}. You can cancel it before then.`}
        >
          {scheduledToBeSentIcon}
        </Tooltip>
      )}
    </>
  );
};

export function isMessageCreationSyncedWithServer(message: Pick<RecordValue<"message">, "server_updated_at">) {
  return !!message.server_updated_at;
}

export function isMessageEditSyncedWithServer(
  message: Pick<RecordValue<"message">, "server_updated_at" | "last_edited_at">,
) {
  return (
    !!message.server_updated_at && (!message.last_edited_at || message.last_edited_at <= message.server_updated_at)
  );
}

const scheduledToBeSentIconCSS = cx(
  "flex justify-center items-center",
  "hover:cursor-pointer text-slate-8 scale-125",
  "hover:text-black",
);

const scheduledToBeSentIcon = (
  <span className={cx(scheduledToBeSentIconCSS, "mr-1")}>
    <MdScheduleSend />
  </span>
);

const onlineIcon = (
  <span className={cx(scheduledToBeSentIconCSS, "mr-1")}>
    <MdSync />
  </span>
);

const offlineIcon = (
  <span className={cx(scheduledToBeSentIconCSS, "mr-1")}>
    <MdSyncProblem />
  </span>
);

/* -----------------------------------------------------------------------------------------------*/
