import { UnreachableCaseError } from "libs/errors";
import {
  DraftAttachmentDoc,
  DraftGroupRecipientDoc,
  DraftUserRecipientDoc,
  generateRecordId,
  getMapRecords,
  getPointer,
  ThreadVisibility,
} from "libs/schema";
import { op } from "libs/transaction";
import { IEditorMention } from "~/components/forms/message-editor";
import { IRecipientOption } from "~/components/forms/ThreadRecipients";
import { toast } from "~/environment/toast-service";
import * as ops from "libs/actions";
import { uniqBy } from "lodash-es";
import { debouncedWrite, getRecordsWithTransactionApplied, withTransaction, write } from "./write";
import dayjs from "dayjs";
import { GetRecordOptions } from "~/environment/RecordLoader";
import { EnqueueOptions } from "~/environment/TransactionQueue";

export type DraftRecipient = { type: IRecipientOption["type"]; id: string };

export function mapRecipientOptionToDraftRecipient(option: IRecipientOption): DraftRecipient {
  return { type: option.type, id: option.value };
}

/* -------------------------------------------------------------------------------------------------
 *  createNewThreadDraft
 * -------------------------------------------------------------------------------------------------
 */

export interface CreateNewThreadDraftProps {
  type: "COMMS" | "EMAIL";
  draftId: string;
  currentUserId: string;
  ownerOrganizationId: string;
  threadId: string;
  branchedFrom?: null | {
    threadId: string;
    messageId: string;
  };
  subject?: string | null;
  visibility?: ThreadVisibility | null;
  bodyHTML?: string;
  to?: DraftRecipient[];
  cc?: DraftRecipient[];
  bcc?: DraftRecipient[];
  userMentions?: IEditorMention[];
  groupMentions?: IEditorMention[];
  attachments?: DraftAttachmentDoc[];
}

export const createNewThreadDraft = withTransaction(
  "createNewThreadDraft",
  async (environment, transaction, props: CreateNewThreadDraftProps) => {
    switch (props.type) {
      case "COMMS": {
        if (props.cc && props.cc.length > 0) {
          alert("Draft cc recipients not supported yet.");
          throw new Error("[mergeDraft] draft cc recipients not supported yet.");
        } else if (props.bcc && props.bcc.length > 0) {
          alert("Draft bcc recipients not supported yet.");
          throw new Error("[mergeDraft] draft bcc recipients not supported yet");
        }

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.createDraft({
            draftId: props.draftId,
            userId: props.currentUserId,
            type: props.type,
            isReply: false,
            ownerOrganizationId: props.ownerOrganizationId,
            threadId: props.threadId,
            attachments: props.attachments,
            bodyHTML: props.bodyHTML,
            branchedFrom: props.branchedFrom,
            subject: props.subject,
            visibility: props.visibility,
            to: getToRecipients(props),
          }),
        );

        break;
      }
      case "EMAIL": {
        throw new Error("[mergeDraft] email drafts are not yet supported.");
      }
      default: {
        throw new UnreachableCaseError(props.type);
      }
    }

    await write(environment, {
      transaction,
      canUndo: false,
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  createReplyDraft
 * -------------------------------------------------------------------------------------------------
 */

export interface CreateReplyDraftProps {
  type: "COMMS" | "EMAIL";
  draftId: string;
  currentUserId: string;
  ownerOrganizationId: string;
  threadId: string;
  branchedFrom?: null | {
    threadId: string;
    messageId: string;
  };
  bodyHTML?: string;
  to?: DraftRecipient[];
  cc?: DraftRecipient[];
  bcc?: DraftRecipient[];
  userMentions?: IEditorMention[];
  groupMentions?: IEditorMention[];
  attachments?: DraftAttachmentDoc[];
}

export const createReplyDraft = withTransaction(
  "createReplyDraft",
  async (environment, transaction, props: CreateReplyDraftProps) => {
    switch (props.type) {
      case "COMMS": {
        if (props.cc && props.cc.length > 0) {
          alert("Draft cc recipients not supported yet.");
          throw new Error("[mergeDraft] draft cc recipients not supported yet.");
        } else if (props.bcc && props.bcc.length > 0) {
          alert("Draft bcc recipients not supported yet.");
          throw new Error("[mergeDraft] draft bcc recipients not supported yet");
        }

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.createDraft({
            draftId: props.draftId,
            userId: props.currentUserId,
            type: props.type,
            isReply: true,
            ownerOrganizationId: props.ownerOrganizationId,
            threadId: props.threadId,
            attachments: props.attachments,
            bodyHTML: props.bodyHTML,
            branchedFrom: props.branchedFrom,
            to: getToRecipients(props),
          }),
        );

        break;
      }
      case "EMAIL": {
        throw new Error("[mergeDraft] email drafts are not yet supported.");
      }
      default: {
        throw new UnreachableCaseError(props.type);
      }
    }

    await write(environment, {
      transaction,
      canUndo: false,
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  setDraft
 * -------------------------------------------------------------------------------------------------
 */

export interface SetDraftProps {
  type: "COMMS" | "EMAIL";
  draftId: string;
  currentUserId: string;
  ownerOrganizationId: string;
  is_reply: boolean;
  is_edit: boolean;
  threadId: string;
  branchedFrom?: null | {
    threadId: string;
    messageId: string;
  };
  subject?: string | null;
  bodyHTML?: string;
  to?: DraftRecipient[];
  cc?: DraftRecipient[];
  bcc?: DraftRecipient[];
  visibility?: ThreadVisibility | null;
  userMentions?: IEditorMention[];
  groupMentions?: IEditorMention[];
  attachments?: DraftAttachmentDoc[];
  noDebounce?: boolean;
}

/**
 * This is intended to be used for updates, but since it's implemented as a "set" I decided to name it that.
 * Prefer "createDraft" for creating a new draft. This function should not be used for "undeleting" a draft.
 * We attempt to guard against accidental undeletes by checking if the draft has been deleted before updating it.
 */
export const setDraft = withTransaction("setDraft", async (environment, transaction, props: SetDraftProps) => {
  const debounceOptions: EnqueueOptions["debounce"] | undefined =
    props.noDebounce ? undefined : { key: getDraftDebounceKey(props.draftId), debounceMs: 1500, maxWaitMs: 4000 };

  await debouncedWrite(environment, debounceOptions, async () => {
    const [draft] = environment.db.getRecord("draft", props.draftId);

    if (draft?.deleted_at) return;

    switch (props.type) {
      case "COMMS": {
        if (props.cc && props.cc.length > 0) {
          alert("Draft cc recipients not supported yet.");
          throw new Error("[mergeDraft] draft cc recipients not supported yet.");
        } else if (props.bcc && props.bcc.length > 0) {
          alert("Draft bcc recipients not supported yet.");
          throw new Error("[mergeDraft] draft bcc recipients not supported yet");
        }

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.mergeDraft({
            draftId: props.draftId,
            userId: props.currentUserId,
            type: props.type,
            is_edit: props.is_edit,
            is_reply: props.is_reply,
            ownerOrganizationId: props.ownerOrganizationId,
            threadId: props.threadId,
            attachments: props.attachments,
            bodyHTML: props.bodyHTML,
            branchedFrom: props.branchedFrom,
            subject: props.subject,
            visibility: props.visibility,
            to: getToRecipients(props),
          }),
        );

        break;
      }
      case "EMAIL": {
        throw new Error("[mergeDraft] email drafts are not yet supported.");
      }
      default: {
        throw new UnreachableCaseError(props.type);
      }
    }

    return {
      transaction,
      canUndo: false,
    };
  });
});

/* -------------------------------------------------------------------------------------------------
 *  sendDraft
 * -------------------------------------------------------------------------------------------------
 */

export const sendDraft = withTransaction(
  "sendDraft",
  async (
    environment,
    transaction,
    props: SetDraftProps & {
      scheduledToBeSentAt: Date;
      afterUndo?: () => void;
      noToast?: boolean;
    },
  ) => {
    const { recordLoader, logger } = environment;

    // Wait for any debounced writes associated with the draft to finish being added to the tx queue.
    await debouncedWrite.flush([getDraftDebounceKey(props.draftId)]);

    const fetchOptions: GetRecordOptions = { fetchStrategy: "cache-first" };

    // Check locally to make sure the draft exists
    const [existingDraft] = await environment.recordLoader.getRecord("draft", props.draftId, fetchOptions);
    if (!existingDraft) return;

    switch (props.type) {
      case "COMMS": {
        if (props.cc && props.cc.length > 0) {
          alert("Draft cc recipients not supported yet.");
          throw new Error("[mergeDraft] draft cc recipients not supported yet.");
        } else if (props.bcc && props.bcc.length > 0) {
          alert("Draft bcc recipients not supported yet.");
          throw new Error("[mergeDraft] draft bcc recipients not supported yet");
        }

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.mergeDraft({
            draftId: props.draftId,
            userId: props.currentUserId,
            type: props.type,
            is_edit: props.is_edit,
            is_reply: props.is_reply,
            ownerOrganizationId: props.ownerOrganizationId,
            threadId: props.threadId,
            attachments: props.attachments,
            bodyHTML: props.bodyHTML,
            branchedFrom: props.branchedFrom,
            subject: props.subject,
            visibility: props.visibility,
            to: getToRecipients(props),
          }),
        );

        break;
      }
      case "EMAIL": {
        throw new Error("[mergeDraft] email drafts are not yet supported.");
      }
      default: {
        throw new UnreachableCaseError(props.type);
      }
    }

    const afterRecordMap = await getRecordsWithTransactionApplied(environment, { transaction }, fetchOptions);

    const [draft] = getMapRecords(afterRecordMap, "draft");

    if (!draft) {
      logger.error(`[mergeDraft] ${props.draftId} not found after merge.`);
      throw new Error(`[mergeDraft] draft not found.`);
    }

    if (draft.is_reply) {
      logger.notice({ draftId: draft.id }, `[mergeDraft] [reply] sending draft ...`);

      const [thread] = await recordLoader.getRecord("thread", draft.thread_id, fetchOptions);

      if (!thread) {
        logger.error(`[mergeDraft] [reply] thread not found ${draft.thread_id}`);

        throw new Error(`[mergeDraft] [reply] thread not found.`);
      }

      ops.applyOperationsToTransaction(
        transaction,
        ops.draft.sendDraftReply({
          scheduledToBeSentAt: props.scheduledToBeSentAt,
          draft,
          thread,
        }),
      );
    } else {
      logger.notice({ draftId: draft.id }, `[mergeDraft] [new] sending draft ...`);

      const branchedFromMessage =
        draft.branched_from_message_id ?
          await recordLoader.getRecord("message", draft.branched_from_message_id, fetchOptions).then(([m]) => m)
        : undefined;

      ops.applyOperationsToTransaction(
        transaction,
        ops.draft.sendNewThreadDraft({
          scheduledToBeSentAt: props.scheduledToBeSentAt,
          draft,
          branchedFromMessage: branchedFromMessage || undefined,
        }),
      );
    }

    // Delete the draft now that it's been sent.
    transaction.operations.push(op.delete("draft", draft));

    // Delete the branched draft timeline entry if it exists.
    if (draft.branched_from_thread_id) {
      const pointer = getPointer("thread_timeline", {
        entry_id: props.draftId,
        thread_id: draft.branched_from_thread_id,
      });

      op.delete(pointer);
    }

    await write(environment, {
      transaction,
      canUndo: () => dayjs().isBefore(props.scheduledToBeSentAt),
      onOptimisticUndo: () => {
        toast("vanilla", {
          subject: "Unsending draft...",
        });

        props.afterUndo?.();
      },
      onServerUndo: () => {
        toast("vanilla", {
          subject: "Unsending draft...Done!",
        });
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  deleteDraft
 * -------------------------------------------------------------------------------------------------
 */

export const deleteDraft = withTransaction(
  "deleteDraft",
  async (
    environment,
    transaction,
    props: {
      currentUserId: string;
      draftId: string;
      afterUndo?: () => void;
    },
  ) => {
    environment.logger.info({ draftId: props.draftId }, `[deleteDraft] deleting draft ...`);

    // Wait for any debounced writes associated with the draft to finish being added to the tx queue.
    await debouncedWrite.flush([getDraftDebounceKey(props.draftId)]);

    const [draft] = await environment.recordLoader.getRecord("draft", props.draftId, { fetchStrategy: "cache-first" });

    if (!draft) {
      environment.logger.error(`Draft with ID ${props.draftId} not found.`);
      return;
    }

    transaction.operations.push(op.delete("draft", draft));

    if (draft.branched_from_thread_id) {
      const pointer = getPointer("thread_timeline", {
        entry_id: props.draftId,
        thread_id: draft.branched_from_thread_id,
      });

      transaction.operations.push(
        op.upsert(pointer, {
          onUpdate: [op.delete(pointer)],
        }),
      );
    }

    try {
      await write(environment, {
        transaction,
        onOptimisticUndo: () => {
          toast("vanilla", {
            subject: "Draft deleted.",
          });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: "Failed to delete draft.",
      });

      throw error;
    }
  },
);

/* -----------------------------------------------------------------------------------------------*/

export function getToRecipients(props: Pick<SetDraftProps, "to" | "userMentions" | "groupMentions">) {
  const { userMentions = [], groupMentions = [], to = [] } = props;

  // Note that drafts for replies only add recipients that are *new* to the thread to the `to`
  // field. If someone is mentioned in a reply who is already participating in the thread, they
  // will not be added to the `to` field. Because of this, we need to ensure that all mentioned
  // users and groups are included as recipients when constructing the message.
  const toRecipientOptions = uniqBy(
    [
      ...userMentions.map((r) => ({ ...r, is_mentioned: true })),
      ...groupMentions.map((r) => ({ ...r, is_mentioned: true })),
      ...to.map((r) => ({ ...r, is_mentioned: false, priority: 300 })),
    ],
    (r) => r.id,
  );

  return toRecipientOptions.map((recipient) => {
    switch (recipient.type) {
      case "user": {
        return {
          id: generateRecordId("draft_user_recipient_doc", {
            type: "USER",
            user_id: recipient.id,
          }),
          type: "USER",
          user_id: recipient.id,
          priority: recipient.priority,
          is_implicit: false,
          is_mentioned: recipient.is_mentioned,
        } satisfies DraftUserRecipientDoc;
      }
      case "group": {
        return {
          id: generateRecordId("draft_group_recipient_doc", {
            type: "GROUP",
            group_id: recipient.id,
          }),
          type: "GROUP",
          group_id: recipient.id,
          priority: recipient.priority,
          is_implicit: false,
          is_mentioned: recipient.is_mentioned,
        } satisfies DraftGroupRecipientDoc;
      }
      case "email": {
        throw new Error("Email drafts are not yet supported.");
      }
      default: {
        throw new UnreachableCaseError(recipient.type);
      }
    }
  });
}

/* -----------------------------------------------------------------------------------------------*/

export function getDraftDebounceKey(draftId: string) {
  return `draft-${draftId}`;
}

/* -----------------------------------------------------------------------------------------------*/
