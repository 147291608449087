import { forwardRef, RefObject, useRef } from "react";
import { IComposeMessageForm, usePromptToRemoveRecipientOnMentionRemoval } from "~/components/ComposeMessageContext";
import { IListRef } from "~/components/list";
import { IRichTextEditorRef } from "~/components/forms/message-editor";
import {
  ComposeMessageReplyBase,
  ComposeReplyHint,
  useAddNewGroupMentionsAsRecipients,
  useAddNewUserMentionsAsRecipients,
} from "~/components/ComposeReplyBase";
import { BranchedThreadDraftHeader } from "./BranchedThreadDraftHeader";
import { DraftActions } from "../utils";
import { useControlState } from "~/components/forms/utils";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";

export const BranchedThreadDraft = forwardRef<
  IRichTextEditorRef,
  {
    control: IComposeMessageForm;
    saveDraftFn: () => Promise<void>;
    listRef: RefObject<IListRef<TThreadTimelineEntry>>;
    treatLessonAsCompleted: boolean;
  }
>((props, ref) => {
  const formRef = useRef<HTMLFormElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const visibility = useControlState(() => props.control.rawValue.visibility, [props.control]);

  const draftType = useControlState(() => props.control.rawValue.type, [props.control]);

  useAddNewGroupMentionsAsRecipients(
    props.control.controls.body.controls.groupMentions,
    props.control.controls.recipients.controls.to,
  );

  useAddNewUserMentionsAsRecipients(
    props.control.controls.body.controls.userMentions,
    props.control.controls.recipients.controls.to,
  );

  usePromptToRemoveRecipientOnMentionRemoval(props.control);

  return (
    <>
      <ComposeMessageReplyBase
        ref={ref}
        control={props.control}
        saveDraftFn={props.saveDraftFn}
        header={
          <BranchedThreadDraftHeader control={props.control} treatLessonAsCompleted={props.treatLessonAsCompleted} />
        }
        draftActions={<DraftActions visibility={visibility} draftType={draftType} />}
        formRef={formRef}
        wrapperRef={wrapperRef}
        focusOnInit
      />

      <ComposeReplyHint />
    </>
  );
});
