import { wait } from "libs/promise-utils";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  fromEvent,
  NEVER,
  Subscription,
  switchMap,
  take,
} from "rxjs";
import { ClientEnvironment } from "./ClientEnvironment";
import { TransactionQueue } from "./TransactionQueue";
import { toast } from "./toast-service";

export class IsPendingUpdateService {
  static readonly isLoadingKey = "pending-update";

  private _isUpdating$ = new BehaviorSubject(false);
  readonly isUpdating$ = this._isUpdating$.pipe(distinctUntilChanged());

  private showPendingChangesWarning$ = new BehaviorSubject(true);
  private subscription: Subscription;

  constructor(private env: Pick<ClientEnvironment, "isLoading">) {
    // When offline, we only care about the tx queue offline loading state.
    this.subscription = this.env.isLoading
      .isLoading$({ key: TransactionQueue.isLoadingKeyOffline })
      .subscribe(this._isUpdating$);
  }

  isUpdating() {
    return this._isUpdating$.getValue();
  }

  add(options?: { unique?: string }): Disposable;
  add<T>(promise: Promise<T>, options?: { unique?: string }): Promise<T>;
  add(a?: Promise<unknown> | { unique?: string }, b?: { unique?: string }) {
    if (a instanceof Promise) {
      return this.env.isLoading.add(a, { ...b, key: IsPendingUpdateService.isLoadingKey });
    }

    return this.env.isLoading.add({ ...a, key: IsPendingUpdateService.isLoadingKey });
  }

  remove(options?: { unique?: string }) {
    this.env.isLoading.remove({ ...options, key: IsPendingUpdateService.isLoadingKey });
  }

  /** Returns a promise which resolves when there are no pending updates */
  async whenNoPendingUpdates(props: { maxWaitMs?: number } = {}) {
    const promise = firstValueFrom(this.isUpdating$.pipe(filter((v) => !v)));

    if (props.maxWaitMs) {
      await Promise.race([promise, wait(props.maxWaitMs)]);
    } else {
      await promise;
    }
  }

  showPendingUpdatesWarning(value: boolean) {
    this.showPendingChangesWarning$.next(value);
  }

  init(env: Pick<ClientEnvironment, "transactionQueue" | "network">) {
    const WINDOW_UNLOAD_EVENTS$ = fromEvent<BeforeUnloadEvent>(window, "beforeunload", {
      capture: true,
    });

    env.network.isOnline$.subscribe((isOnline) => {
      this.subscription.unsubscribe();

      if (isOnline) {
        this.subscription = this.env.isLoading
          .isLoading$({ key: [IsPendingUpdateService.isLoadingKey, TransactionQueue.isLoadingKey] })
          .subscribe(this._isUpdating$);
      } else {
        this.subscription = this.env.isLoading
          .isLoading$({ key: TransactionQueue.isLoadingKeyOffline })
          .subscribe(this._isUpdating$);
      }
    });

    // Here we show the user a warning if they have uncommitted pending
    // updates with the server. Note, I think on every modern browser
    // the message we provide will be ignored and they'll just say
    // something like, "You have unsaved changes." But that's the best
    // we've got.
    //
    // Taken from
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#examples
    combineLatest([this.isUpdating$, this.showPendingChangesWarning$])
      .pipe(
        switchMap(([hasPendingUpdates, showWarning]) =>
          hasPendingUpdates && showWarning ? WINDOW_UNLOAD_EVENTS$ : NEVER,
        ),
      )
      .subscribe((e) => {
        // Immediately flush all debounced transactions. Uploading them will still be async, but at least this way,
        // if the user chooses to stay on the page, the upload might happen faster.
        env.transactionQueue.flush();
        this.warnUserOfPendingUpdates();
        e.returnValue = "Are you sure? You have changes still being uploaded to the server.";
        e.preventDefault();
      });
  }

  private warnUserOfPendingUpdates() {
    const close = toast("vanilla", {
      subject: "Uploading changes...",
      durationMs: Infinity,
    });

    this.isUpdating$
      .pipe(
        filter((v) => !v),
        take(1),
      )
      .subscribe(() => {
        toast("vanilla", {
          subject: "Uploading changes...Complete!",
          description: "You can now close this page.",
        });

        close();
      });
  }
}
