import { usePrevious } from "react-use";

export function useDidDepsChange(deps: unknown[]): boolean {
  const previousDeps = usePrevious(deps);

  const depsChanged = !areHookInputsEqual(deps, previousDeps);

  return depsChanged;
}

function areHookInputsEqual(nextDeps: unknown[], prevDeps: unknown[] | undefined): boolean {
  // If there are no previous dependencies, they have changed
  if (prevDeps === undefined) {
    return false;
  }

  // Dependencies length mismatch means they have changed
  if (nextDeps.length !== prevDeps.length) {
    return false;
  }

  // Check if any dependency has changed
  for (let i = 0; i < nextDeps.length; i++) {
    if (nextDeps[i] === prevDeps[i]) continue;
    return false;
  }

  return true;
}
