import { ComponentType } from "react";
import { inboxState } from "~/pages/inbox/state";

/* -------------------------------------------------------------------------------------------------
 * EndOfListMsg
 * -----------------------------------------------------------------------------------------------*/

export const EndOfListMsg: ComponentType<{
  isEnd: boolean;
}> = (props) => {
  const { inboxEntries, filteredInboxEntries, clearSelectedGroups } = inboxState();
  const remainingEntries = inboxEntries.length - filteredInboxEntries.length;

  if (!props.isEnd) return null;

  return (
    <div className="text-slate-9 mt-8 flex-1 flex justify-center">
      {remainingEntries > 0 ? (
        <>
          <span>{remainingEntries} hidden messages &bull;&nbsp;</span>
          <button className="text-slate-8 hover:text-slate-9" onClick={() => clearSelectedGroups()}>
            Clear filters
          </button>
        </>
      ) : (
        <span className="uppercase">end</span>
      )}
    </div>
  );
};
