import { ClientRecordLoaderObserveGetRecordResultMeta } from "~/environment/RecordLoader";
import { useOrganizationUserMember } from "./useOrganizationUserMember";

export type UseIsUserDeletedFromOrgResult = [boolean, ClientRecordLoaderObserveGetRecordResultMeta];

export function useIsUserDeletedFromOrg(props: {
  userId: string | null | undefined;
  organizationId: string | null | undefined;
}): UseIsUserDeletedFromOrgResult {
  const [organizationUserMember, meta] = useOrganizationUserMember(props);

  return [!!organizationUserMember?.deleted_at, meta];
}
