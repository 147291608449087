import { TimeoutError } from "./errors";

export function wait(ms: number) {
  return new Promise((res) => setTimeout(res, ms));
}

/**
 * A promise that can be resolved or rejected from the outside.
 */
export class DeferredPromise<T, Data = undefined> {
  static from<T, Data = undefined>(promise: Promise<T>, options?: { data?: Data; abortSignal?: AbortSignal }) {
    const deferred = new DeferredPromise<T, Data>(options);
    promise.then(deferred.resolve).catch(deferred.reject);
    return deferred;
  }

  public resolve!: (value: T) => void;
  public reject!: (error: any) => void;
  public settled = false;
  public value!: T;
  public error: any;
  public promise: Promise<T>;
  public data: Data;

  constructor(props: { data?: Data; abortSignal?: AbortSignal } = {}) {
    let originalResolve: (value: T) => void;
    let originalReject: (error: any) => void;

    this.data = props.data as Data;
    const { abortSignal } = props;

    this.promise = new Promise((resolve, reject) => {
      originalResolve = resolve;
      originalReject = reject;
    });

    this.resolve = (value) => {
      if (this.settled) return;
      this.settled = true;
      this.value = value;
      originalResolve(value);
    };

    this.reject = (error) => {
      if (this.settled) return;
      this.settled = true;
      this.error = error;
      originalReject(error);
    };

    if (abortSignal) {
      const listener = () => this.reject(abortSignal.aborted);

      if (abortSignal.aborted) {
        listener();
      } else {
        abortSignal.addEventListener("abort", listener);

        this.promise.finally(() => {
          abortSignal.removeEventListener("abort", listener);
        });
      }
    }
  }
}

export async function promiseAllKeyed<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [key: string]: Promise<any> },
>(obj: T) {
  const entries = Object.entries(obj);

  const results = await Promise.all(entries.map(([key, value]) => value.then((result) => [key, result] as const)));

  return Object.fromEntries(results) as Promise<{
    [K in keyof T]: Awaited<T[K]>;
  }>;
}

export async function promiseAllSettledKeyed<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [key: string]: Promise<any> },
>(obj: T) {
  const entries = Object.entries(obj);

  const results = await Promise.all(
    entries.map(([key, value]) =>
      value.then(
        (result) => [key, { status: "fulfilled", value: result }],
        (error) => [key, { status: "rejected", reason: error }],
      ),
    ),
  );

  return Object.fromEntries(results) as Promise<{
    [K in keyof T]: Awaited<PromiseSettledResult<T[K]>>;
  }>;
}

export async function promiseRaceKeyed<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [key: string]: Promise<any> },
>(obj: T) {
  const entries = Object.entries(obj);

  const result = await Promise.race(entries.map(([key, value]) => value.then((result) => [key, result] as const)));

  return result as { [K in keyof T]: Awaited<T[K]> }[keyof T];
}

/**
 * Accepts an array of booleans and/or Promise<boolean>s and immediately resolves to true if any
 * promise resolves to true, else resolves to false.
 */
export async function promiseRaceSome(promises: Array<boolean | Promise<boolean>>): Promise<boolean> {
  const promise = promiseRaceMatchSome(promises, (value) => value);
  const value = await promise;
  return !!value;
}

/**
 * Accepts an array of booleans and/or Promise<boolean>s and immediately resolves to false if any
 * promise resolves to false, else resolves to true.
 */
export async function promiseRaceEvery(promises: Array<boolean | Promise<boolean>>): Promise<boolean> {
  const deferred = new DeferredPromise<boolean>();

  const allPromises = Promise.all(
    promises.map(async (promise) => {
      if (await promise) return;
      deferred.resolve(false);
    }),
  );

  allPromises
    .then(() => {
      if (deferred.settled) return;
      deferred.resolve(true);
    })
    .catch((err) => {
      if (deferred.settled) {
        console.error("promiseRaceEvery a promise errored", err);
        return;
      }

      deferred.reject(err);
    });

  return deferred.promise;
}

export function promiseRaceMatchSome<T>(
  promises: Array<T | Promise<T>>,
  predicate: (value: T) => boolean,
): Promise<T | undefined> {
  const deferred = new DeferredPromise<T | undefined>();

  const allPromises = Promise.all(
    promises.map(async (promise) => {
      const value = await promise;

      if (!predicate(value)) return;

      deferred.resolve(value);
    }),
  );

  allPromises
    .then(() => {
      if (deferred.settled) return;
      deferred.resolve(undefined);
    })
    .catch((err) => {
      if (deferred.settled) {
        console.error("promiseRaceMatch a promise errored", err);
        return;
      }

      deferred.reject(err);
    });

  return deferred.promise;
}

export type PromiseAllSettledGroupedResult<T> = {
  fulfilled: Array<T>;
  rejected: Array<{ index: number; reason: any }>;
};

export async function promiseAllSettledGrouped<T>(promises: Promise<T>[]): Promise<PromiseAllSettledGroupedResult<T>> {
  const results = await Promise.allSettled(promises);

  const grouped = results.reduce(
    (acc, result, index) => {
      if (result.status === "fulfilled") {
        acc.fulfilled.push(result.value);
      } else {
        acc.rejected.push({ index, reason: result.reason });
      }

      return acc;
    },
    {
      fulfilled: [],
      rejected: [],
    } as PromiseAllSettledGroupedResult<T>,
  );

  return grouped;
}

export type PromiseRetryOnErrorOptions = {
  attempts?: number;
  /** how long to wait between attempts */
  waitMs: number;
  maxWaitMs?: number;
  exponentialBackoff?: boolean;
  /** If provided, only errors matching this filter will be retried. */
  errorFilter?: (error: any) => boolean;
};

export type PromiseRetryOnErrorFnProps = {
  /** Starts at 1 */
  attempt: number;
  lastError?: unknown;
};

export async function promiseRetryOnError<T>(
  options: PromiseRetryOnErrorOptions,
  fn: (props: PromiseRetryOnErrorFnProps) => Promise<T> | T,
) {
  const { attempts = Infinity, waitMs, exponentialBackoff, maxWaitMs, errorFilter } = options;

  let attempt = 0;
  let lastError: unknown;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      attempt++;

      return await fn({ attempt, lastError });
    } catch (error) {
      if (attempt > attempts) {
        throw error;
      } else if (errorFilter && !errorFilter(error)) {
        throw error;
      }

      lastError = error;

      if (exponentialBackoff) {
        const targetDelay = 2 ** attempt * waitMs;
        const actualDelay = Math.min(targetDelay, maxWaitMs || targetDelay);
        await wait(actualDelay);
      } else {
        await wait(waitMs);
      }
    }
  }
}

/**
 * returns a promise that throws an error if the signal is aborted.
 */
export async function getAbortSignalPromise(signal: AbortSignal) {
  return new Promise<never>((_, reject) => {
    if (signal.aborted) {
      reject();
    } else {
      signal.onabort = () => {
        reject();
      };
    }
  });
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * Accepts a function returning a promise and wraps it so that calls to the wrapped function are delegated
 * to the underlying provided function but that the result is a promise of type `Promise<void>`. Additionally,
 * repeated calls return the same promise until the pending call resolves.
 */
export function onlyCallFnOnceWhilePreviousCallIsPending<T extends (...args: any[]) => Promise<unknown>>(
  fn: T,
): (...args: Parameters<T>) => Promise<void> {
  let previous: Promise<void> | null = null;

  return (...args: Parameters<T>) => {
    if (previous) return previous;

    previous = fn(...args)
      .then(() => undefined)
      .finally(() => {
        previous = null;
      });

    return previous;
  };
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * The provided async function will be wrapped such that the first call executes immediately (i.e. as normal).
 * Subsequent calls will be scheduled to execute after the previous call has completed. Multiple
 * subsequent calls will be coalesced into a single call that executes with the most recent arguments provided.
 * Multiple calls all receive back the same promise. Because multiple calls don't execute immediately, it's
 * important that the provided arguments aren't mutated before the returned promise resolves.
 *
 * Optionally, provide a function which recieves the functions arguments and returns a cache key string.
 */
export function throttleAsyncFn<Args extends any[], R>(
  func: (...args: Args) => Promise<R>,
  getCacheKey?: (...args: Args) => string,
) {
  type NextCache = { promise: Promise<R>; args: Args };

  const promiseCache = new Map<
    string, // cache key
    { executingPromise: Promise<R>; next: NextCache | null }
  >();

  const throttledFn = (...args: Args): Promise<R> => {
    const key = getCacheKey?.(...(args as any)) ?? "";
    let cached = promiseCache.get(key);

    if (!cached) {
      cached = {
        executingPromise: func(...args).finally(() => {
          if (cached?.next) return;
          promiseCache.delete(key);
        }),
        next: null,
      };

      promiseCache.set(key, cached);

      return cached.executingPromise;
    }

    if (!cached.next) {
      const runNext = () => {
        const args = cached.next?.args;

        if (!args) {
          throw new Error(`[throttleAsyncFn] missing next args`);
        }

        cached.next = null;

        cached.executingPromise = func(...args).finally(() => {
          if (cached?.next) return;
          promiseCache.delete(key);
        });

        return cached.executingPromise;
      };

      cached.next = { promise: cached.executingPromise.then(runNext, runNext), args };
    } else {
      cached.next.args = args;
    }

    return cached.next.promise;
  };

  throttledFn.promiseCache = promiseCache;

  return throttledFn;
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * Wraps a promise so that, if it doesn't complete within the specified time,
 * it throws a TimeoutError.
 */
export async function promiseTimeout<T>(ms: number, promise: Promise<T>) {
  const timeout = wait(ms).then(() => {
    throw new TimeoutError();
  });

  return Promise.race([promise, timeout]);
}

/* -----------------------------------------------------------------------------------------------*/
