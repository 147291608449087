import { ComponentType, memo, useMemo } from "react";
import { IListOnEntryActionEvent, List } from "../list";
import { isEqual } from "libs/predicates";
import { navigateToEntry } from "./ContentList";
import {
  DeleteDraftEntryAction,
  EntryActions,
  entryCSSClasses,
  EntryTimestamp,
  OtherCommandEntryAction,
  PrivateEntryIcon,
  Recipients,
  Summary,
  useShouldShowChannelLabels,
} from "./layout";
import { GroupLabels, LabelChip, LabelContainer } from "../LabelChip";
import { INavigateServiceOptions, openLinkInNewTabOrWindow } from "~/environment/navigate.service";
import { PointerWithRecord, RecordValue } from "libs/schema";
import { useDraft } from "~/hooks/useDraft";
import { useThread } from "~/hooks/useThread";
import { isModKeyActive } from "~/environment/command.service";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { htmlToText } from "libs/htmlToText";
import { useDraftRecipientNames } from "~/hooks/useDraftRecipientNames";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { cx } from "@emotion/css";

/* -------------------------------------------------------------------------------------------------
 * onDraftReplySelectNavigateToThread
 * -----------------------------------------------------------------------------------------------*/

export function onDraftReplySelectNavigateToThread(
  draft: Pick<RecordValue<"draft">, "id" | "thread_id">,
  options?: INavigateServiceOptions,
) {
  navigateToEntry(draft.id, `/threads/${draft.thread_id}`, options);
}

/* -------------------------------------------------------------------------------------------------
 * onDraftsEntrySelect
 * -----------------------------------------------------------------------------------------------*/

export async function onDraftsEntrySelect(
  { entry, event }: IListOnEntryActionEvent<PointerWithRecord<"draft">>,
  options?: INavigateServiceOptions,
) {
  if (isModKeyActive(event)) {
    if (!entry.record.is_reply) {
      const url = new URL(location.href);
      url.searchParams.set("compose", entry.id);
      openLinkInNewTabOrWindow(url);
      return;
    }

    openLinkInNewTabOrWindow(`/threads/${entry.record.thread_id}`);
    return;
  }

  if (!entry.record.is_reply) {
    openComposeNewThreadDialog(entry.id);
    return;
  }

  onDraftReplySelectNavigateToThread(entry.record, options);
}

/* -------------------------------------------------------------------------------------------------
 * DraftEntry
 * -----------------------------------------------------------------------------------------------*/

export const DraftEntry: ComponentType<{
  draftId: string;
  relativeOrder: number;
}> = memo(({ draftId, relativeOrder }) => {
  const shouldShowChannelLabels = useShouldShowChannelLabels();

  const [draft] = useDraft(draftId);
  const [thread] = useThread(draft?.thread_id);

  const text = useMemo(() => {
    if (!draft?.body_html) return "";
    return htmlToText(draft.body_html);
  }, [draft?.body_html]);

  const entryData = useAsPointerWithRecord("draft", draft);

  const recipientGroupIds = useMemo(() => {
    if (!draft) return [];

    return draft.to.filter((r) => r.type === "GROUP").map((r) => r.id);
  }, [draft?.to]);

  if (!draft || !entryData) return null;

  const isPrivateThread = draft.new_thread_visibility === "PRIVATE" || thread?.visibility === "PRIVATE";

  const subject = draft.new_thread_subject || thread?.subject || "";

  return (
    <List.Entry<PointerWithRecord<"draft">> id={draftId} data={entryData} relativeOrder={relativeOrder}>
      <div role="listitem" className={cx(entryCSSClasses, "DraftEntry")}>
        <Recipients className="w-[240px]" nonTruncatedSuffix={isPrivateThread && <PrivateEntryIcon />}>
          <div className="truncate">
            <span className="text-green-9 mr-3">Draft</span>
            <RecipientNames messageId={draftId} />
          </div>
        </Recipients>

        <Summary
          subject={subject}
          formatAsReply={draft.is_reply}
          details={isPrivateThread ? "private message" : text}
        />

        <LabelContainer>
          {shouldShowChannelLabels && <GroupLabels groupIds={recipientGroupIds} />}

          {draft.type === "EMAIL" && <LabelChip tooltip="This is an email thread">#Email</LabelChip>}
        </LabelContainer>

        <EntryActions defaultComponent={<EntryTimestamp datetime={draft.created_at} />}>
          <DeleteDraftEntryAction />
          <OtherCommandEntryAction />
        </EntryActions>
      </div>
    </List.Entry>
  );
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

const RecipientNames: ComponentType<{ messageId: string }> = (props) => {
  const [names, { isLoading }] = useDraftRecipientNames(props.messageId);

  const recipientNames =
    names.length > 0
      ? ` to ${names.join(", ")}`
      : isLoading
        ? " to loading..."
        : names.length === 0
          ? ""
          : " to unknown";

  return <>{recipientNames}</>;
};

/* -----------------------------------------------------------------------------------------------*/
