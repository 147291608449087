import { useCurrentUserId } from "./useCurrentUserId";
import { useUserProfile } from "./useUserProfile";

/**
 * **Important!**
 *
 * Most components should get the currentUser from the AuthGuardContext
 * via useAuthGuardContext. Only use this hook if outside the
 * AuthGuardContext (rare).
 */
export function useCurrentUser() {
  const currentUserId = useCurrentUserId();
  return useUserProfile(currentUserId);
}
