import { css, cx } from "@emotion/css";
import { checkValueMatchesType } from "libs/type-helpers";
import { blue } from "@radix-ui/colors";
import {
  ChangeEventHandler,
  createContext,
  FocusEventHandler,
  forwardRef,
  Fragment,
  PropsWithChildren,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { FaSearch } from "react-icons/fa";
import { filter, fromEvent } from "rxjs";
import useConstant from "use-constant";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { useComposedRefs } from "~/hooks/useComposedRefs";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";

export interface IKBarHeaderRef {
  focusInput(value: boolean): void;
}

export interface IKBarHeaderContext {
  inputRef: RefObject<HTMLElement | null>;
}

const HeaderContext = createContext<IKBarHeaderContext | null>(null);

const useHeaderContext = createUseContextHook(HeaderContext, "HeaderContext");

export const KBarHeader = forwardRef<
  IKBarHeaderRef,
  PropsWithChildren<{
    scrollboxRef: RefObject<HTMLDivElement>;
    currentPath: string[];
    mode: "hotkey" | "search";
  }>
>((props, ref) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useImperativeHandle(ref, () =>
    checkValueMatchesType<IKBarHeaderRef>({
      focusInput() {
        inputRef.current?.focus();
      },
    }),
  );

  // Here we want to allow the user to use `Shift+ArrowUp` or ArrowDown
  // to select all the text in the input. React's event handlers are
  // called slower than normal event handlers though (react uses "synthetic events").
  // Because the List component is subscribing to the native keydown
  // events in a parent element, we also need to subscribe to the native
  // events else the List component will have already received and
  // responded to these events before react ever calls onKeyDown()
  useEffect(() => {
    if (!inputRef.current) return;

    const sub = fromEvent<KeyboardEvent>(inputRef.current, "keydown")
      .pipe(filter((e) => e.shiftKey && (e.key === "ArrowUp" || e.key === "ArrowDown")))
      .subscribe((e) => e.stopPropagation());

    return () => sub.unsubscribe();
  }, []);

  useTopScrollShadow({
    scrollboxRef: props.scrollboxRef,
    targetRef: headerRef,
  });

  const headerContext = useConstant(() => ({ inputRef }));

  return (
    <div
      ref={headerRef}
      className={cx("flex px-8 py-4 border-b border-slate-5 text-lg", "bg-white border-l-2 border-l-white z-10")}
      onClick={(e) => {
        if (!inputRef.current) return;
        e.preventDefault();
        inputRef.current.focus();
      }}
    >
      <div className="flex items-center">
        <FaSearch
          className={cx(
            "mr-3 text-slate-8 pointer-events-none outline-none",
            "w-[22px]",
            css`
              .hotkey-mode & {
                color: ${blue.blue9};
              }
            `,
          )}
        />

        {props.currentPath.map((segment, index) => {
          const shouldRenderTrailingSlash = props.mode === "search" || index !== props.currentPath.length - 1;

          return (
            <Fragment key={index}>
              <span
                onClick={(e) => {
                  if (!inputRef.current) return;
                  e.preventDefault();
                  inputRef.current.focus();
                }}
              >
                {segment}
              </span>

              {shouldRenderTrailingSlash && <span className="text-slate-8 mx-2 pointer-events-none">/</span>}
            </Fragment>
          );
        })}
      </div>

      <HeaderContext.Provider value={headerContext}>
        {props.mode === "search" ? (
          props.children
        ) : (
          // We need to give the browser something to focus if there isn't an input.
          <div tabIndex={0} />
        )}
      </HeaderContext.Provider>
    </div>
  );
});

export const FilterCommandsInput = forwardRef<
  HTMLInputElement,
  {
    value?: string;
    placeholder?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
  }
>((props, forwardRef) => {
  const { inputRef } = useHeaderContext();

  const ref = useComposedRefs(forwardRef, inputRef);

  return (
    <input
      ref={ref}
      value={props.value}
      className={cx("flex-1 focus:outline-none placeholder:text-slate-8")}
      placeholder={props.placeholder || "Search..."}
      onChange={props.onChange}
      onFocus={props.onFocus}
    />
  );
});
