import { ApiInput, ApiOutput, ApiResponse, ApiTypes, ErrorResponse } from "libs/ApiTypes";
import { AbortError, ApiVersionError, AuthenticationError } from "libs/errors";
import { ClientEnvironment } from "./ClientEnvironment";
import { Semaphore } from "libs/semaphore";
import { config } from "./config";
import { ClientMetadata } from "./ClientMetadata";
import { wait } from "libs/promise-utils";

export type ClientApi = {
  [ApiName in keyof ApiTypes]: ApiInput<ApiName> extends undefined ?
    (args?: ApiInput<ApiName>, options?: ClientApiRequestOptions) => Promise<ApiResponse<ApiOutput<ApiName>>>
  : (args: ApiInput<ApiName>, options?: ClientApiRequestOptions) => Promise<ApiResponse<ApiOutput<ApiName>>>;
};

export type ClientApiRequestOptions = {
  abortSignal?: AbortSignal;
};

export const apiRoot = config.dev.apiServerOrigin ? config.dev.apiServerOrigin + "/api/" : "/api/";

// We use this semaphore to limit the number of concurrent API requests to 10.
const semaphore = new Semaphore(10);

export function createClientApi(
  environment: Pick<ClientEnvironment, "logger" | "network"> & {
    info: Pick<ClientMetadata, "version" | "datadogSessionId">;
  },
  props: {
    onAuthenticationError: () => Promise<void>;
    onApiVersionError: (error: ApiVersionError | null) => Promise<void>;
  },
) {
  const { info, network } = environment;

  const logger = environment.logger.child({ name: "Api" });

  async function apiRequest<T extends keyof ApiTypes>(
    name: T,
    args: ApiInput<T>,
    options: ClientApiRequestOptions = {},
  ) {
    logger.debug({ name, args }, "apiRequest");

    const response = await (httpRequest({
      url: apiRoot + name,
      environment: { logger, network },
      body: args,
      options,
      credentials: config.dev.apiServerOrigin ? "include" : "same-origin",
      headers: {
        "Accept-Version": `${info.version.api}.${info.version.schema.actual ?? info.version.schema.expected}`,
        "X-DD-Session-Id": info.datadogSessionId || "",
        "X-Client-Version": config.version || "",
      },
    }) as Promise<ApiResponse<ApiOutput<T>>>);

    logger.debug({ name, args, response }, "apiResponse");

    if (response.status === AuthenticationError.statusCode) {
      await props.onAuthenticationError();
    }

    if (ApiVersionError.isApiVersionError(response)) {
      const error = ApiVersionError.fromResponse(response);
      await props.onApiVersionError(error);
    }

    return response;
  }

  const api = new Proxy(
    {},
    {
      get(_target, key: any) {
        if (key === "then") return undefined;
        if (key === "toJSON") return undefined;

        return (args: any, options: ClientApiRequestOptions = {}) => {
          return semaphore.use(() => apiRequest(key, args, options));
        };
      },
    },
  ) as ClientApi;

  return api;
}

export function formatResponseError(response: ErrorResponse) {
  const { status, body } = response;
  if (body === null) return `${status}: Unknown error.`;
  if (body === undefined) return `${status}: Unknown error.`;
  if (typeof body === "string") return body;
  if (typeof body === "object") {
    if ("message" in body) {
      if (typeof body.message === "string") {
        return body.message;
      }
    }
  }

  return `${status}: ${JSON.stringify(body)}`;
}

type HttpResponse<Body = any> = { status: 200; body: Body } | { status: number; body?: any };

// Only POST requests for now because this is only used for the API.
async function httpRequest(props: {
  url: string;
  environment: Pick<ClientEnvironment, "network" | "logger">;
  body?: object;
  headers?: Record<string, string>;
  credentials?: RequestCredentials;
  options?: ClientApiRequestOptions;
}): Promise<HttpResponse> {
  const { url, body: requestBody = null, environment, options = {} } = props;
  const { logger } = environment;
  const { abortSignal } = options;

  if (!environment.network.isOnline()) {
    logger.debug(`[httpRequest] Ignoring request to ${url} because we are offline.`);
    return { status: 0 }; // Offline.
  }

  // Request body cannot be blank if content-type is application/json.
  const headers = requestBody ? { "Content-Type": "application/json", ...props.headers } : props.headers;

  const requestInit: RequestInit = {
    method: "POST",
    credentials: props.credentials || "same-origin",
    headers,
    signal: abortSignal,
    body: requestBody === null ? null : JSON.stringify(requestBody),
  };

  let response: Response;
  let attempt = 0;

  while (true) {
    try {
      response = await fetch(url, requestInit);
      break;
    } catch (error) {
      if (error instanceof AbortError) {
        return { status: -1 };
      }

      if (environment.network.isOnline() && attempt < 3) {
        await retryWait(attempt);
        attempt++;
        continue;
      }

      if (error instanceof TypeError) {
        // A typeerror is thrown if the client is offline or if the request otherwise failed
        // to be sent.
        return { status: 0 };
      }

      logger.error({ ...requestInit, error }, "[httpRequest] unknown network error");
      // We choose to treat unknown network errors as an offline response.
      return { status: 0 };
    }
  }

  if (response.status === 200) {
    const body = await response.json();
    return { status: 200, body };
  }

  let responseBody: any;
  try {
    responseBody = await response.json();
  } catch (error) {
    logger.warn(
      { statusCode: response.status, statusText: response.statusText, error },
      "[httpRequest] Could not parse body of error response.",
    );
  }

  return { status: response.status, body: responseBody };
}

/* -----------------------------------------------------------------------------------------------*/

function retryWait(attempt: number) {
  return wait(2 ** attempt * retryDelay);
}

const retryDelay = config.mode === "test" ? 1 : 500;

/* -----------------------------------------------------------------------------------------------*/
