import { MS_IN_SECOND } from "libs/date-helpers";
import { Transaction } from "libs/transaction";
import { Simplify } from "type-fest";
import { EnqueueOptions } from "./TransactionQueue";

export interface UndoRedoEntry {
  transaction: Transaction;
  canUndo?: (props: { isRedo: boolean }) => boolean | Promise<boolean>;
  debounce?: EnqueueOptions["debounce"];
  onOptimisticUndo?: () => void | Promise<void>;
  onServerUndo?: () => void | Promise<void>;
  onOptimisticRedo?: (props: { isRedo: boolean }) => void | Promise<void>;
  onServerRedo?: (props: { isRedo: boolean }) => void | Promise<void>;
}

export type UndoRedoStackApi = Simplify<UndoRedoStack>;

export class UndoRedoStack {
  /** How many ms after a change that you undo it */
  undoWindow = MS_IN_SECOND * 10;
  running = false;
  undoStack: UndoRedoEntry[] = [];
  redoStack: UndoRedoEntry[] = [];

  private timeoutId: ReturnType<typeof setTimeout> | null = null;

  resetUndoWindow() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.undoStack = [];
      this.redoStack = [];
      this.timeoutId = null;
    }, this.undoWindow);
  }
}
