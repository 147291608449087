import { UnreachableCaseError } from "libs/errors";
import { combineLatest, map, of, switchMap } from "rxjs";
import { SetNonNullable } from "type-fest";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useLoadingObservable } from "./useLoadingObservable";
import { RecordValue } from "libs/schema";
import { renderGroupName } from "~/utils/groups-utils";
import { GetOptions } from "~/environment/RecordLoader";

export type UseDraftRecipientNamesResult = [names: string[], meta: { isLoading: boolean }];

export function useDraftRecipientNames(draftId: string | null | undefined): UseDraftRecipientNamesResult {
  const environment = useClientEnvironment();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [environment, draftId],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([{ recordLoader }, draftId]) => {
          if (!draftId) {
            return of<UseDraftRecipientNamesResult>([[], { isLoading: false }]);
          }

          const options: GetOptions = {};

          return recordLoader
            .observeGetRecord(
              {
                table: "draft",
                id: draftId,
              },
              options,
            )
            .pipe(
              switchMap(([draft, { isLoading: isDraftLoading }]) => {
                type Recipient =
                  | {
                      type: "tag";
                      record: RecordValue<"tag"> | null;
                      priority: number;
                      isLoading: boolean;
                    }
                  | {
                      type: "user_profile";
                      record: RecordValue<"user_profile"> | null;
                      priority: number;
                      isLoading: boolean;
                    };

                type RecipientsResult = [Recipient[], { isLoading: boolean }];

                if (!draft || !draft.to.length) {
                  return of<RecipientsResult>([[], { isLoading: isDraftLoading }]);
                }

                return combineLatest(
                  draft.to.map((r) => {
                    switch (r.type) {
                      case "GROUP": {
                        return recordLoader
                          .observeGetRecord(
                            {
                              table: "tag",
                              id: r.group_id,
                            },
                            options,
                          )
                          .pipe(
                            map(([record, { isLoading }]) => ({
                              type: "tag" as const,
                              record,
                              priority: r.priority,
                              isLoading,
                            })),
                          );
                      }
                      case "USER": {
                        return recordLoader
                          .observeGetRecord(
                            {
                              table: "user_profile",
                              id: r.user_id,
                            },
                            options,
                          )
                          .pipe(
                            map(([record, { isLoading }]) => ({
                              type: "user_profile" as const,
                              record,
                              priority: r.priority,
                              isLoading,
                            })),
                          );
                      }
                      default: {
                        throw new UnreachableCaseError(r);
                      }
                    }
                  }),
                ).pipe(
                  map((recipients): RecipientsResult => {
                    return [recipients, { isLoading: isDraftLoading }];
                  }),
                );
              }),
              map(([_recipients, { isLoading: isRecipientsLoading }]): UseDraftRecipientNamesResult => {
                const recipients = _recipients.filter((r): r is SetNonNullable<typeof r, "record"> => !!r.record);

                if (!recipients.length) {
                  return [[], { isLoading: isRecipientsLoading }];
                }

                const names: string[] = [];
                let isLoading = isRecipientsLoading;

                const sortedRecipients = recipients.toSorted((a, b) => {
                  return recipientTypeOrder(a.type) - recipientTypeOrder(b.type) || a.priority - b.priority;
                });

                function recipientTypeOrder(type: "user_profile" | "tag") {
                  switch (type) {
                    case "user_profile": {
                      return 0;
                    }
                    case "tag": {
                      return 1;
                    }
                    default: {
                      throw new UnreachableCaseError(type);
                    }
                  }
                }

                for (const recipient of sortedRecipients) {
                  if (recipient.isLoading) {
                    isLoading = true;
                  }

                  switch (recipient.type) {
                    case "user_profile": {
                      names.push(`@${recipient.record.name}`);
                      break;
                    }
                    case "tag": {
                      if (recipient.record.data?.is_organization_group) continue;
                      names.push(`${renderGroupName(recipient.record)}`);
                      break;
                    }
                    default: {
                      throw new UnreachableCaseError(recipient);
                    }
                  }
                }

                return [names, { isLoading }];
              }),
            );
        }),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]) as unknown as string[],
  Object.freeze({
    isLoading: true,
  }),
]) as UseDraftRecipientNamesResult;
