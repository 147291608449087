import { cx } from "@emotion/css";
import { ComponentType, ReactElement, memo } from "react";
import { Link, useParams } from "react-router-dom";
import { useGroup } from "~/hooks/useGroup";
import { useTagRecords } from "~/hooks/useTagRecords";
import { useThreadPermittedGroupIds } from "~/hooks/useThreadPermittedGroups";
import { Tooltip } from "./Tooltip";
import { isEqual } from "libs/predicates";
import { useThreadResolvedTag } from "~/hooks/useThreadResolvedTag";
import { markThreadNotResolvedCommand } from "~/utils/common-commands";
import { ThreadResolvedThreadTagRecord } from "libs/schema";
import { MdRemoveCircle } from "react-icons/md";
import { renderGroupName } from "~/utils/groups-utils";
import { useUserProfile } from "~/hooks/useUserProfile";
import { useGroups } from "~/hooks/useGroups";

export const LabelContainer: ComponentType<{ className?: string }> = (props) => {
  if (!props.children) return null;

  return <div className={props.className ?? "flex space-x-2"}>{props.children}</div>;
};

export const LabelChip: ComponentType<{
  href?: string;
  tooltip?: string | ReactElement;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  colorClassName?: string;
  className?: string;
  removeButtonTooltip?: string;
  onRemoveButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  if (props.onRemoveButtonClick) {
    return (
      <RemoveLabelButton
        tooltip={props.removeButtonTooltip || "Remove label"}
        onClick={props.onRemoveButtonClick}
        className={props.className}
      >
        <InnerLabelChip
          tooltip={props.tooltip}
          href={props.href}
          onClick={props.onClick}
          colorClassName={props.colorClassName}
        >
          {props.children}
        </InnerLabelChip>
      </RemoveLabelButton>
    );
  }

  return <InnerLabelChip {...props} />;
};

const InnerLabelChip: ComponentType<{
  href?: string;
  tooltip?: string | ReactElement;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  colorClassName?: string;
  className?: string;
}> = (props) => {
  if (props.href) {
    return (
      <Tooltip side="bottom" content={props.tooltip}>
        <Link
          to={props.href}
          className={cx(
            LabelSizeClasses,
            props.colorClassName || LabelOutlineColorClasses + " hover:border-black hover:text-black",
            props.className,
          )}
          onClick={props.onClick}
        >
          {props.children}
        </Link>
      </Tooltip>
    );
  } else if (props.onClick) {
    return (
      <Tooltip side="bottom" content={props.tooltip}>
        <button
          type="button"
          className={cx(
            LabelSizeClasses,
            props.colorClassName || LabelOutlineColorClasses + " hover:border-black hover:text-black",
            props.className,
          )}
          onClick={props.onClick}
        >
          {props.children}
        </button>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip side="bottom" content={props.tooltip}>
        <span
          className={cx(
            LabelSizeClasses,
            props.colorClassName || LabelOutlineColorClasses,
            props.tooltip && "hover:cursor-help",
            props.className,
          )}
        >
          {props.children}
        </span>
      </Tooltip>
    );
  }
};

const LabelSizeClasses = `
  border rounded text-xs truncate max-w-[7rem] pt-[3px] 
  px-[6px] pb-[2px] font-medium
`;

const LabelOutlineColorClasses = `
  bg-transparent border-slateA-9 text-slateA-9 
`;

const LabelOutlineClasses = LabelSizeClasses + LabelOutlineColorClasses;

export const PermittedGroupChips: ComponentType<{ threadId: string }> = (props) => {
  const [groupIds] = useThreadPermittedGroupIds({ threadId: props.threadId }, { fetchStrategy: "cache" });

  if (groupIds.length === 0) return null;

  return <GroupLabels groupIds={groupIds} />;
};

export const GroupLabels: ComponentType<{ groupIds: string[] }> = memo((props) => {
  const params = useParams();

  // If we're on a page associated with a specific group then we
  // don't need to include that group when rendering the labels
  // (i.e. the inclusion of that group is implied).
  const groupIds = params.tagId ? props.groupIds.filter((id) => id !== params.tagId) : props.groupIds;

  const [groups] = useGroups(groupIds);

  const nonOrgGroups = groups.filter((group) => !group.data?.is_organization_group);

  const overflowGroups = nonOrgGroups.slice(2).map((g) => g.id);

  return (
    <>
      {nonOrgGroups.slice(0, 2).map((group) => {
        return <GroupLabel key={group.id} groupId={group.id} />;
      })}

      {overflowGroups.length > 0 && <GroupLabelOverflow groupIds={overflowGroups} />}
    </>
  );
}, isEqual);

const GroupLabel: ComponentType<{ groupId: string }> = (props) => {
  const [group] = useGroup(props.groupId);

  if (!group || group.data?.is_organization_group) return null;

  return (
    <LabelChip tooltip={group.name} href={`/groups/${group.id}`} onClick={(e) => e.stopPropagation()}>
      {renderGroupName(group)}
    </LabelChip>
  );
};

/** @summary renders any additional channels to avoid cluttering the UI. */
const GroupLabelOverflow: ComponentType<{ groupIds: string[] }> = (props) => {
  const [tags] = useTagRecords(props.groupIds);

  if (props.groupIds.length === 0) {
    return null;
  }

  const groupNames = tags.map((g) => `#${g.name}`).join(", ");

  return (
    <Tooltip side="bottom" content={groupNames}>
      <div className={LabelOutlineClasses}>{`+${props.groupIds.length}`}</div>
    </Tooltip>
  );
};

export const ThreadResolvedLabel: ComponentType<{
  threadId: string;
  /**
   * If a messageId is provided, the label will only render if `thread_tag.data.message_id` matches
   * the provided messageId.
   */
  messageId?: string;
  theme?: "light" | "dark";
  onClick?: (props: {
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>;
    threadTag: ThreadResolvedThreadTagRecord;
  }) => void;
  className?: string;
  showRemoveButton?: boolean;
}> = (props) => {
  const [threadTag] = useThreadResolvedTag(props.threadId);
  const [resolverUserProfile] = useUserProfile(threadTag?.creator_user_id);

  if (!threadTag) return null;
  if (props.messageId && threadTag.data.message_id !== props.messageId) {
    return null;
  }

  let tooltip = props.messageId
    ? `This message resolved the thread.`
    : `This thread has been resolved. Click to view resolution.`;

  if (resolverUserProfile) {
    tooltip += ` Marked resolved by ${resolverUserProfile.name}.`;
  }

  const onRemoveButtonClick = !props.showRemoveButton
    ? undefined
    : (e: React.MouseEvent) => {
        e.stopPropagation();

        const isSure = confirm(`Are you sure you want to mark this thread as not resolved?`);

        if (!isSure) return;

        markThreadNotResolvedCommand.trigger();
      };

  return (
    <LabelChip
      colorClassName={cx(
        props.theme === "dark"
          ? `bg-greenDark-11 border-greenDark-11 text-black`
          : `bg-green-10 border-green-10 text-white`,
        props.onClick &&
          (props.theme === "dark"
            ? "hover:bg-greenDark-12 hover:border-greenDark-12"
            : "hover:bg-green-11 hover:border-green-11"),
      )}
      className={props.className}
      tooltip={tooltip}
      onClick={(event) => props.onClick?.({ event, threadTag })}
      removeButtonTooltip="Mark as not resolved"
      onRemoveButtonClick={onRemoveButtonClick}
    >
      {props.messageId ? "Resolution" : "Resolved"}
    </LabelChip>
  );
};

export const RemoveLabelButton: ComponentType<{
  tooltip: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}> = (props) => {
  return (
    <div className={cx("relative inline group", props.className)}>
      <Tooltip side="bottom" content={props.tooltip}>
        <button
          type="button"
          className={`
            absolute z-[1] hidden -right-2 -top-2 text-red-9 group-hover:block bg-white rounded-full
          hover:text-red-11 hover:bg-red-1`}
          onClick={props.onClick}
        >
          <MdRemoveCircle />
        </button>
      </Tooltip>

      {props.children}
    </div>
  );
};
