import { Observable, combineLatest, map, of, switchMap } from "rxjs";
import { get, set } from "lodash-es";
import { observeTagFolderAncestorIds } from "./observeTagFolderAncestors";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { cacheReplayForTime } from "libs/rxjs-operators";
import { MS_IN_MINUTE } from "libs/date-helpers";
import { ObserveOptions } from "~/environment/RecordLoader";

export type ObserveGroupFoldersUserIsSubscribedToResult = [tree: FolderTree, meta: { isLoading: boolean }];

export type FolderTree = {
  [folderId: string]: FolderTree | false;
};

export function observeGroupFoldersUserIsSubscribedTo(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    userId: string;
    includeArchived?: boolean;
  },
  options?: ObserveOptions,
): Observable<ObserveGroupFoldersUserIsSubscribedToResult> {
  const { userId, includeArchived } = props;

  const cacheKey = userId + options?.fetchStrategy + options?.isLoading;

  const cachedQuery = queryCache.get(cacheKey);

  if (cachedQuery) return cachedQuery;

  const observable = environment.recordLoader
    .observeGetGroupsUserIsSubscribedTo({ userId, includeArchived }, options)
    .pipe(
      switchMap(([tags, { isLoading: isGroupsUserIsSubscribedToLoading }]) => {
        if (tags.length === 0) {
          return of<ObserveGroupFoldersUserIsSubscribedToResult>([
            {},
            { isLoading: isGroupsUserIsSubscribedToLoading },
          ]);
        }

        return combineLatest(
          tags.map(({ id }) =>
            observeTagFolderAncestorIds(
              environment,
              { tagId: id },
              {
                fetchStrategy: options?.fetchStrategy,
                isLoading: isGroupsUserIsSubscribedToLoading,
              },
            ).pipe(
              map(([folderPaths, { isLoading }]) => {
                return {
                  isLoading,
                  folderPaths: folderPaths.map((paths) => [...paths.toReversed(), id]),
                };
              }),
            ),
          ),
        ).pipe(
          map((results): ObserveGroupFoldersUserIsSubscribedToResult => {
            const { tree, isLoading } = results.reduce(
              (store, { folderPaths, isLoading }) => {
                store.isLoading = store.isLoading || isLoading;

                folderPaths.forEach((path) => {
                  const alreadyExists = !!get(store.tree, path);
                  if (alreadyExists) return;
                  set(store.tree, path, false);
                });

                return store;
              },
              {
                tree: {} as FolderTree,
                isLoading: isGroupsUserIsSubscribedToLoading,
              },
            );

            return [tree, { isLoading }];
          }),
        );
      }),
      cacheReplayForTime({
        timeMs: MS_IN_MINUTE * 10,
        onInit() {
          queryCache.set(cacheKey, observable);
        },
        onCleanup() {
          queryCache.delete(cacheKey);
        },
      }),
    );

  return observable;
}

const queryCache = new Map<string, Observable<ObserveGroupFoldersUserIsSubscribedToResult>>();
