import { useEffect, useRef, useState } from "react";
import { timer } from "rxjs";

/**
 * A hook which is passed a callback function dependent on a timetamp. The callback function is
 * called immediately on mount to get the initial value (or when deps change) then it is called
 * again when the time provided by the timestamp is reached or the dependencies change.
 *
 * @param callbackFn callback function to be called
 * @param deps callback dependencies, first element should be a timestamp
 * @returns
 */
export function useCallbackAt<T>(
  callbackFn: () => T,
  deps: [timestamp: string | Date | undefined, ...otherDeps: any[]],
): T {
  const [value, setValue] = useState<T>(callbackFn);
  const isInitMountRef = useRef(true);

  useEffect(() => {
    const [timestamp] = deps;

    // On initial mount the value is already set via the useState initializer.
    if (isInitMountRef.current) {
      isInitMountRef.current = false;
    } else {
      setValue(callbackFn);
    }

    if (!timestamp) return;

    const date = typeof timestamp === "string" ? new Date(timestamp) : timestamp;

    const sub = timer(date).subscribe(() => setValue(callbackFn));

    return () => sub.unsubscribe();
  }, deps);

  return value;
}
