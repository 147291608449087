import { useRecord } from "./useRecord";
import { useMemo } from "react";
import { getPointer } from "libs/schema";
import { FetchStrategy } from "~/environment/QueryCache";

export function useThreadSubscription(props: {
  userId: string | null | undefined;
  threadId: string | null | undefined;
  fetchStrategy?: FetchStrategy;
}) {
  const { userId, threadId } = props;

  const pointer = useMemo(() => {
    if (!threadId || !userId) return null;

    return getPointer("thread_subscription", {
      thread_id: threadId,
      user_id: userId,
    });
  }, [threadId, userId]);

  return useRecord(pointer, { fetchStrategy: props.fetchStrategy, name: "useThreadSubscription" });
}
