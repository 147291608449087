import { combineLatest, distinctUntilChanged, map, Observable, of, switchMap } from "rxjs";
import { isEqual } from "libs/predicates";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ObserveOptions } from "~/environment/RecordLoader";

export type ObserveMentionableUsersResult = [
  string[],
  {
    isLoading: boolean;
  },
];

export function observeMentionableUserIds(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    currentUserId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveMentionableUsersResult> {
  return environment.recordLoader.observeGetOrganizationUserMembers({ user_id: props.currentUserId }, options).pipe(
    map(([records, { isLoading }]) => ({
      organizationIds: records.map((r) => r.organization_id),
      isLoading,
    })),
    distinctUntilChanged(isEqual),
    switchMap(({ organizationIds, isLoading }) => {
      if (organizationIds.length === 0) {
        return of<ObserveMentionableUsersResult>([[], { isLoading }]);
      }

      return combineLatest(
        organizationIds.map((organization_id) =>
          environment.recordLoader.observeGetOrganizationUserMembers(
            {
              organization_id,
            },
            {
              fetchStrategy: options?.fetchStrategy,
              isLoading,
            },
          ),
        ),
      ).pipe(
        map((results): ObserveMentionableUsersResult => {
          const { userIds, isLoading: isResultLoading } = results.reduce(
            (store, [records, { isLoading }]) => {
              for (const record of records) {
                store.userIds.add(record.user_id);
              }

              if (isLoading) store.isLoading = true;
              return store;
            },
            { userIds: new Set<string>(), isLoading },
          );

          return [
            Array.from(userIds),
            {
              isLoading: isResultLoading,
            },
          ];
        }),
      );
    }),
  );
}
