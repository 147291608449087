import { css } from "@emotion/css";
import { red } from "@radix-ui/colors";
import { uuid } from "libs/uuid";
import { useObservableEagerState } from "observable-hooks";
import { ComponentType } from "react";
import { MonoTypeOperatorFunction, Observable } from "rxjs";
import useConstant from "use-constant";
import { useIsOnline } from "~/hooks/useIsOnline";
import { IAddLoadingOptions, Key } from "~/environment/is-loading.service";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

/* -------------------------------------------------------------------------------------------------
 * AppLoadingBar
 * -------------------------------------------------------------------------------------------------
 */

export const AppLoadingBar: ComponentType<{}> = () => {
  const environment = useClientEnvironment();
  const isLoading$ = useConstant(() => environment.isLoading.isLoading$());
  const isVisible = useObservableEagerState(isLoading$);
  const isOnline = useIsOnline();

  if (!isVisible || !isOnline) return null;

  return (
    <div className="fixed top-0 left-0 w-screen h-1 z-[9000]">
      <div className={loadingBar}>
        <div className="loaderBar"></div>
      </div>
    </div>
  );
};

const loadingBar = css`
  position: relative;
  padding: 3px;

  &:before {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }

  .loaderBar {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: ${red.red11};
    width: 0;
    animation: borealisBar 2s linear infinite;
  }

  @keyframes borealisBar {
    0% {
      left: 0%;
      right: 100%;
      width: 0%;
    }
    10% {
      left: 0%;
      right: 75%;
      width: 25%;
    }
    90% {
      right: 0%;
      left: 75%;
      width: 25%;
    }
    100% {
      left: 100%;
      right: 0%;
      width: 0%;
    }
  }
`;

/* -----------------------------------------------------------------------------------------------*/
