import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";

/** Returns an array of organization_user_invitation IDs */
export type UseOrganizationUserInvitationsResult = UseRecordLoaderResult<string[]>;

export function useOrganizationUserInvitations(props: {
  organizationId: string | null | undefined;
}): UseOrganizationUserInvitationsResult {
  return useRecordLoader({
    name: "useOrganizationUserInvitations",
    load({ loader, limit, deps: [organizationId] }) {
      if (!organizationId) {
        return loader.createObserveQueryResult<"organization_user_invitation">();
      }

      return loader.observeGetOrganizationUserInvitations({
        organization_id: organizationId,
        limit,
      });
    },
    deps: [props.organizationId],
    initialLimit: 25,
    limitStep: 25,
    map: (records) => records.map((r) => r.id),
  });
}
