import { ComponentType, RefObject } from "react";
import { useDraftIdsForThread } from "~/hooks/useDraftIdsForThread";
import { ComposeThreadReply } from "./ComposeThreadReply";
import { IListRef } from "~/components/list";
import { ComposeReplyHint } from "~/components/ComposeReplyBase";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { IRichTextEditorRef } from "~/components/forms/message-editor";
import { RecordValue } from "libs/schema";

export const ThreadDrafts: ComponentType<{
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  editorRefStore: Map<string, IRichTextEditorRef>;
  thread: RecordValue<"thread">;
  focusOnInit: boolean;
}> = (props) => {
  const [draftIds] = useDraftIdsForThread({ threadId: props.thread.id });

  return (
    <div>
      {draftIds.map((draftId) => (
        <ComposeThreadReply
          key={draftId}
          listRef={props.listRef}
          editorRefStore={props.editorRefStore}
          draftId={draftId}
          threadId={props.thread.id}
          threadType={props.thread.type}
          threadVisibility={props.thread.visibility}
          threadSubject={props.thread.subject}
          isThreadDeleted={!!props.thread.deleted_at}
          isFirstDraft={draftIds[0] === draftId}
          focusOnInit={draftIds[0] === draftId && props.focusOnInit}
        />
      ))}

      {draftIds.length === 0 && (
        <div className="mt-8 mx-10 prose text-slate-9 sm-max-w:hidden">
          <p>
            <strong>Hint:</strong> press <kbd>r</kbd> to reply.
          </p>
        </div>
      )}

      {draftIds.length > 0 && <ComposeReplyHint />}
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/
