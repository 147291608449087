import { JSONContent } from "@tiptap/react";
import { Editor as CoreEditor } from "@tiptap/core";
import { ComponentType, forwardRef, Ref, RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { cx } from "@emotion/css";
import { observable, useControlState } from "../utils";
import { combineLatest, concat, distinctUntilChanged, map, NEVER, of, Subject, switchMap } from "rxjs";
import { IMessageEditorRef, MessageEditorBase } from "./MessageEditorBase";
import {
  IEditorMention,
  IMessageEditorContext,
  IMessageEditorControl,
  MessageEditorContext,
  useMessageEditorContext,
} from "./context";
import { useComposedRefs } from "~/hooks/useComposedRefs";
import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
export { type IMessageEditorRef as IRichTextEditorRef } from "./MessageEditorBase";
import { BiError } from "react-icons/bi";
import { isNonNullable } from "libs/predicates";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { isTagPrivate } from "libs/schema/predicates";
import { DraftAttachmentDoc, MentionPriority } from "libs/schema";
import { IImageExtentionAttrs } from "./extensions/image/context";
import { EditorMentionContext, IEditorMentionContext } from "./extensions/mention/context";
import { isString } from "lodash-comms";
import { WINDOW_FOCUSED$ } from "~/utils/dom-helpers";

/* -------------------------------------------------------------------------------------------------
 * MessageEditor
 * -----------------------------------------------------------------------------------------------*/

export interface IMessageEditorProps {
  control: IMessageEditorControl;
  saveDraftFn: () => Promise<void>;
  onEditorStartOverflow?: () => void;
  onEditorEndOverflow?: () => void;
  initialTabIndex?: number;
}

export const MessageEditor = forwardRef<IMessageEditorRef, IMessageEditorProps>((props, forwardedRef) => {
  const control = props.control as IMessageEditorContext["control"];

  const editorRef = useRef<IMessageEditorRef>(null);
  const composeRefs = useComposedRefs(forwardedRef, editorRef);

  const isInvalid = useControlState(() => !control.controls.body.isValid, [control]);

  const isTouched = useControlState(() => control.controls.body.isTouched, [control]);

  const context = useMemo(() => ({ control, saveDraftFn: props.saveDraftFn }), [control, props.saveDraftFn]);

  useContentRequiredValidator(control);
  useGroupMentionsValidator(control);
  useSyncControlReadonlyAndDisabledStatusToEditor(control, editorRef);

  const getInitialValue = useCallback(() => {
    return control.rawValue.body.content;
    // we only use this once to get the initial value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback(
    ({ editor }: { editor: CoreEditor }) => {
      const json = editor.getJSON();

      const {
        userMentions,
        groupMentions,
        mentionsCount,
        attachments: inlineAttachments,
      } = extractMentionsFromEditorJSON(json);

      const regularAttachments = control.controls.attachments.rawValue.filter((a) => a.contentDisposition !== "inline");

      control.patchValue({
        attachments: [...inlineAttachments, ...regularAttachments],
        body: {
          content: editor.isEmpty ? "" : editor.getHTML(),
          userMentions,
          groupMentions,
          // This property allows form code to observe these changes and
          // respond whenever a new `@mention` is added.
          possiblyIncorrectMentionsCount: mentionsCount,
        },
      });
    },
    [control],
  );

  const onBlur = useCallback(() => {
    control.controls.body.markTouched(true);
  }, [control]);

  const isFieldEmpty = useControlState(
    () => control.rawValue.body.content === "<p></p>" || !control.rawValue.body.content,
    [control],
  );

  const messageId = useControlState(() => control.rawValue.messageId, [control]);

  const placeholder = (
    <span
      className={cx(
        "absolute whitespace-nowrap pointer-events-none",
        isTouched && isInvalid ? "text-red-9" : "text-slateDark-11",
        { hidden: !isFieldEmpty },
      )}
    >
      {isTouched && isInvalid ? `Content required...` : `Content...`}
    </span>
  );

  return (
    <MessageEditorContext.Provider value={context}>
      <EditorMentionContextProvider>
        <div
          className="relative flex-1 prose"
          onClick={(e) => {
            if (e.defaultPrevented) return;
            // If the user clicks on the whitespace padding above/below the editor
            // (i.e. this element), we want to focus the editor. However if the user
            // clicked within the editor and that event is just bubbling up to this
            // element, then we want to ignore the event.
            let el = e.target as Element | null;

            while (el) {
              if (el.getAttribute("contenteditable") === "true") return;
              el = el.parentElement;
            }

            editorRef.current?.focus("end");
          }}
        >
          <div className="h-4 w-full" />

          {placeholder}

          <MessageEditorBase
            ref={composeRefs}
            onChange={onChange}
            onBlur={onBlur}
            messageId={messageId}
            saveDraftFn={props.saveDraftFn}
            onEditorStartOverflow={props.onEditorStartOverflow}
            onEditorEndOverflow={props.onEditorEndOverflow}
            getInitialValue={getInitialValue}
            initialTabIndex={props.initialTabIndex}
            className={`Draft-${messageId}`}
          />

          <div className="h-4 w-full" />
        </div>
      </EditorMentionContextProvider>
    </MessageEditorContext.Provider>
  );
});

/* -----------------------------------------------------------------------------------------------*/

function extractMentionsFromEditorJSON(json: JSONContent) {
  const { userMentions, groupMentions, attachments } = getMentionsAndAttachments(json);

  const userMentionsMap = reduceMentionsByPriority(userMentions);
  const groupMentionsMap = reduceMentionsByPriority(groupMentions);

  return {
    userMentions: Array.from(userMentionsMap.values()),
    groupMentions: Array.from(groupMentionsMap.values()),
    mentionsCount: userMentionsMap.size + groupMentionsMap.size,
    attachments,
  };
}

/* -----------------------------------------------------------------------------------------------*/

function getMentionsAndAttachments(
  json: JSONContent,
  userMentions: IEditorMention[] = [],
  groupMentions: IEditorMention[] = [],
  attachments: DraftAttachmentDoc[] = [],
): {
  userMentions: IEditorMention[];
  groupMentions: IEditorMention[];
  attachments: DraftAttachmentDoc[];
} {
  // Mentions inside of a blockquote should be ignored
  if (json.type === "blockquote") {
    return { userMentions, groupMentions, attachments };
  }

  if (json.type === "mention-@" || json.type === "mention-@@" || json.type === "mention-@@@") {
    const attrs = json.attrs as {
      id: string;
      label: string;
      subject: "user" | "group";
      priority: string;
    };

    const data: IEditorMention = {
      id: attrs.id,
      type: attrs.subject,
      priority: Number(attrs.priority) as MentionPriority,
    };

    switch (attrs.subject) {
      case "user": {
        userMentions.push(data);
        break;
      }
      case "group": {
        groupMentions.push(data);
        break;
      }
      default: {
        throw new UnreachableCaseError(attrs.subject);
      }
    }
  } else if (json.type === "image" && json.attrs?.imageId) {
    const attrs = json.attrs as IImageExtentionAttrs;

    attachments.push({
      id: attrs.imageId!,
      fileName: attrs.title,
      contentDisposition: "inline",
      contentType: attrs.contentType,
      fileSize: attrs.fileSize,
    });
  } else if (json.content) {
    json.content.forEach((j) => getMentionsAndAttachments(j, userMentions, groupMentions, attachments));
  }

  return { userMentions, groupMentions, attachments };
}

/* -----------------------------------------------------------------------------------------------*/

function reduceMentionsByPriority(mentions: IEditorMention[]) {
  const mentionsMap = new Map<IEditorMention["id"], IEditorMention>();

  for (const mention of mentions) {
    const existingPriority = mentionsMap.get(mention.id)?.priority || 400;

    const newPriority = mention.priority;

    if (existingPriority <= newPriority) continue;

    mentionsMap.set(mention.id, mention);
  }

  return mentionsMap;
}

/* -----------------------------------------------------------------------------------------------*/

function validateRequiredTextInput(value: string) {
  const text = value?.trim();

  if (!text || text === "<p></p>") return "Required.";
  return;
}

/* -----------------------------------------------------------------------------------------------*/

function useContentRequiredValidator(control: IMessageEditorContext["control"]) {
  useEffect(() => {
    const source = { source: "required-validator" };

    const sub = combineLatest([
      observable(() => control.controls.body.controls.content.isRequired),
      observable(() => control.rawValue.body.content),
    ]).subscribe(([isRequired, content]) => {
      if (isRequired && validateRequiredTextInput(content)) {
        control.controls.body.controls.content.setErrors({ isEmpty: true }, source);
        return;
      }

      control.controls.body.controls.content.setErrors(null, source);
    });

    return () => sub.unsubscribe();
  }, [control]);
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * Validate the group mentions to make sure they are acceptible
 * given the thread's visibility. This is necessary because someone
 * could mention a public group in a public thread draft and then,
 * before the draft has been sent, the group is updated to be
 * private.
 */
function useGroupMentionsValidator(control: IMessageEditorContext["control"]) {
  const { recordLoader } = useClientEnvironment();

  useEffect(() => {
    const errorKey = "groupMentionsValidator";

    const areMentionedGroupsPrivate$ = observable(() => control.rawValue.body.groupMentions).pipe(
      switchMap((mentions) => {
        if (mentions.length === 0) return of([]);

        return combineLatest(
          mentions.map((m) =>
            recordLoader.observeGetRecord({ table: "tag", id: m.id }).pipe(
              map(([group]) => {
                return !group ? null : isTagPrivate(group);
              }),
            ),
          ),
        );
      }),
      map((groups) => {
        const filteredGroups = groups.filter(isNonNullable);

        return filteredGroups.length === 0 ? null : filteredGroups.every((c) => c);
      }),
    );

    const sub = combineLatest([areMentionedGroupsPrivate$, observable(() => control.rawValue.visibility)]).subscribe(
      ([areMentionedGroupsPrivate, threadVisibility]) => {
        const groupMentionsControl = control.controls.body.controls.groupMentions;

        if (!threadVisibility || areMentionedGroupsPrivate === null) {
          groupMentionsControl.setErrors(null, {
            source: errorKey,
          });

          return;
        }

        const areGroupMentionsValid =
          threadVisibility === "PRIVATE" ? areMentionedGroupsPrivate
          : threadVisibility === "SHARED" ? !areMentionedGroupsPrivate
          : throwUnreachableCaseError(threadVisibility);

        if (areGroupMentionsValid) {
          groupMentionsControl.setErrors(null, {
            source: errorKey,
          });
        } else {
          groupMentionsControl.setErrors({ invalidChannelMentions: true }, { source: errorKey });
        }
      },
    );

    return () => sub.unsubscribe();
  }, [control, recordLoader]);
}

/* -------------------------------------------------------------------------------------------------
 * MessageEditorErrors
 * -----------------------------------------------------------------------------------------------*/

export const MessageEditorErrors: ComponentType<{
  control: IMessageEditorContext["control"];
}> = (props) => {
  const controlErrorMsg = useControlState(() => {
    if (props.control.status !== "INVALID") return;

    if (props.control.errors?.invalidChannelMentions) {
      return props.control.rawValue.visibility === "PRIVATE" ?
          "Cannot mention public channels in a private thread."
        : "Cannot mention private channels in a shared thread.";
    }

    const errors = Object.values(props.control.errors || {})
      .filter(isString)
      // It's an error if there aren't any recipients in a new message, but I don't think
      // it's helpful to show this error to the user since it strikes me as a bit obvious.
      .filter((msg) => msg !== "recipients");

    if (errors.length === 0) return;

    const firstError = errors.shift();

    if (errors.length === 1) {
      `${firstError} + 1 more error.`;
    } else if (errors.length > 1) {
      `${firstError} + ${errors.length} more errors.`;
    } else {
      return firstError;
    }
  }, [props.control]);

  const isTouched = useControlState(() => props.control.isTouched, [props.control]);

  if (!controlErrorMsg || !isTouched) return null;

  return (
    <div className="rounded-lg bg-red-5 px-4 py-2 mb-4 font-medium flex text-red-11">
      <div className="flex justify-center items-center">
        <BiError className=" text-2xl" />
      </div>

      <div className="ml-2">{controlErrorMsg}</div>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useSyncControlReadonlyAndDisabledStatusToEditor(
  control: IMessageEditorContext["control"],
  editorRef: RefObject<IMessageEditorRef>,
) {
  useEffect(() => {
    // First we set the initial editable state of the editor
    const isDisabledOrReadonly = control.isDisabled || control.isReadonly;
    editorRef.current?.addOnCreate((editor) => {
      editor.setEditable(!isDisabledOrReadonly);
    });

    // Next we subscribe to changes which would affect the editable state and then update the editor
    const sub1 = observable(() => control.isDisabled || control.isReadonly).subscribe((isDisabledOrReadonly) => {
      editorRef.current?.editor?.setEditable(!isDisabledOrReadonly);
    });

    // Finally,
    // For some reason TipTap keeps trying to set the "isEditable" property back to `true`. As a workaround, I've found
    // that we can just monitor update events and set the property back to `false`, if appropriate.
    const sub2 = editorRef.current!.updates$.subscribe(({ editor }) => {
      const isEditable = !(control.isDisabled || control.isReadonly);
      if (editor.isEditable === isEditable) return;
      editor.setEditable(isEditable);
    });

    sub1.add(sub2);

    return () => sub1.unsubscribe();
  }, [control, editorRef]);
}

/* -------------------------------------------------------------------------------------------------
 * useSyncDraftContentChangesFromOtherWindowToEditor
 * -----------------------------------------------------------------------------------------------*/

export function useSyncDraftContentChangesFromOtherWindowToEditor(
  control: IMessageEditorControl,
  editorRef: RefObject<IMessageEditorRef>,
) {
  const environment = useClientEnvironment();
  const draftId = useControlState(() => control.rawValue.messageId, [control]);

  useEffect(() => {
    WINDOW_FOCUSED$.pipe(
      switchMap((isFocused) =>
        isFocused ? NEVER : (
          environment.recordLoader.observeGetRecord("draft", draftId, {
            fetchStrategy: "cache",
          })
        ),
      ),
      map(([draft]) => draft?.body_html),
      distinctUntilChanged(),
    ).subscribe((content) => {
      if (typeof content !== "string") return;
      // The editor changes will in turn update the control's body content
      editorRef.current?.editor?.commands.setContent(content, true);
    });
  }, [draftId, environment]);
}

/* -----------------------------------------------------------------------------------------------*/

const EditorMentionContextProvider: ComponentType = (props) => {
  const { control } = useMessageEditorContext();
  const visibility = useControlState(() => control.rawValue.visibility, [control]);

  const context = useMemo((): IEditorMentionContext => {
    return {
      restrictToVisibility: visibility,
    };
  }, [visibility]);

  return <EditorMentionContext.Provider value={context}>{props.children}</EditorMentionContext.Provider>;
};

/* -----------------------------------------------------------------------------------------------*/
