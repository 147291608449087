import { stringComparer } from "libs/comparers";
import { cacheReplayForTime } from "libs/rxjs-operators";
import { GetGroupsUserHasAccessToParams } from "libs/database";
import { map, Observable } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ClientRecordLoaderObserveQueryResult, ObserveOptions } from "~/environment/RecordLoader";

export interface ObserveGroupsUserHasAccessToProps {
  userId: string;
  hideArchivedGroups?: boolean;
  ownerOrganizationId?: string;
  orderBy?: GetGroupsUserHasAccessToParams["orderBy"];
  limit?: number;
}

export type ObserveGroupsUserHasAccessToResult = ClientRecordLoaderObserveQueryResult<"tag">;

export function observeGroupsUserHasAccessTo(
  environment: Pick<ClientEnvironment, "recordLoader" | "logger">,
  props: ObserveGroupsUserHasAccessToProps,
  options?: ObserveOptions,
): Observable<ObserveGroupsUserHasAccessToResult> {
  const cacheKey =
    props.userId +
    props.hideArchivedGroups +
    props.ownerOrganizationId +
    props.orderBy +
    props.limit +
    options?.fetchStrategy +
    options?.isLoading;

  const cachedQuery = queryCache.get(cacheKey);

  if (cachedQuery) return cachedQuery;

  const observable = environment.recordLoader
    .observeGetGroupsUserHasAccessTo(
      {
        currentUserId: props.userId,
        orderBy: props.orderBy,
      },
      options,
    )
    .pipe(
      map(([groups, meta]): ClientRecordLoaderObserveQueryResult<"tag"> => {
        groups = groups.toSorted((a, b) => stringComparer(a.name, b.name) || stringComparer(a.id, b.id));

        if (props.ownerOrganizationId) {
          groups = groups.filter((group) => group.owner_organization_id === props.ownerOrganizationId);
        }

        if (props.hideArchivedGroups) {
          groups = groups.filter((group) => !group.archived_at);
        }

        let nextId = meta.nextId;

        // The getGroupsUserHasAccessTo query doesn't current support a `limit` parameter,
        // so we're simulating it here by slicing the array.
        if (props.limit) {
          // Note that the order of these two lines is important since the second line
          // reduces the length of the `groups` variable.
          nextId = groups.at(props.limit)?.id || null;
          groups = groups.slice(0, props.limit);
        }

        return [groups, { ...meta, nextId }];
      }),
      cacheReplayForTime({
        timeMs: 5000,
        onInit() {
          queryCache.set(cacheKey, observable);
        },
        onCleanup() {
          queryCache.delete(cacheKey);
        },
      }),
    );

  return observable;
}

const queryCache = new Map<string, Observable<ObserveGroupsUserHasAccessToResult>>();
