import { distinctUntilChanged, map, of, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { isEqual } from "libs/predicates";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { isTagPrivate } from "libs/schema";

const DEFAULT_VALUE = Object.freeze({ isTagPrivate: null, isLoading: true });

export function useIsTagPrivate(tagId: string | null | undefined) {
  const { recordLoader } = useClientEnvironment();

  return useLoadingObservable({
    deps: [recordLoader, tagId],
    initialValue: DEFAULT_VALUE,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([recordLoader, tagId]) => {
          if (!tagId) return of({ isTagPrivate: false, isLoading: false });

          return recordLoader.observeGetRecord({ table: "tag", id: tagId }).pipe(
            map(([record, { isLoading }]) => {
              return {
                isTagPrivate: record ? isTagPrivate(record) : null,
                isLoading,
              };
            }),
            distinctUntilChanged(isEqual),
          );
        }),
      );
    },
  });
}
