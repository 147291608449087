import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { MentionableUser } from "~/observables/observeMentionableUserRecords";
import { GetOptions } from "~/environment/RecordLoader";

/* -------------------------------------------------------------------------------------------------
 * getMentionableUser
 * -------------------------------------------------------------------------------------------------
 */

export interface GetMentionableUserRecordProps {
  id: string;
}

export type GetMentionableUserRecordResult = MentionableUser | null;

export async function getMentionableUser(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: GetMentionableUserRecordProps,
  options?: GetOptions,
): Promise<GetMentionableUserRecordResult> {
  const [[userProfile], [userContactInfo]] = await Promise.all([
    environment.recordLoader.getRecord({ table: "user_profile", id: props.id }, options),
    environment.recordLoader.getRecord({ table: "user_contact_info", id: props.id }, options),
  ]);

  if (!userProfile) return null;

  return {
    type: "user",
    id: userProfile.id,
    profile: userProfile,
    contact: userContactInfo,
  };
}

/* -----------------------------------------------------------------------------------------------*/
