import dayjs from "dayjs";
import type { NormalizedUserSettingsDoc } from "libs/constants/defaultUserSettings";
import { isNonNullable } from "libs/predicates";

export function getLastScheduledDeliveryDatetime(
  settings: Partial<Pick<NormalizedUserSettingsDoc, "scheduled_days" | "scheduled_times" | "most_recent_deliver_now">>,
) {
  const now = dayjs();

  const { scheduled_days = [], scheduled_times = [] } = settings;

  if (scheduled_days.length === 0 || scheduled_times.length === 0) {
    return null;
  }

  const sortedDates = scheduled_days
    .map(mapDayToNumber)
    .filter(isNonNullable)
    .map((day) => {
      const date = now.set("day", day);
      return date.isAfter(now, "date") ? date.subtract(1, "week") : date;
    })
    .sort((a, b) => b.valueOf() - a.valueOf());

  const sortedTimes = scheduled_times.slice().sort().reverse();

  const mostRecentDeliverNowTime =
    typeof settings.most_recent_deliver_now === "number" ? dayjs(settings.most_recent_deliver_now) : null;

  for (const date of sortedDates) {
    for (const time of sortedTimes) {
      const [h, m] = time.split(":").map((t) => Number.parseInt(t, 10));

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const datetime = date.set("hour", h!).set("minute", m!).startOf("minute");

      if (datetime.isBefore(now) || datetime.isSame(now)) {
        if (mostRecentDeliverNowTime?.isAfter(datetime)) {
          // This works because the mostRecentDeliverNowTime will always be
          // before `now`.
          return mostRecentDeliverNowTime;
        }

        return datetime;
      }
    }
  }

  return null;
}

export function getNextScheduledDeliveryDatetime(
  settings: Partial<Pick<NormalizedUserSettingsDoc, "scheduled_days" | "scheduled_times">>,
) {
  const now = dayjs();

  const { scheduled_days = [], scheduled_times = [] } = settings;

  if (scheduled_days.length === 0 || scheduled_times.length === 0) {
    return null;
  }

  const sortedDates = scheduled_days
    .map(mapDayToNumber)
    .filter(isNonNullable)
    .map((day) => {
      const date = now.set("day", day);
      return date.isBefore(now, "date") ? date.add(1, "week") : date;
    })
    .sort((a, b) => a.valueOf() - b.valueOf());

  const sortedTimes = scheduled_times.slice().sort();

  for (const date of sortedDates) {
    for (const time of sortedTimes) {
      const [h, m] = time.split(":").map((t) => Number.parseInt(t, 10));

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const datetime = date.set("hour", h!).set("minute", m!);

      if (datetime.isAfter(now)) {
        return datetime;
      }
    }
  }

  return null;
}

type DayAsNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

/**
 * Accepts a day of the week string and returns that day of the
 * week converted to a number following the JS Date convention
 * (i.e. Sunday is 0 and Monday is 1).
 *
 * @param day e.g. "Tuesday"
 * @returns e.g. 2
 */
function mapDayToNumber(day: string): DayAsNumber | undefined {
  switch (day) {
    case "Sunday":
      return 0;
    case "Monday":
      return 1;
    case "Tuesday":
      return 2;
    case "Wednesday":
      return 3;
    case "Thursday":
      return 4;
    case "Friday":
      return 5;
    case "Saturday":
      return 6;
  }
}
