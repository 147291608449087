import { cx } from "@emotion/css";
import { startWith } from "libs/rxjs-operators";
import { useObservableEagerState } from "observable-hooks";
import { useMemo } from "react";
import { combineLatest, delay, distinctUntilChanged, of, shareReplay, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { usePendingSentMessageIds } from "~/hooks/usePendingSentMessageIds";
import { pluralize } from "~/utils/string-utils";

export function OfflineBanner() {
  const onLine = useShowOfflineBanner();
  const [pendingMessageIds] = usePendingSentMessageIds();

  if (onLine) return null;

  return (
    <aside
      className={cx(
        "fixed bottom-0 h-8 w-full flex justify-center items-center",
        "font-medium bg-tealDark-6 text-white text-sm z-[8999]",
      )}
    >
      <span className="uppercase">Offline mode</span>&nbsp;-{" "}
      {pendingMessageIds.length ?
        `${pendingMessageIds.length} ${pluralize("message", pendingMessageIds.length)} to be sent`
      : "It appears as though you are offline."}
    </aside>
  );
}

/**
 * When going offline, we delay showing the banner for 3 seconds to avoid
 * flickering when the network is momentarily unstable. If the user goes
 * offline due to forcing "offline mode", the banner will show immediately.
 */
function useShowOfflineBanner() {
  const { network } = useClientEnvironment();

  const observable = useMemo(() => {
    return combineLatest([network.isOnline$, network.mode$]).pipe(
      switchMap(([isOnline, mode]) => {
        if (mode === "FORCE_OFFLINE") return of(false);
        if (isOnline) return of(isOnline);
        return of(isOnline).pipe(
          delay(3000),
          startWith(() => true),
        );
      }),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }, [network.isOnline$, network.mode$]);

  return useObservableEagerState(observable);
}
