import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { GetOptions } from "~/environment/RecordLoader";

export type GetTagFolderAncestorIdPathsResult = {
  /**
   * Returns an array of folderId arrays. Each folderId array represents a
   * possible path from the root folder to the current folder.
   */
  folderPaths: string[][];
};

export function getTagFolderAncestorIdPaths(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    tagId: string;
    maxDepth?: number;
  },
  options?: GetOptions,
) {
  const { tagId, maxDepth = Infinity } = props;

  return getTagFolderAncestorsInner(
    environment,
    {
      tagId,
      currentDepth: 0,
      maxDepth,
    },
    options,
  );
}

async function getTagFolderAncestorsInner(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    tagId: string;
    currentDepth: number;
    maxDepth: number;
  },
  options?: GetOptions,
): Promise<GetTagFolderAncestorIdPathsResult> {
  const { tagId, currentDepth, maxDepth } = props;
  const { recordLoader } = environment;

  const [parentFolders] = await recordLoader.getTagFolderMembers({ tag_id: tagId }, options);

  const nextDepth = currentDepth + 1;
  const isComplete = parentFolders.length === 0 || nextDepth === maxDepth;

  if (isComplete) {
    return {
      folderPaths: parentFolders.map(({ folder_id }) => [folder_id]),
    };
  }

  const ancestors = await Promise.all(
    parentFolders.map(({ folder_id }) =>
      getTagFolderAncestorsInner(
        environment,
        {
          tagId: folder_id,
          currentDepth: nextDepth,
          maxDepth,
        },
        options,
      ),
    ),
  );

  return {
    folderPaths: parentFolders.flatMap(({ folder_id }, index) => {
      const ancestor = ancestors[index]!;

      if (ancestor.folderPaths.length === 0) {
        return [[folder_id]];
      }

      return ancestor.folderPaths.map((folderIds) => {
        return [folder_id, ...folderIds];
      });
    }),
  };
}
