/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Client/id
 * @returns The clientId of the current context.
 */
export async function getClientId(): Promise<string> {
  // Use a Web Lock to determine our clientId.
  const nonce = Math.random().toString();

  const clientId = await navigator.locks.request(nonce, async () => {
    const { held } = await navigator.locks.query();
    return held?.find((lock) => lock.name === nonce)?.clientId;
  });

  if (typeof clientId !== "string") {
    throw new Error(`[getClientId] could not get clientId`);
  }

  return clientId;
}
