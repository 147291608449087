import { Editor as CoreEditor } from "@tiptap/core";
import { forwardRef, memo, Ref, useCallback, useEffect, useMemo, useRef } from "react";
import { cx } from "@emotion/css";
import { useControlState } from "../utils";
import { useComposedRefs } from "~/hooks/useComposedRefs";
import { ISearchEditorRef, SearchEditorBase } from "./SearchEditorBase";
import { IFormControl, IFormGroup } from "solid-forms-react";
import { useRegisterCommands } from "~/environment/command.service";
import { fromEventPattern, map, merge, switchMap } from "rxjs";
import { startWith } from "libs/rxjs-operators";
import { isEqual } from "libs/predicates";
import { EditorMentionContext, IEditorMentionContext } from "../message-editor/extensions/mention/context";
import { SuggestionsDropdownProvider } from "./suggest/SuggestionsDropdownProvider";
import { CheckboxInput } from "../CheckboxInput";
import { onlySearchSeenPostsControl } from "~/pages/search/useSearch/state";

export interface ISearchEditorProps {
  control: IFormGroup<{
    queryHTML: IFormControl<string>;
    queryText: IFormControl<string>;
  }>;
  editorRef?: Ref<ISearchEditorRef>;
  onEditorStartOverflow?: () => void;
  onEditorEndOverflow?: () => void;
  initialTabIndex?: number;
}

export const SearchEditor = memo(
  forwardRef<HTMLDivElement, ISearchEditorProps>((props, forwardedRef) => {
    const control = props.control;

    const editorRef = useRef<ISearchEditorRef>(null);
    const composeRefs = useComposedRefs(props.editorRef, editorRef);

    const isInvalid = useControlState(() => !control.isValid, [control]);

    const isTouched = useControlState(() => control.isTouched, [control]);

    useEffect(() => {
      if (editorRef.current) {
        editorRef.current.onCreate$.subscribe((editor) => {
          editor.view.focus();
          editorRef.current?.focus("end");
        });
      }
    }, [editorRef]);

    const context = useMemo(() => {
      return { restrictToVisibility: null } as IEditorMentionContext;
    }, []);

    const getInitialValue = useCallback(() => {
      return control.controls.queryHTML.rawValue;
      // we only use this once to get the initial value
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = useCallback(
      ({ editor }: { editor: CoreEditor }) => {
        const getTextOptions = { blockSeparator: "\n" };

        control.patchValue({
          queryHTML: editor.isEmpty ? "" : editor.getHTML(),
          queryText: editor.isEmpty ? "" : editor.getText(getTextOptions),
        });
      },
      [control],
    );

    const onBlur = useCallback(() => {
      control.markTouched(true);
    }, [control]);

    // Register "Show suggestions" command
    useRegisterCommands({
      commands() {
        const onCreate$ = editorRef.current?.onCreate$;

        if (!onCreate$) return [];

        return onCreate$.pipe(
          switchMap((editor) => {
            const editorEvent = (event: "focus" | "blur") => (handler: (...args: unknown[]) => void) =>
              editor.on(event, handler);

            return merge(
              fromEventPattern(editorEvent("focus")).pipe(map(() => true)),
              fromEventPattern(editorEvent("blur")).pipe(map(() => false)),
            ).pipe(
              startWith(() => editor.isFocused),
              map((isFocused) => {
                if (!isFocused) return [];

                return [
                  {
                    label: "Show suggestions",
                    showInKbar: false,
                    hotkeys: ["Control+Space"],
                    triggerHotkeysWhenInputFocused: true,
                    callback: () => {
                      editor.commands.showSearchSuggestions();
                    },
                  },
                ];
              }),
            );
          }),
        );
      },
    });

    const isFieldEmpty = useControlState(
      () => control.controls.queryHTML.rawValue === "<p></p>" || !control.controls.queryHTML.rawValue,
      [control],
    );

    const placeholder = (
      <span
        className={cx(
          "absolute whitespace-nowrap pointer-events-none text-base",
          isTouched && isInvalid ? "text-red-9" : "text-slateDark-11",
          { hidden: !isFieldEmpty },
        )}
      >
        What are you looking for?
      </span>
    );

    return (
      <EditorMentionContext.Provider value={context}>
        <div
          ref={forwardedRef}
          className={cx(
            "flex flex-col w-full px-4 py-[7px] pr-[5.5rem] rounded overflow-y-auto",
            "bg-slate-3 focus-within:bg-slate-3",
            "text-base text-slate-12",
            "border border-slate-5 focus-within:border-slate-8",
          )}
        >
          {placeholder}

          <SuggestionsDropdownProvider>
            <SearchEditorBase
              ref={composeRefs}
              onChange={onChange}
              onBlur={onBlur}
              onEditorStartOverflow={props.onEditorStartOverflow}
              onEditorEndOverflow={props.onEditorEndOverflow}
              getInitialValue={getInitialValue}
              initialTabIndex={props.initialTabIndex}
              className="max-h-[140px]"
            />
          </SuggestionsDropdownProvider>
        </div>
        <div className="flex items-center mt-3">
          <CheckboxInput
            id="only-seen"
            control={onlySearchSeenPostsControl}
            checkedValue={true}
            uncheckedValue={false}
          />

          <label htmlFor="only-seen" className="ml-2 text-sm">
            only messages I've sent or have been in my Inbox
          </label>
        </div>
      </EditorMentionContext.Provider>
    );
  }),
  isEqual,
);
