import { ComponentType, useState } from "react";
import { config } from "~/environment/config";

/**
 * A banner that displays when the app is in beta or development mode.
 */
export const BuildModeBanner: ComponentType = () => {
  const [show, setShow] = useState(true);

  const isDevOrBetaBuild = config.mode === "development" || config.isBeta;

  if (!isDevOrBetaBuild) return null;

  // We only want to allow hiding the banner in development mode. We don't want to let
  // someone do this is beta though since it's important that we can see the beta banner in
  // a Loom video.
  //
  // At time of writing, the beta build is also in "development" mode so we need to check
  // if we're in beta mode specifically.
  const shouldHideBanner = !config.isBeta && !show;

  if (shouldHideBanner) return null;

  // We add a thin fixed bar to the top of the screen so that we can see if someone's on beta/development
  // in a Loom
  return (
    <>
      <div className="fixed w-screen top-0 h-1 bg-red-9 z-[9000]" />
      <div className="bg-red-9 text-center text-white font-bold text-sm py-1" onClick={() => setShow(false)}>
        {config.isBeta ? "Beta" : "Development"} Version
      </div>
    </>
  );
};
