import { interval, switchMap } from "rxjs";
import { useRecordLoader } from "./useRecordLoader";
import { startWith } from "libs/rxjs-operators";
import { useCallback, useMemo, useState } from "react";
import { RecordValue } from "libs/schema";
import { stringComparer } from "libs/comparers";
import { useCallbackAt } from "./useCallbackAt";

export type UseSoonestScheduledMessageForThreadResult = [RecordValue<"message"> | null, { isLoading: boolean }];

export function useSoonestScheduledMessageForThread(props: {
  threadId: string | null | undefined;
}): UseSoonestScheduledMessageForThreadResult {
  const [pendingMessages, pendingMeta] = useRecordLoader({
    name: "useSoonestScheduledMessageForThread-pending",
    deps: [props.threadId],
    load({ loader, currentUserId, deps: [threadId] }) {
      if (!threadId) {
        return loader.createObserveQueryResult<"message">();
      }

      return loader.observeGetPendingSentMessages({ currentUserId, threadId }, { fetchStrategy: "cache" });
    },
  });

  const [scheduledMessages, scheduledMeta] = useRecordLoader({
    name: "useSoonestScheduledMessageForThread-scheduled",
    deps: [props.threadId],
    load({ loader, currentUserId, deps: [threadId] }) {
      if (!threadId) {
        return loader.createObserveQueryResult<"message">();
      }

      return interval(120_000).pipe(
        startWith(() => null),
        switchMap(() =>
          loader.observeGetSentMessages({
            userId: currentUserId,
            threadId,
            sentAfter: new Date().toISOString(),
          }),
        ),
      );
    },
  });

  const { timestamp, reevaluate } = useCurrentTimestamp();

  const [message, meta] = useMemo(() => {
    const message = [...pendingMessages, ...scheduledMessages]
      .toSorted(
        (a, b) =>
          stringComparer(a.sent_at, b.sent_at) ||
          stringComparer(a.scheduled_to_be_sent_at, b.scheduled_to_be_sent_at) ||
          stringComparer(a.id, b.id),
      )
      .filter(
        (m) =>
          m.sent_at > timestamp ||
          !m.server_updated_at ||
          (!!m.last_edited_at && m.last_edited_at > m.server_updated_at),
      )
      .at(-1);

    const isLoading = pendingMeta.isLoading || scheduledMeta.isLoading;

    return [message ?? null, { isLoading }];
  }, [timestamp, pendingMessages, pendingMeta, scheduledMessages, scheduledMeta]);

  useCallbackAt(() => {
    if (!message) return;
    const now = new Date().toISOString();
    if (message.sent_at > now) return;
    reevaluate();
  }, [message?.sent_at]);

  return [message, meta];
}

/* -----------------------------------------------------------------------------------------------*/

function useCurrentTimestamp() {
  const [timestamp, setTimestamp] = useState(() => new Date().toISOString());

  const reevaluate = useCallback(() => setTimestamp(new Date().toISOString()), []);

  return { timestamp, reevaluate };
}

/* -----------------------------------------------------------------------------------------------*/
