import { Simplify } from "type-fest";
import type { PubsubClientApi } from "./WebsocketPubsubClient";
import { Observable, concat, distinctUntilChanged, fromEvent, map, merge, shareReplay, takeUntil } from "rxjs";
import { startWith } from "libs/rxjs-operators";
import { Logger } from "libs/logger";
import { navigatorIsOnline } from "~/utils/navigatorIsOnline";

/* -------------------------------------------------------------------------------------------------
 * NetworkService
 * -------------------------------------------------------------------------------------------------
 * Responsible for managing the "mode" of the client (normal or force offline) as well as providing
 * information about the client's online status.
 *
 * Note that, at the moment, the NetworkService is a simple wrapper around the PubsubClientApi. We
 * have this separate class so that components which only need network status information can depend
 * on this class instead of the PubsubClientApi. This makes testing easier and will also help us if
 * we want to change the underlying implementation in the future.
 */

export type NetworkServiceApi = Simplify<NetworkService>;

export class NetworkService {
  mode$: Observable<"NORMAL" | "FORCE_OFFLINE">;
  isOnline$: Observable<boolean>;

  private _isOnline!: boolean;

  constructor(private env: { logger: Logger; pubsub: PubsubClientApi }) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "NetworkService" }),
    };

    this.mode$ = this.env.pubsub._mode$;

    /**
     * We prefer to use our websocket connection to the server to determine if a client is
     * online because it's more accurate. But we'll fallback to navigator.onLine if we're
     * still negotiating the initial websocket connection.
     */
    const FALLBACK_IS_DEVICE_ONLINE$ = merge(
      fromEvent(globalThis, "online").pipe(map(() => true)),
      fromEvent(globalThis, "offline").pipe(map(() => false)),
    ).pipe(
      startWith(navigatorIsOnline),
      distinctUntilChanged(),
      shareReplay(1),
      takeUntil(this.env.pubsub.initialConnectionAttempt.promise),
    );

    this.isOnline$ = concat(FALLBACK_IS_DEVICE_ONLINE$, this.env.pubsub.isConnected$).pipe(distinctUntilChanged());

    // Note that isOnline$ emits synchronously with the current value when it's
    // subscribed to, so `this._isOnline` is always non-null.
    this.isOnline$.subscribe((isOnline) => {
      this._isOnline = isOnline;
      this.env.logger.info(`[NetworkService] online ${isOnline}`);
    });
  }

  setMode(mode: "NORMAL" | "FORCE_OFFLINE") {
    this.env.pubsub._mode$.next(mode);
  }

  mode() {
    return this.env.pubsub._mode$.getValue();
  }

  isOnline() {
    return this._isOnline;
  }
}
