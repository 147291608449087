import { RecordValue, RecordTable, tablePKeys, RecordPointer } from "./schema";
import { Decoder, DecoderError, DecoderSuccess } from "ts-decoders";
import * as d from "ts-decoders/decoders";
import { JsonValue } from "type-fest";
import { getRecordDecoderBases } from "./getRecordDecoderBases";
import { isUuid } from "libs/uuid";

/* -------------------------------------------------------------------------------------------------
 * Decoders
 * -------------------------------------------------------------------------------------------------
 *
 * These objects help with taking an unknown value, validating it, and returning a new, known value.
 */

const tableNames = Object.keys(tablePKeys);

export const tableD = new Decoder<RecordTable>((input) => {
  if (typeof input !== "string") {
    return new DecoderError(input, "invalid-type", "must be a string");
  }

  if (!tableNames.includes(input)) {
    return new DecoderError(input, "invalid-value", "must be a valid table");
  }

  return new DecoderSuccess(input as RecordTable);
});

/** This decoder returns a record pointer known to be safe. */
export const pointerD = d.objectD({
  table: tableD,
  id: d.stringD(),
}) as Decoder<RecordPointer>;

export const uuidD = new Decoder((input) => {
  if (typeof input !== "string") {
    return new DecoderError(input, "invalid-type", "must be a uuid string");
  } else if (!isUuid(input)) {
    return new DecoderError(input, "invalid-uuid", "must be a uuid string");
  }

  return new DecoderSuccess(input);
});

/**
 * While `null` is a valid json type, we'd prefer top-level `null` values
 * to be saved to the database as `null` rather than saved as "null"
 * (i.e. JSON null). Additionally, if we want to allow top-level null values
 * we'll use the "nullable" version of the JSON decoder (created
 * inside the `getRecordDecoderBases` function). By "top-level" I mean
 * that nested JSON values should still accept null, but this decoder
 * rejects a simple `null` value.
 */
export const NonNullableJsonD = new Decoder((input) => {
  if (input === null) {
    return new DecoderError(input, "invalid-type", "top-level `null` value not allowed");
  }

  return JsonD.decode(input);
});

/**
 * Note that this decoder accepts `null` as a value as `null` is a valid JSON
 * value. If you wish to exclude `null` use the NonNullableJsonD decoder.
 */
export const JsonD: Decoder<JsonValue> = new Decoder((input) => {
  switch (typeof input) {
    case "object": {
      if (input === null) {
        return new DecoderSuccess(input);
      }

      if (Array.isArray(input)) {
        return JsonArrayD.decode(input);
      }

      return JsonDictionaryD.decode(input);
    }
    case "boolean":
    case "number":
    case "string": {
      return new DecoderSuccess(input);
    }
    default: {
      return new DecoderError(input, `invalid-type`, `invalid JSON type ${typeof input}`);
    }
  }
});

const JsonArrayD = d.arrayD(JsonD);
const JsonDictionaryD = d.dictionaryD(JsonD);

/* -------------------------------------------------------------------------------------------------
 *  decodeRecord
 * -------------------------------------------------------------------------------------------------
 */

/**
 * Decodes a given record to make sure it has the expected interface.
 */
export function decodeRecord<T extends RecordTable>(table: T, record: any) {
  const decoder = recordDecoderMap[table] as Decoder<RecordValue<T>> | undefined;

  if (!decoder) {
    throw new Error(`[validateRecord] unknown record table "${table}"`);
  }

  return decoder.decode(record);
}

const recordDecoderBases = getRecordDecoderBases({
  JsonD,
  BooleanD: d.booleanD(),
  DateTimeD: d.stringD(),
  IntegerD: d.integerD(),
  TextD: d.stringD(),
  UuidD: d.stringD(),
});

const recordDecoderMap = Object.fromEntries(
  Object.entries(recordDecoderBases).map(([table, base]) => [table, d.objectD(base as any)]),
);

/* -----------------------------------------------------------------------------------------------*/
