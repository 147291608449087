import { useEffect, useState } from "react";
import { EntryId, IListRef } from "~/components/list";

export function useSelectedListEntries<T>(
  props: { listRef?: React.RefObject<IListRef<T>>; isListRefSet?: boolean } = {},
) {
  const { listRef, isListRefSet } = props;

  const [selectedEntryIds, setSelectedEntryIds] = useState<EntryId[]>([]);

  useEffect(() => {
    if (!listRef?.current) return;

    const sub = listRef.current.selectedEntryIds$.subscribe((selectedEntryIds) => {
      setSelectedEntryIds(Array.from(selectedEntryIds));
    });

    return () => sub.unsubscribe();
  }, [isListRefSet]);

  return selectedEntryIds;
}
