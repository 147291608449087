import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { RefObject, useCallback, useRef, useState } from "react";
import { IListRef } from "~/components/list";
import { navigateBackOrToInbox, navigateService } from "~/environment/navigate.service";
import { showNotImplementedToastMsg } from "~/environment/toast-service";
import {
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
  getCommandFactory,
} from "~/utils/common-commands";
// import { performSearch } from "./useSearch";
import { ISearchEditorProps, ISearchEditorRef } from "~/components/forms/search-editor";
import { IFormControl } from "solid-forms-react";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useControlState } from "~/components/forms/utils";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { PointerWithRecord } from "libs/schema";
import { SearchMessagesParams } from "libs/database";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { SetOptional } from "type-fest";
import { useAsRef } from "~/hooks/useAsRef";
import { ClientApiRequestOptions } from "~/environment/api.service";
import { AbortError } from "libs/errors";
import { useUnmount } from "react-use";
import { MentionPluginKey } from "~/components/forms/search-editor/SearchEditorBase";
import { isAbortError } from "libs/predicates";

/* -------------------------------------------------------------------------------------------------
 * useRegisterSearchViewCommands
 * -----------------------------------------------------------------------------------------------*/

export function useRegisterSearchViewCommands(props: {
  editorRef: RefObject<ISearchEditorRef>;
  searchControl: ISearchEditorProps["control"];
  search: () => Promise<void>;
  onlySearchSeenPostsControl: IFormControl<boolean>;
}) {
  const { editorRef, searchControl, search, onlySearchSeenPostsControl } = props;

  const { currentUser } = useAuthGuardContext();
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      return [
        performSearchCommand({
          callback: () => {
            // Only perform search if the mention plugin is NOT active
            const editorState = editorRef.current?.editor?.state;
            if (editorState) {
              const mentionPlugin = MentionPluginKey.getState(editorState);
              if (mentionPlugin && mentionPlugin.active) {
                return false;
              }
            }

            search();
            return true;
          },
        }),
        {
          label: "Go to Search",
          hotkeys: ["/", "Shift+/"],
          callback: () => {
            editorRef.current?.focus("all");
          },
        },
        {
          label: "Select query or Back",
          showInKBar: false,
          triggerHotkeysWhenInputFocused: true,
          hotkeys: ["Escape"],
          callback: () => {
            const editor = editorRef.current?.editor;
            if (!editor) return false;

            const editorState = editor.state;
            if (editorState) {
              const mentionPlugin = MentionPluginKey.getState(editorState);
              if (mentionPlugin && mentionPlugin.active) {
                return false;
              }
            }
            navigateBackOrToInbox();
          },
        },
        markDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads Done from the
              Search page.
            `);
          },
        }),
        markNotDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads not Done from the
              Search page.
            `);
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the
              Search page.
            `);
          },
        }),
        removeThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the
              Search page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star threads from the
              Search page.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't unstar threads from the
              Search page.
            `);
          },
        }),
      ];
    },
    deps: [editorRef, searchControl, currentUser.id, environment, search, onlySearchSeenPostsControl],
  });
}

export const performSearchCommand = getCommandFactory(
  "PERFORM_SEARCH",
  (options: SetOptional<ICommandArgs, "label" | "triggerHotkeysWhenInputFocused" | "hotkeys">): ICommandArgs => ({
    label: "Submit",
    hotkeys: ["Enter"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

export function usePerformSearch(props: {
  searchControl: ISearchEditorProps["control"];
  resultsListRef: RefObject<IListRef<PointerWithRecord<"message">>>;
  onlySearchSeenPostsControl: IFormControl<boolean>;
}) {
  const { searchControl, onlySearchSeenPostsControl, resultsListRef } = props;
  const [searchResults, setSearchResults] = useState<{
    messages: never[] | (Partial<Record<string, any>> | undefined)[];
    processingTimeMs?: number;
    estimatedTotalHits?: number;
  }>({
    messages: [],
  });
  const [searchLimit, setSearchLimit] = useState(100);
  const [isEndOfSearch, setIsEndOfSearch] = useState(true);
  const { currentUser } = useAuthGuardContext();
  const currentUserId = currentUser.id;
  const environment = useClientEnvironment();

  const isSearchPending = useControlState(() => searchControl.isPending, [searchControl]);

  const searchLimitRef = useAsRef(searchLimit);

  const abortControllerRef = useRef<AbortController | null>(null);

  const abortSearch = useCallback(() => {
    abortControllerRef.current?.abort(new AbortError());
    abortControllerRef.current = null;
  }, [abortControllerRef]);

  // Abort an existing search if we navigate away from the search page
  useUnmount(abortSearch);

  const search = useCallback(
    async (props: { isFetchMore?: boolean } = {}) => {
      // Abort any existing search
      abortSearch();

      if (!searchControl.isValid) {
        environment.logger.debug("[search] Search is invalid. Ignoring...");
        return;
      }

      const query = searchControl.rawValue.queryText;

      if (!query) {
        setSearchResults({ messages: [] });
        setIsEndOfSearch(true);
        updateNavigationParams(query);
        return;
      }

      searchControl.markPending(true);
      abortControllerRef.current = new AbortController();

      const isSeen = onlySearchSeenPostsControl.rawValue;

      if (!props.isFetchMore) {
        setSearchResults({ messages: [] });
      }

      try {
        const startTime = Date.now();

        const result = await performSearch(
          {
            query: query + (isSeen ? " is:seen" : ""),
            currentUserId: currentUserId,
            environment,
            limit: searchLimitRef.current,
          },
          {
            abortSignal: abortControllerRef.current.signal,
          },
        );

        // search is successful so we don't need the abortController anymore
        abortControllerRef.current = null;

        updateNavigationParams(query);

        setSearchResults({
          messages: result.messages,
          processingTimeMs: result.processingTimeMs,
          estimatedTotalHits: result.estimatedTotalHits,
        });

        setIsEndOfSearch(result.messages.length < searchLimitRef.current);

        if (result.messages.length) {
          resultsListRef.current?.focus();
        }

        searchControl.markPending(false);

        environment.logger.debug(`[search] Search took ${Date.now() - startTime}ms`);
      } catch (error) {
        if (!isAbortError(error)) {
          environment.logger.error({ error }, `[search] Error performing search`);
        }

        searchControl.markPending(false);
      }
    },
    [
      abortSearch,
      searchControl,
      onlySearchSeenPostsControl.rawValue,
      currentUserId,
      environment,
      searchLimitRef,
      resultsListRef,
    ],
  );

  const fetchMore = useCallback(async () => {
    setSearchLimit((prev) => prev + 100);
    search({ isFetchMore: true });
  }, [search]);

  return {
    search,
    abortSearch,
    fetchMore,
    searchResults,
    isEndOfSearch,
    isSearchPending,
  };
}

const performSearch = async (
  props: {
    query: string;
    currentUserId: string;
    environment: Pick<ClientEnvironment, "api" | "writeRecordMap" | "isLoading">;
    limit: number;
  },
  options?: ClientApiRequestOptions,
) => {
  const { query, currentUserId, environment, limit } = props;

  const params: SearchMessagesParams = {
    query,
    currentUserId,
    limit,
  };

  using disposable = environment.isLoading.add();

  const response = await environment.api.search(params, options);

  if (response.status === 200 && response.body.recordMap) {
    environment.writeRecordMap(response.body.recordMap);

    return {
      messages: response.body.hits,
      processingTimeMs: response.body.processingTimeMs,
      estimatedTotalHits: response.body.estimatedTotalHits,
    };
  } else {
    return { messages: [] };
  }
};

function updateNavigationParams(query: string) {
  if (!query) {
    navigateService(`/search`, {
      replace: true,
    });
  } else {
    navigateService(`/search?q=${encodeURIComponent(query)}`, {
      replace: true,
    });
  }
}
