import { ComponentType, useMemo, useState } from "react";
import { cx } from "@emotion/css";
import { List } from "~/components/list";
import useLocalStorageState from "use-local-storage-state";
import { oneLine } from "common-tags";
import { ShortcutHintContents } from "~/environment/hint-service/hints";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { RecordValue, getPointer } from "libs/schema";
import { useGroup } from "~/hooks/useGroup";
import { useMatch, useNavigate } from "react-router-dom";
import { FolderTree } from "~/observables/observeGroupFoldersUserIsSubscribedTo";
import { useGroupFoldersUserIsSubscribedTo } from "~/hooks/useGroupFoldersUserIsSubscribedTo";
import { stringComparer } from "libs/comparers";
import { useRecords } from "~/hooks/useRecords";
import { useUsersOrganizationIds } from "~/hooks/useUserOrganizationsByUserId";
import { isTagPrivate } from "libs/schema/predicates";
import { BsLockFill } from "react-icons/bs";
import { renderGroupName } from "~/utils/groups-utils";
import { FaRegPlusSquare } from "react-icons/fa";
import { MdOutlineMoreVert, MdEdit, MdArchive, MdUnarchive } from "react-icons/md";
import { DropdownMenu } from "~/components/DropdownMenu";
import { Tooltip } from "~/components/Tooltip";
import { createGroupCommand } from "~/utils/common-commands";
import { archiveTag, unarchiveTag } from "~/actions/tag";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { EditGroupDialogState } from "~/dialogs/group-edit/EditGroupDialog";

export function convertShortcutToTrigger(shortcut: number) {
  return `g ${shortcut.toString().split("").join(" ")}`;
}

export const SidebarGroups: ComponentType<{}> = () => {
  const { currentUser } = useAuthGuardContext();

  const tree = useSidebarGroupTree();

  const treeEntries = useTreeEntries(tree);

  return (
    <>
      {treeEntries.map(([groupId, tree], index) => (
        <GroupEntry
          key={groupId}
          parentEntryId={currentUser.id}
          groupId={groupId}
          childTree={tree}
          relativeOrder={index}
          level={1}
        />
      ))}
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const GroupEntry: ComponentType<{
  parentEntryId: string;
  groupId: string;
  childTree: FolderTree | false;
  relativeOrder: number;
  level: number;
}> = (props) => {
  const [group] = useGroup(props.groupId);
  const entryId = `${props.parentEntryId}${props.groupId}`;
  const [isExpanded, setIsExpanded] = useIsGroupExpanded(entryId);
  const navigate = useNavigate();
  const to = `/groups/${props.groupId}`;
  const isActive = !!useMatch(to);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  if (!group) return null;

  const isPrivate = isTagPrivate(group);

  return (
    <li className="flex flex-col">
      <List.Entry<RecordValue<"tag">>
        // We need to namespace the IDs because the same group can appear
        // in multiple spots within the SidebarGroups tree. If we don't
        // namespace these IDs then hovering a list entry causes a
        // recursion depth error.
        id={entryId}
        data={group}
        relativeOrder={props.relativeOrder}
        onEntryAction={({ event }) => {
          if (event.defaultPrevented) return;
          navigate(to);
        }}
      >
        <div
          className={cx(
            `group flex items-center pl-3 pr-4 py-2`,
            `focus:bg-slate-4 outline-none hover:cursor-pointer`,
            isContextMenuOpen && "bg-slate-4",
          )}
          style={{ paddingLeft: `${props.level * 0.9}rem` }}
        >
          {!props.childTree ? (
            <div className="w-[9px]" />
          ) : (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsExpanded((v) => !v);
              }}
            >
              <TriangleIcon className={cx({ "rotate-90": isExpanded })} />
            </button>
          )}

          <span className={cx("text-left", isActive ? "font-bold" : "font-medium")}>{renderGroupName(group)}</span>

          {isPrivate && (
            <span className="inline-flex ml-1">
              <small>
                <BsLockFill />
              </small>
            </span>
          )}

          <span className="flex-1" />

          {props.level === 1 ? (
            <Tooltip side="bottom" content="Add group">
              <button
                type="button"
                className="ml-2 text-slate-9 hover:text-black"
                onClick={(e) => {
                  e.preventDefault();
                  createGroupCommand.trigger();
                }}
              >
                <FaRegPlusSquare />
              </button>
            </Tooltip>
          ) : (
            <GroupEntryContextMenu open={isContextMenuOpen} setOpen={setIsContextMenuOpen} groupId={props.groupId} />
          )}
        </div>
      </List.Entry>

      {isExpanded && props.childTree && (
        <GroupChildren parentEntryId={entryId} tree={props.childTree} level={props.level + 1} />
      )}
    </li>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const GroupEntryContextMenu: ComponentType<{
  groupId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const environment = useClientEnvironment();
  const [group] = useGroup(props.groupId);

  return (
    <DropdownMenu
      open={props.open}
      setOpen={props.setOpen}
      trigger={
        <div className="relative h-4">
          <div className={cx("absolute right-0 -top-1", props.open ? "flex" : "hidden group-focus:flex")}>
            <div className="h-6 w-6 bg-gradient-to-l from-slate-4 to-transparent" />

            <Tooltip side="bottom" content="Group options">
              <button
                type="button"
                className="py-1 bg-slate-4 text-slate-9 hover:text-black"
                onClick={(e) => {
                  e.preventDefault();
                  props.setOpen((v) => !v);
                }}
              >
                <MdOutlineMoreVert />
              </button>
            </Tooltip>
          </div>
        </div>
      }
    >
      <DropdownMenu.Item
        icon={<MdEdit />}
        onClick={(e) => {
          e.preventDefault();
          if (!group) return;

          props.setOpen(false);

          EditGroupDialogState.open({
            prefill: {
              id: group.id,
              name: group.name,
              description: group.description,
              icon: group.icon,
              isPrivate: isTagPrivate(group),
            },
          });
        }}
      >
        Edit group...
      </DropdownMenu.Item>

      <DropdownMenu.Item
        icon={<FaRegPlusSquare />}
        onClick={(e) => {
          e.preventDefault();
          if (!group) return;

          props.setOpen(false);

          EditGroupDialogState.open({
            prefill: {
              parentGroupIds: [group.id],
              isPrivate: isTagPrivate(group),
            },
          });
        }}
      >
        Add nested group...
      </DropdownMenu.Item>

      {group?.archived_at ? (
        <DropdownMenu.Item
          icon={<MdUnarchive />}
          onClick={(e) => {
            e.preventDefault();
            props.setOpen(false);
            unarchiveTag(environment, { tagId: props.groupId });
          }}
        >
          Unarchive group
        </DropdownMenu.Item>
      ) : (
        <DropdownMenu.Item
          icon={<MdArchive />}
          onClick={(e) => {
            e.preventDefault();
            props.setOpen(false);
            archiveTag(environment, { tagId: props.groupId });
          }}
        >
          Archive group
        </DropdownMenu.Item>
      )}
    </DropdownMenu>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const GroupChildren: ComponentType<{
  parentEntryId: string;
  tree: FolderTree;
  level: number;
}> = (props) => {
  const treeEntries = useTreeEntries(props.tree);

  if (treeEntries.length === 0) return null;

  return (
    <ul className="list-none">
      {treeEntries.map(([groupId, childTree], index) => (
        <GroupEntry
          key={groupId}
          parentEntryId={props.parentEntryId}
          groupId={groupId}
          childTree={childTree}
          relativeOrder={index}
          level={props.level}
        />
      ))}
    </ul>
  );
};

// const GroupsInFolderThatUserIsSubscribedTo: ComponentType<{
//   folderId: string;
//   level: number;
// }> = (props) => {
//   const { currentUser } = useAuthGuardContext();

//   const groupIds = [] as any[];

//   // const { tagIds: groupIds } = useTagsInFolderThatUserIsSubscribedTo({
//   //   userId: currentUser.id,
//   //   folderId: props.folderId,
//   // });

//   // useFolderChildrenUserIsSubscribedTo(
//   //   props.folderId,
//   // );

//   useConsoleLog("debug", "SidebarChannelGroups", props.folderId, groupIds);

//   if (groupIds.length === 0) return null;

//   return (
//     <ul className="list-none mb-10">
//       {groupIds.map((groupId, index) => (
//         <GroupEntry
//           key={groupId}
//           groupId={groupId}
//           relativeOrder={index}
//           level={props.level}
//         />
//       ))}
//     </ul>
//   );
// };

/* -----------------------------------------------------------------------------------------------*/

function useSidebarGroupTree() {
  const { currentUserId } = useAuthGuardContext();

  const [tree] = useGroupFoldersUserIsSubscribedTo({
    userId: currentUserId,
    // The SyncService is already loading all of these records so we can just pull from the cache
    fetchStrategy: "cache",
  });

  const [organizationIds] = useUsersOrganizationIds({
    userId: currentUserId,
    // The SyncService is already loading all of these records so we can just pull from the cache
    fetchStrategy: "cache",
  });

  // If the current user isn't subscribed to any groups in an organization,
  // we still want that organization to show up in their sidebar
  return useMemo(() => {
    const organizationEntries = Object.fromEntries(organizationIds.map((id) => [id, false])) as FolderTree;

    return {
      ...organizationEntries,
      ...tree,
    };
  }, [tree, organizationIds]);
}

function useTreeEntries(tree: FolderTree) {
  const pointers = useMemo(() => Object.keys(tree).map((groupId) => getPointer("tag", groupId)), [tree]);

  const [groups] = useRecords(pointers, { name: "useTreeEntries" });

  return useMemo(
    () =>
      groups
        .toSorted((a, b) => {
          return stringComparer(a.record.name, b.record.name) || stringComparer(a.id, b.id);
        })
        .map((group) => [group.id, tree[group.id]!] as const),
    [tree, groups],
  );
}

function useIsGroupExpanded(entryId: string) {
  const { currentUser } = useAuthGuardContext();

  return useLocalStorageState(`${currentUser.id}.sidebar.groupExpanded.${entryId}`, { defaultValue: true });
}

const TriangleIcon: ComponentType<{ className?: string }> = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx("scale-150 mr-2", props.className)}
    >
      <path d="M6 11L6 4L10.5 7.5L6 11Z" fill="currentColor"></path>
    </svg>
  );
};

export const sidebarEntryCSS = oneLine`
  flex py-2 pl-9 pr-4 leading-6 focus:bg-slate-4 hover:bg-slate-4 
  outline-none focus:border-black border-l-2 
  border-white group relative items-center
`;

export const ShortcutHint: ComponentType<{
  hint: string;
}> = ({ hint }) => {
  return (
    <div className={cx("items-center hidden group-hover:flex", "absolute top-0 right-0 h-full text-white", "mr-4")}>
      <ShortcutHintContents hint={hint} keyClassName="bg-slate-10" adverbClassName="text-slate-10 font-medium" />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/
