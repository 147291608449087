import { registerSW } from "virtual:pwa-register";
import { BehaviorSubject, distinctUntilChanged, interval, merge, Subject, switchMap } from "rxjs";
import { Logger } from "libs/logger";
import { Simplify } from "type-fest";
import { UnreachableCaseError } from "libs/errors";
import { oneLine } from "common-tags";
import { checkNotificationSupport } from "~/utils/dom-helpers";
import { MS_IN_MINUTE, MS_IN_SECOND } from "libs/date-helpers";
import { navigatorIsOnline } from "~/utils/navigatorIsOnline";
import { LocalStorageKVStore } from "~/utils/KVStore";
import { ClientEnvironment } from "../ClientEnvironment";
import { startWith } from "libs/rxjs-operators";
import { isEqual } from "libs/predicates";

/**
 * The `ServiceWorkerService` class is responsible for managing the service worker for the app.
 */

export type ServiceWorkerServiceApi = Simplify<ServiceWorkerService>;

export class ServiceWorkerService {
  static store = new LocalStorageKVStore({
    namespace: "service-worker",
  });

  /** Will return a UTC string or null if no update is available */
  static getWhenServiceWorkerUpdateBecameAvailable() {
    return ServiceWorkerService.store.getItem<string>(SW_UPDATE_AVAILABLE_KEY) ?? null;
  }

  /**
   * Note that this is typed as non-nullable for ease of use, but it will be
   * undefined until the `isReady` promise resolves.
   */
  private serviceWorker!: ServiceWorker;
  /**
   * Note that this is typed as non-nullable for ease of use, but it will be
   * undefined until the `isReady` promise resolves.
   */
  private registration!: ServiceWorkerRegistration;

  /**
   * There isn't a native event for push subscription changes. We manually emit this event so that
   * the UI is more responsive to user changes.
   */
  private pushNotificationSubscriptionChange$ = new Subject<void>();

  /**
   * Emits the current push notification subscription or null if this client doesn't have a
   * native push subscription.
   */
  pushNotificationSubscription$ = merge(interval(10_000), this.pushNotificationSubscriptionChange$).pipe(
    startWith(() => null),
    switchMap(() => this.getPushNotificationSubscription()),
    distinctUntilChanged((a, b) => {
      const x = { ...a, pushSubscription: a?.toJSON() };
      const y = { ...b, pushSubscription: b?.toJSON() };
      return isEqual(x, y);
    }),
  );

  isReady: Promise<void>;

  private _isUpdateAvailable$ = new BehaviorSubject(false);
  isUpdateAvailable$ = this._isUpdateAvailable$.pipe(distinctUntilChanged());

  private _isUpdateBeingInstalled$ = new BehaviorSubject(false);
  isUpdateBeingInstalled$ = this._isUpdateBeingInstalled$.pipe(distinctUntilChanged());

  get isUpdateAvailable() {
    return this._isUpdateAvailable$.getValue();
  }

  constructor(private env: Pick<ClientEnvironment, "logger" | "auth" | "isPendingUpdate">) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "ServiceWorkerService" }),
    };

    registerSW({
      onRegisteredSW: this.onRegistered.bind(this),
      onNeedRefresh: this.onUpdateFound.bind(this),
      onOfflineReady: this.onInstall.bind(this),
    });

    // Previously we didn't namespace the key for the service worker update available
    // time. As part of migrating to the new KVStore, we clear that previous value, if
    // it exists. This can be removed in the future.
    // -- John 2024-10-28
    localStorage.removeItem("service-worker-update-available");

    navigator.serviceWorker.addEventListener("controllerchange", async () => {
      // If a service worker is installed for the first time, it will not control
      // the page until either (1) the page is reloaded or (2) we tell the service
      // worker to take control of the page.
      //
      // If we tell the service worker to take control of an active page, then the
      // `controllerchange` event will emit. When it does so, `this.serviceWorker` will
      // be `undefined` if there previously wasn't a service worker controlling the page.
      // If `this.serviceWorker` is defined, then this page was already controlled by a
      // service worker and we've just updated it.
      const isActivationAfterInstall = !this.serviceWorker;

      if (isActivationAfterInstall) {
        // Service worker was installed for the first time. Since the service worker's
        // code is identical to the current page's code, we don't need to reload the page.
        return;
      }

      // Service worker was updated.
      this.env.logger.notice(`[controllerchange] service worker was updated`);

      // If the service worker controlling the page changes after being updated,
      // we need to reload the page to ensure all in-memory resources are updated
      // to the latest version. While we wait for pending updates to complete before
      // activating the update, that wait only applies to the tab that the update was
      // activated in. Meanwhile, this controllerchange event will fire for all tabs
      // that are currently controlled by the old service worker. So we want to wait
      // for pending updates for this tab before reloading the page.
      await this.env.isPendingUpdate.whenNoPendingUpdates({ maxWaitMs: MS_IN_SECOND * 4 });

      this.env.isPendingUpdate.showPendingUpdatesWarning(false);

      window.location.reload();
    });

    this.isReady = getServiceWorker(this.env.logger).then(({ serviceWorker, registration }) => {
      this.serviceWorker = serviceWorker;
      this.registration = registration;
      this._isUpdateAvailable$.next(!!registration.waiting);

      this.isUpdateAvailable$.subscribe((isUpdateAvailable) => {
        const storage = ServiceWorkerService.store;

        // Update the local storage to indicate that a service worker update is available and also
        // indicate *when* that update became available.
        if (!isUpdateAvailable) {
          storage.removeItem(SW_UPDATE_AVAILABLE_KEY);
        } else if (!storage.getItem(SW_UPDATE_AVAILABLE_KEY)) {
          const now = new Date().toISOString();
          storage.setItem(SW_UPDATE_AVAILABLE_KEY, now);
        }
      });

      // Check for updates every 5 minutes
      setInterval(this.checkForUpdate.bind(this), MS_IN_MINUTE * 5);
    });
  }

  /**
   * This function immediately checks for a service worker update, busting the cache.
   */
  async checkForUpdate() {
    await this.isReady;

    this.env.logger.debug("Checking for sw update");

    const registration = await navigator?.serviceWorker?.getRegistration();

    if (!registration) return;
    if (registration.installing) return;
    if ("connection" in navigator && !navigatorIsOnline()) return;

    // From MDM:
    // The update() method of the ServiceWorkerRegistration interface attempts to update the service
    // worker. It fetches the worker's script URL, and if the new worker is not byte-by-byte identical
    // to the current worker, it installs the new worker. The fetch of the worker bypasses any browser
    // caches if the previous fetch occurred over 24 hours ago. It resolves with a
    // ServiceWorkerRegistration object (the same object that was passed in). If an update was found,
    // the service worker registration will have a non-null `installing` property.
    //
    // Note that the typescript types for this method are incorrect.
    return registration.update() as unknown as Promise<ServiceWorkerRegistration>;
  }

  /**
   * This function immediately checks for a service worker update, busting the cache, and sets a flag
   * that causes the next service worker update to automatically be activated, whenever that
   * update becomes available (even if, at the time this function is called, there isn't an update
   * available).
   */
  async checkForUpdateAndActivateIfAvailable() {
    this._isUpdateBeingInstalled$.next(true);

    await this.isReady;

    const registration = await this.checkForUpdate();

    // If we found a new service worker while checking for an update, we need to wait for it to
    // finish installing before we can activate it. Note that it's also possible that there is an
    // update which has already been installed and is awaiting activation.
    //
    // For more info see https://github.com/levelshealth/comms/pull/1213
    await new Promise<void>((res) => {
      const newWorker = registration?.installing;
      if (!newWorker) return res();
      newWorker.addEventListener("statechange", () => newWorker.state === "installed" && res(), { once: true });
    });

    await this.activateUpdate();
  }

  async activateUpdate() {
    await this.isReady;

    const newWorker = this.registration.waiting;

    // There is no update to activate.
    if (!newWorker) return;

    await this.env.isPendingUpdate.whenNoPendingUpdates({ maxWaitMs: MS_IN_SECOND * 4 });

    newWorker.postMessage({ type: "SKIP_WAITING" });
  }

  async getPushNotificationSubscription() {
    await this.isReady;
    if (!checkNotificationSupport()) return null;
    return this.registration.pushManager.getSubscription();
  }

  async subscribeToPushNotifications() {
    await this.isReady;
    if (!checkNotificationSupport()) {
      alert(oneLine`
        We're sorry, but your browser doesn't appear to support push notifications.
      `);

      return null;
    }

    const subscribe = async () => {
      const subscription = await this.registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: import.meta.env.VITE_VAPID_PUBLIC_KEY,
      });

      this.pushNotificationSubscriptionChange$.next();

      return subscription;
    };

    switch (Notification.permission) {
      case "denied": {
        alert(oneLine`
          Your browser has denied push notifications for app.comms.day. You'll need to manually
          allow push notifications for app.comms.day in your browser's settings. In most browsers
          you can accomplish this by pressing the small button located *in the URL bar* immediately
          to the left of "app.comms.day". Again, the button is *in* the URL bar--you might not even 
          realize it's a button. It looks different on different browsers but in Google Chome it 
          looks like a small "i" with a circle around it.
        `);

        return null;
      }
      case "granted": {
        return subscribe();
      }
      case "default": {
        const permission = await Notification.requestPermission();
        if (permission !== "granted") return null;
        return subscribe();
      }
      default: {
        throw new UnreachableCaseError(Notification.permission);
      }
    }
  }

  async unsubscribeFromPushNotifications() {
    await this.isReady;
    if (!checkNotificationSupport()) return true;
    const subscription = await this.getPushNotificationSubscription();
    if (!subscription) return true;
    const result = await subscription.unsubscribe();
    this.pushNotificationSubscriptionChange$.next();
    return result;
  }

  /**
   * As noted above, the install event is the first event a service worker gets, and it only
   * happens once per service worker.
   */
  private onInstall() {
    this.env.logger.debug("onInstall");
    this.activateUpdate();
  }

  /**
   * "registration" is the process of a tab "loading" a service worker script and getting a
   * handle to it. In reality, if the service worker is already installed for the domain then
   * "loading" it will not download or reinstall it, instead it just returns a handle to the
   * existing service worker. The handle is called the service worker's registration.
   */
  private onRegistered(swUrl: string, registration: ServiceWorkerRegistration | undefined) {
    this.env.logger.debug({ swUrl, registration }, "onRegistered");
  }

  /**
   * Called each time a new update is available
   */
  private onUpdateFound() {
    this.env.logger.debug("onUpdateFound");

    this._isUpdateAvailable$.next(true);

    // If the current user is logged out then we should automatically update the
    // service worker immediately.
    if (!this.env.auth.getCurrentUserId()) {
      this.activateUpdate();
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

function getServiceWorker(logger: Logger) {
  return new Promise<{
    serviceWorker: ServiceWorker;
    registration: ServiceWorkerRegistration;
  }>((res) => {
    navigator.serviceWorker.ready.then((registration) => {
      const resolve = (serviceWorker: ServiceWorker) => res({ serviceWorker, registration });

      // Check if the service worker is controlling the page immediately
      if (navigator.serviceWorker.controller) {
        resolve(navigator.serviceWorker.controller);
      } else {
        logger.debug("awaiting service worker controllerchange");

        // Use the 'controllerchange' event to detect when control is claimed
        navigator.serviceWorker.addEventListener(
          "controllerchange",
          () => resolve(navigator.serviceWorker.controller!),
          { once: true },
        );

        if (registration.active) {
          // This might occur if the user force refreshes the page. In this case,
          // the service worker will be ignored on page refresh even though it's already
          // installed. We need to tell the service worker to claim this tab in order
          // for it to take over.
          registration.active.postMessage({ type: "SKIP_WAITING" });
        }
      }
    });
  });
}

/* -----------------------------------------------------------------------------------------------*/

const SW_UPDATE_AVAILABLE_KEY = "update-available";

/* -----------------------------------------------------------------------------------------------*/
