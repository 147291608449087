import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useRecord } from "./useRecord";
import { useMemo } from "react";
import { getPointer } from "libs/schema";
import { FetchStrategy } from "~/environment/QueryCache";

export function useCurrentUserTagSubscription(props: { tagId?: string | null; fetchStrategy?: FetchStrategy }) {
  const { currentUserId } = useAuthGuardContext();

  const pointer = useMemo(() => {
    if (!props.tagId || !currentUserId) return null;

    return getPointer("tag_subscription", {
      tag_id: props.tagId,
      user_id: currentUserId,
    });
  }, [props.tagId, currentUserId]);

  return useRecord(pointer, { fetchStrategy: props.fetchStrategy, name: "useCurrentUserTagSubscription" });
}
