import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { createFormControl, createFormGroup, useControl } from "solid-forms-react";
import { handleSubmit, useControlState } from "~/components/forms/utils";
import { PLATFORM_MODIFIER_KEY, useRegisterCommands } from "~/environment/command.service";
import { SubmitDialogHint } from "../DialogLayout";
import { useAutocompleteMenuPositioning } from "~/components/forms/AutocompleteSelect";
import { Tooltip } from "~/components/Tooltip";
import { ThreadVisibility } from "libs/schema";
import { GroupSelect, IGroupOption } from "~/components/forms/GroupSelect";
import { isTagPrivate } from "libs/schema/predicates";
import { SingleValue } from "react-select";
import { addGroupToThread, updateThreadVisibility } from "~/actions/thread";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export type AddGroupToThreadDialogData = {
  threadId: string;
};

export const AddGroupToThreadDialogState = new DialogState<AddGroupToThreadDialogData>();

interface IFormValue {
  threadId: string;
  visibility: ThreadVisibility;
  group: SingleValue<IGroupOption>;
}

export const AddGroupToThreadDialog = withModalDialog({
  dialogState: AddGroupToThreadDialogState,
  async loadData({ environment, data }) {
    if (!data) {
      throw new Error("AddGroupToThreadDialogData not provided");
    }

    const [thread] = await environment.recordLoader.getRecord("thread", data.threadId);

    if (!thread) {
      alert("Could not find thread to edit");
    }

    return {
      thread,
    };
  },
  Component: (props) => {
    const { thread } = props.data;

    if (!thread) {
      AddGroupToThreadDialogState.close();
      return null;
    }

    const environment = useClientEnvironment();

    const control = useControl(() =>
      createFormGroup({
        threadId: createFormControl(thread.id),
        visibility: createFormControl<ThreadVisibility>(thread.visibility),
        group: createFormControl<SingleValue<IGroupOption>>(null),
      }),
    );

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              AddGroupToThreadDialogState.close();
            },
          },
          {
            label: "Mark thread as private",
            hotkeys: [
              "$mod+Alt+p",
              // Note that, depending on the browser, the emitted KeyboardEvent#key
              // value may be "p" or "P" (chrome on windows seems to do "p" whereas
              // Firefox does "P"). For this reason, we also add a second shortcut
              // using "KeyP". The first shortcut will be shown in the kbar
              "$mod+Alt+KeyP",
            ],
            triggerHotkeysWhenInputFocused: true,
            callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
              const oldVisibility = control.rawValue.visibility;
              const newVisibility: ThreadVisibility = oldVisibility === "PRIVATE" ? "SHARED" : "PRIVATE";

              control.patchValue({
                visibility: newVisibility,
                group: null,
              });

              try {
                await updateThreadVisibility(environment, {
                  threadId: control.rawValue.threadId,
                  visibility: newVisibility,
                });
              } catch {
                control.patchValue({
                  visibility: oldVisibility,
                });
              }
            }),
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
              console.log("attempting submit");
              handleSubmit({ environment, control, submit });
            }),
          },
        ];
      },
      deps: [environment, control],
    });

    const visibility = useControlState(() => control.rawValue.visibility, [control]);

    const groupOption = useControlState(() => control.rawValue.group, [control]);

    const [recipientsAutocompleteRef, recipientsAutocompletePortalEl, recipientsAutocompletePortalJSX] =
      useAutocompleteMenuPositioning<IGroupOption, true>();

    return (
      <>
        <DialogTitle>
          <h2>Add thread to group</h2>
        </DialogTitle>

        <div className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="px-4 py-2">
            <GroupSelect
              multiple={false}
              value={groupOption}
              onChange={(value: SingleValue<IGroupOption>) => {
                control.controls.group.setValue(value);
              }}
              isOptionDisabledFn={({ group }) => {
                const isDisabled = visibility === "PRIVATE" ? !isTagPrivate(group) : isTagPrivate(group);
                let disabledReason: string | undefined;

                if (isDisabled) {
                  if (visibility === "PRIVATE") {
                    disabledReason = "Cannot add shared group to private thread.";
                  } else {
                    disabledReason = "Cannot add private group to shared thread.";
                  }
                }

                return { isDisabled, disabledReason };
              }}
              autocompleteRef={recipientsAutocompleteRef}
              autocompleteMenuEl={recipientsAutocompletePortalEl}
              autoFocus
            />
          </div>

          <div className="flex px-4 py-2 border-t border-mauve-5">
            <div className="flex-1" />

            <Tooltip side="bottom" content={`${PLATFORM_MODIFIER_KEY.name} + Enter`}>
              <button
                type="button"
                className={`rounded bg-slate-5 border px-2
                  border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
                onClick={() => handleSubmit({ environment, control, submit })}
              >
                Submit
              </button>
            </Tooltip>
          </div>
        </div>

        {recipientsAutocompletePortalJSX}

        <SubmitDialogHint />
      </>
    );
  },
});

const submit = async (environment: ClientEnvironment, values: IFormValue) => {
  console.log("submitting...", values);

  if (!values.group) return;

  AddGroupToThreadDialogState.close();

  await addGroupToThread(environment, {
    threadId: values.threadId,
    groupId: values.group.value,
  });

  console.log("submitted successfully!");
};
