import { useObservable, useObservableState } from "observable-hooks";
import { useEffect, useRef } from "react";
import { Observable } from "rxjs";
import { useDidDepsChange } from "./useDidDepsChange";

/**
 * The "observable-hooks" library updates observable deps inside a useEffect hook
 * which has the undesirable side effect that the observable always updates a frame
 * after the deps update. For this reason, observables that return an object containg
 * an `isLoading: boolean` value update their isLoading value a frame late, which can
 * cause challenges. This hook wraps the "observable-hooks" functions and returns
 * an observable which has the isLoading value update syncronously when the dependencies
 * update.
 */
export function useLoadingObservable<
  Result extends { isLoading: boolean } | [any, { isLoading: boolean }],
  Deps extends readonly any[],
>(props: {
  fn: (inputs$: Observable<[...Deps]>) => Observable<Result>;
  deps: [...Deps];
  initialValue: Result;
}): Result {
  const isFirstRender = useIsFirstRender();

  const didDepsChange = useDidDepsChange(props.deps);

  const observable = useObservable(props.fn, props.deps);

  const result = useObservableState(observable, props.initialValue);

  // When the dependencies change, they will be passed to the observable inside a useEffect hook.
  // Because of this, the render pass in which the deps change will not have the updated value from the
  // observable. Below we detect this and synchronously update the `isLoading` value of the observable
  // for the render pass in which the deps change (and after that, we can rely on the observable's value).
  //
  // Previously we decided that we didn't want to reuse the previous observable value for the render pass after
  // the deps changed and we instead returned the initialValue again (after all, if the deps changed then
  // it's a new query). However, this causes issues with pagination. When paginating through a query, the
  // `limit` dependency is changing. If, after increasing the `limit`, we return the initialValue again and
  // the initialValue is length 0, then after increasing the pagination limit the page will briefly be rendered
  // as empty, causing the user's scroll position to be reset to the top of the page. Because of this we
  // prefer to simply reuse the previous observable value for the render pass after the deps change.
  //
  // Old code:
  // // Immediately after the deps change, we show the default initial value. This will
  // // always be true for the first render, and that's fine.
  // if (didDepsChange) {
  //   return props.initialValue;
  // }

  if (Array.isArray(result)) {
    const [value, meta] = result;

    return [
      value,
      {
        ...meta,
        // If it's the first render, then we don't care if the deps changed
        isLoading: isFirstRender ? meta.isLoading : meta.isLoading || didDepsChange,
      },
    ] as Result;
  }

  return {
    ...result,
    // If it's the first render, then we don't care if the deps changed
    isLoading: isFirstRender ? result.isLoading : result.isLoading || didDepsChange,
  };
}

function useIsFirstRender(): boolean {
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return isFirstRender.current;
}
