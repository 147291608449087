import * as ops from "libs/actions";
import { op } from "libs/transaction";
import { getUserPushNotificationSubscriptionForThisClient } from "~/queries/getUserPushNotificationSubscriptionForThisClient";
import { WebPushSubscription } from "libs/schema";
import { oneLine } from "common-tags";
import { withTransaction, write } from "./write";
import { AlertDialogState } from "~/dialogs/alert/AlertDialog";

/* -------------------------------------------------------------------------------------------------
 * subscribeToPushNotifications
 * -------------------------------------------------------------------------------------------------
 */

export const subscribeToPushNotifications = withTransaction(
  "subscribeToPushNotifications",
  async (environment, transaction) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

    // Creating the PushSubscription object requires a network request to Google's
    // servers. Here we first check to see if we already have a PushSubscription for
    // this client. If we don't, then we require the user to be online in order to
    // proceed.
    let subscription = await environment.serviceWorker.getPushNotificationSubscription();

    if (!subscription) {
      // Comms doesn't need to be online to create the "user_push_notification_subscriptions"
      // record, but creating the native PushSubscription object requires communicating
      // with Google's servers.
      if (!environment.network.isOnline()) {
        AlertDialogState.open({
          content: oneLine`
            You appear to be offline. Unfortunately, you must be online to grant Comms
            access to push notifications.
          `,
        });

        return;
      }

      // Here we prompt the user for permission if we haven't already and also create a
      // PushSubscription object
      subscription = await environment.serviceWorker.subscribeToPushNotifications();
    }

    if (!subscription) return;

    ops.applyOperationsToTransaction(
      transaction,
      ops.userPushNotificationSubscription.createPushNotificationSubscription({
        userId: currentUserId,
        ownerOrganizationId,
        subscription: subscription.toJSON() as WebPushSubscription,
      }),
    );

    await write(environment, { transaction });
  },
);

/* -------------------------------------------------------------------------------------------------
 * unsubscribeFromPushNotifications
 * -------------------------------------------------------------------------------------------------
 */

export const unsubscribeFromPushNotifications = withTransaction(
  "unsubscribeFromPushNotifications",
  async (environment, transaction) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();

    // While we *could* unsubscribe from the native PushSubscription object, there isn't
    // a reason to so so far as I'm aware. The native push notification object just means
    // that Comms has permission to send notifications to this client *and knows how to
    // do so.* But just deleting the "user_push_notification_subscriptions" record will
    // mean that Comms won't send any notifications to this client. Also, creating the
    // native PushSubscription takes a network request to Google's servers and seems to
    // take about 500ms, which results in a user noticable delay betwen pressing the
    // "Receive push notifications" taggle in settings and the toggle showing as
    // checked (because the toggle just reflects the actual subscription state). If we
    // keep the subscription around, then toggling push notifications on and off is
    // instantaneous from the user's perspective after the first time. Also, while the
    // initial time you press the "Receive push notifications" button you need to be
    // online, subsequent toggling of the button doesn't require being online because
    // we're just reusing the same PushSubscription object that was already created by
    // the browser.
    //
    // await environment.serviceWorker.unsubscribeFromPushNotifications();

    const subscription = await getUserPushNotificationSubscriptionForThisClient(environment, {
      userId: currentUserId,
    });

    if (!subscription) return;

    transaction.operations.push(op.delete("user_push_notification_subscriptions", subscription));

    await write(environment, { transaction });
  },
);

/* -----------------------------------------------------------------------------------------------*/
