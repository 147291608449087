import { cx } from "@emotion/css";
import { memo, PropsWithChildren } from "react";

export const ActionPanel = memo((props: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={cx(
        "ActionPanel flex-1 flex justify-end relative",
        "sm-w:min-w-[64px]",
        "lg-w:min-w-[185px]",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
});
