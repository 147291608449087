import "./polyfills";
import "./setup";
import { StrictMode } from "react";
import { render } from "react-dom";
import "tailwindcss/tailwind.css";
import App from "./App";
import "./index.scss";
import { beforeInstallPrompt$ } from "./utils/beforeInstallPrompt";

// See
// https://github.com/khmyznikov/pwa-install?tab=readme-ov-file#async-mode
beforeInstallPrompt$.subscribe();

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root"),
);
