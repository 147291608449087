import { observeMentionableUsers } from "~/observables/observeMentionableUserRecords";
import { observeMentionableGroupRecords } from "~/observables/observeMentionableGroupRecords";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { observeCurrentUserMentionFrequency } from "~/observables/observeCurrentUserMentionFrequency";
import { ObserveOptions } from "~/environment/RecordLoader";
import { Observable, combineLatest, distinctUntilChanged, map } from "rxjs";

export function observeMentionExtensionData(
  environment: Pick<ClientEnvironment, "logger" | "subscriptionManager" | "recordLoader">,
  props: { currentUserId: string },
  options?: ObserveOptions,
): Observable<unknown> {
  const { currentUserId } = props;

  const getIsLoading$ = (observable: Observable<[unknown, { isLoading: boolean }]>) => {
    return observable.pipe(
      map(([_, { isLoading }]) => isLoading),
      distinctUntilChanged(),
    );
  };

  return combineLatest([
    getIsLoading$(observeMentionableUsers(environment, { currentUserId }, options)),
    getIsLoading$(observeMentionableGroupRecords(environment, { currentUserId }, options)),
    getIsLoading$(observeCurrentUserMentionFrequency(environment, { currentUserId }, options)),
  ]).pipe(
    map((values) => values.some((isLoading) => isLoading)),
    distinctUntilChanged(),
  );
}
