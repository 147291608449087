import { useRecordLoader } from "./useRecordLoader";

export function useInboxSectionIds() {
  return useRecordLoader({
    name: "useInboxSectionIds",
    load({ loader, limit, currentUserId }) {
      return loader.observeGetInboxSections({
        currentUserId: currentUserId,
        limit,
      });
    },
    limitStep: 25,
    map: (records) => records.map((r) => r.id),
  });
}
