import { RefObject } from "react";
import { setScrollTop } from "~/utils/dom-helpers";
import { elementPositionInContainer } from "~/utils/dom-helpers";
import { IListRef } from "./List";

export function focusLastListEntry<T>(listRef: RefObject<IListRef<T>>) {
  const lastEntry = listRef.current?.entries.at(-1);

  if (!lastEntry) return;

  lastEntry.node.focus({ preventScroll: true });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const scrollboxEl = lastEntry.scrollboxContext.scrollboxRef.current!;

  const { bottom } = elementPositionInContainer({
    container: scrollboxEl,
    element: lastEntry.node,
    containerPosOffset: {
      top: lastEntry.scrollboxContext.offsetHeaderEl?.current?.offsetHeight,
    },
  });

  if (bottom !== "above") return;

  setScrollTop(scrollboxEl, (oldValue) => oldValue - 100);
}
