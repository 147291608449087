import { ComponentType, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { useReminderNotifications } from "~/hooks/useReminderNotifications";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { NotificationEntry, onNotificationSelectNavigateToThread } from "~/components/content-list/NotificationEntry";
import { PointerWithRecord } from "libs/schema";
import { triageThread } from "~/actions/notification";
import { EndOfListMsg } from "~/components/EndOfListMsg";
import { useListPaging } from "~/hooks/useListPaging";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  MarkAllDoneEntryAction,
  MarkAllNotDoneEntryAction,
  OtherCommandEntryAction,
  SetReminderForAllEntryAction,
} from "~/components/content-list/layout";
import { useRegisterBulkRecordActionCommands } from "~/hooks/useRegisterBulkEntryActions";

export const RemindersView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);

  const [notifications, { isLoading, fetchMore, nextId, error }] = useReminderNotifications();

  const containsDoneNotification = notifications.some((n) => n.is_done);
  const containsNotDoneNotification = notifications.some((n) => !n.is_done);

  const noMoreNotifications = (!nextId && !isLoading) || !!error;

  useListPaging({
    fetchMore,
    isLoading,
    isListEnd: noMoreNotifications,
    pagingScrollboxRef: scrollboxRef,
  });

  const listRef = useRef<IListRef<PointerWithRecord<"notification">>>(null);

  useRegisterBulkRecordActionCommands({
    priority: { delta: 1 },
    listRef,
    isListRefSet: !!notifications.length,
  });

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<PointerWithRecord<"notification">>();

  const headerRef = useRef<HTMLElement>(null);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <RegisterReminderNotificationEntryCommands useFocusedNotification={useFocusedEntry} />

      <Helmet>
        <title>Reminders | Comms</title>
      </Helmet>

      <MainLayout.Header
        ref={headerRef}
        noStickyWhenEntriesSelected={{
          listRef,
          isListRefSet: notifications.length > 0,
        }}
      >
        <h1 className="text-3xl">Reminders</h1>
      </MainLayout.Header>

      <MainLayout.ActionsBar
        listRef={listRef}
        isListRefSet={notifications.length > 0}
        multiSelectActions={
          <>
            {containsNotDoneNotification && <MarkAllDoneEntryAction />}
            {containsDoneNotification && <MarkAllNotDoneEntryAction />}
            <SetReminderForAllEntryAction />
            <OtherCommandEntryAction />
          </>
        }
      />

      <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
        {notifications.length === 0 ? (
          NoRemindersMessage
        ) : (
          <ContentList
            ref={listRef}
            onEntryFocused={setFocusedEntry}
            onEntryAction={onNotificationSelectNavigateToThread}
            className="mb-20"
            autoFocus
          >
            {notifications.map((notification, index) => (
              <NotificationEntry key={notification.id} notificationId={notification.id} relativeOrder={index} />
            ))}

            <EndOfListMsg isEnd={noMoreNotifications} />
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};

const NoRemindersMessage = (
  <EmptyListMessage text="None.">
    <div className="mt-8 mx-10 prose text-slate-11 max-w-[600px] min-w-0 rounded bg-slate-2 p-4">
      <p>
        <strong>Hint:</strong> focus an inbox notification and press <kbd>h</kbd> to temporarily remove it from your
        inbox and set a reminder for it. A.K.A. "snoozing" notifications.
      </p>
    </div>
  </EmptyListMessage>
);

const RegisterReminderNotificationEntryCommands: ComponentType<{
  useFocusedNotification: () => PointerWithRecord<"notification"> | null;
}> = (props) => {
  const focusedNotification = props.useFocusedNotification();
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      if (focusedNotification) {
        commands.push(
          markDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: true,
              });
            },
          }),
          markNotDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: false,
              });
            },
          }),
          setThreadReminderCommand({
            label: "Update reminder",
            callback: () => {
              RemindMeDialogState.open({
                threadId: focusedNotification.record.thread_id,
                fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
              });
            },
          }),
          removeThreadReminderCommand({
            hotkeys: [],
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                triagedUntil: null,
              });
            },
          }),
          {
            label: "Remove reminder & move to Inbox",
            altLabels: ["Unsnooze & move to Inbox", "Remove snooze & move to Inbox"],
            hotkeys: ["Shift+H"],
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: false,
                triagedUntil: null,
              });
            },
          },
        );

        if (focusedNotification.record.is_starred) {
          commands.push(
            unstarThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: false,
                });
              },
            }),
          );
        } else {
          commands.push(
            starThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: true,
                });
              },
            }),
          );
        }
      }

      return commands;
    },
    deps: [focusedNotification, environment],
  });

  return null;
};
