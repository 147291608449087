import { RecordValue } from "libs/schema";
import { PropsWithChildren, createContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { LoadingText } from "~/components/LoadingText";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { createUseContextHook } from "~/utils/createUseContextHook";

/**
 * A HOC which ensures that the wrapped component is only rendered
 * if the user is logged in (else redirects to the login page).
 * Also provides an `AuthGuardContext` for children.
 */
export function withAuthGuard<P extends PropsWithChildren<unknown>>(Component: React.ComponentType<P>) {
  return function GuardedRoute(props: P) {
    const [currentUser, { isLoading }] = useCurrentUser();
    const location = useLocation();

    if (currentUser) {
      return (
        <AuthGuardContext.Provider
          value={{
            currentUserId: currentUser.id,
            ownerOrganizationId: currentUser.owner_organization_id,
            currentUser,
          }}
        >
          <Component {...props} />
        </AuthGuardContext.Provider>
      );
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return <Navigate to={"/login"} state={{ from: location }} replace />;
  };
}

export type TAuthGuardContext = {
  currentUserId: string;
  ownerOrganizationId: string;
  currentUser: RecordValue<"user_profile">;
};

export const AuthGuardContext = createContext<TAuthGuardContext | undefined>(undefined);

export const useAuthGuardContext = createUseContextHook(AuthGuardContext, "AuthGuardContext");
