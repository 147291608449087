import { ComponentType } from "react";
import { useDarkModePreference } from "./DarkMode";
import { cx } from "@emotion/css";

export const LoadingText: ComponentType<{ fullscreen?: boolean }> = (props) => {
  const [isDarkActive] = useDarkModePreference();
  const fullscreen = props.fullscreen ?? true;

  return (
    <div className={cx("LoadingText flex justify-center items-center", fullscreen && "w-screen h-screen")}>
      <div>
        <img src={isDarkActive ? "/comms-icon-tan.gif" : "/comms-icon.gif"} alt="Loading" className="w-[100px]" />
      </div>
    </div>
  );
};
