import { ComponentType } from "react";
import { Observable } from "rxjs";
import { QuoteParentThreadTimeline } from "~/components/QuoteParentThreadTimeline";
import { ThreadTimelineEntry } from "~/components/thread-timeline-entry/ThreadTimelineEntry";
import { ParentThreadTimelineContextProvider } from "~/components/thread-timeline-entry/context";

/* -------------------------------------------------------------------------------------------------
 * QuoteBranchedThreadTimeline
 * -----------------------------------------------------------------------------------------------*/

export const QuoteBranchedThreadTimeline: ComponentType<{
  parentThreadId: string;
  parentMessageId: string;
  branchCreatedAt: string;
  loadMoreMessagesButtonFocusEvents: Observable<void>;
}> = (props) => {
  return (
    <ParentThreadTimelineContextProvider>
      <QuoteParentThreadTimeline
        isFirstBranch
        parentThreadId={props.parentThreadId}
        parentMessageId={props.parentMessageId}
        branchCreatedAt={props.branchCreatedAt}
        loadMoreMessagesButtonFocusEvents={props.loadMoreMessagesButtonFocusEvents}
      >
        {({ id, relativeIndex }) => {
          return <ThreadTimelineEntry key={id} timelineId={id} relativeOrder={relativeIndex} />;
        }}
      </QuoteParentThreadTimeline>
    </ParentThreadTimelineContextProvider>
  );
};
