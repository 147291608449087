import { UnreachableCaseError } from "libs/errors";
import {
  RecordMap,
  RecordPointer,
  assignToRecordMap,
  createRecordMapFromPointersWithRecords,
  getMapRecord,
  getMapRecords,
  getPointer,
} from "libs/schema";
import { Observable, combineLatest, map, of, switchMap, throttleTime } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ObserveOptions } from "~/environment/RecordLoader";

export interface ObserveAllThreadViewDataProps {
  threadId: string;
  getParentThreads?: "none" | "first" | "all";
}

export type ObserveAllThreadViewDataResult = {
  recordMap: RecordMap;
};

/**
 * Observes all data needed to render the thread view. Note that, at time of creation,
 * the use case for this observable is for preloading data for the thread view and we
 * use a heavy throttle internally as a performance optimization.
 */
export function observeAllThreadViewData(
  environment: Pick<ClientEnvironment, "auth" | "recordLoader">,
  props: ObserveAllThreadViewDataProps,
  fetchOptions: ObserveOptions = {},
): Observable<ObserveAllThreadViewDataResult> {
  const currentUserId = environment.auth.getAndAssertCurrentUserId();
  const { recordLoader } = environment;
  const { threadId, getParentThreads = "none" } = props;

  const pointers = [
    getPointer("thread", threadId),
    getPointer("notification", {
      thread_id: threadId,
      user_id: currentUserId,
    }),
    getPointer("thread_read_receipt", {
      thread_id: threadId,
      user_id: currentUserId,
    }),
    getPointer("thread_seen_receipt", {
      thread_id: threadId,
      user_id: currentUserId,
    }),
  ];

  return combineLatest([
    recordLoader.observeGetRecords(pointers, fetchOptions).pipe(
      map(([pointersWithRecords]) => {
        const recordMap = createRecordMapFromPointersWithRecords(pointersWithRecords);

        return [undefined, { recordMap }] as const;
      }),
    ),
    recordLoader.observeGetThreadTags({ thread_id: threadId }, fetchOptions),
    recordLoader.observeGetThreadGroupPermissions({ thread_id: threadId }, fetchOptions),
    recordLoader.observeGetThreadUserParticipants({ thread_id: threadId }, fetchOptions),
    recordLoader.observeGetThreadUserPermissions({ thread_id: threadId }, fetchOptions),
    recordLoader.observeGetThreadTimelineEntries({ thread_id: threadId }, fetchOptions),
    recordLoader.observeGetDrafts({ threadId, currentUserId: currentUserId }, fetchOptions),
    recordLoader.observeGetThreadSubscriptions({ thread_id: threadId }, fetchOptions),
  ]).pipe(
    throttleTime(5000, undefined, { leading: true, trailing: true }),
    map((queries) => {
      const recordMaps = queries.map(([, { recordMap }]) => recordMap) as [RecordMap, ...RecordMap[]];

      const recordMap: RecordMap = {};

      assignToRecordMap(recordMap, ...recordMaps);

      return recordMap;
    }),
    switchMap((recordMap) => {
      const threadTags = getMapRecords(recordMap, "thread_tag");
      const groupPermissions = getMapRecords(recordMap, "thread_group_permission");

      const userPermissions = getMapRecords(recordMap, "thread_user_permission");

      const userParticipants = getMapRecords(recordMap, "thread_user_participant");

      const threadSubscriptions = getMapRecords(recordMap, "thread_subscription");

      const timelineEntries = getMapRecords(recordMap, "thread_timeline");

      const additionalPointers = [
        ...threadTags.map((r) => getPointer("tag", r.tag_id)),
        ...threadTags.map((r) =>
          getPointer("tag_subscription", {
            tag_id: r.tag_id,
            user_id: currentUserId,
          }),
        ),
        ...groupPermissions.map((r) => getPointer("tag", r.group_id)),
        ...groupPermissions.map((r) =>
          getPointer("tag_subscription", {
            tag_id: r.group_id,
            user_id: currentUserId,
          }),
        ),
        ...userPermissions.map((r) => getPointer("user_profile", r.user_id)),
        ...userParticipants.map((r) => getPointer("user_profile", r.user_id)),
        ...threadSubscriptions.map((r) => getPointer("user_profile", r.user_id)),
        ...timelineEntries.flatMap((r): RecordPointer[] => {
          switch (r.type) {
            case "BRANCHED_DRAFT": {
              return [getPointer("draft", r.entry_id)];
            }
            case "BRANCHED_THREAD": {
              return [getPointer("thread", r.entry_id)];
            }
            case "MESSAGE": {
              return [getPointer("message", r.entry_id), getPointer("message_reactions", r.entry_id)];
            }
            default: {
              throw new UnreachableCaseError(r.type);
            }
          }
        }),
      ];

      return combineLatest([of(recordMap), recordLoader.observeGetRecords(additionalPointers, fetchOptions)]);
    }),
    map(([originalRecordMap, [additionalPointerWithRecords]]) => {
      const recordMap = structuredClone(originalRecordMap);
      assignToRecordMap(recordMap, additionalPointerWithRecords);
      return recordMap;
    }),
    throttleTime(5000, undefined, { leading: true, trailing: true }),
    switchMap((originalRecordMap) => {
      const thread = getMapRecord(originalRecordMap, {
        table: "thread",
        id: threadId,
      });

      if (thread?.branched_from_thread_id) {
        switch (getParentThreads) {
          case "none": {
            break;
          }
          case "first":
          case "all": {
            return observeAllThreadViewData(
              environment,
              {
                threadId: thread.branched_from_thread_id,
                getParentThreads: getParentThreads === "first" ? "none" : "all",
              },
              fetchOptions,
            ).pipe(
              map(({ recordMap: parentRecordMap }) => {
                const recordMap = structuredClone(originalRecordMap);
                assignToRecordMap(recordMap, parentRecordMap);
                return { recordMap };
              }),
            );
          }
          default: {
            throw new UnreachableCaseError(getParentThreads);
          }
        }
      }

      return of({ recordMap: originalRecordMap });
    }),
  );
}
