import { ComponentType, useCallback, useRef } from "react";
import { IListRef, useListScrollboxContext } from "~/components/list";
import useConstant from "use-constant";
import { Subject } from "rxjs";
import { elementPositionInContainer, setScrollTop } from "~/utils/dom-helpers";
import { ContentList, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { useThreadTimelineIds } from "~/hooks/useThreadTimelineEntries";
import { ThreadTimelineContextProvider } from "./context";
import { generateRecordId } from "libs/schema";
import { useThread } from "~/hooks/useThread";
import { QuoteBranchedThreadTimeline } from "./QuoteBranchedThreadTimeline";
import { ThreadDrafts } from "./thread-drafts/ThreadDrafts";
import { RegisterThreadTimelineCommands } from "./RegisterThreadTimelineCommands";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { MessageReactionPickerDialog } from "~/dialogs/message-reaction-picker/MessageReactionPicker";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAsync } from "react-use";
import { useSearchParams } from "react-router-dom";
import { IRichTextEditorRef } from "~/components/forms/message-editor";
import { ThreadTimelineEntry } from "./thread-timeline-entry/ThreadTimelineEntry";
import { GetOptions } from "~/environment/RecordLoader";
import { ThreadLoading } from "./ThreadLoading";

/* -------------------------------------------------------------------------------------------------
 * ThreadTimeline
 * -----------------------------------------------------------------------------------------------*/

export const ThreadTimeline: ComponentType<{
  threadId: string;
}> = (props) => {
  const { threadId } = props;
  const listRef = useRef<IListRef<TThreadTimelineEntry>>(null);
  const editorRefStore = useConstant(() => new Map<string, IRichTextEditorRef>());
  const [searchParams] = useSearchParams();

  const [timelineIds, { isLoading: areTimelineIdsLoading }] = useThreadTimelineIds({ threadId });

  const initiallyFocusEntryId = useInitiallyFocusEntryId({
    threadId,
    messageId: searchParams.get("message"),
  });

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<TThreadTimelineEntry>();

  const loadMoreMessagesButtonFocusEvents$ = useConstant(() => new Subject<void>());

  const collapseMessageEvents$ = useConstant(() => new Subject<"expand" | "collapse" | string>());

  const [thread] = useThread(threadId, { includeSoftDeletes: true });

  const { onArrowUpOverflow, onArrowDownOverflow } = useMessageListOverflowFns({
    isThreadDeleted: !!thread?.deleted_at,
    loadMorePostsButtonFocusEvents$: loadMoreMessagesButtonFocusEvents$,
  });

  if (initiallyFocusEntryId.loading) return <ThreadLoading />;

  return (
    <>
      {!thread?.deleted_at && (
        <RegisterThreadTimelineCommands
          listRef={listRef}
          threadId={threadId}
          editorRefStore={editorRefStore}
          useFocusedEntry={useFocusedEntry}
          collapseMessageEvents$={collapseMessageEvents$}
        />
      )}

      <MessageReactionPickerDialog />

      <ContentList<TThreadTimelineEntry>
        ref={listRef}
        initiallyFocusEntryId={initiallyFocusEntryId.value || undefined}
        onArrowUpOverflow={onArrowUpOverflow}
        onArrowDownOverflow={onArrowDownOverflow}
        focusOnMouseOver={false}
        onEntryFocused={setFocusedEntry}
        maintainInitialFocusForTimeMs={3000}
      >
        {timelineIds.length === 0 && areTimelineIdsLoading ?
          <ThreadLoading />
        : <ThreadTimelineContextProvider threadId={threadId}>
            {thread?.is_branch && (
              <QuoteBranchedThreadTimeline
                parentThreadId={thread.branched_from_thread_id!}
                parentMessageId={thread.branched_from_message_id!}
                branchCreatedAt={thread.created_at}
                loadMoreMessagesButtonFocusEvents={loadMoreMessagesButtonFocusEvents$}
              />
            )}

            {thread &&
              timelineIds.map((timelineId, index) => {
                return (
                  <ThreadTimelineEntry
                    key={timelineId}
                    timelineId={timelineId}
                    listRef={listRef}
                    editorRefStore={editorRefStore}
                    threadId={threadId}
                    threadType={thread.type}
                    threadVisibility={thread.visibility}
                    threadSubject={thread.subject}
                    isThreadDeleted={!!thread.deleted_at}
                    relativeOrder={index}
                  />
                );
              })}
          </ThreadTimelineContextProvider>
        }
      </ContentList>

      {thread && (
        <ThreadDrafts
          listRef={listRef}
          editorRefStore={editorRefStore}
          thread={thread}
          focusOnInit={initiallyFocusEntryId.value === "draft"}
        />
      )}

      <div className="h-20 sm-max-w:mb-8" />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useInitiallyFocusEntryId(props: { threadId: string; messageId: string | null }) {
  const { threadId, messageId } = props;
  const { currentUserId } = useAuthGuardContext();
  const { recordLoader } = useClientEnvironment();

  return useAsync(async () => {
    if (messageId) return messageId;

    const fetchStrategy =
      recordLoader.options.defaultFetchStrategy.startsWith("cache") ?
        recordLoader.options.defaultFetchStrategy
      : "cache-first";

    const fetchOptions: GetOptions = { fetchStrategy };

    const [[readReceipt], [timelineEntries], [drafts]] = await Promise.all([
      recordLoader.getRecord(
        {
          table: "thread_read_receipt",
          id: generateRecordId("thread_read_receipt", {
            thread_id: threadId,
            user_id: currentUserId,
          }),
        },
        fetchOptions,
      ),
      recordLoader.getThreadTimelineEntries({ thread_id: threadId }, fetchOptions),
      recordLoader.getDrafts({ threadId, currentUserId }, fetchOptions),
    ]);

    if (drafts.length > 0) return "draft";

    if (!readReceipt) {
      return timelineEntries[0]?.entry_id || null;
    }

    let nextEntry = timelineEntries.find((r) => {
      return r.order > readReceipt.read_to_timeline_order;
    });

    if (!nextEntry) {
      nextEntry = timelineEntries.at(-1);
    }

    return nextEntry?.entry_id || null;
  }, [recordLoader, currentUserId, messageId, threadId]);
}

/* -----------------------------------------------------------------------------------------------*/

function useMessageListOverflowFns(props: {
  isThreadDeleted: boolean;
  loadMorePostsButtonFocusEvents$: Subject<void>;
}) {
  const { loadMorePostsButtonFocusEvents$ } = props;
  const { scrollboxRef, offsetHeaderEl: scrollboxOffsetHeaderEl } = useListScrollboxContext();

  /** Scroll list container up if possible */
  const onArrowUpOverflow = useCallback((e) => {
    loadMorePostsButtonFocusEvents$.next();
    if (!scrollboxRef.current) return;
    e.preventDefault();
    setScrollTop(scrollboxRef.current, (oldValue) => oldValue - 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Focus and scroll to the compose draft editor */
  const onArrowDownOverflow = useCallback((e) => {
    const el = document.querySelector(`.IsFirstDraft .RichTextEditor`);
    const editorEl = el?.firstChild as HTMLDivElement | undefined;

    e.preventDefault();

    if (!editorEl || !scrollboxRef.current) return;

    editorEl.focus({ preventScroll: true });

    const offset = scrollboxOffsetHeaderEl?.current?.offsetHeight || 0;

    const { bottom } = elementPositionInContainer({
      container: scrollboxRef.current,
      element: editorEl,
      containerPosOffset: {
        top: props.isThreadDeleted ? offset + 42 : offset,
      },
    });

    if (bottom !== "below") return;

    setScrollTop(scrollboxRef.current, (oldValue) => oldValue + 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onArrowUpOverflow,
    onArrowDownOverflow,
  };
}

/* -----------------------------------------------------------------------------------------------*/
