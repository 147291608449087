/* -------------------------------------------------------------------------------------------------
 * thread-prev-next-service-utils
 * -------------------------------------------------------------------------------------------------
 *
 * This module contains helpers for working with the thread-prev-next service.
 */

import {
  IThreadListNavigationLocationStateGroupView,
  ThreadListNavigationLocationStateKey,
} from "~/environment/thread-prev-next.service";

export function buildThreadViewPrevNextStateForTagView(type: "group" | "tag", tagId: string | undefined) {
  if (!tagId) {
    return null;
  }

  switch (type) {
    case "group": {
      return {
        [ThreadListNavigationLocationStateKey]: {
          origin: "GroupView",
          groupId: tagId,
        } satisfies IThreadListNavigationLocationStateGroupView,
      };
    }
    case "tag":
    default: {
      return null;
    }
  }
}
