import { BehaviorSubject } from "rxjs";
import { navigateService, router, updateSearchParams, openLinkInNewTabOrWindow } from "~/environment/navigate.service";
import { ComponentType, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useObservableState } from "observable-hooks";

interface IPageDialogDataMap {
  ComposeMessage: null | undefined;
}

interface IPageDialogDataStruct<T extends keyof IPageDialogDataMap> {
  type: T;
  data: IPageDialogDataMap[T] | undefined;
}

export type IPageDialogData = IPageDialogDataStruct<keyof IPageDialogDataMap>;

function openPageDialog<T extends keyof IPageDialogDataMap>(type: T, data?: IPageDialogDataMap[T]) {
  PAGE_DIALOG_STATE$.next({ type, data });
}

function closePageDialog() {
  PAGE_DIALOG_STATE$.next(null);
}

export const PAGE_DIALOG_STATE$ = new BehaviorSubject<IPageDialogData | null>(null);

export function usePageDialogState() {
  return useObservableState(PAGE_DIALOG_STATE$);
}

/**
 * @param draftId id of an existing draft or "new" to create a new draft
 */
export function openComposeNewThreadDialog(
  draftId: string,
  options: {
    andNavigateToPathname?: string;
    inNewWindow?: boolean;
  } = {},
) {
  const isComposeNewThreadDialogAlreadyOpen = PAGE_DIALOG_STATE$.getValue()?.type === "ComposeMessage";

  const existingDraftId = isComposeNewThreadDialogAlreadyOpen && new URL(location.href).searchParams.get("compose");

  if (isComposeNewThreadDialogAlreadyOpen && existingDraftId !== "new" && existingDraftId !== "new-email") {
    // If we're already composing a new darft, the ComposeNewThreadDialog
    // doesn't expect the draftId to change so we close and reopen the
    // dialog.
    closeComposeNewThreadDialog();
  }

  if (options.inNewWindow) {
    const url = new URL(location.href);
    url.searchParams.set("compose", draftId);
    openLinkInNewTabOrWindow(url);
  } else if (options.andNavigateToPathname && location.pathname !== options.andNavigateToPathname) {
    navigateService(
      {
        pathname: options.andNavigateToPathname,
        search: `compose=${draftId}`,
      },
      { replace: true },
    );
  } else {
    updateSearchParams((searchParams) => searchParams.set("compose", draftId), {
      replace: !!existingDraftId,
    });
  }
}

export function closeComposeNewThreadDialog() {
  router.navigate(-1);
}

export const OpenComposeMessageService: ComponentType<{}> = () => {
  const [searchParams] = useSearchParams();
  const draftId = searchParams.get("compose");

  useEffect(() => {
    if (draftId) {
      openPageDialog("ComposeMessage");
    } else {
      closePageDialog();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!draftId]);

  return null;
};
