import { groupBy, uniqWith } from "lodash-comms";
import { RecordPointer, RecordTable, TABLE_NAMES } from "libs/schema";
import { ValidationError } from "libs/errors";
import { isEqual } from "libs/predicates";

export function groupPointersByTable(pointers: RecordPointer<any>[]) {
  const distinctPointers = uniqWith(pointers, isEqual);

  for (const pointer of distinctPointers) {
    if (TABLE_NAMES.includes(pointer.table)) continue;
    throw new ValidationError(`liveRecord: invalid record table "${pointer.table}"`);
  }

  const groupedPointersMap = groupBy(distinctPointers, (p) => p.table);

  return Object.entries(groupedPointersMap) as Array<[RecordTable, RecordPointer<RecordTable>[]]>;
}
