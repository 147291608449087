import { useEffect, useState } from "react";
import { useAsRef } from "./useAsRef";
import { wait } from "libs/promise-utils";
import { MS_IN_MINUTE } from "libs/date-helpers";

export function useAttemptRefetchOnError(props: { refetch: () => void; isLoading: boolean; error?: unknown }) {
  const { refetch, error, isLoading } = props;
  const [attempts, setAttempts] = useState(0);
  const attemptsRef = useAsRef(attempts);
  const isError = !!error;

  useEffect(() => {
    if (isLoading) return;
    if (!isError) return;
    if (attemptsRef.current >= 2) return;
    let unmounted = false;
    setAttempts((prev) => prev + 1);
    // Add a random delay before refetching
    wait(Math.random() * MS_IN_MINUTE * 2).then(() => {
      if (unmounted) return;
      refetch();
    });

    return () => {
      unmounted = true;
    };
  }, [isError, isLoading, refetch]);

  return { refetchFailed: isError && !isLoading && attempts >= 2 };
}
