import { getPointer, ThreadSubscriptionPreference } from "libs/schema";
import { op } from "libs/transaction";
import { toast } from "~/environment/toast-service";
import { withTransaction, write } from "./write";

export const updateThreadSubscription = withTransaction(
  "updateThreadSubscription",
  async (
    environment,
    transaction,
    props: {
      threadId: string;
      preference: ThreadSubscriptionPreference;
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

    const subscriptionPointer = getPointer("thread_subscription", {
      thread_id: props.threadId,
      user_id: currentUserId,
    });

    const participationPointer = getPointer("thread_user_participant", {
      thread_id: props.threadId,
      user_id: currentUserId,
    });

    transaction.operations.push(
      op.set("thread_subscription", {
        id: subscriptionPointer.id,
        owner_organization_id: ownerOrganizationId,
        preference: props.preference,
        thread_id: props.threadId,
        user_id: currentUserId,
      }),
      // It's important that this upsert comes after setting the `thread_subscription`
      // so that the subscription preference is set already. This way the "upsert_on_update"
      // operation's `where` clause will be evaluated against the new subscription
      // preference.
      op.upsert(subscriptionPointer, {
        onUpdate: [
          {
            type: "upsert_on_update",
            where: { preference: { eq: "involved" } },
            operations: [op.delete(participationPointer)],
          },
        ],
      }),
    );

    try {
      await write(environment, {
        transaction,
        onOptimisticWrite: () => {
          toast("vanilla", {
            subject: props.preference === "all" ? `Subscribed.` : `Unsubscribed.`,
            description:
              props.preference === "all" ?
                `You will receive notifications for every reply
                 to this thread.`
              : `You won't receive notifications unless a reply is addressed
                 to you or @mentions you.`,
          });
        },
        onOptimisticUndo: () => {
          toast("vanilla", { subject: "Undoing thread subscription update." });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: props.preference === "all" ? `Error subscribing to thread.` : `Error unsubscribing to thread.`,
      });

      throw error;
    }
  },
);
