import { ComponentType, useRef } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { showNotImplementedToastMsg } from "~/environment/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { EndOfListMsg } from "~/components/EndOfListMsg";
import { useListPaging } from "~/hooks/useListPaging";
import { useTrashViewThreadIds } from "~/hooks/useTrashViewThreadIds";
import { ThreadEntry, onThreadSelectNavigateToThread } from "~/components/content-list/ThreadEntry";

export const TrashView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const [threadIds, { fetchMore, nextId, isLoading }] = useTrashViewThreadIds();

  const noMoreThreads = !nextId && !isLoading;

  useListPaging({
    fetchMore,
    isLoading,
    isListEnd: noMoreThreads,
    pagingScrollboxRef: scrollboxRef,
  });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        ESCAPE_TO_INBOX_COMMAND,
        markDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't mark threads Done from the Trash.
            `);
          },
        }),
        markNotDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't mark threads not Done from the Trash.
            `);
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't edit reminders from the Trash.
            `);
          },
        }),
        removeThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't edit reminders from the Trash.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't star threads from the Trash.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              You can't unstar threads from the Trash.
            `);
          },
        }),
      ];

      return commands;
    },
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <Helmet>
        <title>Trash | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl">Trash</h1>
      </MainLayout.Header>

      <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
        {threadIds.length === 0 ?
          <EmptyListMessage text="Nothing yet." />
        : <ContentList onEntryAction={onThreadSelectNavigateToThread} className="mb-20" autoFocus>
            {threadIds.map((threadId, index) => (
              <ThreadEntry key={threadId} threadId={threadId} relativeOrder={index} />
            ))}

            <EndOfListMsg isEnd={noMoreThreads} />
          </ContentList>
        }
      </ListScrollbox>
    </>
  );
};
