import { isEqual } from "libs/predicates";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { FetchStrategy } from "~/environment/RecordLoader";

/**
 * Gets the user_push_notification_subscription record associated with this
 * client, if this client has subscribed to push notifications.
 */
export async function getUserPushNotificationSubscriptionForThisClient(
  environment: ClientEnvironment,
  props: {
    userId: string;
  },
  options?: { fetchStrategy?: FetchStrategy },
) {
  const { userId } = props;

  const pushSubscription = await environment.serviceWorker.getPushNotificationSubscription();

  if (!pushSubscription) return null;

  const [records] = await environment.recordLoader.getUserPushNotificationSubscriptions({ user_id: userId }, options);

  const json = pushSubscription.toJSON();

  const record = records.find((r) => isEqual(r.subscription, json));

  return record || null;
}
