import { distinctUntilChanged, map, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { isEqual } from "libs/predicates";
import { ThreadVisibility } from "libs/schema";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export function useThreadVisibility(threadId: string | null | undefined) {
  const { recordLoader } = useClientEnvironment();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [recordLoader, threadId],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([recordLoader, threadId]) =>
          threadId
            ? recordLoader.observeGetRecord({ table: "thread", id: threadId })
            : recordLoader.createObserveGetResult<"thread">(),
        ),
        map(([record, { isLoading }]) => {
          return {
            visibility: record && record.visibility,
            isLoading,
          };
        }),
        distinctUntilChanged(isEqual),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze({
  visibility: null as ThreadVisibility | null,
  isLoading: true as boolean,
});
