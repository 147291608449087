import { ThreadVisibility } from "libs/schema";
import { createContext } from "react";
import { createUseContextHook } from "~/utils/createUseContextHook";

export interface IEditorMentionContext {
  restrictToVisibility: ThreadVisibility | null;
}

export const EditorMentionContext = createContext<IEditorMentionContext | null>(null);

export const useEditorMentionContext = createUseContextHook(EditorMentionContext, "EditorMentionContext");
