import { RecordValue } from "libs/schema";
import { ComponentType, createContext, useContext, useMemo } from "react";
import { Observable, Subject } from "rxjs";
import { createUseContextHook } from "~/utils/createUseContextHook";

/* -------------------------------------------------------------------------------------------------
 * ThreadTimelineContext
 * -----------------------------------------------------------------------------------------------*/

export type TThreadTimelineContext = {
  collapseMessageEvents: Observable<"expand" | "collapse" | string>;
  /** `true` if this thread timeline is being quoted inside another thread */
  isQuoted?: boolean;
  initiallyFocusEntryId?: string;
  onMessageInView?: (message: RecordValue<"message">) => void;
};

export const ThreadTimelineContext = createContext<TThreadTimelineContext | null>(null);

export const useThreadTimelineContext = createUseContextHook(ThreadTimelineContext, "ThreadTimelineContext");

/* -------------------------------------------------------------------------------------------------
 * ParentThreadTimelineContextProvider
 * -----------------------------------------------------------------------------------------------*/

export const ParentThreadTimelineContextProvider: ComponentType<{}> = (props) => {
  const threadContext = useContext(ThreadTimelineContext);

  const parentThreadContext = useMemo((): TThreadTimelineContext => {
    const collapseMessageEvents = threadContext?.collapseMessageEvents ?? new Subject<"expand" | "collapse" | string>();

    return {
      isQuoted: true,
      collapseMessageEvents,
    };
  }, [threadContext]);

  return <ThreadTimelineContext.Provider value={parentThreadContext}>{props.children}</ThreadTimelineContext.Provider>;
};
