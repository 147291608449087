import { UnreachableCaseError, throwUnreachableCaseError } from "libs/errors";
import { RecordPointer, RecordValue, getPointer } from "libs/schema";
import { useObservable, useObservableState } from "observable-hooks";
import { useLocation } from "react-router-dom";
import { combineLatest, delay, distinctUntilChanged, map, merge, Observable, of, switchMap } from "rxjs";
import { getLocationState, ILocation } from "./navigate.service";
import { ClientEnvironment } from "./ClientEnvironment";
import { useClientEnvironment } from "./ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useMemo } from "react";
import { GetOptions, ObserveOptions } from "./RecordLoader";
import { tapObservable } from "libs/rxjs-operators";
import { observeAllThreadViewData } from "~/observables/observeAllThreadViewData";
import { isEqual } from "libs/predicates";
import { inboxState } from "~/pages/inbox/state";
import { observeZustandState } from "~/utils/rxjs-utils";

/* -------------------------------------------------------------------------------------------------
 * thread-prev-next.service
 * -------------------------------------------------------------------------------------------------
 *
 * This service is responsible for managing what the previous/next buttons on the ThreadView do.
 */

/** State saved to `router.location.state`. */

export const ThreadListNavigationLocationStateKey = "ThreadListNavigationLocationState";

interface IBaseThreadListNavigationLocationState {
  origin: string;
}

export interface IThreadListNavigationLocationStateInboxView extends IBaseThreadListNavigationLocationState {
  origin: "InboxView";
  inboxSectionId: string;
}

export interface IThreadListNavigationLocationStateGroupView extends IBaseThreadListNavigationLocationState {
  origin: "GroupView";
  groupId: string;
}

export type IThreadListNavigationLocationState =
  | IThreadListNavigationLocationStateInboxView
  | IThreadListNavigationLocationStateGroupView;

interface IThreadListNavigationArgsBase<Entry, State> {
  readonly previousEntry: Entry | null;
  readonly nextEntry: Entry | null;
  readonly state: {
    readonly [ThreadListNavigationLocationStateKey]: State;
  };
}

export type IThreadListNavigationArgs = IThreadListNavigationArgsInboxView | IThreadListNavigationArgsGroupView;

export type IThreadListNavigationArgsInboxView = IThreadListNavigationArgsBase<
  RecordValue<"inbox_entry">,
  IThreadListNavigationLocationStateInboxView
>;

export type IThreadListNavigationArgsGroupView = IThreadListNavigationArgsBase<
  RecordValue<"thread">,
  IThreadListNavigationLocationStateGroupView
>;

export function isThreadListNavigationArgsForInboxView(
  args: IThreadListNavigationArgs | null | undefined,
): args is IThreadListNavigationArgsInboxView {
  return args?.state[ThreadListNavigationLocationStateKey].origin === "InboxView";
}

export function isThreadListNavigationArgsForGroupView(
  args: IThreadListNavigationArgs | null | undefined,
): args is IThreadListNavigationArgsGroupView {
  return args?.state[ThreadListNavigationLocationStateKey].origin === "GroupView";
}

export function getThreadListNavigationLocationState<
  T extends IBaseThreadListNavigationLocationState = IThreadListNavigationLocationState,
>(location?: ILocation) {
  return getLocationState<T>(ThreadListNavigationLocationStateKey, location);
}

export function useThreadViewPrevNextArgs(table: "draft" | "thread", id: string) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const location = useLocation();

  const pointer = useMemo((): RecordPointer<"draft" | "thread"> => {
    return { table, id };
  }, [table, id]);

  const observable = useObservable(
    (inputs$) =>
      inputs$.pipe(
        switchMap(([environment, currentUserId, location, pointer]) =>
          observeThreadViewPrevNextArgs({
            environment,
            currentUserId,
            pointer,
            location,
          }),
        ),
      ),
    [environment, currentUserId, location, pointer],
  );

  return useObservableState(observable);
}

export async function getThreadViewPrevNextArgs(
  props: {
    environment: Pick<ClientEnvironment, "recordLoader" | "db">;
    currentUserId: string;
    pointer: RecordPointer<"draft"> | RecordPointer<"notification">;
    location: ILocation;
  },
  options?: GetOptions,
): Promise<IThreadListNavigationArgsInboxView | null>;
export async function getThreadViewPrevNextArgs(
  props: {
    environment: Pick<ClientEnvironment, "recordLoader" | "db">;
    currentUserId: string;
    pointer: RecordPointer<"thread">;
    location: ILocation;
  },
  options?: GetOptions,
): Promise<IThreadListNavigationArgsGroupView | null>;
export async function getThreadViewPrevNextArgs(
  props: {
    environment: Pick<ClientEnvironment, "recordLoader" | "db">;
    currentUserId: string;
    pointer: RecordPointer;
    location: ILocation;
  },
  options: GetOptions = {},
): Promise<IThreadListNavigationArgs | null> {
  const state = getThreadListNavigationLocationState(props.location);

  if (!state?.origin) return null;

  switch (state.origin) {
    case "InboxView": {
      // Use the filteredInboxEntries from the global state to determine the previous/next entries.
      const filteredInboxEntries = inboxState.getState().filteredInboxEntries;
      const index = filteredInboxEntries.findIndex((entry) => {
        switch (props.pointer.table) {
          case "draft":
            return entry.draft_id === props.pointer.id;
          case "thread":
            return entry.thread_id === props.pointer.id;
          case "notification":
            return entry.notification_id === props.pointer.id;
          default:
            throwUnreachableCaseError(props.pointer as never);
        }
      });
      return {
        previousEntry: index > 0 ? (filteredInboxEntries[index - 1] ?? null) : null,
        nextEntry: index < filteredInboxEntries.length - 1 ? (filteredInboxEntries[index + 1] ?? null) : null,
        state: {
          [ThreadListNavigationLocationStateKey]: state,
        },
      };
    }
    case "GroupView": {
      const [thread] = await props.environment.recordLoader.getRecord("thread", props.pointer.id, options);

      const startAt = thread ? getPointer("thread", thread.id) : undefined;

      const [[previousEntries], [nextEntries]] = await Promise.all([
        props.environment.recordLoader.getGroupViewThreads(
          {
            currentUserId: props.currentUserId,
            groupId: state.groupId,
            limit: 5,
            startAt,
            // GroupView sorts threads DESC so the previous threads are ASC
            sortDirection: "ASC",
          },
          options,
        ),
        props.environment.recordLoader.getGroupViewThreads(
          {
            currentUserId: props.currentUserId,
            groupId: state.groupId,
            limit: 5,
            startAt,
            // GroupView sorts threads DESC
            sortDirection: "DESC",
          },
          options,
        ),
      ]);

      // The first entry is the current entry, so we skip it.
      const previousEntry = previousEntries.at(1) || null;
      const nextEntry = nextEntries.at(1) || null;

      return mapInboxEntriesToPrevNextArgs({
        previousEntry,
        nextEntry,
        historyState: state,
      });
    }
    default: {
      throw new UnreachableCaseError(state);
    }
  }
}

export function observeThreadViewPrevNextArgs(props: {
  environment: Pick<ClientEnvironment, "recordLoader" | "db" | "auth">;
  currentUserId: string;
  pointer: RecordPointer<"draft"> | RecordPointer<"thread">;
  location: ILocation;
}): Observable<IThreadListNavigationArgs | null> {
  const state = getThreadListNavigationLocationState(props.location);

  if (!state?.origin) return of(null);

  switch (state.origin) {
    case "InboxView": {
      return observeThreadViewPrevNextArgsForInboxView(props.environment, {
        currentUserId: props.currentUserId,
        pointer: props.pointer as RecordPointer<"draft"> | RecordPointer<"thread">,
        historyState: state,
      });
    }
    case "GroupView": {
      return observeThreadViewPrevNextArgsForGroupView(props.environment, {
        currentUserId: props.currentUserId,
        pointer: props.pointer as RecordPointer<"thread">,
        historyState: state,
      });
    }
    default: {
      throw new UnreachableCaseError(state);
    }
  }
}

function observeThreadViewPrevNextArgsForInboxView(
  environment: Pick<ClientEnvironment, "recordLoader" | "db" | "auth">,
  props: {
    currentUserId: string;
    pointer: RecordPointer<"draft"> | RecordPointer<"thread"> | RecordPointer<"notification">;
    historyState: IThreadListNavigationLocationStateInboxView;
  },
  options?: ObserveOptions,
): Observable<IThreadListNavigationArgsInboxView> {
  const filteredInboxEntries$ = observeZustandState(inboxState, (state) => state.filteredInboxEntries);

  return filteredInboxEntries$.pipe(
    map((filteredInboxEntries) => {
      const index = filteredInboxEntries.findIndex((entry) => {
        switch (props.pointer.table) {
          case "draft":
            return entry.draft_id === props.pointer.id;
          case "thread":
            return entry.thread_id === props.pointer.id;
          case "notification":
            return entry.notification_id === props.pointer.id;
          default:
            throwUnreachableCaseError(props.pointer);
        }
      });

      return {
        previousEntry: index > 0 ? (filteredInboxEntries[index - 1] ?? null) : null,
        nextEntry: index < filteredInboxEntries.length - 1 ? (filteredInboxEntries[index + 1] ?? null) : null,
        state: {
          [ThreadListNavigationLocationStateKey]: props.historyState,
        },
      };
    }),
    tapObservable((source) => {
      return observeAllDataForPrevNextThread(
        environment,
        source.pipe(
          map(({ previousEntry, nextEntry }) => {
            return {
              prevThreadId: previousEntry?.thread_id,
              nextThreadId: nextEntry?.thread_id,
            };
          }),
        ),
        options,
      );
    }),
  );
}

function observeThreadViewPrevNextArgsForGroupView(
  environment: Pick<ClientEnvironment, "recordLoader" | "db" | "auth">,
  props: {
    currentUserId: string;
    pointer: RecordPointer<"thread">;
    historyState: IThreadListNavigationLocationStateGroupView;
  },
  options?: ObserveOptions,
): Observable<IThreadListNavigationArgsGroupView> {
  return environment.recordLoader.observeGetRecord(props.pointer, options).pipe(
    map(([record]) => (record ? props.pointer : undefined)),
    distinctUntilChanged(isEqual),
    switchMap((startAt) => {
      return combineLatest([
        environment.recordLoader.observeGetGroupViewThreads(
          {
            currentUserId: props.currentUserId,
            groupId: props.historyState.groupId,
            limit: 5,
            startAt,
            // GroupView sorts threads DESC so the previous threads are ASC
            sortDirection: "ASC",
          },
          options,
        ),
        environment.recordLoader.observeGetGroupViewThreads(
          {
            currentUserId: props.currentUserId,
            groupId: props.historyState.groupId,
            limit: 5,
            startAt,
            // GroupView sorts threads DESC
            sortDirection: "DESC",
          },
          options,
        ),
      ]);
    }),
    map(([[previousEntries], [nextEntries]]) => {
      // The first entry is the current entry, so we skip it.
      const previousEntry = previousEntries.at(1) || null;
      const nextEntry = nextEntries.at(1) || null;
      return { previousEntry, nextEntry };
    }),
    distinctUntilChanged(isEqual),
    tapObservable((source) => {
      return observeAllDataForPrevNextThread(
        environment,
        source.pipe(
          map(({ previousEntry, nextEntry }) => {
            return {
              prevThreadId: previousEntry?.id,
              nextThreadId: nextEntry?.id,
            };
          }),
        ),
        options,
      );
    }),
    map(({ previousEntry, nextEntry }) => {
      return mapInboxEntriesToPrevNextArgs({
        previousEntry,
        nextEntry,
        historyState: props.historyState,
      });
    }),
  );
}

function mapInboxEntriesToPrevNextArgs(props: {
  previousEntry: RecordValue<"inbox_entry"> | null;
  nextEntry: RecordValue<"inbox_entry"> | null;
  historyState: IThreadListNavigationLocationStateInboxView;
}): IThreadListNavigationArgsInboxView;
function mapInboxEntriesToPrevNextArgs<Entry>(props: {
  previousEntry: RecordValue<"thread"> | null;
  nextEntry: RecordValue<"thread"> | null;
  historyState: IThreadListNavigationLocationStateGroupView;
}): IThreadListNavigationArgsGroupView;
function mapInboxEntriesToPrevNextArgs(props: {
  previousEntry: unknown | null;
  nextEntry: unknown | null;
  historyState: IThreadListNavigationLocationState;
}): IThreadListNavigationArgsBase<unknown, IThreadListNavigationLocationState> {
  const { previousEntry, nextEntry, historyState } = props;

  return {
    previousEntry: previousEntry || null,
    nextEntry: nextEntry || null,
    state: {
      [ThreadListNavigationLocationStateKey]: historyState,
    },
  };
}

export function convertThreadViewPrevNextArgsToURL(args: IThreadListNavigationArgs, direction: "previous" | "next") {
  if (isThreadListNavigationArgsForInboxView(args)) {
    const entry =
      direction === "next" ? args.nextEntry
      : direction === "previous" ? args.previousEntry
      : throwUnreachableCaseError(direction);

    if (!entry) return null;

    switch (entry.type) {
      case "draft": {
        if (entry.draft_is_reply) {
          return `/threads/${entry.thread_id}`;
        }

        return `${location.pathname}?compose=${entry.draft_id}`;
      }
      case "notification": {
        return `/threads/${entry.thread_id}`;
      }
      default: {
        throw new UnreachableCaseError(entry.type);
      }
    }
  } else if (isThreadListNavigationArgsForGroupView(args)) {
    const entry =
      direction === "next" ? args.nextEntry
      : direction === "previous" ? args.previousEntry
      : throwUnreachableCaseError(direction);

    if (!entry) return null;

    return `/threads/${entry.id}`;
  } else {
    throw new UnreachableCaseError(args);
  }
}

function observeAllDataForPrevNextThread(
  environment: Pick<ClientEnvironment, "auth" | "recordLoader">,
  source: Observable<{ prevThreadId?: string; nextThreadId?: string }>,
  options?: ObserveOptions,
) {
  return source.pipe(
    distinctUntilChanged(isEqual),
    delay(1000),
    switchMap(({ prevThreadId, nextThreadId }) => {
      const prevThread$ =
        !prevThreadId ?
          of(null)
        : observeAllThreadViewData(
            environment,
            {
              threadId: prevThreadId,
              getParentThreads: "first",
            },
            options,
          );

      const nextThread$ =
        !nextThreadId ?
          of(null)
        : observeAllThreadViewData(
            environment,
            {
              threadId: nextThreadId,
              getParentThreads: "first",
            },
            options,
          );

      return merge(prevThread$, nextThread$);
    }),
  );
}
