import { BsLockFill } from "react-icons/bs";
import { combineLatest, map } from "rxjs";
import { toggleFocusMode } from "~/actions/toggleFocusMode";
import { redo, undo } from "~/actions/write";
import { EditGroupDialogState } from "~/dialogs/group-edit/EditGroupDialog";
import { KBarState } from "~/dialogs/kbar";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { getMostRecentInboxPagePathname, navigateService } from "~/environment/navigate.service";
import { useIsOnline } from "~/hooks/useIsOnline";
import { showNotImplementedToastMsg, toast } from "~/environment/toast-service";
import { observeNormalizedUserSettings } from "~/observables/observeNormalizedUserSettings";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { composeEmailCommand, composeMessageCommand } from "~/utils/common-commands";
import { useSidebarLayoutContext } from "./context";
import { isTagPrivate } from "libs/schema/predicates";
import { observeUsersGroupsWithFolderAncestorRecords } from "~/observables/observeUsersGroupsWithFolderAncestorRecords";
import {
  subscribeToPushNotifications,
  unsubscribeFromPushNotifications,
} from "~/actions/userPushNotificationSubscription";
import { checkNotificationSupport, isPWASupported } from "~/utils/dom-helpers";
import { observeUserPushNotificationSubscriptionForThisClient } from "~/observables/observeUserPushNotificationSubscriptionForThisClient";
import { renderGroupName } from "~/utils/groups-utils";
import { showPWAInstallDialog } from "~/dialogs/pwa-install/PWAInstallDialog";
import { AlertDialogState } from "~/dialogs/alert/AlertDialog";

export function useRegisterGeneralNavigationCommands() {
  const environment = useClientEnvironment();
  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [
        {
          label: "Go to Inbox",
          hotkeys: ["g i"],
          callback: () => {
            navigateService(getMostRecentInboxPagePathname());
          },
        },
        {
          label: "Go to Starred",
          hotkeys: ["g r"],
          callback: () => {
            navigateService("/starred");
          },
        },
        {
          label: "Go to Drafts",
          hotkeys: ["g d"],
          callback: () => {
            navigateService("/drafts");
          },
        },
        {
          label: "Go to Sent",
          hotkeys: ["g t"],
          callback: () => {
            navigateService("/sent");
          },
        },
        {
          label: "Go to Scheduled",
          callback: () => {
            navigateService("/scheduled");
          },
        },
        {
          label: "Go to Done",
          hotkeys: ["g e"],
          callback: () => {
            navigateService("/done");
          },
        },
        {
          label: "Go to Reminders",
          hotkeys: ["g h"],
          callback: () => {
            navigateService("/reminders");
          },
        },
        {
          label: "Go to Search",
          hotkeys: ["/", "Shift+/"],
          callback: () => {
            navigateService("/search");
          },
        },
        {
          label: "Go to Shared Messages",
          hotkeys: ["g s"],
          callback: () => {
            const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

            navigateService(`/groups/${ownerOrganizationId}`);
          },
        },
        {
          // TODO:
          // Update this command when we support users being part of
          // multiple organizations and when we support users not
          // being a part of an organization.
          label: "Go to Explore Groups",
          altLabels: ["View all groups"],
          callback: () => {
            const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

            navigateService(`/organizations/${ownerOrganizationId}/explore-groups`);
          },
        },
        {
          label: "Go to Settings",
          callback: () => {
            navigateService("/settings");
          },
        },
        {
          label: "Go to Trash",
          callback: () => {
            navigateService("/trash");
          },
        },
        {
          label: "Go to group...",
          keywords: ["group"],
          hotkeys: ["g g", "g c"],
          closeKBarOnSelect: false,
          callback: () => {
            KBarState.open({
              path: ["Groups"],
              mode: "search",
            });
          },
        },
        {
          label: "Go to archived groups",
          keywords: ["archived", "archive"],
          hotkeys: ["g x"],
          callback: () => {
            const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

            navigateService(`/organizations/${ownerOrganizationId}/archive/groups`);
          },
        },
        {
          label: "Share feedback",
          callback: () => {
            window.open("https://comms.canny.io/comms-feature-requests", "_blank");
          },
        },
      ];

      return commands;
    },
    deps: [environment],
  });
}

export function useRegisterSidebarLayoutCommands() {
  const context = useSidebarLayoutContext();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        {
          label: "Open sidebar",
          altLabels: ["focus sidebar"],
          hotkeys: ["ArrowLeft"],
          callback: () => {
            context.emitFocusEvent("Sidebar");
          },
        },
        {
          label: "Update your profile",
          callback: () => showNotImplementedToastMsg(),
        },
        {
          label: "Install Comms",
          callback: () => {
            if (isPWASupported()) {
              showPWAInstallDialog(true);
            } else {
              AlertDialogState.open({
                content: `Unfortunately, your browser doesn't appear to support installation of progressive web apps.`,
              });
            }
          },
        },
      ];

      return commands;
    },
    deps: [context],
  });
}

export function useRegisterComposeNewMessageCommands() {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  useRegisterCommands({
    commands() {
      return observeNormalizedUserSettings(environment, {
        userId: currentUserId,
      }).pipe(
        map(({ settings }) => {
          const commands: ICommandArgs[] = [
            composeMessageCommand({
              callback: () => {
                openComposeNewThreadDialog("new");
              },
            }),
          ];

          const canUserSendEmail =
            import.meta.env.VITE_FIREBASE_EMULATORS === "true" || settings?.linked_gmail_email_account === true;

          if (canUserSendEmail) {
            commands.push(
              composeEmailCommand({
                callback: () => {
                  openComposeNewThreadDialog("new-email");
                },
              }),
            );
          }

          return commands;
        }),
      );
    },
    deps: [environment, currentUserId],
  });
}

export function useRegisterToggleOfflineModeCommands() {
  const environment = useClientEnvironment();
  const isAppOnline = useIsOnline();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [];

      if (isAppOnline) {
        commands.push({
          label: "Enable offline mode",
          altLabels: ["Go offline", "Disable online mode"],
          keywords: ["Disable offline mode"],
          callback: () => {
            toast("vanilla", {
              subject: "Offline mode enabled",
            });

            environment.network.setMode("FORCE_OFFLINE");
          },
        });
      } else {
        commands.push({
          label: "Disable offline mode",
          altLabels: ["Go online", "Enable online mode"],
          keywords: ["Enable offline mode"],
          callback: () => {
            const mode = environment.network.mode();

            if (mode === "NORMAL") {
              toast("vanilla", {
                subject: "Device offline",
                description: `
                  Your device appears to not be connected to the internet.
                  We've disabled offline mode, but you still don't have
                  internet.
                `,
                durationMs: 15_000,
              });
            } else {
              toast("vanilla", {
                subject: "Offline mode disabled",
              });

              environment.network.setMode("NORMAL");
            }
          },
        });
      }

      return commands;
    },
    deps: [isAppOnline, environment],
  });
}

export function useRegisterGroupCommands() {
  const { currentUser } = useAuthGuardContext();
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      return observeUsersGroupsWithFolderAncestorRecords(environment, {
        userId: currentUser.id,
      }).pipe(
        map(([groups]) => {
          const commands: ICommandArgs[] = [];

          if (groups.length === 0) return commands;

          commands.push(
            {
              label: "Edit group...",
              altLabels: ["Update group...", "Rename group..."],
              closeKBarOnSelect: false,
              callback: () => {
                KBarState.open({
                  path: ["Edit group"],
                  mode: "search",
                });
              },
            },
            {
              label: "View group subscribers...",
              closeKBarOnSelect: false,
              callback: () => {
                KBarState.open({
                  path: ["Group subscribers"],
                  mode: "search",
                });
              },
            },
            // {
            //   label: "Subscribe users to channel",
            //   altLabels: [
            //     "Invite users to channel",
            //     `Add users to channel`,
            //     `Subscribe users to channel`,
            //     `Send channel invites`,
            //     `Send channel invitations`,
            //     `Invite to channel`,
            //   ],
            //   callback: () => {
            //     ChannelInviteDialogState.toggle(true);
            //   },
            // },
          );

          groups.forEach(({ group, folderPaths }) => {
            if (!group) return;

            const isPrivate = isTagPrivate(group);

            const ancestorNames = folderPaths.map((path) => path.map((doc) => doc.name).join(" > "));

            commands.push(
              // Add the ~ "Go to group" command
              {
                id: `Go to ${group.id}`,
                label: (
                  <>
                    <span className="text-slateA-10">Go to </span>
                    <span>{renderGroupName(group)}</span>
                    {isPrivate && (
                      <span className="inline-flex ml-1 hover:cursor-help mt-1">
                        <small>
                          <BsLockFill />
                        </small>
                      </span>
                    )}
                    <span className="text-slateA-8 ml-4">{ancestorNames.join(", ")}</span>
                  </>
                ),
                keywords: [
                  `Go to ${renderGroupName(group)}`,
                  ...ancestorNames.map((name) => `${renderGroupName(group)} ${name}`),
                ],
                path: [`Groups`, ...(group.archived_at ? ["Archived"] : [])],
                callback: () => {
                  navigateService(`/groups/${group.id}`);
                },
              },
            );

            if (!group.data?.is_organization_group) {
              commands.push(
                // Add the ~ "View group subscribers" command
                {
                  id: `View ${renderGroupName(group)} subscribers`,
                  label: (
                    <>
                      <span>View {renderGroupName(group)}</span>
                      {isPrivate && (
                        <span className="inline-flex ml-1 hover:cursor-help mt-1">
                          <small>
                            <BsLockFill />
                          </small>
                        </span>
                      )}
                      <span className="ml-1">subscribers</span>
                      <span className="text-slateA-8 ml-4">{ancestorNames.join(", ")}</span>
                    </>
                  ),
                  keywords: [`View ${renderGroupName(group)} subscribers`],
                  path: ["Group subscribers", ...(group.archived_at ? ["Archived"] : [])],
                  callback: () => {
                    navigateService(`/groups/${group.id}/subscribers`);
                  },
                },
                {
                  id: `Edit "${group.id}" group`,
                  label: `Edit "${renderGroupName(group)}" group`,
                  altLabels: [`Update "${renderGroupName(group)}" group`, `Rename "${renderGroupName(group)}" group`],
                  path: ["Edit group", ...(group.archived_at ? ["Archived"] : [])],
                  callback: () => {
                    if (!environment.network.isOnline()) {
                      toast("vanilla", {
                        subject: "Not supported in offline mode",
                        description: "Can't edit groups when offline.",
                      });

                      return;
                    }

                    EditGroupDialogState.open({
                      prefill: {
                        id: group.id,
                        icon: group.icon,
                        name: group.name,
                        description: group.description,
                        isPrivate,
                      },
                    });
                  },
                },
              );
            }
          });

          return commands;
        }),
      );
    },
    deps: [currentUser.id, environment],
  });
}

export function useRegisterOrganizationCommands() {
  const { currentUser } = useAuthGuardContext();
  const { recordLoader } = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      return recordLoader
        .observeGetUserOrganizationProfiles({
          userId: currentUser.id,
        })
        .pipe(
          map(([organizationProfiles]) => {
            return [
              {
                label: "View organization members...",
                closeKBarOnSelect: false,
                callback: () => {
                  KBarState.open({
                    path: ["Organization members"],
                    mode: "search",
                  });
                },
              },
              ...organizationProfiles.flatMap((organization) => {
                return [
                  {
                    id: `View "${organization.id}" members`,
                    label: `View "${organization.name}" members`,
                    path: ["Organization members"],
                    callback: () => {
                      navigateService(`/organizations/${organization.id}/members`);
                    },
                  },
                ];
              }),
            ];
          }),
        );
    },
    deps: [currentUser.id, recordLoader],
  });
}

export function useRegisterSettingsCommands() {
  const { currentUserId } = useAuthGuardContext();
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      return combineLatest([
        observeNormalizedUserSettings(environment, {
          userId: currentUserId,
        }),
        observeUserPushNotificationSubscriptionForThisClient(environment, {
          currentUserId,
        }),
      ]).pipe(
        map(([{ settings }, [pushNotificationRecord]]) => {
          const commands: ICommandArgs[] = [];

          const isFocusModeEnabled = settings?.enable_focus_mode ?? false;

          if (isFocusModeEnabled) {
            commands.push({
              label: "Disable focus mode",
              keywords: ["Enable focus mode"],
              altLabels: ["Toggle focus mode"],
              callback: () => toggleFocusMode(environment, { enableFocusMode: false }),
            });
          } else {
            commands.push({
              label: "Enable focus mode",
              keywords: ["Disable focus mode"],
              altLabels: ["Toggle focus mode"],
              callback: () => toggleFocusMode(environment, { enableFocusMode: true }),
            });
          }

          commands.push({
            label: "Update focus mode",
            altLabels: ["Edit focus mode"],
            callback: () => {
              navigateService("/settings#focus-mode");
            },
          });

          if (checkNotificationSupport()) {
            if (pushNotificationRecord) {
              commands.push({
                label: "Disable push notifications",
                altLabels: ["Turn off push notifications"],
                callback: () => {
                  unsubscribeFromPushNotifications(environment);
                },
              });
            } else {
              commands.push({
                label: "Enable push notifications",
                altLabels: ["Turn on push notifications"],
                callback: () => {
                  subscribeToPushNotifications(environment);
                },
              });
            }
          }

          commands.push({
            label: "Edit scheduled delivery",
            altLabels: ["Update scheduled delivery", "Edit batched delivery", "Update batched delivery"],
            callback: () => {
              navigateService("/settings#scheduled-delivery");
            },
          });

          commands.push({
            label: "Notification preferences",
            altLabels: ["Edit notification preferences", "Update notification preferences"],
            callback: () => {
              if (!environment.network.isOnline()) {
                toast("vanilla", {
                  subject: "Not supported in offline mode",
                  description: "Can't edit your profile while offline.",
                });

                return;
              }

              navigateService("/settings#notification-preferences");
            },
          });

          return commands;
        }),
      );
    },
    deps: [environment, currentUserId],
  });
}

export function useRegisterUserProfileCommands() {
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      return [
        {
          label: "Edit your user profile",
          altLabels: [
            "Update your user profile",
            "Edit my profile",
            "Update my profile",
            "Change phone number",
            "Update phone number",
            "Edit phone number",
          ],
          callback: () => {
            if (!environment.network.isOnline()) {
              toast("vanilla", {
                subject: "Not supported in offline mode",
                description: "Can't edit your profile while offline.",
              });

              return;
            }

            navigateService("/settings#user-profile");
          },
        },
      ];
    },
    deps: [environment],
  });
}

export function useRegisterUndoRedoCommands() {
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      return [
        {
          label: "Undo",
          keywords: ["Redo"],
          hotkeys: ["z", "$mod+z"],
          callback: () => {
            undo(environment);
          },
        },
        {
          label: "Redo",
          keywords: ["Redo"],
          hotkeys: ["Shift+Z", "Shift+$mod+z"],
          callback: () => {
            redo(environment);
          },
        },
      ];
    },
    deps: [environment],
  });
}
