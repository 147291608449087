import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { SuggestionProps } from "@tiptap/suggestion";
import { css } from "@emotion/css";
import { onSuggestionDialogClose, onSuggestionDialogOpen } from "../utils";

export interface ISuggestionEntryProps {
  emoji?: string;
  name: string;
  shortcodes: string[];
  tags: string[];
  group: string;
  emoticons?: string[];
  version?: number;
  fallbackImage: string;
}

export const EmojiList = forwardRef<
  { onKeyDown(o: { event: KeyboardEvent }): boolean | undefined },
  SuggestionProps<ISuggestionEntryProps>
>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    // We need to track whether a mentioned list is opened or not
    // for the EditorOverflowHandler. See `EditorOverflowHandler.ts`
    // for more info.
    onSuggestionDialogOpen();

    return onSuggestionDialogClose;
  });

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command(item);
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (props.items.length === 0) {
        return false;
      }

      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  if (props.items.length === 0) {
    return null;
  }

  return (
    <div className={emojiStyles}>
      {props.items.map((item, index) => (
        <button
          key={index}
          type="button"
          className={`item ${index === selectedIndex ? "is-selected" : ""}`}
          onClick={() => selectItem(index)}
        >
          {item.fallbackImage ? <img src={item.fallbackImage} /> : item.emoji}
          <span className="ml-2">:{item.name}:</span>
        </button>
      ))}
    </div>
  );
});

const emojiStyles = css`
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1);

  & .item {
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    border-radius: 0.4rem;
    border: 1px solid transparent;
    padding: 0.2rem 0.4rem;

    &.is-selected {
      border-color: #000;
    }

    img {
      width: 1em;
      height: 1em;
    }
  }
`;
