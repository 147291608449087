import useConstant from "use-constant";
import { useIsDesktopBrowser } from "./useIsDesktopBrowser";

export function useInitialQueryLimit(desktopQueryLimit: number) {
  const isDesktopBrowser = useIsDesktopBrowser();
  const initialScreenHeight = useConstant(() => window.screen.height);

  return useConstant(() => {
    const pxHeightOfEntry = 48;
    // On mobile devices we render fewer entries initially because the device is slower.
    return isDesktopBrowser ? desktopQueryLimit : Math.ceil(initialScreenHeight / pxHeightOfEntry + 5);
  });
}
