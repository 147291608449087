import { cx } from "@emotion/css";
import dayjs from "dayjs";
import { MS_IN_MINUTE } from "libs/date-helpers";
import { useObservable, useObservableEagerState } from "observable-hooks";
import { ComponentType, useEffect } from "react";
import { map } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ICommandArgs, PLATFORM_MODIFIER_KEY, useRegisterCommands } from "~/environment/command.service";
import { ServiceWorkerService } from "~/environment/service-worker";
import { useIsOnline } from "~/hooks/useIsOnline";
import { useIsServiceWorkerUpdateAvailable } from "~/hooks/useIsServiceWorkerUpdateAvailable";
import { useIsServiceWorkerUpdateBeingInstalled } from "~/hooks/useIsServiceWorkerUpdateBeingInstalled";
import { useTimeFromNow } from "~/hooks/useTimeFromNow";

export const UpdateAvailableBanner: ComponentType = () => {
  const environment = useClientEnvironment();
  const isUpdateAvailable = useIsServiceWorkerUpdateAvailable();
  const isUpdateBeingInstalled = useIsServiceWorkerUpdateBeingInstalled();
  const isOnline = useIsOnline();

  useEventuallyAutoTriggerUpdate();

  const autoInstallAt = useTimestampWhenUpdateWillBeAutoInstalled();
  const autoUpdateTimeFromNow = useTimeFromNow({ timestamp: autoInstallAt, reevaluateMs: MS_IN_MINUTE * 5 });

  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [
        {
          label: "See what's new in Comms",
          callback: () => {
            window.open("https://github.com/levelshealth/comms-release-notes/releases", "_blank");
          },
        },
      ];

      if (isUpdateAvailable && isOnline) {
        commands.push({
          label: "Install Comms update",
          hotkeys: ["$mod+u"],
          callback: () => {
            environment.serviceWorker.checkForUpdateAndActivateIfAvailable();
          },
        });
      }

      return commands;
    },
    deps: [isUpdateAvailable, isOnline, environment],
  });

  if (isUpdateBeingInstalled) return null;
  if (!isUpdateAvailable || !isOnline) return null;

  return (
    <aside
      onClick={() => {
        if (!isOnline) return;
        environment.serviceWorker.checkForUpdateAndActivateIfAvailable();
      }}
      className={cx(
        "fixed bottom-0 px-8 py-2 w-full text-center font-medium bg-blue-9 text-white text-sm z-[8999]",
        "cursor-pointer",
      )}
    >
      <strong>Update available</strong>&nbsp;- {getAutoInstallMessage(autoUpdateTimeFromNow)} Click to install now (
      <span className="inline-flex items-center scale" style={{ transform: "scale(0.9) translateY(3px)" }}>
        <PLATFORM_MODIFIER_KEY.symbol className="mr-[3px]" />+ U
      </span>
      ).
    </aside>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function getAutoInstallMessage(autoUpdateTimeFromNow: string | null) {
  if (!autoUpdateTimeFromNow) return;

  let text = `It will be automatically installed `;

  if (autoUpdateTimeFromNow.startsWith("in")) {
    text += autoUpdateTimeFromNow;
  } else {
    text += `in ${autoUpdateTimeFromNow}`;
  }

  text += ".";

  return text;
}

/* -----------------------------------------------------------------------------------------------*/

function useEventuallyAutoTriggerUpdate() {
  const environment = useClientEnvironment();
  const isOnline = useIsOnline();
  const willBeInstalledAt = useTimestampWhenUpdateWillBeAutoInstalled();

  // Auto trigger available update 30 minutes after it becomes available
  useEffect(() => {
    if (!willBeInstalledAt || !isOnline) return;

    const updateInMs = dayjs(willBeInstalledAt).diff(dayjs(), "milliseconds");

    if (updateInMs <= 0) {
      environment.serviceWorker.checkForUpdateAndActivateIfAvailable();
    } else {
      const timeoutId = setTimeout(() => {
        environment.serviceWorker.checkForUpdateAndActivateIfAvailable();
      }, updateInMs);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [willBeInstalledAt, isOnline, environment]);
}

/* -----------------------------------------------------------------------------------------------*/

// Auto install update in 1 day
function useTimestampWhenUpdateWillBeAutoInstalled() {
  const environment = useClientEnvironment();

  const observable = useObservable(() =>
    environment.serviceWorker.isUpdateAvailable$.pipe(
      map((isUpdateAvailable) => {
        if (!isUpdateAvailable) return null;
        const timestamp = ServiceWorkerService.getWhenServiceWorkerUpdateBecameAvailable();
        if (!timestamp) return null;
        return dayjs(timestamp).add(1, "day").toISOString();
      }),
    ),
  );

  return useObservableEagerState(observable);
}

/* -----------------------------------------------------------------------------------------------*/
