import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useAuthGuardContext } from "./withAuthGuard";
import { useIsUserDeletedFromOrg } from "~/hooks/useIsUserDeletedFromOrg";

/**
 * A HOC which ensures that the wrapped component is only rendered
 * if the user is still active in the organization.
 */
export function withOrgMemberGuard<P extends PropsWithChildren<unknown>>(Component: React.ComponentType<P>) {
  return function GuardedRoute(props: P) {
    const { ownerOrganizationId, currentUserId } = useAuthGuardContext();

    const [isDeletedFromOrg] = useIsUserDeletedFromOrg({
      userId: currentUserId,
      organizationId: ownerOrganizationId,
    });

    if (isDeletedFromOrg) {
      return <Navigate to={"/deactivated"} replace />;
    } else {
      return <Component {...props} />;
    }
  };
}
