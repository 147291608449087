import { FetchStrategy, ObserveOptions } from "~/environment/RecordLoader";
import { useRecordLoader } from "./useRecordLoader";

export function useDraftIdsForThread(props: { threadId: string | null | undefined; fetchStrategy?: FetchStrategy }) {
  return useRecordLoader({
    name: "useDraftIdsForThread",
    load({ loader, currentUserId, deps: [threadId, fetchStrategy] }) {
      if (!threadId) {
        return loader.createObserveQueryResult<"draft">();
      }

      const options: ObserveOptions = { fetchStrategy };

      return loader.observeGetDrafts(
        {
          threadId,
          currentUserId: currentUserId,
        },
        options,
      );
    },
    deps: [props.threadId, props.fetchStrategy],
    map: (records) => records.map((r) => r.id),
  });
}
