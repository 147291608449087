export async function isGoogleWorkspaceEmail(email: string) {
  if (!email.includes("@")) {
    return false;
  }

  const domain = email.split("@")[1];

  if (!domain) {
    return false;
  }

  // We don't allow gmail addresses at the moment
  if (domain.endsWith("gmail.com")) {
    return false;
  }

  const response = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
  const data = await response.json();

  if (data.Status !== 0) {
    console.error("DNS query failed");
    return false;
  }

  const googleMxRecords = data.Answer.filter((record: any) => record.data.endsWith(".google.com."));

  if (googleMxRecords.length > 0) {
    return true;
  } else {
    return false;
  }
}
