import { ComponentType, RefObject, memo } from "react";
import { isEqual } from "libs/predicates";
import { useThreadTimelineEntry } from "~/hooks/useThreadTimelineEntry";
import { UnreachableCaseError } from "libs/errors";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { ComposeThreadReply } from "../thread-drafts/ComposeThreadReply";
import { ThreadType, ThreadVisibility } from "libs/schema";
import { IRichTextEditorRef } from "~/components/forms/message-editor";
import { IListRef } from "~/components/list";
import { MessageEntry } from "~/components/thread-timeline-entry/MessageEntry";
import { BranchedThreadEntry } from "~/components/thread-timeline-entry/BranchedThreadEntry";
import { BranchedDraftEntry } from "~/components/thread-timeline-entry/BranchedDraftEntry";
import { useDraftEdit } from "~/hooks/useDraftEdit";

/* -------------------------------------------------------------------------------------------------
 * ThreadPostEntry
 * -----------------------------------------------------------------------------------------------*/

export interface IThreadPostEntryProps {
  timelineId: string;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  editorRefStore: Map<string, IRichTextEditorRef>;
  threadId: string;
  threadType: ThreadType;
  threadVisibility: ThreadVisibility;
  threadSubject: string;
  isThreadDeleted: boolean;
  relativeOrder: number;
}

export const ThreadTimelineEntry: ComponentType<IThreadPostEntryProps> = memo((props) => {
  const [timelineEntry] = useThreadTimelineEntry({
    timelineId: props.timelineId,
  });

  const [draftEdit] = useDraftEdit(timelineEntry?.entry_id);

  if (!timelineEntry) return null;

  switch (timelineEntry.type) {
    case "MESSAGE": {
      if (draftEdit) {
        return (
          <ComposeThreadReply
            listRef={props.listRef}
            editorRefStore={props.editorRefStore}
            draftId={draftEdit.id}
            isEditingExistingMessage
            threadId={props.threadId}
            threadType={props.threadType}
            threadVisibility={props.threadVisibility}
            threadSubject={props.threadSubject}
            isThreadDeleted={props.isThreadDeleted}
            isFirstDraft={false}
            focusOnInit={true}
          />
        );
      }

      return <MessageEntry messageId={timelineEntry.entry_id} relativeOrder={props.relativeOrder} />;
    }
    case "BRANCHED_THREAD": {
      return <BranchedThreadEntry threadId={timelineEntry.entry_id} relativeOrder={props.relativeOrder} />;
    }
    case "BRANCHED_DRAFT": {
      return <BranchedDraftEntry draftId={timelineEntry.entry_id} relativeOrder={props.relativeOrder} />;
    }
    default: {
      throw new UnreachableCaseError(timelineEntry.type);
    }
  }
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/
