import { combineLatest, map, of, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { getPointer } from "libs/schema";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ObserveOptions } from "~/environment/RecordLoader";

export type UseThreadFound = {
  threadFound: "found" | "not-found" | "permission-denied";
  isLoading: boolean;
};

export function useThreadFound(threadId: string | null | undefined): UseThreadFound {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useLoadingObservable({
    initialValue: threadFoundDefaultValue,
    deps: [environment, threadId, currentUserId],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, threadId, currentUserId]) => {
          if (!threadId) {
            return of({
              threadFound: "not-found" as const,
              isLoading: false,
            });
          }

          return combineLatest([
            environment.recordLoader.observeGetRecord(
              {
                table: "thread",
                id: threadId,
              },
              { includeSoftDeletes: true },
            ),
            environment.recordLoader.observeGetRecord(
              getPointer("notification", {
                thread_id: threadId,
                user_id: currentUserId,
              }),
            ),
          ]).pipe(
            map(([[thread, { isLoading: isThreadLoading }], [notification, { isLoading: isNotificationLoading }]]) => ({
              threadFound:
                thread ? ("found" as const)
                : notification ? ("permission-denied" as const)
                : ("not-found" as const),
              isLoading: isThreadLoading || isNotificationLoading,
            })),
          );
        }),
      );
    },
  });
}

const threadFoundDefaultValue: UseThreadFound = Object.freeze({
  threadFound: "not-found" as const,
  isLoading: true,
});
