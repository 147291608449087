import { distinctUntilChanged, map, of, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { isEqual } from "libs/predicates";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export function useThreadSubject(threadId: string | null | undefined) {
  const { recordLoader } = useClientEnvironment();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [recordLoader, threadId],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([recordLoader, threadId]) =>
          threadId
            ? recordLoader.observeGetRecord({ table: "thread", id: threadId })
            : recordLoader.createObserveGetResult<"thread">(),
        ),
        map(([record, { isLoading }]) => {
          return {
            subject: record && record.subject,
            isLoading,
          };
        }),
        distinctUntilChanged(isEqual),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze({
  subject: null as string | null,
  isLoading: true as boolean,
});
