import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { observeBrowserPushNotificationSubscription } from "./observeBrowserPushNotificationSubscription";
import { Observable, distinctUntilChanged, switchMap } from "rxjs";
import { isEqual } from "libs/predicates";
import { ClientRecordLoaderObserveGetRecordResult, ObserveOptions } from "~/environment/RecordLoader";

export type ObserveUserPushNotificationSubscriptionForThisClientResult =
  ClientRecordLoaderObserveGetRecordResult<"user_push_notification_subscriptions">;

/**
 * Returns an observable for the user_push_notification_subscriptions record for _this client_
 * if one exists.
 */
export function observeUserPushNotificationSubscriptionForThisClient(
  environment: ClientEnvironment,
  props: {
    currentUserId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveUserPushNotificationSubscriptionForThisClientResult> {
  const { currentUserId } = props;

  return observeBrowserPushNotificationSubscription(environment, options).pipe(
    switchMap(({ pushSubscription, isLoading }) => {
      if (!pushSubscription) {
        return environment.recordLoader.createObserveGetResult<"user_push_notification_subscriptions">([
          undefined,
          { isLoading },
        ]);
      }

      return environment.recordLoader
        .observeGetUserPushNotificationSubscriptions(
          { user_id: currentUserId },
          { isLoading, fetchStrategy: options?.fetchStrategy },
        )
        .pipe(
          switchMap(([records, { isLoading }]) => {
            const json = pushSubscription.toJSON();
            const record = records.find((r) => isEqual(r.subscription, json));
            return environment.recordLoader.createObserveGetResult<"user_push_notification_subscriptions">([
              record,
              { isLoading },
            ]);
          }),
          distinctUntilChanged(isEqual),
        );
    }),
  );
}
