import { ComponentType, forwardRef, RefObject, useEffect, useRef } from "react";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import {
  IMessageEditorControl,
  IRichTextEditorRef,
  onElementMouseDownFocusTiptap,
  MessageEditor,
  MessageEditorErrors,
  useSyncDraftContentChangesFromOtherWindowToEditor,
} from "~/components/forms/message-editor";
import { useControl } from "solid-forms-react";
import { observable, useControlState } from "~/components/forms/utils";
import { css, cx } from "@emotion/css";
import dayjs from "dayjs";
import { filter, throttleTime } from "rxjs";
import { TThreadRecipientsRef } from "~/components/forms/ThreadRecipients";
import { useSidebarLayoutContext } from "~/page-layouts/sidebar-layout";
import { slate } from "@radix-ui/colors";
import {
  tour,
  useConfigurePrivateMessageLesson,
} from "~/environment/lesson-service/lessons/private-message-walkthrough";
import { useIsTourInProgress } from "~/environment/lesson-service";
import { useBottomScrollShadow, useTopScrollShadow } from "~/hooks/useScrollShadow";
import { UnreachableCaseError } from "libs/errors";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { useSetWebpageBackgroundColor } from "~/hooks/useSetWebpageBackgroundColor";
import {
  DraftActions,
  makePrivateCommandCallback,
  saveNewThreadDraft,
  useAddOrganizationGroupOnRecipientAndVisibilityChanges,
  useRegisterSharedComposeNewMessageCommands,
  useSaveDraftImmediatelyFn,
  useUpdateDraftTypeOnRecipientChanges,
  useUpdateRecipientsOnDraftTypeChanges,
  useUpdateRecipientsOnVisibilityChanges,
  useUpdateVisibilityOnRecipientChanges,
} from "../utils";
import { openComposeNewThreadDialog } from "../../page-dialog-state";
import { ComposeInfoPanel } from "../ComposeInfoPanel";
import { Header } from "../Header";
import {
  createComposeMessageForm,
  IComposeMessageForm,
  IComposeMessageFormValue,
  useAutosaveDraft,
  usePromptToRemoveRecipientOnMentionRemoval,
  useSyncDraftChangesFromOtherWindowToControl,
} from "~/components/ComposeMessageContext";
import { useAddNewGroupMentionsAsRecipients, useAddNewUserMentionsAsRecipients } from "~/components/ComposeReplyBase";
import { HeaderFields } from "./HeaderFields";
import { DraftType, ThreadVisibility } from "libs/schema";
import { mapRecipientOptionToDraftRecipient, sendDraft } from "~/actions/draft";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { AttachmentsContainer, DraftAttachment } from "~/components/Attachment";

export const ComposeNewThread: ComponentType<{
  initialFormValues: IComposeMessageFormValue;
}> = (props) => {
  const control = useControl(() =>
    createComposeMessageForm(props.initialFormValues, {
      recipientsRequired: true,
    }),
  );

  useSetWebpageBackgroundColor(slate.slate3);

  // const [lesson] = useUserLesson(tour.lessonName);
  const isLessonInProgress = useIsTourInProgress(tour);

  useConfigurePrivateMessageLesson();

  // const treatLessonAsCompleted =
  //   isLessonInProgress ||
  //   lesson === undefined ||
  //   (lesson?.version === tour.lessonVersion && lesson?.is_completed);

  // Temporarily disabling this lesson until I have time to fix it
  const treatLessonAsCompleted = true;

  useUpdateDraftTypeOnRecipientChanges(control);
  useUpdateRecipientsOnDraftTypeChanges(control);
  useUpdateVisibilityOnRecipientChanges(control);
  useUpdateRecipientsOnVisibilityChanges(control);
  useAddOrganizationGroupOnRecipientAndVisibilityChanges({
    control,
    walkthroughNotCompleted: !treatLessonAsCompleted,
  });

  const editorRef = useRef<IRichTextEditorRef>(null);
  const toRecipientsRef = useRef<TThreadRecipientsRef>(null);

  useAutosaveDraft(control, saveNewThreadDraft);
  useSyncDraftChangesFromOtherWindowToControl(control);

  const saveDraftImmediatelyFn = useSaveDraftImmediatelyFn(control);

  useRegisterSharedComposeNewMessageCommands({
    control,
    submit,
  });

  useFocusRecipientsWhenSidebarOutletIsFocused(toRecipientsRef);

  useHandleTourEvents({ control });

  useAddNewGroupMentionsAsRecipients(
    control.controls.body.controls.groupMentions,
    control.controls.recipients.controls.to,
  );

  useAddNewUserMentionsAsRecipients(
    control.controls.body.controls.userMentions,
    control.controls.recipients.controls.to,
  );

  usePromptToRemoveRecipientOnMentionRemoval(control);

  const visibility = useControlState(() => control.rawValue.visibility, [control]);

  const draftType = useControlState(() => control.rawValue.type, [control]);

  const scrollboxRef = useRef<HTMLDivElement>(null);
  const contentTopRef = useRef<HTMLDivElement>(null);
  const contentBottomRef = useRef<HTMLDivElement>(null);

  const calcTopScrollShadow = useTopScrollShadow({
    scrollboxRef,
    targetRef: contentTopRef,
  });

  const calcBottomScrollShadow = useBottomScrollShadow({
    scrollboxRef,
    targetRef: contentBottomRef,
  });

  // useTopScrollShadow and useBottomScrollShadow only reevaluate the need for
  // a shadow on scroll events. If a user deletes content from the editor
  // we may no longer need a scroll shadow but a scroll event will not have
  // been emitted. We handle this possibility here.
  useEffect(() => {
    const sub = observable(() => control.rawValue.body.content)
      .pipe(throttleTime(100, undefined, { leading: false, trailing: true }))
      .subscribe(() => {
        calcTopScrollShadow();
        calcBottomScrollShadow();
      });

    return () => sub.unsubscribe();
  }, [control, calcTopScrollShadow, calcBottomScrollShadow]);

  return (
    <>
      <div className="MainPanel md-w:mr-[280px] h-dynamic-screen flex flex-col">
        <Header control={control} isLessonComplete={treatLessonAsCompleted} />

        <ThreadLayout.ContentPanel className="mx-auto flex-1 flex flex-col overflow-hidden">
          <form
            onSubmit={(e) => e.preventDefault()}
            className={cx(formCSS, visibility === "PRIVATE" && "private-message")}
          >
            <HeaderFields
              control={control}
              contentTopRef={contentTopRef}
              visibility={visibility}
              toRecipientsRef={toRecipientsRef}
              treatLessonAsCompleted={treatLessonAsCompleted}
            />

            <Content
              control={control}
              saveDraftImmediatelyFn={saveDraftImmediatelyFn}
              editorRef={editorRef}
              scrollboxRef={scrollboxRef}
            />

            <Footer ref={contentBottomRef} threadVisibility={visibility} draftType={draftType} />
          </form>

          <div className="h-8" />
        </ThreadLayout.ContentPanel>
      </div>

      <ComposeInfoPanel control={control} />
    </>
  );
};

function useFocusRecipientsWhenSidebarOutletIsFocused(threadRecipientsRef: RefObject<TThreadRecipientsRef>) {
  const { focusEvent$ } = useSidebarLayoutContext();

  useEffect(() => {
    const sub = focusEvent$.pipe(filter((e) => e === "Outlet")).subscribe(() => {
      threadRecipientsRef.current?.focus();
    });

    return () => sub.unsubscribe();
  }, [threadRecipientsRef, focusEvent$]);
}

function useHandleTourEvents(args: { control: IComposeMessageForm }) {
  const { control } = args;
  const { emitFocusEvent } = useSidebarLayoutContext();

  useEffect(() => {
    const sub = tour.event$.subscribe((e) => {
      switch (e) {
        case "set-shared": {
          if (control.rawValue.visibility === "SHARED") return;
          makePrivateCommandCallback(control);
          return;
        }
        case "set-private": {
          if (control.rawValue.visibility === "PRIVATE") return;
          makePrivateCommandCallback(control);
          return;
        }
        case "focus-outlet": {
          emitFocusEvent("Outlet");
          return;
        }
        case "focus-sidebar": {
          emitFocusEvent("Sidebar");
          return;
        }
        default: {
          throw new UnreachableCaseError(e);
        }
      }
    });

    return () => sub.unsubscribe();
  }, [control, emitFocusEvent]);
}

const Footer = forwardRef<HTMLDivElement, { threadVisibility: ThreadVisibility | null; draftType: DraftType }>(
  (props, ref) => {
    return (
      <div ref={ref} className="flex p-4 sm-w:px-8 border-t border-mauve-5 space-x-3">
        <DraftActions visibility={props.threadVisibility} draftType={props.draftType} />
      </div>
    );
  },
);

const formCSS = cx(
  "flex flex-col flex-1 bg-white relative shadow-lg",
  "overflow-hidden",
  css`
    max-height: calc(100% - 2rem);

    input {
      background-color: transparent;
    }

    &.private-message {
      margin-top: 1rem;
    }
  `,
);

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  async (
    environment: ClientEnvironment,
    values: IComposeMessageFormValue,
    options: { sendImmediately?: boolean } = {},
  ) => {
    const logger = environment.logger.child({ name: "ComposeNewThread Submit" });

    logger.info(values, "submitting...");

    if (values.visibility === null) {
      logger.error("Attempted to send post with visibility === null");
      return;
    }

    // We want the new post form to close immediately without
    // waiting for this promise to resolve.
    // See `createNewDraft` jsdoc.
    sendDraft(environment, {
      currentUserId: environment.auth.getAndAssertCurrentUserId(),
      ownerOrganizationId: environment.auth.getAndAssertCurrentUserOwnerOrganizationId(),
      is_edit: values.isEdit,
      is_reply: values.isReply,
      type: values.type,
      draftId: values.messageId,
      threadId: values.threadId,
      visibility: values.visibility,
      subject: values.subject || "",
      bodyHTML: values.body.content,
      to: values.recipients.to.map(mapRecipientOptionToDraftRecipient),
      cc: values.recipients.cc.map(mapRecipientOptionToDraftRecipient),
      bcc: values.recipients.bcc.map(mapRecipientOptionToDraftRecipient),
      groupMentions: values.body.groupMentions,
      userMentions: values.body.userMentions,
      attachments: values.attachments,
      scheduledToBeSentAt: options.sendImmediately ? new Date() : dayjs().add(20_000, "ms").toDate(),
      afterUndo: () => {
        openComposeNewThreadDialog(values.messageId);
      },
    })
      .then(() => logger.info("submitted successfully!"))
      .catch((error) => logger.error({ error }, "failed to submit"));
  },
);

// Naming this component "Content" rather than "Body" because
// the placeholder is generated based on the field name and I
// think "Content..." makes a better placeholder than "Body..."
// and I want to name the component after the field name.
const Content: ComponentType<{
  control: IMessageEditorControl;
  editorRef: RefObject<IRichTextEditorRef>;
  scrollboxRef: RefObject<HTMLDivElement>;
  saveDraftImmediatelyFn: () => Promise<void>;
}> = (props) => {
  useSyncDraftContentChangesFromOtherWindowToEditor(props.control, props.editorRef);

  return (
    <div
      ref={props.scrollboxRef}
      className="flex flex-col flex-1 relative overflow-y-auto px-4 min-h-48"
      onClick={(e) => onElementMouseDownFocusTiptap(e, props.editorRef)}
    >
      <MessageEditor ref={props.editorRef} control={props.control} saveDraftFn={props.saveDraftImmediatelyFn} />

      <Attachments control={props.control} />

      <MessageEditorErrors control={props.control} />
    </div>
  );
};

const Attachments: ComponentType<{ control: IMessageEditorControl }> = (props) => {
  const attachments = useControlState(
    () => props.control.controls.attachments.rawValue.filter((a) => a.contentDisposition !== "inline"),
    [props.control],
  );

  if (attachments.length === 0) return null;

  return (
    <div className="mb-3">
      <AttachmentsContainer>
        {attachments.map((attachment) => (
          <DraftAttachment key={attachment.id} control={props.control} attachment={attachment} />
        ))}
      </AttachmentsContainer>
    </div>
  );
};
