import { useMemo } from "react";
import { useThreadPermittedGroupIds } from "./useThreadPermittedGroups";
import { TagSubscriptionPreference, ThreadSubscriptionPreference, getPointer } from "libs/schema";
import { useRecords } from "./useRecords";
import { useThreadSubscription } from "./useThreadSubscription";
import { UnreachableCaseError } from "libs/errors";

type SubscriptionPreference = ThreadSubscriptionPreference | TagSubscriptionPreference;

/**
 * Returns the normalized subscription preference for the given user and thread.
 * Considers any tag subscriptions as well as thread subscription.
 */
export function useSubscriptionPreferenceForThread(props: {
  userId: string | null | undefined;
  threadId: string | null | undefined;
}) {
  const { userId } = props;

  const [groupIds, { isLoading: areGroupIds }] = useThreadPermittedGroupIds(props);

  const [threadSubscription, { isLoading: isThreadSubLoading }] = useThreadSubscription({
    userId,
    threadId: props.threadId,
  });

  const tagSubPointers = useMemo(() => {
    if (!userId) return [];

    return groupIds.map((groupId) =>
      getPointer("tag_subscription", {
        user_id: userId,
        tag_id: groupId,
      }),
    );
  }, [userId, groupIds]);

  const [tagSubs, { isLoading: areTagSubsLoading }] = useRecords(tagSubPointers, {
    name: "useSubscriptionPreferenceForThread",
  });

  const isLoading = areGroupIds || isThreadSubLoading || areTagSubsLoading;

  const preference = useMemo(() => {
    if (threadSubscription) return threadSubscription.preference;

    return tagSubs.reduce<SubscriptionPreference>((pref, tagSub) => {
      const existingPref = getSubPreferenceValue(pref);
      const newPref = getSubPreferenceValue(tagSub.record.preference);
      return newPref > existingPref ? tagSub.record.preference : pref;
    }, "involved");
  }, [threadSubscription, tagSubs]);

  return [preference, { isLoading }] as const;
}

function getSubPreferenceValue(pref: SubscriptionPreference) {
  switch (pref) {
    case "all": {
      return 3;
    }
    case "all-new": {
      return 2;
    }
    case "involved": {
      return 1;
    }
    default: {
      throw new UnreachableCaseError(pref);
    }
  }
}
