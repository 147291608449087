import { cx } from "@emotion/css";
import { ThreadVisibility } from "libs/schema";
import { ComponentType, RefObject } from "react";
import { IComposeMessageForm } from "~/components/ComposeMessageContext";
import { ThreadRecipients, TThreadRecipientsRef } from "~/components/forms/ThreadRecipients";
import { useControlState } from "~/components/forms/utils";
import {
  getClassForPrivateMessageTourStep,
  tour,
} from "~/environment/lesson-service/lessons/private-message-walkthrough";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { Subject } from "../utils";

export const HeaderFields: ComponentType<{
  control: IComposeMessageForm;
  visibility: ThreadVisibility | null;
  toRecipientsRef: RefObject<TThreadRecipientsRef>;
  treatLessonAsCompleted: boolean;
  contentTopRef: RefObject<HTMLDivElement>;
}> = (props) => {
  const { control, visibility, toRecipientsRef, treatLessonAsCompleted, contentTopRef } = props;

  const threadType = useControlState(() => props.control.rawValue.type, [props.control]);

  const isToInvalid = useControlState(() => !props.control.controls.recipients.controls.to.isValid, [props.control]);

  const isToTouched = useControlState(() => props.control.controls.recipients.controls.to.isTouched, [props.control]);

  const isCcInvalid = useControlState(() => !props.control.controls.recipients.controls.cc.isValid, [props.control]);

  const isCcTouched = useControlState(() => props.control.controls.recipients.controls.cc.isTouched, [props.control]);

  const { settings } = useCurrentUserSettings();

  const isEmailEnabled = settings?.linked_gmail_email_account === true;

  return (
    <>
      <div className="flex items-baseline mx-4 py-2 border-b border-slate-6">
        <label htmlFor="to" className={cx("mr-2 font-medium", isToTouched && isToInvalid && "text-red-9")}>
          To
        </label>

        <ThreadRecipients
          ref={toRecipientsRef}
          control={control.controls.recipients.controls.to}
          name="to"
          wrapperClassName="flex-1"
          autoFocus
          threadType={threadType}
          canAddEmailRecipients={isEmailEnabled}
          isThreadPrivate={visibility === null ? null : visibility === "PRIVATE"}
          onClick={(e) => {
            if (treatLessonAsCompleted) return;

            const el = document.querySelector<HTMLDivElement>(`.${getClassForPrivateMessageTourStep(1)}`);

            if (el?.contains(e.target as Node)) {
              tour.start();
              e.preventDefault();
            }
          }}
          onKeyDown={(e) => {
            if (treatLessonAsCompleted) return;
            if (e.key !== "Enter") return;

            // The keydown events target is the input element, not the
            // focused option, we we need to manually check to see if
            // an option was focused when Enter was pressed.
            const el = document.querySelector<HTMLDivElement>(
              `.${getClassForPrivateMessageTourStep(1)}.option-is-focused`,
            );

            if (el) {
              tour.start();
              e.preventDefault();
            }
          }}
        />
      </div>

      {threadType === "EMAIL" && (
        <div className="flex items-baseline mx-4 py-2 border-b border-slate-6">
          <label htmlFor="cc" className={cx("mr-2 font-medium", isCcInvalid && isCcTouched && "text-red-9")}>
            Cc
          </label>

          <ThreadRecipients
            name="cc"
            control={control.controls.recipients.controls.cc}
            wrapperClassName="flex-1"
            threadType={threadType}
            canAddEmailRecipients={isEmailEnabled}
            isThreadPrivate={visibility === null ? null : visibility === "PRIVATE"}
          />
        </div>
      )}

      <div ref={contentTopRef}>
        <div className="flex items-center mx-4 py-2 border-b border-slate-6">
          <Subject control={control.controls.subject} />
        </div>
      </div>
    </>
  );
};
