import { useMemo } from "react";
import { useRecord } from "./useRecord";
import { generateRecordId } from "libs/schema";

/**
 * Expects either an organizationUserMemberId or a userId and organizationId.
 */
export function useOrganizationUserMember(props: {
  organizationUserMemberId?: string | null;
  userId?: string | null;
  organizationId?: string | null;
}) {
  const organizationUserMemberId = useMemo(() => {
    if (props.organizationUserMemberId) {
      return props.organizationUserMemberId;
    }

    if (!props.userId || !props.organizationId) {
      return null;
    }

    return generateRecordId("organization_user_member", {
      organization_id: props.organizationId,
      user_id: props.userId,
    });
  }, [props.organizationUserMemberId, props.userId, props.organizationId]);

  return useRecord("organization_user_member", organizationUserMemberId, { name: "useOrganizationUserMember" });
}
