import { ComponentType, createContext, useEffect, useState } from "react";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { ClientEnvironment } from "./ClientEnvironment";
import { createEnvironment, logger, serviceWorker } from "./createEnvironment";
import { generateRecordId } from "libs/schema";
import { LoadingText } from "~/components/LoadingText";
import { promiseTimeout } from "libs/promise-utils";
import { MS_IN_SECOND } from "libs/date-helpers";
import { setLogLevel } from "./createClientLogger";
import { Logger } from "libs/logger";
import type { LogFn } from "pino";

const ClientEnvironmentContext = createContext<ClientEnvironment | undefined>(undefined);

export const ClientEnvironmentProvider: ComponentType<{
  environment: ClientEnvironment;
}> = (props) => {
  return (
    <ClientEnvironmentContext.Provider value={props.environment}>{props.children}</ClientEnvironmentContext.Provider>
  );
};

export const CreateAndProvideClientEnvironment: ComponentType<{}> = (props) => {
  const [environment, setReactEnvironment] = useState<ClientEnvironment | undefined>();
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    const envLogger = createEnvLogger(logger);

    promiseTimeout(MS_IN_SECOND * 15, createEnvironment({ logger, envLogger }))
      .then((e) => {
        // for debugging purposes
        (window as any).environment = e;
        (window as any).generateRecordId = generateRecordId;
        (window as any).setLogLevel = setLogLevel;

        setReactEnvironment(e);
      })
      .catch((e) => {
        logger.error({ error: e, envLogs: envLogger.getLogs() }, "Failed to create environment");
        // If we fail to create the environment, we attempt to automatically update the app.
        // This is to prevent the possibility that a client is unable to update to the latest
        // version of Comms because of a bug while initializing Comms.
        serviceWorker.checkForUpdateAndActivateIfAvailable();
        setError(e);
      });
  }, []);

  if (!environment) {
    return <div className="flex items-center justify-center h-screen">{error ? errorMessage : <LoadingText />}</div>;
  }

  return <ClientEnvironmentProvider environment={environment}>{props.children}</ClientEnvironmentProvider>;
};

const errorMessage = (
  <div className="max-w-sm">
    <h1 className="font-semibold text-xl mb-2">Failed to load the environment.</h1>
    <p>
      Please erase your cache and cookies and try again. If the problem persists please contact us at team@comms.day
    </p>
  </div>
);

export const useClientEnvironment = createUseContextHook(ClientEnvironmentContext, "ClientEnvironmentContext");

function createEnvLogger(logger: Logger) {
  const _envLogger = logger.child({ name: "createEnvironment" });

  const envLogs: any[] = [];

  const debug: LogFn = function (...args: any[]) {
    const msg = args.at(-1) as string | object | undefined;

    if (typeof msg === "string") {
      // We're not including the object metadata along with the log because many of them are environment
      // objects which are not serializable (e.g. the ClientApiService).
      envLogs.push({ timestamp: Date.now(), msg });
    }

    (_envLogger.debug as any)(...args);
  };

  const envLogger = { debug, getLogs: () => envLogs };

  return envLogger;
}
