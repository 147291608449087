import { useMatch } from "react-router-dom";

/**
 * Returns true if the current route path is for a group, false otherwise.
 * Useful for showing error messages when attempting to load a tag route
 * but not finding the tag (i.e. you can know if the user is attempting to
 * view a generic tag or a group tag).
 */
export function useIsCurrentRouteForAGroup() {
  const match = useMatch("/groups/:tagId");
  return !!match;
}
