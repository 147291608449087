import { ComponentType, memo, useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "libs/predicates";
import { List, useListScrollboxContext } from "~/components/list";
import { cx } from "@emotion/css";
import { Link, useSearchParams } from "react-router-dom";
import { observe } from "react-intersection-observer";
import { filter, Observable } from "rxjs";
import { RiGitBranchLine } from "react-icons/ri";
import { Tooltip } from "~/components/Tooltip";
import { MdEdit, MdEditOff, MdLink, MdOutlineAddReaction } from "react-icons/md";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { PointerWithRecord, RecordValue } from "libs/schema";
import { useThreadReadReceipt } from "~/hooks/useThreadReadReceipt";
import { useThreadSeenReceipt } from "~/hooks/useThreadSeenReceipt";
import { useUserProfiles } from "~/hooks/useUserProfiles";
import { toggleMessageReaction } from "~/actions/message";
import { useThread } from "~/hooks/useThread";
import { useMessageReactions } from "~/hooks/useMessageUserReactions";
import {
  copyLinkToFocusedPostCommand,
  createBranchedReplyCommand,
  editMessageCommand,
  markThreadResolvedCommand,
  reactToMessageCommand,
} from "~/utils/common-commands";
import { useThreadTimelineContext } from "../context";
import { CollapsedMessage } from "./CollapsedMessage";
import { ExpandedMessage } from "./ExpandedMessage";
import { useMessage } from "~/hooks/useMessage";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useThreadResolvedTag } from "~/hooks/useThreadResolvedTag";
import { useTimelineEntryElementId } from "~/hooks/useTimelineEntryId";
import { collapsedTimelineEntryCSS } from "../util";
import { useIsMessageSent } from "~/hooks/useIsMessageSent";
import {
  ScheduledToBeSentIcons,
  isMessageCreationSyncedWithServer,
  isMessageEditSyncedWithServer,
} from "~/components/content-list/MessageEntry";
import { useIsDesktopBrowser } from "~/hooks/useIsDesktopBrowser";
import { SendingMessage } from "./SendingMessage";
import { useIsOnline } from "~/hooks/useIsOnline";

/**
 * A component which renders a message entry in a thread timeline.
 */
export const MessageEntry: ComponentType<{
  messageId: string;
  relativeOrder: number;
}> = (props) => {
  const [message] = useMessage(props.messageId);

  const isOnline = useIsOnline();
  const isMobile = !useIsDesktopBrowser();

  const entryData = useMemo(() => {
    if (!message) return null;
    return { table: "message" as const, id: message.id, record: message };
  }, [message]);

  const [thread] = useThread(message?.thread_id, { includeSoftDeletes: true });

  const { collapseMessageEvents, isQuoted, onMessageInView } = useThreadTimelineContext();

  const listEntryRef = useUpdateIsSeen({
    message,
    onMessageInView,
  });

  const { isClosed, setIsClosed } = useIsEntryClosedState({
    messageId: message?.id,
    threadId: message?.thread_id,
    timelineOrder: message?.timeline_order,
    isLastMessage: !thread || !message ? null : thread.last_message_id === message.id,
    collapseMessageEvents,
  });

  const elementId = useTimelineEntryElementId(message);

  const doNotRender = !message || !thread || !entryData || !elementId || isClosed === null;

  if (doNotRender) return null;

  const isFirstMessage = thread.first_message_id === message.id;
  const isLastMessage = thread.last_message_id === message.id;
  const isSendingMessage = !isMessageCreationSyncedWithServer(message) || !isMessageEditSyncedWithServer(message);
  const showSendingMessageNotification = isOnline && isSendingMessage && isMobile;

  return (
    <List.Entry<PointerWithRecord<"message">> id={message.id} data={entryData} relativeOrder={props.relativeOrder}>
      <div
        ref={listEntryRef}
        id={elementId}
        className={showSendingMessageNotification || !isClosed ? expandedMessageCss : collapsedMessageCSS}
        onKeyDown={(e) => {
          if (showSendingMessageNotification) return;
          if (e.key !== "Enter" || e.target instanceof HTMLAnchorElement || e.target instanceof HTMLButtonElement) {
            // if the user has focused an anchor or button element and they've
            // pressed "Enter", we don't want to collapse the entry.
            return;
          }

          setIsClosed((s) => !s);
        }}
        onClick={() => {
          if (showSendingMessageNotification) return;
          if (!isClosed) return;

          setIsClosed(false);
        }}
      >
        {showSendingMessageNotification ?
          <SendingMessage />
        : isClosed ?
          <CollapsedMessage message={message} />
        : <ExpandedMessage
            message={message}
            messageActions={
              isQuoted ? <ViewOriginalMessage threadId={message.thread_id} messageId={message.id} />
              : thread.deleted_at ?
                null
              : <MessageActions message={message} isFirstMessage={isFirstMessage} isLastMessage={isLastMessage} />
            }
            onHeaderClick={() => {
              setIsClosed(true);
            }}
          />
        }
      </div>
    </List.Entry>
  );
};

const collapsedMessageCSS = cx(collapsedTimelineEntryCSS, "Message");

export const expandedMessageCss = cx(
  "TimelineEntry Message bg-white my-4 shadow-lg border-l-[3px] border-white",
  "focus:outline-none focus-within:border-black",
);

/* -----------------------------------------------------------------------------------------------*/

function useIsSeen(props: { threadId?: string; timelineOrder?: string }) {
  const [seenReceipt] = useThreadSeenReceipt({ threadId: props.threadId });

  if (!props.timelineOrder || !props.threadId) return null;

  return !seenReceipt?.seen_to_timeline_order ? false : seenReceipt.seen_to_timeline_order >= props.timelineOrder;
}

function useIsRead(props: { threadId?: string; timelineOrder?: string }) {
  const [readReceipt] = useThreadReadReceipt({ threadId: props.threadId });

  if (!props.timelineOrder || !props.threadId) return null;

  return !readReceipt?.read_to_timeline_order ? false : readReceipt.read_to_timeline_order >= props.timelineOrder;
}

/* -----------------------------------------------------------------------------------------------*/

function useUpdateIsSeen(props: {
  message: RecordValue<"message"> | null;
  onMessageInView?: (message: RecordValue<"message">) => void;
}) {
  const { scrollboxRef } = useListScrollboxContext();
  const listEntryRef = useRef<HTMLDivElement>(null);

  const isSeen = useIsSeen({
    threadId: props.message?.thread_id,
    timelineOrder: props.message?.timeline_order,
  });

  useEffect(() => {
    if (!scrollboxRef.current || !listEntryRef.current) return;
    if (isSeen) return;

    const { onMessageInView, message } = props;

    if (!onMessageInView || !message) return;

    return observe(
      listEntryRef.current,
      (inView) => {
        if (!inView) return;
        onMessageInView(message);
      },
      {
        root: scrollboxRef.current,
        threshold: 0.5,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSeen, props.message, props.onMessageInView]);

  return listEntryRef;
}

/* -----------------------------------------------------------------------------------------------*/

function useIsEntryClosedState(props: {
  messageId?: string;
  threadId?: string;
  timelineOrder?: string;
  isLastMessage: boolean | null;
  collapseMessageEvents: Observable<"expand" | "collapse" | string>;
}) {
  const [searchParams] = useSearchParams();

  const isMessageIdAQueryParam = searchParams.get("message") === props.messageId;

  const isRead = useIsRead({
    threadId: props.threadId,
    timelineOrder: props.timelineOrder,
  });

  const [isClosed, setIsClosed] = useState<boolean | null>(null);

  // Wait for messages to load and then set the isClosed initial state.
  useEffect(() => {
    if (props.isLastMessage === null) return;
    if (isRead === null) return;
    if (isClosed !== null) return;

    setIsClosed(isMessageIdAQueryParam || props.isLastMessage ? false : isRead);
    // We only want to rerun this hook on props.isLastMessage === null changes.
    // Not all props.isLastMessage changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosed, isMessageIdAQueryParam, isRead, props.isLastMessage === null]);

  const missingThreadIdOrTimelineOrder = !props.threadId || !props.timelineOrder;

  useEffect(() => {
    if (!props.messageId || missingThreadIdOrTimelineOrder) return;

    const sub = props.collapseMessageEvents
      .pipe(filter((e) => e === "expand" || e === "collapse" || e === props.messageId))
      .subscribe((e) => {
        if (e === "expand" || e === "collapse") {
          setIsClosed(e === "collapse");
        } else {
          setIsClosed(false);
        }
      });

    return () => sub.unsubscribe();
  }, [props.messageId, props.collapseMessageEvents, missingThreadIdOrTimelineOrder]);

  return { isClosed, setIsClosed };
}

/* -------------------------------------------------------------------------------------------------
 * MessageActions
 * -----------------------------------------------------------------------------------------------*/

const MessageActions: ComponentType<{
  message: RecordValue<"message">;
  isFirstMessage: boolean;
  isLastMessage: boolean;
}> = (props) => {
  const { currentUser } = useAuthGuardContext();

  const [messageReactions] = useMessageReactions({
    messageId: props.message.id,
  });

  const reactionEntries = useReactionEntries(messageReactions);

  const canCancelSendingMessage = !useIsMessageSent(props.message);

  const isCreationSyncedWithServer = !!props.message.server_updated_at;

  const [threadResolvedTag] = useThreadResolvedTag(props.message.thread_id);

  const reasonCannotEdit =
    props.message.sender_user_id !== currentUser.id ? `You can only edit messages which you sent.`
      // The reason for this restriction is that, if someone tries to
      // edit a message which they "sent" but which hasn't *actually*
      // been sent yet, we want to unsend the draft so that it doesn't
      // get sent while they are in the process of editing it. But if the
      // message is the first message in a thread and the current user has
      // already optimistically replied to that thread, then we won't be
      // able to send those replies if the user "unsends" the first message
      // in the thread. Handling this scenerio will require extra effort which
      // we're not prioritizing at the moment.
    : !isCreationSyncedWithServer && props.isFirstMessage && !props.isLastMessage ?
      `You must wait for this message to finish sending before you can edit it.`
    : "";

  const canEdit = !reasonCannotEdit;

  return (
    <>
      <Tooltip side="bottom" content="Copy link to this message">
        <button
          type="button"
          tabIndex={-1}
          className={cx(actionButtonCSS, "mr-4")}
          onClick={(e) => {
            e.preventDefault();
            copyLinkToFocusedPostCommand.trigger();
          }}
        >
          <MdLink />
        </button>
      </Tooltip>

      <Tooltip
        side="bottom"
        content={
          <span>
            New Branch <kbd>Shift</kbd> + <kbd>R</kbd>
          </span>
        }
      >
        <button
          type="button"
          tabIndex={-1}
          className={cx(actionButtonCSS, "mr-4")}
          onClick={(e) => {
            e.preventDefault();
            createBranchedReplyCommand.trigger();
          }}
        >
          <RiGitBranchLine />
        </button>
      </Tooltip>

      {!canEdit && (
        <Tooltip side="bottom" content={reasonCannotEdit}>
          <button type="button" tabIndex={-1} className={cx(actionButtonCSS, "mr-4")} disabled={true}>
            <MdEditOff />
          </button>
        </Tooltip>
      )}

      {canEdit && (
        <Tooltip side="bottom" content={canCancelSendingMessage ? "Unsend and edit draft" : "Edit message"}>
          <button
            type="button"
            tabIndex={-1}
            className={cx(actionButtonCSS, "mr-4")}
            onClick={(e) => {
              e.preventDefault();
              editMessageCommand.trigger();
            }}
          >
            <MdEdit />
          </button>
        </Tooltip>
      )}

      {threadResolvedTag?.data.message_id !== props.message.id && (
        <Tooltip side="bottom" content="Mark thread resolved by this message.">
          <button
            type="button"
            tabIndex={-1}
            className={cx(actionButtonCSS, "mr-4")}
            onClick={(e) => {
              e.preventDefault();
              markThreadResolvedCommand.trigger();
            }}
          >
            <IoCheckmarkCircle />
          </button>
        </Tooltip>
      )}

      <Tooltip
        side="bottom"
        content={
          <span>
            Add reaction <kbd>Shift</kbd> + <kbd>;</kbd>
          </span>
        }
      >
        <button
          type="button"
          tabIndex={-1}
          className={cx(actionButtonCSS, "mr-1")}
          onClick={() => reactToMessageCommand.trigger()}
        >
          <MdOutlineAddReaction />
        </button>
      </Tooltip>

      <div className="flex overflow-auto">
        {reactionEntries.map(([reactionId, userIds]) => (
          <Reaction key={reactionId} messageId={props.message.id} reactionId={reactionId} userIds={userIds} />
        ))}
      </div>

      <span className="flex-1" />

      <div className="ml-4 flex space-x-2">
        <ScheduledToBeSentIcons message={props.message} showScheduledToBeSentIconFor="messageId" />
      </div>
    </>
  );
};

const actionButtonCSS = cx(
  "flex justify-center items-center",
  "hover:cursor-pointer text-slate-8 scale-125",
  "hover:text-black",
);

const ViewOriginalMessage: ComponentType<{
  threadId: string;
  messageId: string;
}> = (props) => {
  return (
    <Link to={`/threads/${props.threadId}?message=${props.messageId}`} className="hover:underline">
      View original message
    </Link>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const Reaction: ComponentType<{
  messageId: string;
  reactionId: string;
  userIds: string[];
}> = memo((props) => {
  const { currentUser } = useAuthGuardContext();
  const environment = useClientEnvironment();

  const [userProfiles] = useUserProfiles(props.userIds.slice(0, 10));

  const isSelectedByCurrentUser = props.userIds.some((userId) => userId === currentUser.id);

  const tooltip = getReactionTooltip({
    users: userProfiles,
    isSelectedByCurrentUser,
    currentUserId: currentUser.id,
  });

  return (
    <Tooltip side="bottom" content={tooltip}>
      <button
        type="button"
        tabIndex={-1}
        className={cx(
          "px-2 flex justify-center items-center border rounded-full",
          "border-slate-8 hover:cursor-pointer ml-2",
          isSelectedByCurrentUser ?
            "bg-slate-3 border-slate-11 dark:bg-slateDark-7 dark:border-slateDark-11"
          : "border-slate-7",
        )}
        onClick={() => {
          toggleMessageReaction(environment, {
            messageId: props.messageId,
            reactionId: props.reactionId,
          });
        }}
      >
        <span>{props.reactionId}</span> <span className="ml-2">{props.userIds.length}</span>
      </button>
    </Tooltip>
  );
}, isEqual);

function getReactionTooltip(props: {
  users: RecordValue<"user_profile">[];
  isSelectedByCurrentUser: boolean;
  currentUserId: string;
}) {
  let tooltip: string;

  if (props.users.length === 0) {
    tooltip = "";
  } else if (props.users.length === 1) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    tooltip = props.isSelectedByCurrentUser ? "You" : props.users[0]!.name;
  } else if (props.users.length < 11) {
    tooltip = props.isSelectedByCurrentUser ? "You, " : "";
    tooltip += props.users
      .filter((user) => user.id !== props.currentUserId)
      .map((user) => user.name)
      .join(", ");
  } else {
    tooltip = props.isSelectedByCurrentUser ? "You, " : "";
    tooltip += props.users
      .filter((user) => user.id !== props.currentUserId)
      .slice(0, 11)
      .map((user) => user.name)
      .join(", ");
    tooltip += `...`;
  }

  return tooltip;
}

/* -----------------------------------------------------------------------------------------------*/

function useReactionEntries(messageReactions: RecordValue<"message_reactions"> | null) {
  return useMemo(() => {
    const reactionToUserIdsMap = Object.entries(messageReactions?.reactions || {}).reduce(
      (store, [userId, reactions]) => {
        for (const reaction of reactions) {
          if (!store[reaction]) {
            store[reaction] = [];
          }

          store[reaction]!.push(userId);
        }

        return store;
      },
      {} as Record<string, string[]>,
    );

    return Object.entries(reactionToUserIdsMap).sort((a, b) => {
      const [_0, valueA] = a;
      const [_1, valueB] = b;
      return valueB.length - valueA.length;
    });
  }, [messageReactions]);
}
