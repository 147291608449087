import { Operation, Transaction } from "libs/transaction";

export function applyOperationsToTransaction(
  transaction: Transaction | Operation[],
  ...operations: Array<Operation[]>
) {
  const transactionOperations = Array.isArray(transaction) ? transaction : transaction.operations;

  transactionOperations.push(...operations.flat());
}
