import { useRecordLoader } from "./useRecordLoader";

export function usePendingSentMessageIds() {
  return useRecordLoader({
    name: "usePendingSentMessageIds",
    isAuthOptional: true,
    load({ loader, currentUserId }) {
      return loader.observeGetPendingSentMessages({ currentUserId }, { fetchStrategy: "cache" });
    },
    map: (records) => records.map((record) => record.id),
  });
}
