import { createContext, MutableRefObject, useContext } from "react";
import { BehaviorSubject, Observable } from "rxjs";
import { IListScrollboxContext } from "./ListScrollbox";

export type EntryId = string | number;

export interface IListEntry<EntryData> {
  readonly id: EntryId;
  readonly data: EntryData;
  readonly disabled: boolean;
  readonly node: HTMLElement;
  readonly scrollboxContext: IListScrollboxContext;
}

export interface IListContext<EntryData> {
  mode: "focus" | "active-descendent";
  autoFocus: boolean;
  maintainInitialFocusForTimeMs: number | undefined;
  entries: ReadonlyArray<IListEntry<EntryData>>;
  entries$: Observable<ReadonlyArray<IListEntry<EntryData>>>;
  selectedEntryIds: ReadonlySet<EntryId>;
  selectedEntryIds$: Observable<ReadonlySet<EntryId>>;
  /**
   * ### In "focus" mode,
   * This observable tracks which entry in the list is currently focusable.
   * If there are no entries or if all entries are disabled, returns `null`.
   *
   * The current "focusable" entry is marked with `tabindex="0"` in the DOM
   * while all other entries have `tabindex="-1"`. This allows the user to
   * tab away from this list and then tab back and have the correct element
   * focused.
   *
   * ### In "active-descendent" mode
   * This observable tracks which entry is active.
   */
  focusableOrActiveEntryId$: BehaviorSubject<EntryId | null>;
  /**
   * ### In "focus" mode
   * This observable tracks which entry in the list is currently focused, if any.
   * This observable can also be used to move focus to the provided `EntryId`.
   *
   * _Note:_
   * _Doesn't support passing `null` in order to blur a currently focused entry._
   *
   * ### In "active-descendent" mode
   * This observable always returns `null`.
   */
  focusedEntryId$: BehaviorSubject<EntryId | null>;
  initiallyFocusableOrActiveEntryId: EntryId | null;
  focusEntryOnMouseOver: boolean;
  /**
   * If called without an ID, will focus the current focusableEntry.
   * If called with an ID, will focus the entry with that ID.
   */
  focus(id?: EntryId): void;
  /** Adds the specified entryId to the selectedEntryIds set. */
  select(id: EntryId): void;
  /** Removes the specified entryId to the selectedEntryIds set. */
  deselect(id: EntryId): void;
  /**
   * Tells the List component to recalculate the order of List#entries.
   * Calls to this method are batched and passed to the list component
   * on the next tick.
   */
  sortEntries(): void;
  mergeEntry(args: IListEntry<EntryData>): void;
  removeEntry(id: EntryId): void;
  /**
   * Called when a user clicks on a list entry or when they focus
   * a list entry and press the "Enter" key.
   */
  onEntryAction: MutableRefObject<((args: IListOnEntryActionEvent<EntryData>) => void) | undefined>;
  onEntryFocusIn: MutableRefObject<((args: IListOnEntryFocusEvent<EntryData>) => void) | undefined>;
  onEntryFocusLeave: MutableRefObject<((args: IListOnEntryFocusEvent<EntryData>) => void) | undefined>;
}

export interface IListOnEntryActionEvent<EntryData> {
  id: EntryId;
  entry: EntryData;
  event: KeyboardEvent | MouseEvent;
}

export interface IListOnEntrySelectionChangeEvent<EntryData> {
  id: EntryId;
  entry: EntryData;
  isSelected: boolean;
}

export interface IListOnEntryFocusEvent<EntryData> {
  id: EntryId;
  entry: EntryData;
  event: FocusEvent;
}

export const ListContext = createContext<IListContext<any> | null>(null);

export function useListContext<EntryData>() {
  const context = useContext<IListContext<EntryData> | null>(ListContext);

  if (!context) {
    throw new Error("Must provide ListContext");
  }

  return context;
}
