import { ComponentType, useRef } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { showNotImplementedToastMsg } from "~/environment/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { useDistinctSentMessageIdsByThread } from "~/hooks/useDistinctSentMessageIdsByThread";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { MessageEntry, onMessageSelectNavigateToThread } from "~/components/content-list/MessageEntry";
import { EndOfListMsg } from "~/components/EndOfListMsg";
import { useListPaging } from "~/hooks/useListPaging";

export const SentView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const [messageIds, { fetchMore, nextId, isLoading }] = useDistinctSentMessageIdsByThread();

  const noMoreMessages = !nextId && !isLoading;

  useListPaging({
    fetchMore,
    isLoading,
    isListEnd: noMoreMessages,
    pagingScrollboxRef: scrollboxRef,
  });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        ESCAPE_TO_INBOX_COMMAND,
        markDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads Done from the 
              Sent page.
            `);
          },
        }),
        markNotDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads not Done from the 
              Sent page.
            `);
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Sent page.
            `);
          },
        }),
        removeThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Sent page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star threads from the
              Sent page.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't unstar threads from the
              Sent page.
            `);
          },
        }),
      ];

      return commands;
    },
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <Helmet>
        <title>Sent | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl">Sent</h1>
      </MainLayout.Header>

      <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
        {messageIds.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList onEntryAction={onMessageSelectNavigateToThread} className="mb-20" autoFocus>
            {messageIds.map((messageId, index) => (
              <MessageEntry
                key={messageId}
                messageId={messageId}
                relativeOrder={index}
                showRecipientNames
                showScheduledToBeSentIconFor="threadId"
              />
            ))}

            <EndOfListMsg isEnd={noMoreMessages} />
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};
