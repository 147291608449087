import type { ReactRenderer } from "@tiptap/react";
import type { SuggestionProps } from "@tiptap/suggestion";

/**
 * This is the type returned by `new ReactRenderer(MySuggestionEntryComponent)`
 */
export type SuggestionEntryComponent<T> = ReactRenderer<
  {
    onKeyDown(o: { event: KeyboardEvent }): boolean | undefined;
  },
  SuggestionProps<T> &
    React.RefAttributes<{
      onKeyDown(o: { event: KeyboardEvent }): boolean | undefined;
    }>
>;

/**
 * Returns true if there is an opened mention list suggestion box.
 * Useful for determining how the RichTextEditor should handle
 * key presses such as "Escape"
 */
export function isSuggestionDialogOpened() {
  return openedSuggestionCount > 0;
}

export function onSuggestionDialogOpen() {
  openedSuggestionCount++;
}

export function onSuggestionDialogClose() {
  openedSuggestionCount--;
}

let openedSuggestionCount = 0;
