import { Simplify } from "type-fest";
import { ClientEnvironment } from "./ClientEnvironment";
import { datadogLogs } from "@datadog/browser-logs";
import { MessagePortService, RecipientPort, Request, SerializedError } from "./MessagePortService";
import { getContextFromLogEvent, getLogLevel, sendLogToConsole, sendLogToDatadog } from "./createClientLogger";
import { NEVER, debounceTime, distinctUntilChanged, filter, from, map, retry, switchMap, take, takeUntil } from "rxjs";
import { DeferredPromise, promiseRetryOnError, wait } from "libs/promise-utils";
import { globalState } from "~/state/global.state";
import { isSingleTabClient } from "~/utils/dom-helpers";

declare module "./MessagePortService" {
  interface MessageTypeMap {
    SHARED_WORKER_CONNECT: {
      request: Message<
        "SHARED_WORKER_CONNECT",
        {
          portId: string;
          currentUserId: string | null;
          ownerOrganizationId: string | null;
          datadogSessionId: string | null;
          logLevel: string | undefined;
        }
      >;
      response: Message<"SHARED_WORKER_CONNECT", { portId: string }>;
    };
    SHARED_WORKER_GET_PERSISTED_DB_PORT: {
      request: Message<"SHARED_WORKER_GET_PERSISTED_DB_PORT", null>;
      response: Message<"SHARED_WORKER_GET_PERSISTED_DB_PORT", { port: MessagePort; portId: string }>;
    };
  }
}

/**
 * The `ServiceWorkerService` class is responsible for managing the service worker for the app.
 */

export type SharedWorkerServiceApi = Simplify<SharedWorkerService>;
export type SharedWorkerServiceEnv = Pick<
  ClientEnvironment,
  | "logger"
  | "clientId"
  | "auth"
  // Note that it's important that we attempt to acquire a lock
  // on leadership before initializing the shared worker. This way the SharedWorkerLeadershipService can
  // look up who holds the leadership lock during initialization. A good way to enforce this order is to
  // make this service depend on the leader service.
  | "leader"
>;

export class SharedWorkerService {
  static async create(
    env: SharedWorkerServiceEnv,
    props: {
      onGetPersistedDbPortForSharedWorker: (request: Request<"PERSISTED_DB_PORT">) => Promise<void>;
    },
  ) {
    const service = new SharedWorkerService(env, props);
    await service.createWorker();
    return service;
  }

  connection: MessagePortService;
  isActivePromise = new DeferredPromise<void>();

  private worker: SharedWorker | Worker | null = null;

  constructor(
    private env: SharedWorkerServiceEnv,
    private props: {
      onGetPersistedDbPortForSharedWorker: (request: Request<"PERSISTED_DB_PORT">) => Promise<void>;
    },
  ) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "SharedWorkerService" }),
    };

    this.connection = new MessagePortService({
      serviceName: "SharedWorkerService",
      senderId: env.clientId,
      logger: env.logger,
      defaultRetryOnDisconnect: true,
    });

    this.connection.activated$.subscribe(() => {
      this.env.logger.notice(`Connection activated`);
      this.isActivePromise.resolve();
    });

    // Listen to logs from the shared worker and send them to datadog. Note that
    // we're subscribing to the messages stream rather than the more traditional requests
    // stream. This is because the requests stream buffers messages until the connection
    // has been activated. If a log error occurred which prevented the connection from
    // being activated, we wouldn't see the log message.
    this.connection.messages$
      .pipe(
        filter((message): message is Request<"LOG"> => !message.replyTo && message.type === "LOG"),
        takeUntil(this.connection.activated$),
      )
      .subscribe((request) => this.onLog(request));

    this.connection.requests$.subscribe((request) => {
      this.env.logger.debug({ request }, "request received");

      try {
        this.handleRequest(request);
      } catch (error) {
        this.connection.sendError(
          request,
          new Error(`[SharedWorkerService] uncaught handleRequest error`, { cause: error }),
        );
      }
    });

    from(this.isActivePromise.promise)
      .pipe(
        switchMap(() => this.env.leader.leaderClientId$),
        // By using switchMap here, we will discard an in-flight request for the persisted db port
        // if the leader changes before the request completes.
        switchMap((leaderId) => {
          if (!leaderId) return NEVER;

          // If we get disconnected from the persisted database for whatever reason, we need to reconnect.
          return this.connection.recipients$.pipe(
            debounceTime(10),
            map((recipients) => recipients.has(MessagePortService.uniqueContexts.PERSISTED_DB_WORKER)),
            distinctUntilChanged(),
            filter((hasConnection) => !hasConnection),
            switchMap(() => {
              this.env.logger.notice({ leaderId }, "Connecting to persisted db...");

              const { port1: ourPort, port2: theirPort } = new MessageChannel();

              return from(
                this.connection.connect({
                  type: "initiate",
                  recipientId: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
                  port: ourPort,
                  message: MessagePortService.buildRequest("PERSISTED_DB_PORT", {
                    from: this.env.clientId,
                    to: MessagePortService.uniqueContexts.SHARED_WORKER,
                    data: { portId: "will be set by connect()", port: theirPort },
                    transfer: [theirPort],
                  }),
                }),
              ).pipe(retry({ delay: 50 }));
            }),
          );
        }),
      )
      .subscribe();
  }

  private handleRequest(request: Request) {
    switch (request.type) {
      case "LOG": {
        return this.onLog(request);
      }
      case "PERSISTED_DB_PORT": {
        return this.props.onGetPersistedDbPortForSharedWorker(request);
      }
      default: {
        this.connection.sendError(request, new Error(`[SharedWorkerService] Unknown request type: ${request.type}`));
      }
    }
  }

  onAuthChange() {
    return this.connection.sendRequest("AUTH_CHANGE", {
      to: MessagePortService.uniqueContexts.SHARED_WORKER,
      data: {
        currentUserId: this.env.auth.getCurrentUserId(),
        ownerOrganizationId: this.env.auth.getCurrentUserOwnerOrganizationId(),
      },
    });
  }

  private onLog(request: Request<"LOG">) {
    const { logEvent } = request.data;
    const context = getContextFromLogEvent(logEvent);
    if (context.error) context.error = new SerializedError(context.error);
    sendLogToDatadog(logEvent.level.value, context);
  }

  private async createWorker() {
    try {
      await promiseRetryOnError(
        {
          waitMs: 10,
          exponentialBackoff: true,
          maxWaitMs: 1000,
          attempts: 3,
        },
        async ({ attempt, lastError }) => {
          if (lastError) {
            this.env.logger.error(
              { error: lastError, attempt },
              `[createWorker] error connecting to worker. Retrying...`,
            );
          }

          if (this.worker instanceof Worker) {
            this.env.logger.warn("[createWorker] terminating previous worker.");
            this.worker.terminate();
          }

          let port: RecipientPort;

          if (isSingleTabClient()) {
            this.worker = new Worker(new URL("./shared-worker", import.meta.url), {
              name: `CommsFakeSharedWorker`,
              type: "module",
            });

            port = this.worker;
          } else {
            this.worker = new SharedWorker(new URL("./shared-worker", import.meta.url), {
              name: `CommsSharedWorker`,
              type: "module",
            });

            this.worker.onerror = (event) => {
              this.env.logger.error({ error: event.error }, `[createWorker] shared worker error`);
            };

            port = this.worker.port;
          }

          await this.connection.connect({
            type: "initiate",
            recipientId: MessagePortService.uniqueContexts.SHARED_WORKER,
            message: MessagePortService.buildRequest("SHARED_WORKER_CONNECT", {
              from: this.connection.senderId,
              to: MessagePortService.uniqueContexts.SHARED_WORKER,
              data: {
                portId: "set by connect() method",
                currentUserId: this.env.auth.getCurrentUserId(),
                ownerOrganizationId: this.env.auth.getCurrentUserOwnerOrganizationId(),
                datadogSessionId: datadogLogs.getInternalContext()?.session_id ?? null,
                logLevel: getLogLevel(),
              },
            }),
            port,
            // Previously we tried a 5 second timeout, but it was too short for Safari and would
            // trigger while the worker was still initializing.
            timeoutSignal: AbortSignal.timeout(30_000),
          });
        },
      );
    } catch (error) {
      globalState.getState().setError({
        message: `
          Comms has run into an unrecoverable error. If you run into this repeatedly, you may need to 
          quit and then reopen the app.
        `,
        code: "01",
      });

      this.env.logger.fatal(
        { error, isSingleTabClient: isSingleTabClient() },
        `[createWorker] failed to create shared worker`,
      );

      throw error;
    }

    // Locally in development we've seen Safari terminate the SharedWorker unexpectedly and seemingly
    // without reason. Because of this, we need to handle the scenerio where the SharedWorker is disconnected.
    this.connection.recipients$
      .pipe(
        filter((recipients) => !recipients.has(MessagePortService.uniqueContexts.SHARED_WORKER)),
        take(1),
      )
      .subscribe(() => {
        this.env.logger.warn(`[createWorker] shared worker disconnected after creation. Reconnecting...`);
        this.createWorker();
      });
  }

  async activate() {
    this.connection.activate();
  }
}

/* -----------------------------------------------------------------------------------------------*/
