import { observeGroupsUserHasAccessTo } from "~/observables/observeGroupsUserHasAccessTo";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";
import { useInitialQueryLimit } from "./useInitialQueryLimit";

export type UseGroupsUserHasAccessToResult = UseRecordLoaderResult<string[]>;

export function useGroupsUserHasAccessTo(props: {
  userId: string | null | undefined;
  ownerOrganizationId: string | null | undefined;
  hideArchivedGroups?: boolean;
  orderBy?: "name";
}): UseGroupsUserHasAccessToResult {
  const environment = useClientEnvironment();
  const initialLimit = useInitialQueryLimit(50);

  return useRecordLoader({
    name: "useGroupsUserHasAccessTo",
    load({ loader, limit, deps: [userId, ownerOrganizationId, orderBy, hideArchivedGroups] }) {
      if (!userId) {
        return loader.createObserveQueryResult<"tag">();
      }

      return observeGroupsUserHasAccessTo(environment, {
        userId: userId,
        ownerOrganizationId: ownerOrganizationId || undefined,
        hideArchivedGroups,
        orderBy: orderBy || undefined,
        limit,
      });
    },
    initialLimit,
    limitStep: Math.ceil(initialLimit / 2),
    deps: [props.userId, props.ownerOrganizationId, props.orderBy, props.hideArchivedGroups],
    map: (records) => records.map((r) => r.id),
  });
}
