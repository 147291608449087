import { UserSettingsDoc } from "libs/schema";
import { SetRequired } from "type-fest";

export type NormalizedUserSettingsDoc = SetRequired<
  UserSettingsDoc,
  | "inbox_layout"
  | "enable_nav_back_on_thread_done"
  | "enable_focus_mode"
  | "focus_mode_exceptions"
  | "enable_scheduled_delivery"
  | "scheduled_days"
  | "scheduled_times"
  | "seconds_to_wait_to_disable_scheduled_delivery"
  | "linked_gmail_email_account"
  | "seconds_for_undoing_sent_message"
  | "show_archived_groups"
  | "dont_show_reminders_in_inbox_unless_mentioned"
  | "enable_google_calendar"
>;

export function defaultUserSettings(): NormalizedUserSettingsDoc {
  return {
    inbox_layout: "consolidated-inbox",
    enable_nav_back_on_thread_done: false,
    enable_focus_mode: false,
    focus_mode_exceptions: [100],
    enable_scheduled_delivery: false,
    scheduled_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    scheduled_times: ["10:00", "15:00"],
    seconds_to_wait_to_disable_scheduled_delivery: 60,
    linked_gmail_email_account: false,
    seconds_for_undoing_sent_message: 10,
    show_archived_groups: false,
    dont_show_reminders_in_inbox_unless_mentioned: false,
    enable_google_calendar: false,
  } satisfies UserSettingsDoc;
}
