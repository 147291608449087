import { Observable, map } from "rxjs";
import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";
import { TagSubscriptionPreference, getMapRecord, getPointer } from "libs/schema";
import { ClientRecordLoaderObserveQueryResultMeta } from "~/environment/RecordLoader";
import { useInitialQueryLimit } from "./useInitialQueryLimit";

export type TagSubscriberDoc = {
  userId: string;
  subscriptionPreference: TagSubscriptionPreference;
};

export type UseTagSubscriberUserIdsResult = UseRecordLoaderResult<TagSubscriberDoc[]>;

export function useTagSubscribers(props: { tagId: string | null | undefined }): UseTagSubscriberUserIdsResult {
  const initialLimit = useInitialQueryLimit(50);

  return useRecordLoader({
    name: "useTagSubscribers",
    load({ loader, limit, deps: [tagId] }) {
      if (!tagId) {
        return loader.createObserveQueryResult<"user_profile">() as unknown as Observable<
          [TagSubscriberDoc[], ClientRecordLoaderObserveQueryResultMeta]
        >;
      }

      return loader
        .observeGetTagSubscriberUsers({
          tagId: tagId,
          limit,
        })
        .pipe(
          map(([records, meta]) => {
            const data = records.map((record) => {
              const subscription = getMapRecord(
                meta.recordMap,
                getPointer("tag_subscription", {
                  tag_id: tagId,
                  user_id: record.id,
                }),
              );

              return {
                userId: record.id,
                subscriptionPreference: subscription!.preference,
              };
            });

            return [data, meta];
          }),
        );
    },
    deps: [props.tagId],
    initialLimit,
    limitStep: Math.ceil(initialLimit / 2),
  });
}
