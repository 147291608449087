import { useCallback, useState } from "react";

const tips = [
  {
    title: "Negative search",
    description: (
      <>
        Use minus (<code>-</code>) to exclude words from search. For example, to return messages that contain the word{" "}
        <code>master</code> but not <code>plan</code>:
      </>
    ),
    example: "master -plan",
  },
  {
    title: "Exact phrase",
    description: (
      <>
        Terms in quotes (<code>"</code>) will only return messages containing those terms in the order they were given.
        For example, to return all messages that contain "master" and "plan" in that order:
      </>
    ),

    // eslint-disable-next-line quotes
    example: '"master plan"',
  },
  {
    title: "Filters",
    description: (
      <>Filters allow you to narrow your search. Click on any of the filters above to add them to your search.</>
    ),
    example: "",
  },
  {
    title: "Date range",
    description: (
      <>
        Use <code>after:</code> and <code>before:</code> filters to narrow your search. For example, to search for all
        the messages sent by Sally Smith on April 25, 2024:
      </>
    ),
    example: "from:@Sally Smith after:2024-04-25 before:2024-04-26",
  },
  {
    title: "Not Filter",
    description: (
      <>
        Use the <code>not</code> filter to negate other filters. For example, to search for all messages that are not
        replies and not from Sally Smith:
      </>
    ),
    example: "not( is:reply ) not( from:@Sally Smith )",
  },
];

export function useTips() {
  const [index, setIndex] = useState(Math.floor(Math.random() * tips.length));

  const nextTip = useCallback(() => {
    setIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % tips.length;
      return newIndex;
    });
  }, []);

  return { tip: tips[index], nextTip };
}
