import { FirebaseError, FirebaseOptions, initializeApp } from "firebase/app";
import {
  Auth,
  GoogleAuthProvider,
  getAuth,
  getRedirectResult,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import { ClientEnvironment } from "./environment/ClientEnvironment";
import { config } from "./environment/config";

const firebaseConfig: FirebaseOptions = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  appId: config.firebase.appId,
};

const lazilyGetAuth = (() => {
  let auth: Auth | undefined;

  return () => {
    if (isEmulatingAuth()) {
      throw new Error("lazilyGetAuth: cannot use Firebase auth when emulating auth");
    }

    if (!auth) {
      const app = initializeApp(firebaseConfig);
      auth = getAuth(app);
    }

    return auth;
  };
})();

export async function firebaseSignOut() {
  const auth = lazilyGetAuth();
  await signOut(auth);
}

// We're not currently using this function because, despite efforts, Safari continues to block
// the popup window. So we're using the redirect method instead.
// - - -
// Note that we're intentionally not using an async function here because `signInWithPopup()`
// needs to be called synchronously after a "click" event to avoid being blocked by the browser.
export function firebaseSignInWithGoogle_ViaPopup(environment: Pick<ClientEnvironment, "logger">) {
  const auth = lazilyGetAuth();

  // Set language to the default browser preference
  auth.useDeviceLanguage();

  return signInWithPopup(auth, new GoogleAuthProvider())
    .then((result) => {
      return GoogleAuthProvider.credentialFromResult(result);
    })
    .catch((error) => {
      environment.logger.error({ error }, "firebaseSignInWithPopup: Error signing in with Google");

      return GoogleAuthProvider.credentialFromError(error);
    });
}

// Note that we're intentionally not using an async function here because `signInWithPopup()`
// needs to be called synchronously after a "click" event to avoid being blocked by the browser.
export async function firebaseSignInWithGoogle_ViaRedirect() {
  const auth = lazilyGetAuth();

  // Set language to the default browser preference
  auth.useDeviceLanguage();

  const provider = new GoogleAuthProvider();

  // We want the user's email address to be available in the ID token

  // Request access to the user's email via Google Signin API
  // https://developers.google.com/identity/protocols/oauth2/scopes#google-sign-in
  provider.addScope("email");
  // Request access to the user's email via Google OAuth API v2
  // https://developers.google.com/identity/protocols/oauth2/scopes#oauth2
  provider.addScope("https://www.googleapis.com/auth/userinfo.email");

  // The await here is necessary to ensure that the google redirect is successful
  return await signInWithRedirect(auth, provider);
}

export async function firebaseSignInWithGoogle_GetRedirectResult() {
  if (isEmulatingAuth()) return;

  const auth = lazilyGetAuth();

  try {
    const result = await getRedirectResult(auth);
    console.log("[firebase] getRedirectResult", result);

    if (!result) return;

    return GoogleAuthProvider.credentialFromResult(result);
  } catch (error) {
    if (error instanceof FirebaseError) {
      return GoogleAuthProvider.credentialFromError(error);
    }

    throw error;
  }
}

export function isEmulatingAuth() {
  return !config.firebase.apiKey;
}
