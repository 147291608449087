import { UseBoundStore, StoreApi } from "zustand";
import { Observable, distinctUntilChanged } from "rxjs";

export function observeZustandState<T, S = T>(
  store: UseBoundStore<StoreApi<T>>,
  selector = ((state: T) => state) as unknown as (state: T) => S,
  isEqual: (a: S, b: S) => boolean = Object.is,
): Observable<S> {
  return new Observable<S>((subscriber) => {
    // Subscribe to store changes
    const unsubscribe = store.subscribe((state) => {
      subscriber.next(selector(state as T));
    });

    // Immediately emit the current state
    subscriber.next(selector(store.getState()));

    return unsubscribe;
  }).pipe(distinctUntilChanged(isEqual));
}
