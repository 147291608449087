import { wait } from "libs/promise-utils";
import { ClientEnvironment } from "./ClientEnvironment";
import { MessagePortService } from "./MessagePortService";
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom } from "rxjs";
import { isSingleTabClient } from "~/utils/dom-helpers";
import { getClientId } from "~/utils/getClientId";
import { isNonNullable } from "libs/predicates";

export class LeadershipService {
  private _leaderClientId$ = new BehaviorSubject<string | null>(null);

  leaderClientId$ = this._leaderClientId$.pipe(distinctUntilChanged());

  get leaderClientId() {
    return this._leaderClientId$.getValue();
  }

  isLeader = false;

  isLeaderPromise: Promise<void>;

  isReady: Promise<unknown>;

  constructor(protected env: Pick<ClientEnvironment, "logger">) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "LeadershipService" }),
    };

    this.isLeaderPromise = MessagePortService.acquireContextLock(MessagePortService.uniqueContexts.LEADER, {
      // If we're running as an installed mobile version of Comms, then we know that
      // only a single tab is running and that tab is the leader. In theory we shouldn't
      // need to do this, but in the past we've found that Safari has bugs in the web
      // locks API that can prevent locks from being released.
      steal: isSingleTabClient(),
    });

    Promise.all([getClientId(), this.isLeaderPromise]).then(([clientId]) => {
      this.isLeader = true;
      this._leaderClientId$.next(clientId);
      // If promotion happens during env creation than the datadog logger will not be able to
      // automatically add the client ID to this log. So we manually add it here.
      this.env.logger.notice({ clientId }, `promoted to leader`);
    });

    this.isReady = firstValueFrom(this.leaderClientId$.pipe(filter(isNonNullable)));

    this.watchForLeaderChanges();
  }

  private async watchForLeaderChanges() {
    while (true) {
      const leaderClientId = await getLeaderClientId();
      this._leaderClientId$.next(leaderClientId);
      await navigator.locks.request(leaderClientId, { mode: "shared" }, () => {
        if (this.leaderClientId !== leaderClientId) return;
        this._leaderClientId$.next(null);
      });
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function getLeaderClientId() {
  while (true) {
    const state = await navigator.locks.query();
    const leadershipLock = state.held?.find((lock) => lock.name === MessagePortService.uniqueContexts.LEADER);
    // The leadership lock can only be undefined during a leadership change
    if (leadershipLock?.clientId) return leadershipLock.clientId;
    await wait(5);
  }
}
/* -----------------------------------------------------------------------------------------------*/
