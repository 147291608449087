import { useInboxSection } from "./useInboxSection";

/** Defaults to true if the inboxSection record hasn't loaded */
export function useIsDefaultInboxSection(inboxSectionId: string | null | undefined) {
  const [inboxSection, { isLoading }] = useInboxSection(inboxSectionId);

  return {
    isDefaultInboxSection: inboxSection ? inboxSection.data.order === 0 : true,
    isLoading,
  };
}
