// These return 1 if a is greater than b, -1 if a is less than b, and 0 if they
// are equal. 1 means that the first value will be positioned after the second
// whereas -1 means that the first value will be positioned before the second.

/**
 * Sorts numbers. Honestly just doing `.sort((a: number, b: number) => a - b)`
 * accomplishes the same thing.
 */
export function numberComparer(a: number, b: number) {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
}

export function dateComparer(a: Date, b: Date) {
  return numberComparer(a.valueOf(), b.valueOf());
}

/**
 * Sorts strings while ignoring casing but falls back on
 * casing to break ties.
 */
export function stringComparer(a: string, b: string, caseSensitive?: boolean) {
  const aa = caseSensitive ? a : a.toLowerCase();
  const bb = caseSensitive ? a : b.toLowerCase();

  if (aa > bb) return 1;
  if (aa < bb) return -1;
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
}

/**
 * Sorts strings while ignoring casing but falls back on
 * casing to break ties.
 */
export function dayOfWeekComparer(a: string, b: string) {
  const aa = DAYS_OF_WEEK.indexOf(a.toLowerCase());
  const bb = DAYS_OF_WEEK.indexOf(b.toLowerCase());

  return numberComparer(aa, bb);
}

export type TDayOfWeek = (typeof DAYS_OF_WEEK)[number];

const DAYS_OF_WEEK: readonly string[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
