import { fromEvent, shareReplay, take, tap } from "rxjs";

// The beforeInstallPrompt only fires once so we subscribe to it and then store the result in this observable.
// See
// https://github.com/khmyznikov/pwa-install?tab=readme-ov-file#async-mode
export const beforeInstallPrompt$ = fromEvent<BeforeInstallPromptEvent>(window, "beforeinstallprompt").pipe(
  tap((e) => {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }),
  shareReplay(1),
  take(1),
);
