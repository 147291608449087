import { css } from "@emotion/css";
import { BehaviorSubject, distinctUntilChanged, fromEvent, merge } from "rxjs";
import { ClientEnvironment } from "./ClientEnvironment";

export type InputMode = "keyboard" | "mouse";

export class InputModeService {
  private _appInputMode$ = new BehaviorSubject<InputMode>("mouse");

  appInputMode$ = this._appInputMode$.pipe(distinctUntilChanged());

  get appInputMode() {
    return this._appInputMode$.getValue();
  }

  constructor(private env: Pick<ClientEnvironment, "logger">) {
    this.env = {
      ...this.env,
      logger: this.env.logger.child({ service: "InputModeService" }),
    };
  }

  init() {
    const removeCursorCSS = css`
      cursor: none !important;

      & * {
        cursor: none !important;
      }
    `;

    this.appInputMode$.subscribe((mode) => {
      this.env.logger.debug(`INPUT MODE ${mode}`);

      if (mode === "keyboard") {
        document.body.classList.add(removeCursorCSS);
      } else {
        document.body.classList.remove(removeCursorCSS);
      }
    });

    const eventListenerOptions = {
      capture: true,
    };

    // We're subscribing via "capture" here because, even if the event is suppressed before it reaches the body,
    // we will want to update the input mode.
    merge(
      fromEvent(document.body, "click", eventListenerOptions),
      fromEvent(document.body, "mousemove", eventListenerOptions),
    ).subscribe(() => this._appInputMode$.next("mouse"));

    fromEvent(document.body, "keydown", eventListenerOptions).subscribe(() => this._appInputMode$.next("keyboard"));
  }
}
