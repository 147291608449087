import { RecordValue, RecordTable } from "./schema";
import { Decoder } from "ts-decoders";
import * as d from "ts-decoders/decoders";

/* -------------------------------------------------------------------------------------------------
 * getRecordDecoderBases
 * -------------------------------------------------------------------------------------------------
 */

/**
 * Note, the decoders provided to props should all be non-nullable.
 */
export function getRecordDecoderBases<
  TextT extends Decoder<any>,
  BooleanT extends Decoder<any>,
  IntegerT extends Decoder<any>,
  JsonT extends Decoder<any>,
  UuidT extends Decoder<any>,
  DateTimeT extends Decoder<any>,
>(props: { TextD: TextT; BooleanD: BooleanT; IntegerD: IntegerT; JsonD: JsonT; UuidD: UuidT; DateTimeD: DateTimeT }) {
  // Note that these decoders are all non-nullable
  const { TextD, BooleanD, IntegerD, JsonD, UuidD, DateTimeD } = props;

  const NullableTextD = d.nullableD(TextD);
  const NullableBooleanD = d.nullableD(BooleanD);
  const NullableIntegerD = d.nullableD(IntegerD);
  const NullableJsonD = d.nullableD(JsonD);
  const NullableUuidD = d.nullableD(UuidD);
  const NullableDateTimeD = d.nullableD(DateTimeD);

  return {
    auth_token: {
      id: UuidD,
      user_id: UuidD,
      expires_at: DateTimeD,
      is_api_key: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    draft: {
      id: UuidD,
      user_id: UuidD,
      thread_id: UuidD,
      type: TextD,
      new_thread_subject: NullableTextD,
      new_thread_visibility: NullableTextD,
      branched_from_thread_id: NullableUuidD,
      branched_from_message_id: NullableUuidD,
      is_reply: BooleanD,
      is_edit: BooleanD,
      body_html: TextD,
      to: JsonD,
      attachments: JsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    draft_edit: {
      id: UuidD,
      user_id: UuidD,
      thread_id: UuidD,
      body_html: TextD,
      to: JsonD,
      attachments: JsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    inbox_entry: {
      id: UuidD,
      type: TextD,
      user_id: UuidD,
      inbox_section_id: UuidD,
      thread_id: UuidD,
      draft_id: NullableUuidD,
      draft_is_reply: NullableBooleanD,
      notification_id: NullableUuidD,
      inbox_subsection_id: NullableUuidD,
      inbox_subsection_order: NullableIntegerD,
      order: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    user_lesson: {
      id: UuidD,
      user_id: UuidD,
      lesson_id: UuidD,
      lesson_version: IntegerD,
      is_completed: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    message_reactions: {
      id: UuidD,
      thread_id: UuidD,
      reactions: JsonD,
      message_sent_at: DateTimeD,
      message_sender_user_id: NullableUuidD,
      message_timeline_order: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    message: {
      id: UuidD,
      thread_id: UuidD,
      type: TextD,
      is_reply: BooleanD,
      to: JsonD,
      subject: TextD,
      body_text: TextD,
      body_html: TextD,
      attachments: JsonD,
      sent_at: DateTimeD,
      scheduled_to_be_sent_at: DateTimeD,
      timeline_order: TextD,
      sender_user_id: NullableUuidD,
      was_edited: BooleanD,
      last_edited_at: NullableDateTimeD,
      email_message_id: NullableTextD,
      email_sender: NullableTextD,
      is_delivered: BooleanD,
      delivered_at: NullableDateTimeD,
      data: NullableJsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    notification: {
      id: UuidD,
      user_id: UuidD,
      thread_id: UuidD,
      thread_type: TextD,
      message_id: UuidD,
      tag_ids: JsonD,
      is_done: BooleanD,
      done_at: NullableDateTimeD,
      done_last_modified_by: TextD,
      oldest_message_not_marked_done_message_id: NullableUuidD,
      oldest_message_not_marked_done_sent_at: NullableDateTimeD,
      sent_at: DateTimeD,
      priority: IntegerD,
      has_reminder: BooleanD,
      remind_at: NullableDateTimeD,
      is_starred: BooleanD,
      starred_at: NullableDateTimeD,
      is_delivered: BooleanD,
      delivered_at: NullableDateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    organization_profile: {
      id: UuidD,
      name: TextD,
      name_short: TextD,
      photo_url: NullableTextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    organization_user_invitation: {
      id: UuidD,
      organization_id: UuidD,
      email_address: TextD,
      creator_user_id: UuidD,
      expires_at: DateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    organization_user_member: {
      id: UuidD,
      organization_id: UuidD,
      user_id: UuidD,
      creator_user_id: UuidD,
      owner_organization_id: UuidD,
      is_admin: BooleanD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    organization_controlled_domain: {
      id: UuidD,
      domain: TextD,
      organization_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    organization: {
      id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
      premium: BooleanD,
      stripe_customer_id: NullableTextD,
    },
    tag_metadata: {
      id: UuidD,
      data: JsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    tag_folder_member: {
      id: UuidD,
      tag_id: UuidD,
      folder_id: UuidD,
      creator_user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    tag_group_member: {
      id: UuidD,
      tag_id: UuidD,
      group_id: UuidD,
      creator_user_id: UuidD,
      is_organization_group: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    tag_subscription: {
      id: UuidD,
      tag_id: UuidD,
      user_id: UuidD,
      creator_user_id: UuidD,
      preference: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    tag_user_member: {
      id: UuidD,
      tag_id: UuidD,
      user_id: UuidD,
      creator_user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    tag: {
      id: UuidD,
      type: TextD,
      name: TextD,
      icon: NullableTextD,
      description: NullableTextD,
      data: NullableJsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      archived_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    thread_group_permission: {
      id: UuidD,
      thread_id: UuidD,
      group_id: UuidD,
      start_at: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
      creator_user_id: NullableUuidD,
      thread_sent_at: DateTimeD,
    },
    thread_read_receipt: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      read_to_timeline_id: UuidD,
      read_to_timeline_order: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    thread_seen_receipt: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      seen_to_timeline_id: UuidD,
      seen_to_timeline_order: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    thread_subscription: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      preference: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    thread_tag: {
      id: UuidD,
      thread_id: UuidD,
      tag_id: UuidD,
      creator_user_id: UuidD,
      data: NullableJsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    thread_timeline: {
      id: UuidD,
      entry_id: UuidD,
      thread_id: UuidD,
      type: TextD,
      order: TextD,
      data: NullableJsonD,
      creator_user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    thread_user_participant: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    thread_user_permission: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      start_at: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
      creator_user_id: NullableUuidD,
      thread_sent_at: DateTimeD,
    },
    thread: {
      id: UuidD,
      type: TextD,
      subject: TextD,
      visibility: TextD,
      first_message_sender_user_id: NullableUuidD,
      first_message_id: UuidD,
      first_message_timeline_order: TextD,
      first_message_sent_at: DateTimeD,
      last_message_id: UuidD,
      last_message_sent_at: DateTimeD,
      last_message_timeline_order: TextD,
      is_branch: BooleanD,
      branched_from_thread_id: NullableUuidD,
      branched_from_message_id: NullableUuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    user_contact_info: {
      id: UuidD,
      email_address: TextD,
      phone_number: NullableTextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    user_oauth: {
      id: TextD,
      user_id: UuidD,
      provider: TextD,
      federated_id: TextD,
      email: NullableTextD,
      oauth_id_token: NullableTextD,
      oauth_access_token: NullableTextD,
      firebase_auth_id: TextD,
      firebase_id_token: TextD,
      firebase_id_token_expires_at: DateTimeD,
      firebase_refresh_token: TextD,
      is_linked_to_user: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    user_profile: {
      id: UuidD,
      first_name: TextD,
      middle_name: NullableTextD,
      last_name: TextD,
      name: TextD,
      photo_url: NullableTextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    user_settings: {
      id: UuidD,
      settings: JsonD,
      mention_frequency: JsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    user: {
      id: UuidD,
      firebase_auth_id: TextD,
      email: TextD,
      email_verified: BooleanD,
      email_verified_at: NullableDateTimeD,
      is_disabled: BooleanD,
      disabled_at: NullableDateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
    user_push_notification_subscriptions: {
      id: TextD,
      user_id: UuidD,
      version: IntegerD,
      subscription: JsonD,
      owner_organization_id: UuidD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      server_updated_at: NullableDateTimeD,
      deleted_at: NullableDateTimeD,
      deleted_by_user_id: NullableUuidD,
    },
  } satisfies {
    [Table in RecordTable]: {
      [Prop in keyof RecordValue<Table>]: Decoder<any>;
    };
  };
}
