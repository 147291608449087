import { isEqual } from "libs/predicates";
import { filter, map, Subject, switchMap } from "rxjs";
import { startWith } from "libs/rxjs-operators";
import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
import localForage from "localforage";

export type StorageChange =
  | {
      type: "item";
      /** key with namespacing removed */
      key: string;
      /** key with namespacing applied (i.e. as the key appears in storage) */
      rawKey: string;
      value: unknown;
    }
  | { type: "clear" };

abstract class KVStoreBase {
  protected namespace?: string;
  protected _changes = new Subject<StorageChange>();

  changes = this._changes.asObservable();

  constructor(args: { namespace?: string }) {
    this.namespace = args.namespace ? `comms:${args.namespace}` : `comms`;
  }

  _emitChange(change: StorageChange) {
    this._changes.next(change);
  }

  protected isRawKeyInNamespace(key: string) {
    return this.namespace ? key.startsWith(this.namespace) : true;
  }

  protected getRawKey(key: string) {
    return this.namespace ? `${this.namespace}:${key}` : key;
  }

  protected getKeyFromRawKey(rawKey: string) {
    return this.namespace ? rawKey.replace(`${this.namespace}:`, "") : rawKey;
  }
}

type SyncStorage = {
  getItem(key: string): string | null;
  setItem(key: string, value: string): unknown;
  removeItem(key: string): void;
  clear(): void;
  key(index: number): string | null;
  length: number;
};

/**
 * A wrapper around the browser's Storage interface that allows observing changes to stored data
 * and also serializes/deserializes values to/from JSON.
 */
export class KVStore extends KVStoreBase {
  protected db: SyncStorage;

  constructor(args: { db: SyncStorage; namespace?: string }) {
    super(args);
    this.db = args.db;
  }

  getItem<T = unknown>(key: string): T | undefined {
    const value = this.db.getItem(this.getRawKey(key));
    if (typeof value !== "string") return undefined;
    return JSON.parse(value);
  }

  getItem$<T = unknown>(key: string) {
    return this.changes.pipe(
      filter((c) =>
        c.type === "item" ? c.key === key
        : c.type === "clear" ? true
        : throwUnreachableCaseError(c),
      ),
      startWith(() => null),
      map(() => this.getItem<T>(key)),
    );
  }

  /** @returns true if the value was changed */
  setItem<T>(key: string, value: T) {
    const rawKey = this.getRawKey(key);
    const json = JSON.stringify(value);
    if (isEqual(this.db.getItem(rawKey), json)) return false;
    this.db.setItem(rawKey, json);
    this._emitChange({ type: "item", key, rawKey, value });
    return true;
  }

  /** @returns true if the key previously existed */
  removeItem(key: string): boolean {
    const rawKey = this.getRawKey(key);
    if (this.getItem(key) === undefined) return false;
    this.db.removeItem(rawKey);
    this._emitChange({ type: "item", key, rawKey, value: undefined });
    return true;
  }

  key(index: number): string | null {
    return this.db.key(index);
  }

  length(): number {
    return this.db.length;
  }
}

type AsyncStorage = {
  getItem(key: string): Promise<string | null>;
  setItem(key: string, value: string): Promise<unknown>;
  removeItem(key: string): Promise<void>;
  clear(): Promise<void>;
  key(index: number): Promise<string | null>;
  length(): Promise<number>;
};

export class AsyncKVStore extends KVStoreBase {
  protected db: AsyncStorage;

  constructor(args: { db: AsyncStorage; namespace?: string }) {
    super(args);
    this.db = args.db;
  }

  async getItem<T = unknown>(key: string): Promise<T | undefined> {
    const value = await this.db.getItem(this.getRawKey(key));
    if (typeof value !== "string") return undefined;
    return JSON.parse(value);
  }

  getItem$<T = unknown>(key: string) {
    return this.changes.pipe(
      filter((c) =>
        c.type === "item" ? c.key === key
        : c.type === "clear" ? true
        : throwUnreachableCaseError(c),
      ),
      startWith(() => null),
      switchMap(() => this.getItem<T>(key)),
    );
  }

  /** @returns true if the value was changed */
  async setItem<T>(key: string, value: T) {
    const rawKey = this.getRawKey(key);
    const json = JSON.stringify(value);
    const curr = await this.db.getItem(rawKey);
    if (isEqual(curr, json)) return false;
    await this.db.setItem(rawKey, json);
    this._emitChange({ type: "item", key, rawKey, value });
    return true;
  }

  /** @returns true if the key previously existed */
  async removeItem(key: string): Promise<boolean> {
    const rawKey = this.getRawKey(key);
    const curr = await this.getItem(key);
    if (curr === undefined) return false;
    await this.db.removeItem(rawKey);
    this._emitChange({ type: "item", key, rawKey, value: undefined });
    return true;
  }

  async key(index: number): Promise<string | null> {
    return this.db.key(index);
  }

  async length(): Promise<number> {
    return this.db.length();
  }
}

/**
 * A key-value store that uses `localStorage` as the underlying storage mechanism.
 */
export class LocalStorageKVStore extends KVStore {
  constructor(args: { namespace?: string }) {
    super({ db: localStorage, namespace: args.namespace });

    // only emitted if the change comes from another window/context
    globalThis.addEventListener("storage", (event: StorageEvent) => {
      const wasStorageCleared = event.key === null;

      if (wasStorageCleared) {
        this._emitChange({ type: "clear" });
      } else if (this.isRawKeyInNamespace(event.key)) {
        const key = this.getKeyFromRawKey(event.key);

        this._emitChange({
          type: "item",
          key,
          rawKey: event.key,
          value: this.getItem(key),
        });
      }
    });
  }
}

/**
 * A key-value store that uses `localForage` as the underlying storage mechanism.
 */
export class LocalForageKVStore extends AsyncKVStore {
  private channel: BroadcastChannel;

  constructor(args: { namespace?: string }) {
    super({
      db: localForage.createInstance({ name: args.namespace }),
      namespace: args.namespace,
    });

    this.channel = new BroadcastChannel(`LocalForageKVStore:${args.namespace}`);

    this.channel.onmessage = (e) => {
      const message = e.data as StorageChange;
      this._emitChange(message, true);
    };

    this.changes.subscribe((change: StorageChange & { _fromRemote?: true }) => {
      if (change._fromRemote) return;
      // We close the change via JSON.parse/stringify to remove any proxy values (which aren't serializable)
      this.channel.postMessage(JSON.parse(JSON.stringify(change)));
    });
  }

  clear() {
    return this.db.clear();
  }

  _emitChange(change: StorageChange, fromRemote?: boolean) {
    this._changes.next({ ...change, _fromRemote: fromRemote } as StorageChange & { _fromRemote?: true });
  }
}

/**
 * A key-value store that uses `sessionStorage` as the underlying storage mechanism and
 * which synchronizes state across tabs (which the raw SessionStorage does not do).
 */
export class SharedSessionStorageKVStore extends KVStore {
  protected messageChannel: BroadcastChannel;

  constructor(args: { serviceName: string; namespace?: string }) {
    super({
      db: sessionStorage,
      namespace: args.namespace,
    });

    this.messageChannel = new BroadcastChannel(`SharedSessionStorageKVStore:${args.serviceName}`);

    this.messageChannel.onmessage = (e) => {
      type SessionStorageChange =
        | (StorageChange & { _fromRemote?: true })
        | { type: "getSessionStorage"; _fromRemote?: true }
        | { type: "sessionStorage"; value: string; _fromRemote?: true };

      const change = e.data as SessionStorageChange;

      switch (change.type) {
        case "item": {
          if (change.value === undefined) {
            this.db.removeItem(change.rawKey);
          } else {
            this.db.setItem(change.rawKey, JSON.stringify(change.value));
          }

          this._emitChange(change, true);
          return;
        }
        case "clear": {
          this.db.clear();
          this._emitChange({ type: "clear" }, true);
          return;
        }
        case "getSessionStorage": {
          this.messageChannel.postMessage({
            type: "sessionStorage",
            value: JSON.stringify(sessionStorage),
          });

          return;
        }
        case "sessionStorage": {
          if (sessionStorage.length > 0) return;

          for (const [key, value] of Object.entries<any>(JSON.parse(e.data.value))) {
            if (this.namespace && !key.startsWith(this.namespace)) continue;
            this.db.setItem(key, value);
          }

          return;
        }
        default: {
          throw new UnreachableCaseError(change);
        }
      }
    };

    this.changes.subscribe((change: StorageChange & { _fromRemote?: true }) => {
      if (change._fromRemote) return;
      // We close the change via JSON.parse/stringify to remove any proxy values (which aren't serializable)
      this.messageChannel.postMessage(JSON.parse(JSON.stringify(change)));
    });

    // initialize
    this.messageChannel.postMessage({
      type: "getSessionStorage",
    });
  }

  _emitChange(change: StorageChange, fromRemote?: boolean) {
    this._changes.next({
      ...change,
      _fromRemote: fromRemote,
    } as StorageChange & { _fromRemote?: true });
  }
}
