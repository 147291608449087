import { ComponentType, memo } from "react";
import { isEqual } from "libs/predicates";
import { cx } from "@emotion/css";

export const InfoPanel: ComponentType<{}> = memo((props) => {
  return (
    <aside
      className={cx(
        "InfoPanel h-screen fixed right-0 top-0 z-[50] w-[280px]",
        "bg-white shadow-lg hidden md-w:block overflow-y-auto",
      )}
    >
      {props.children}
    </aside>
  );
}, isEqual);
