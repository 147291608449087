import { ComponentType } from "react";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { IComposeMessageForm } from "~/components/ComposeMessageContext";

export const ComposeInfoPanel: ComponentType<{
  control: IComposeMessageForm;
}> = (props) => {
  return (
    <ThreadLayout.InfoPanel>
      <div className="mx-6 my-4">
        <p className="mt-4">
          <strong>Hint</strong>
        </p>

        <div className="mt-2 prose">
          <p>
            <code>@mention</code> someone to loop them into this conversation. <code>@@request-response</code> someone
            to loop them into the conversation and also request a response from them. Including someone in the message's
            recipient field is the same as <code>@mentioning</code> them in the body.
          </p>
        </div>
      </div>

      {/* <UsersWhoWillBeNotified control={props.control} />

      <UsersWhoWillBeAbleToViewThread control={props.control} /> */}
    </ThreadLayout.InfoPanel>
  );
};

// const UsersWhoWillBeNotified: ComponentType<{
//   control: IComposeMessageForm;
// }> = (props) => {
//   const thread = useControlState(() => {
//     const data = [
//       ...props.control.rawValue.recipients.to,
//       ...props.control.rawValue.recipients.cc,
//       ...props.control.rawValue.recipients.bcc,
//     ].reduce(
//       (store, option) => {
//         switch (option.type) {
//           case "group": {
//             store.permittedGroupIds.add(option.value);
//             return store;
//           }
//           case "user": {
//             store.participatingUserIds.add(option.value);
//             return store;
//           }
//           case "email": {
//             store.participatingUserIds.add(option.value);
//             return store;
//           }
//           default: {
//             throw new UnreachableCaseError(option);
//           }
//         }
//       },
//       {
//         permittedGroupIds: new Set<string>(),
//         participatingUserIds: new Set<string>(),
//       },
//     );

//     props.control.rawValue.body.userMentions.forEach(({ id }) => {
//       data.participatingUserIds.add(id);
//     });

//     props.control.rawValue.body.groupMentions.forEach(({ id }) => {
//       data.permittedGroupIds.add(id);
//     });

//     return {
//       id: props.control.rawValue.messageId,
//       permittedGroupIds: Array.from(data.permittedGroupIds),
//       participatingUserIds: Array.from(data.participatingUserIds),
//     };
//   }, [props.control]);

//   // Note, in production this will generate "and X other users" sometimes.
//   // This is because we've manually removed a few former Levels staff from
//   // Comms. Because we did the removal manually, we left their subscriptions
//   // and just retired their organization membership.

//   return (
//     <UsersWhoWillReceiveThreadNotificationsSection
//       thread={thread}
//       onlyCountTheseSubscriptionPreferences={
//         subscriptionsOfUsersWhoWillBeNotified
//       }
//       dontIncludeCurrentUser
//     >
//       Will be notified
//       <Tooltip
//         side="bottom"
//         content="These users will receive a notification for this new thread."
//       >
//         <span className="ml-1 cursor-help">(?)</span>
//       </Tooltip>
//     </UsersWhoWillReceiveThreadNotificationsSection>
//   );
// };

// // This array will be included as a hook dependency so we want it to be a constant
// const subscriptionsOfUsersWhoWillBeNotified = ["all", "all-new"] as const;

// const UsersWhoWillBeAbleToViewThread: ComponentType<{
//   control: IComposeMessageForm;
// }> = (props) => {
//   const thread = useControlState(() => {
//     const data = [
//       ...props.control.rawValue.recipients.to,
//       ...props.control.rawValue.recipients.cc,
//       ...props.control.rawValue.recipients.bcc,
//     ].reduce(
//       (store, option) => {
//         switch (option.type) {
//           case "group": {
//             store.permittedGroupIds.add(option.value);
//             return store;
//           }
//           case "user": {
//             store.permittedUserIds.add(option.value);
//             return store;
//           }
//           case "email": {
//             store.permittedUserIds.add(option.value);
//             return store;
//           }
//           default: {
//             throw new UnreachableCaseError(option);
//           }
//         }
//       },
//       {
//         permittedGroupIds: new Set<string>(),
//         permittedUserIds: new Set<string>(),
//       },
//     );

//     props.control.rawValue.body.userMentions.forEach(({ id }) => {
//       data.permittedUserIds.add(id);
//     });

//     props.control.rawValue.body.groupMentions.forEach(({ id }) => {
//       data.permittedGroupIds.add(id);
//     });

//     return {
//       permittedGroupIds: Array.from(data.permittedGroupIds),
//       permittedUserIds: Array.from(data.permittedUserIds),
//     };
//   }, [props.control]);

//   return (
//     <UsersWhoCanViewThreadSection thread={thread}>
//       Will be able to view
//       <Tooltip
//         side="bottom"
//         content={`These users will have permission to view this thread.
//           Separately, they may or may not get a notification
//           for this thread.`}
//       >
//         <span className="ml-1 cursor-help">(?)</span>
//       </Tooltip>
//     </UsersWhoCanViewThreadSection>
//   );
// };
