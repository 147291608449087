import { useMemo } from "react";
import { getTimelineEntryElementId } from "~/utils/dom-helpers";

export function useTimelineEntryElementId(props: { id?: string | null; thread_id?: string | null } | null) {
  const entry_id = props?.id;
  const thread_id = props?.thread_id;

  return useMemo(() => {
    if (!entry_id || !thread_id) return null;

    return getTimelineEntryElementId({
      thread_id,
      entry_id,
    });
  }, [entry_id, thread_id]);
}

/* -----------------------------------------------------------------------------------------------*/
