import { startWith } from "libs/rxjs-operators";
import { RefObject, useEffect } from "react";
import { filter, fromEvent, merge, throttleTime } from "rxjs";
import { WINDOW_RESIZE_EVENT$, getMaxScrollTop, getScrollTop } from "~/utils/dom-helpers";
import { useAsRef } from "./useAsRef";

/**
 * This hook aids with implementing paging functionality
 * in other hooks which are returning firestore data. This
 * hook receives a ref for the dom element where scrolling will
 * take place as well as a callback function which will be
 * called when the user scrolls to the bottom of the scrolling
 * element.
 */
export function useListPaging(props: {
  isLoading: boolean;
  fetchMore: () => void;
  isListEnd: boolean;
  /**
   * Loads an initial chunk of threads and then loads
   * more when the user scrolls to the bottom of the
   * element associated with this scrollboxRef.
   */
  pagingScrollboxRef: RefObject<HTMLElement>;
}) {
  const { fetchMore, pagingScrollboxRef, isLoading, isListEnd } = props;

  const fetchMoreRef = useAsRef(fetchMore);
  const isListEndRef = useAsRef(isListEnd);

  // We want to rerun this effect whenever the isLoading changes.
  // This is because it's possible the initial query doesn't return enough results to fill the
  // page (which would prevent scroll events from firing). In this case we want to immediately
  // fetch more results when loading completes.
  //
  // Similarly, we also want to rerun this effect whenever the isListEnd changes so that we can
  // unsubscribe from the scroll event when the list is done.
  useEffect(() => {
    if (isListEnd) return;

    const scrollboxEl = pagingScrollboxRef.current;

    if (!scrollboxEl) {
      console.warn("useListPaging: pagingScrollboxRef is null");
      return;
    }

    const sub = merge(fromEvent(scrollboxEl === document.body ? window : scrollboxEl, "scroll"), WINDOW_RESIZE_EVENT$)
      .pipe(
        startWith(() => null),
        throttleTime(100, undefined, { leading: false, trailing: true }),
        filter(() => {
          if (isLoading) return false;
          // When loading ends, isListEnd might turn `true` at the same time that isLoading
          // turns `false`. Because this is happening in an effect which occurs after rendering
          // the component (i.e. after the `isLoading` variable is updated), it's possible for
          // isLoadingRef.current to reflect the latest state while `isListEnd` reflects stale
          // state. This can cause this observable to trigger when it shouldn't unless we guard
          // against the possibility using this ref.
          if (isListEndRef.current) return false;

          const targetPxFromBottom = 400;

          const maxScrollHeight = getMaxScrollTop(scrollboxEl);

          const targetHeight = maxScrollHeight - targetPxFromBottom;

          const isTargetHeightReached = getScrollTop(scrollboxEl) > targetHeight;

          return isTargetHeightReached;
        }),
        throttleTime(1500, undefined, { leading: true, trailing: true }),
      )
      .subscribe(() => {
        fetchMoreRef.current();
      });

    return () => sub.unsubscribe();
  }, [isLoading, isListEnd, isListEndRef, fetchMoreRef, pagingScrollboxRef]);
}
