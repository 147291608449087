import { getPointer } from "libs/schema";
import { useRecordLoader } from "./useRecordLoader";

export function useThreadTimelineIds(props: {
  threadId: string | null | undefined;
  endAtTimelineId?: string;
  endAtMessageId?: string;
}) {
  return useRecordLoader({
    name: "useThreadTimelineIds",
    load({ loader, limit, deps: [threadId, endAtTimelineId, endAtMessageId] }) {
      if (!threadId) {
        return loader.createObserveQueryResult<"thread_timeline">();
      }

      const endAt = endAtTimelineId
        ? getPointer("thread_timeline", endAtTimelineId)
        : endAtMessageId
          ? getPointer("thread_timeline", {
              entry_id: endAtMessageId,
              thread_id: threadId,
            })
          : undefined;

      return loader.observeGetThreadTimelineEntries({
        thread_id: threadId,
        endAt,
        limit,
      });
    },
    deps: [props.threadId, props.endAtTimelineId, props.endAtMessageId],
    map: (records) => records.map((r) => r.id),
  });
}
