import { startWith } from "libs/rxjs-operators";
import { useObservable, useObservableState } from "observable-hooks";
import { interval, map, switchMap } from "rxjs";

export function useCurrentTimestamp(props: { reevaluateMs?: number }) {
  const { reevaluateMs = 10_000 } = props;

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([reevaluateMs]) =>
          interval(reevaluateMs).pipe(
            startWith(() => null),
            map(() => new Date().toISOString()),
          ),
        ),
      ),
    [reevaluateMs],
  );

  return useObservableState(observable, () => new Date().toISOString());
}
