import { useMemo } from "react";
import { useRecords } from "./useRecords";

export function useGroups(groupIds: string[]) {
  const [pointers, meta] = useRecords(
    groupIds.map((id) => ({ table: "tag", id })),
    { name: "useGroups" },
  );

  return useMemo(() => {
    return [pointers.map((p) => p.record), meta] as const;
  }, [pointers, meta]);
}
