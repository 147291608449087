import { DOMParser, Element as XmlDomElement } from "@xmldom/xmldom";
import { Logger } from "libs/logger";
import { isNonNullable } from "libs/predicates";

let parser: DOMParser | undefined;

function getDOMParser() {
  if (parser) return parser;
  parser = new DOMParser();
  return parser;
}

export function preloadImgsInHTML(environment: { logger: Logger }, html: string) {
  let imgsUrls: string[];

  try {
    // xmldom DOMParser only supports a single root node
    const document = getDOMParser().parseFromString(`<div>${html}</div>`, "text/html");
    const imgs = Array.from(document.getElementsByTagName("img")) as XmlDomElement[];
    imgsUrls = imgs.map((img) => img.getAttribute("src")).filter(isNonNullable);
  } catch (e) {
    environment.logger.error({ error: e, html }, `preloadImgsInHTML parsing html failed`);
    imgsUrls = [];
  }

  return Promise.all(
    // force-cache — The browser looks for a matching request in its HTTP cache.
    // If there is a match, fresh or stale, it will be returned from the cache.
    // If there is no match, the browser will make a normal request, and will
    // update the cache with the downloaded resource.
    // See https://developer.mozilla.org/en-US/docs/Web/API/Request/cache#value
    imgsUrls.map((url) => fetch(url, { cache: "force-cache" })),
  );
}
