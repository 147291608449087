import { Extension, Editor } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export const PasteHandler = Extension.create({
  name: "pasteHandler",
  addOptions: () => ({
    onPaste: undefined as OnPasteFn | undefined,
  }),
  addProseMirrorPlugins() {
    if (!this.options.onPaste) {
      throw new Error(`PasteHandler: must provide onPaste fn`);
    }

    return [
      PasteHandlerPlugin({
        key: new PluginKey(this.name),
        editor: this.editor,
        onPaste: this.options.onPaste,
      }),
    ];
  },
});

export type OnPasteFn = (props: { editor: Editor; event: ClipboardEvent }) => boolean;

function PasteHandlerPlugin(props: { key: PluginKey; editor: Editor; onPaste: OnPasteFn }) {
  const { key, editor, onPaste } = props;

  return new Plugin({
    key,
    props: {
      handlePaste(editorView, event) {
        const wasHandled = onPaste({ editor, event });

        if (wasHandled) {
          event.preventDefault();
          event.stopPropagation();
        }

        return wasHandled;
      },
    },
  });
}
