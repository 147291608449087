/* -------------------------------------------------------------------------------------------------
 * thread-prev-next-service-utils
 * -------------------------------------------------------------------------------------------------
 *
 * This module contains helpers for working with the thread-prev-next service.
 */

import {
  IThreadListNavigationLocationStateInboxView,
  ThreadListNavigationLocationStateKey,
} from "~/environment/thread-prev-next.service";

export function buildThreadViewPrevNextStateForInboxView(inboxSectionId: string) {
  return {
    [ThreadListNavigationLocationStateKey]: {
      origin: "InboxView",
      inboxSectionId,
    } satisfies IThreadListNavigationLocationStateInboxView,
  };
}
