import { useInitialQueryLimit } from "./useInitialQueryLimit";
import { useRecordLoader } from "./useRecordLoader";
import { uniqBy } from "lodash-es";

export function useDistinctSentMessageIdsByThread() {
  const initialLimit = useInitialQueryLimit(50);

  return useRecordLoader({
    name: "useDistinctSentMessageIdsByThread",
    load({ loader, limit, currentUserId }) {
      return loader.observeGetSentMessages({
        userId: currentUserId,
        limit,
      });
    },
    initialLimit,
    limitStep: Math.ceil(initialLimit / 2),
    map: (records) => uniqBy(records, "thread_id").map((record) => record.id),
  });
}
