import { useEffect } from "react";
import { fromEvent } from "rxjs";

/**
 * For "sticky" css elements, this hook adds a drop shadow when the element is stuck to the top of the viewport.
 */
export function useAddDropShadowWhenSticky(elementRef: React.RefObject<HTMLElement>, deps: unknown[] = []) {
  useEffect(() => {
    const subheaderEl = elementRef.current;

    if (!subheaderEl) return;

    const sub = fromEvent(window, "scroll").subscribe(() => {
      const rect = subheaderEl.getBoundingClientRect();

      if (rect.top === 0) {
        subheaderEl.classList.add("shadow-md");
      } else {
        subheaderEl.classList.remove("shadow-md");
      }
    });

    return () => sub.unsubscribe();
  }, deps);
}
