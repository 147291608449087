import { useEffect, useMemo, useRef } from "react";
import { isEqual as _isEqual } from "libs/predicates";

/**
 * Hook which always returns the same object reference until
 * that object has changed as determined by a user provided
 * `isEqual` function. By default, a deep equality check is
 * performed.
 */
export function useDistinctUntilChanged<T>(value: T, isEqual: (a: T, b: T) => boolean = _isEqual): T {
  const previousValueRef = useRef(value);

  const distinctValue = useMemo(() => {
    return isEqual(value, previousValueRef.current) ? previousValueRef.current : value;
  }, [value, isEqual]);

  useEffect(() => {
    previousValueRef.current = distinctValue;
  });

  return distinctValue;
}
