import { memo, ComponentType, useState } from "react";
import { IListOnEntryActionEvent, List, useListContext } from "../list";
import { isEqual } from "libs/predicates";
import { navigateToEntry } from "./ContentList";
import {
  Recipients,
  Summary,
  DisplayDate,
  entryCSSClasses,
  useShouldShowChannelLabels,
  PrivateEntryIcon,
  StarredEntryIcon,
  EntryActions,
  MarkDoneEntryAction,
  SetReminderEntryAction,
  OtherCommandEntryAction,
  MarkNotDoneEntryAction,
} from "./layout";
import { isModKeyActive } from "~/environment/command.service";
import { cx } from "@emotion/css";
import { INavigateServiceOptions, openLinkInNewTabOrWindow } from "~/environment/navigate.service";
import { Avatar } from "../Avatar";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { PointerWithRecord, RecordValue } from "libs/schema";
import { useNotification } from "~/hooks/useNotification";
import { useMessage } from "~/hooks/useMessage";
import { useThread } from "~/hooks/useThread";
import { useMessageSender } from "~/hooks/useMessageSender";
import { LabelContainer, PermittedGroupChips, ThreadResolvedLabel } from "../LabelChip";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { onMessageSelectNavigateToThread } from "./MessageEntry";
import { useDoesThreadHaveDraft } from "~/hooks/useDoesThreadHaveDraft";

export function onNotificationSelectNavigateToThread(
  { entry, event }: IListOnEntryActionEvent<PointerWithRecord<"notification">>,
  options?: INavigateServiceOptions,
) {
  const to = `/threads/${entry.record.thread_id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(to);
  } else {
    navigateToEntry(entry.id, to, options);
  }
}

export const NotificationEntry: ComponentType<{
  notificationId: string;
  relativeOrder: number;
}> = memo((props) => {
  const [notification] = useNotification({
    notificationId: props.notificationId,
  });

  const [thread] = useThread(notification?.thread_id);
  const [message] = useMessage(notification?.message_id);
  const sender = useMessageSender(notification?.message_id);

  const [hasDraft] = useDoesThreadHaveDraft({
    threadId: notification?.thread_id,
    // In the environment we create and maintain a subscription to all of the user's drafts.
    // For this reason, we can use a cache-only fetch strategy here. Note that the environment
    // creates a subscription to the user's drafts after a delay, so if the user is initially
    // loading Comms' inbox they won't see these drafts appear until that subscription is created.
    // But in this case we're deciding that that is ok.
    fetchStrategy: "cache",
  });

  const senderLabel = sender?.label || (sender.isLoading ? "loading..." : "unknown");

  const senderPhotoURL = sender?.photoUrl;

  const showChannelLabels = useShouldShowChannelLabels();

  const [isChecked, setIsChecked] = useState(false);

  const listContext = useListContext();

  const entryData = useAsPointerWithRecord("notification", notification);

  if (!notification || !thread || !message || !entryData) return null;

  const isPrivateThread = thread.visibility === "PRIVATE";

  return (
    <List.Entry<PointerWithRecord<"notification">>
      key={notification.id}
      id={notification.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
      onEntrySelectionChange={(event) => {
        setIsChecked(event.isSelected);
      }}
    >
      <div role="listitem" className={cx(entryCSSClasses, isChecked && "is-checked", "NotificationEntry")}>
        <div className="pr-2 sm-w:pr-3">
          {isChecked ? (
            <MdOutlineCheckBox
              size={30}
              className="p-1"
              onClick={(e) => {
                e.stopPropagation();
                listContext.deselect(notification.id);
              }}
            />
          ) : (
            <>
              <Avatar label={senderLabel} photoURL={senderPhotoURL} width="30px" className="group-hover:hidden" />

              <MdOutlineCheckBoxOutlineBlank
                size={30}
                className="hidden group-hover:block p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  listContext.select(notification.id);
                }}
              />
            </>
          )}
        </div>

        <div className="flex flex-col md-w:flex-row flex-1 min-w-0">
          <Recipients
            nonTruncatedSuffix={
              <>
                {hasDraft && <span className="text-green-9 ml-2 shrink-0">(+ draft)</span>}

                {isPrivateThread && <PrivateEntryIcon />}
                {notification.is_starred && <StarredEntryIcon />}
              </>
            }
          >
            <span className="truncate">{senderLabel}</span>
          </Recipients>

          <Summary
            subject={message.subject}
            formatAsReply={message.is_reply && message.type === "COMMS"}
            details={isPrivateThread ? "private message" : message.body_text}
          />
        </div>

        <LabelContainer>
          {showChannelLabels && <PermittedGroupChips threadId={message.thread_id} />}

          <ThreadResolvedLabel
            threadId={notification.thread_id}
            onClick={({ event, threadTag }) => {
              event.preventDefault();

              onMessageSelectNavigateToThread({
                event: event.nativeEvent,
                id: threadTag.data.message_id,
                entry: {
                  table: "message",
                  id: threadTag.data.message_id,
                  record: { thread_id: threadTag.thread_id },
                },
              });
            }}
          />
        </LabelContainer>

        <EntryActions
          defaultComponent={
            <div className="ml-2">
              <NotificationTimestamp notification={notification} sentAt={message.sent_at} showReminder />
            </div>
          }
        >
          {notification.is_done ? <MarkNotDoneEntryAction /> : <MarkDoneEntryAction />}
          <SetReminderEntryAction />
          <OtherCommandEntryAction />
        </EntryActions>
      </div>
    </List.Entry>
  );
}, isEqual);

export const NotificationTimestamp: ComponentType<{
  notification: Pick<RecordValue<"notification">, "done_last_modified_by" | "remind_at">;
  sentAt: string;
  showReminder?: boolean;
}> = (props) => {
  const wrapperCSS = "flex items-center text-sm";

  if (props.showReminder && props.notification.remind_at) {
    return (
      <div className={wrapperCSS}>
        <span className="text-plumA-8">
          Remind me:{" "}
          <span className="uppercase">
            <DisplayDate date={props.notification.remind_at} />
          </span>
        </span>
      </div>
    );
  }

  const isInInboxDueToReminder = props.showReminder && props.notification.done_last_modified_by === "reminder";

  return (
    <div className={wrapperCSS}>
      <span className={cx("uppercase", isInInboxDueToReminder ? "text-plumA-8" : "text-slateA-9")}>
        <DisplayDate date={props.sentAt} />
      </span>
    </div>
  );
};
