import { Observable, of, switchMap } from "rxjs";
import { observeBlockingLayoutInboxSubsectionId } from "~/observables/observeInboxEntries";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useLoadingObservable } from "./useLoadingObservable";
import { InboxSubsectionTagRecord } from "libs/schema";
import { ClientRecordLoaderObserveGetRecordResultMeta, FetchStrategy } from "~/environment/RecordLoader";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";

export type UseBlockingLayoutInboxSubsectionResult = [
  InboxSubsectionTagRecord | null,
  ClientRecordLoaderObserveGetRecordResultMeta,
];

export function useBlockingLayoutInboxSubsection(props: {
  inboxSectionId?: string | null;
  fetchStrategy?: FetchStrategy;
}): UseBlockingLayoutInboxSubsectionResult {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [props.inboxSectionId, props.fetchStrategy, currentUserId, environment],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([inboxSectionId, fetchStrategy, currentUserId, environment]) => {
          const options = { fetchStrategy: fetchStrategy };

          if (!inboxSectionId) {
            return of<UseBlockingLayoutInboxSubsectionResult>([null, { isLoading: false }]);
          }

          return observeBlockingLayoutInboxSubsectionId(
            environment,
            {
              userId: currentUserId,
              inboxSectionId,
            },
            options,
          ).pipe(
            switchMap(([inboxSubsectionId, { isLoading: isInboxSubsectionIdLoading }]) => {
              if (!inboxSubsectionId) {
                return of<UseBlockingLayoutInboxSubsectionResult>([null, { isLoading: isInboxSubsectionIdLoading }]);
              }

              return environment.recordLoader.observeGetRecord("tag", inboxSubsectionId, {
                fetchStrategy: options.fetchStrategy,
                isLoading: isInboxSubsectionIdLoading,
              }) as Observable<UseBlockingLayoutInboxSubsectionResult>;
            }),
          );
        }),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([null, { isLoading: true as boolean }]) as UseBlockingLayoutInboxSubsectionResult;
