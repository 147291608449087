import { create } from "zustand";

/**
 * Global app state.
 */
export const globalState = create<{
  error: { message: string; code: string } | null;
  setError: (props: { message: string; code: string }) => void;
}>((set) => ({
  error: null,
  setError: (props) => {
    set({ error: { message: props.message, code: props.code } });
  },
}));
