import { observeInboxEntries } from "../observables/observeInboxEntries";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useRecordLoader } from "./useRecordLoader";

export function useInboxEntries(props: { inboxSectionId: string | null; initialLimit: number }) {
  const { inboxSectionId, initialLimit } = props;
  const environment = useClientEnvironment();

  return useRecordLoader({
    name: "useInboxEntries",
    deps: [environment, inboxSectionId],
    load({ loader, deps: [environment, inboxSectionId], limit, currentUserId }) {
      if (!inboxSectionId) {
        return loader.createObserveQueryResult<"inbox_entry">([undefined, { limit }]);
      }

      return observeInboxEntries(environment, {
        userId: currentUserId,
        inboxSectionId,
        limit,
      });
    },
    initialLimit,
    limitStep: Math.ceil(initialLimit / 2),
  });
}
