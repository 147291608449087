import { ClientEnvironment } from "./ClientEnvironment";
import { MessagePortService, Request, SerializedError } from "./MessagePortService";
import { LocalForageKVStore } from "~/utils/KVStore";
import { filter, skip, take, takeUntil } from "rxjs";
import { DeferredPromise, promiseRetryOnError } from "libs/promise-utils";
import { Logger } from "libs/logger";
import { getContextFromLogEvent, getLogLevel, sendLogToConsole, sendLogToDatadog } from "./createClientLogger";

export type PersistedDatabaseWorkerServiceEnv = Pick<ClientEnvironment, "logger" | "clientId" | "leader" | "auth">;

export class PersistedDatabaseWorkerService {
  private static broadcastChannel = new BroadcastChannel("persisted-database");

  static async setLastDatabaseUserId(userId: string | null) {
    if (userId) {
      await kvStore.setItem("lastDatabaseUserId", userId);
    } else {
      await kvStore.removeItem("lastDatabaseUserId");
    }
  }

  /**
   * The database will be cleared and reinstalled from scratch the next time an instance
   * of the PersistedDatabaseServiceProvider is promoted to leader. The PersistedDatabaseServiceProvider
   * _leader tab_ will also react to this action by telling all tabs to reload the page.
   * This reload will cause a new leader tab to be elected which will cause the persisted database schema to
   * be updated. It's necessary that all tabs reload because some running services (e.g. the SyncService)
   * might expect the persisted database cache to have certain records available which it will no longer have
   * after the reinstall.
   *
   * Note that reloading all tabs does require that the leader tab be initialized properly. If a bug has prevented
   * the leader tab from properly initializing, the behavior might be unexpected.
   */
  static reloadAllTabsAndReinstallCommsDatabase() {
    return setShouldMigrateCommsSchema(true);
  }

  readonly isActivePromise = new DeferredPromise<void>();

  connection: MessagePortService;

  private worker: Worker | null = null;

  constructor(
    protected env: PersistedDatabaseWorkerServiceEnv,
    protected props: {
      onReinstallSchema: (env: { logger: Logger }) => Promise<void>;
    },
  ) {
    this.env = { ...env, logger: env.logger.child({ name: "PersistedDatabaseWorkerService" }) };

    this.connection = new MessagePortService({
      serviceName: "MainThreadPersistedDatabaseMessagePortService",
      senderId: MessagePortService.uniqueContexts.LEADER,
      logger: this.env.logger,
      defaultRetryOnDisconnect: true,
    });

    this.connection.activated$.subscribe(() => {
      this.env.logger.notice(`Connection activated`);
      this.isActivePromise.resolve();
    });

    PersistedDatabaseWorkerService.broadcastChannel.onmessage = (event) => {
      if (event.data === "RELOAD_ALL_TABS") {
        location.reload();
      }
    };

    // Note that these events may be emitted as a result of changes in other tabs.
    kvStore
      .getItem$<boolean>("MIGRATE_COMMS_SCHEMA")
      // The curent value will be emitted immediately on subscription. We're only interested in changes
      // so we skip the first value.
      .pipe(skip(1))
      .subscribe(async (value) => {
        if (!value) return;
        if (!this.env.leader.isLeader) return;

        this.env.logger.info("[PersistedDatabaseService] MIGRATE_COMMS_SCHEMA change");

        await this.props.onReinstallSchema({ logger: this.env.logger });

        // In case there isn't a service worker update available, we reload all tabs.
        // It's necessary that all tabs reload because some running services (e.g. the SyncService)
        // might expect the persisted database cache to have certain records available which it
        // will no longer have after the reinstall.
        PersistedDatabaseWorkerService.broadcastChannel.postMessage("RELOAD_ALL_TABS");
        // Posting the message to the broadcast channel will notify other tabs but will not notify this
        // tab. We need to manually reload this tab as well.
        location.reload();
      });

    this.watchForLeaderChanges();

    // Listen to logs from the persisted db worker and send them to datadog. Note that
    // we're subscribing to the messages stream rather than the more traditional requests
    // stream. This is because the requests stream buffers messages until the connection
    // has been activated. If a log error occurred which prevented the connection from
    // being activated, we wouldn't see the log message.
    this.connection.messages$
      .pipe(
        filter((message): message is Request<"LOG"> => !message.replyTo && message.type === "LOG"),
        takeUntil(this.connection.activated$),
      )
      .subscribe((request) => this.onLog(request));

    this.connection.requests$.subscribe((request) => {
      this.env.logger.debug({ request }, "request received");

      try {
        this.handleRequest(request);
      } catch (error) {
        this.connection.sendError(
          request,
          new Error(`[PersistedDatabaseWorkerService] uncaught handleRequest error`, { cause: error }),
        );
      }
    });
  }

  private handleRequest(request: Request) {
    switch (request.type) {
      case "LOG": {
        return this.onLog(request);
      }
      default: {
        this.connection.sendError(
          request,
          new Error(`[PersistedDatabaseWorkerService] Unknown request type: ${request.type}`),
        );
      }
    }
  }

  private onLog(request: Request<"LOG">) {
    const { logEvent } = request.data;
    const context = getContextFromLogEvent(logEvent);
    if (context.error) context.error = new SerializedError(context.error);
    sendLogToDatadog(logEvent.level.value, context);
  }

  private watchForLeaderChanges() {
    // Handle becoming the leader
    this.env.leader.isLeaderPromise
      .then(() => this.openDatabase())
      .catch((error) => {
        this.env.logger.fatal(
          { error, isSqliteError: isSqliteError(error) },
          `[PersistedDatabaseService] Error opening database after promoting to leader`,
        );
      });
  }

  /**
   * Opens the database if it hasn't been opened yet. This method is idempotent.
   */
  private async openDatabase() {
    await navigator.locks.request(`open-persisted-db`, { ifAvailable: true }, async (lock) => {
      if (!lock) return;

      const currentUserId = this.env.auth.getCurrentUserId();
      const lastDatabaseUserId = await getLastDatabaseUserId();

      // We don't do client-side authorization of queries so if the the database was last
      // used by another user it probably has records in it which the current user doesn't have
      // permission to access. Because of this, we need to clear the offline database on user changes.
      const wasDatabaseLastUsedByAnotherUser = !!lastDatabaseUserId && lastDatabaseUserId !== currentUserId;

      const clearOnInit = wasDatabaseLastUsedByAnotherUser || (await getShouldMigrateCommsSchema());

      await this.createWorker({ clearOnInit });

      if (clearOnInit) {
        await setShouldMigrateCommsSchema(false);
      }

      await PersistedDatabaseWorkerService.setLastDatabaseUserId(currentUserId);

      this.connection.activate();
    });
  }

  private async createWorker(props: { clearOnInit: boolean }) {
    await promiseRetryOnError(
      { waitMs: 10, exponentialBackoff: true, maxWaitMs: 1000 },
      async ({ attempt, lastError }) => {
        const clearOnInit = props.clearOnInit || attempt > 2;

        if (lastError) {
          this.env.logger.error(
            { error: lastError, clearOnInit, attempt },
            "[createWorker] Error connecting to worker. Retrying...",
          );
        }

        if (this.worker) {
          this.env.logger.warn("[createWorker] terminating previous worker.");
          this.worker.terminate();
        }

        this.worker = new Worker(new URL("./persisted-database-worker/start", import.meta.url), {
          name: "PersistedDatabaseWorker",
          type: "module",
        });

        await this.connection.connect({
          type: "initiate",
          recipientId: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
          port: this.worker,
          message: MessagePortService.buildRequest("PERSISTED_DB_CONNECT", {
            from: MessagePortService.uniqueContexts.LEADER,
            to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
            data: { portId: "set by connect() method", clearOnInit, logLevel: getLogLevel() },
          }),
          // Previously we tried a 5 second timeout, but it was too short for Safari and would
          // trigger while the worker was still initializing.
          timeoutSignal: AbortSignal.timeout(30_000),
        });
      },
    );

    // Locally in development we've seen Safari terminate the SharedWorker unexpectedly and seemingly
    // without reason. Because of this, we're also handling the scenerio where the PersistedDB worker
    // is disconnected.
    this.connection.recipients$
      .pipe(
        filter((recipients) => !recipients.has(MessagePortService.uniqueContexts.PERSISTED_DB_WORKER)),
        take(1),
      )
      .subscribe(() => {
        this.env.logger.warn(`[createWorker] worker disconnected after creation. Reconnecting...`);
        this.createWorker({ clearOnInit: false });
      });
  }

  ////////
  // requests
  //

  async getPortForSharedWorker(request: Request<"PERSISTED_DB_PORT">) {
    await this.isActivePromise.promise;

    this.connection.forwardMessage({
      ...request,
      to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
    });
  }
}

/* -----------------------------------------------------------------------------------------------*/

const kvStore = new LocalForageKVStore({ namespace: "PersistedDatabaseServiceProvider" });

function getLastDatabaseUserId() {
  return kvStore.getItem<string>("lastDatabaseUserId");
}

async function getShouldMigrateCommsSchema() {
  return !!(await kvStore.getItem("MIGRATE_COMMS_SCHEMA"));
}

/**
 * The constructor observes changes to this value and reacts. Note that the constructor
 * will see changes to this value from other tabs as well (which is desired).
 */
async function setShouldMigrateCommsSchema(value: boolean) {
  if (value) {
    await kvStore.setItem("MIGRATE_COMMS_SCHEMA", value);
  } else {
    await kvStore.removeItem("MIGRATE_COMMS_SCHEMA");
  }
}

/* -----------------------------------------------------------------------------------------------*/

export function isSqliteError(error: any) {
  return (
    error &&
    typeof error === "object" &&
    "name" in error &&
    "message" in error &&
    typeof error.name === "string" &&
    error.name.includes("SQLite3Error")
  );
}

/* -----------------------------------------------------------------------------------------------*/
