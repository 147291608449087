import type Shepherd from "shepherd.js";
import { wait } from "libs/promise-utils";
import { createKeybindingsHandler } from "libs/tinykeys";
import { renderStep, createTour } from "../shepherd-utils";
import { PLATFORM_ALT_KEY, PLATFORM_MODIFIER_KEY } from "~/environment/command.service";
import { useEffect } from "react";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export type TNewPrivateMessageWalkthroughEvent = "set-private" | "set-shared" | "focus-sidebar" | "focus-outlet";

const LESSON_NAME = "new-private-message";
const LESSON_VERSION = 4;

export function getClassForPrivateMessageTourStep(step: string | number) {
  return `WALKTHROUGH-${LESSON_NAME}-step-${step}`;
}

function getSelectorForStep(step: string | number) {
  return `.WALKTHROUGH-${LESSON_NAME}-step-${step}`;
}

const steps: Shepherd.Step.StepOptions[] = [
  {
    id: "0",
    text: () =>
      renderStep({
        noBack: true,
        showEscape: true,
        content: `
          Your account is owned by the "${tour.context.organizationName}" organization. 
          ${tour.context.organizationName} wants all its
          members to be able to view the communications of every other
          member—unless the communication is sensitive, that is. To
          facilitate this, ${tour.context.organizationName} has a special channel 
          called "${tour.context.sharedChannelName}". Click next to continue...
        `,
      }),
    when: {
      show() {
        tour.navigationContext.allowEscape = true;
      },
      hide() {
        tour.navigationContext.allowEscape = false;
      },
    },
  },
  {
    id: "1",
    attachTo: { element: getSelectorForStep(1), on: "bottom" },
    modalOverlayOpeningPadding: 3,
    classes: "bottom",
    text: () =>
      renderStep({
        content: `
          This "${tour.context.sharedChannelName}" channel is automatically 
          included as a recipient in all conversations involving 
          ${tour.context.organizationName} users. Since you are a 
          ${tour.context.organizationName} user, it is automatically added 
          as a recipient to all of your conversations. Click next to continue...
        `,
      }),
  },
  {
    id: "2",
    attachTo: { element: getSelectorForStep(1), on: "bottom" },
    modalOverlayOpeningPadding: 3,
    classes: "bottom",
    text: () =>
      renderStep({
        content: `
          Other than being automatically included as a recipient, 
          the ${tour.context.sharedChannelName} channel is just a regular channel 
          which every ${tour.context.organizationName} user has access to.
          Click next to continue...
        `,
      }),
  },
  {
    id: "3",
    attachTo: { element: getSelectorForStep(3), on: "right" },
    beforeShowPromise: async () => {
      tour.event$.next("focus-sidebar");
      await wait(200);
    },
    classes: "right",
    text: () =>
      renderStep({
        content: `
          You can view the ${tour.context.sharedChannelName} 
          channel by selecting the "Shared Messages" option in the
          sidebar. Click next to continue...
        `,
      }),
    when: {
      hide() {
        tour.event$.next("focus-outlet");
      },
    },
  },
  {
    id: "4",
    text: () =>
      renderStep({
        content: `
          If you don't want a conversation to be visible to everyone else
          in ${tour.context.organizationName}, you can mark a message as 
          "Private". Private messages are only accessible to the specific
          users and *private channels* which are included as recipients.
          Importantly, private messages cannot have non-private
          channels as recipients and they are not shared with the
          "${tour.context.sharedChannelName}" channel. If you aren't
          already aware, private channels are a special type of channel 
          which are only visible to invited members. Most channels are 
          considered shared and viewable to everyone in an organization.
          Click next to continue...
        `,
      }),
  },
  {
    id: "5",
    attachTo: { element: getSelectorForStep(5), on: "bottom" },
    classes: "bottom",
    text: () =>
      renderStep({
        noNext: true,
        noBack: true,
        content: (
          <>
            Now press {PLATFORM_MODIFIER_KEY.name}+{PLATFORM_ALT_KEY.name}+P to mark this message as Private and
            continue the tutorial.
          </>
        ),
      }),
    when: {
      show() {
        tour.event$.next("set-shared");
        tour.navigationContext.stopBack = true;
        tour.navigationContext.stopNext = true;
        addEventListener("keydown", makePrivateCommandListener);
      },
      hide() {
        tour.navigationContext.stopBack = false;
        tour.navigationContext.stopNext = false;
        removeEventListener("keydown", makePrivateCommandListener);
      },
    },
  },
  {
    id: "6",
    attachTo: { element: getSelectorForStep(5), on: "bottom" },
    classes: "bottom",
    text: () =>
      renderStep({
        content: `
          Now this is a Private message. Private messages don't include 
          shared channels as recipients, including the ${tour.context.sharedChannelName}
          channel (but private messages can include private channels
          as recipients). Click next to continue...
        `,
      }),
  },
  {
    id: "7",
    // For some reason selecting the compose post header directly doesn't
    // work, but selecting it's only child does work.
    attachTo: { element: getSelectorForStep(7), on: "bottom" },
    text: () =>
      renderStep({
        content: `
          Private messages have a distinctive, dark header bar. If
          you open up a thread and see a dark header like this 
          at the top of the page, it indicates that it's a private 
          thread. Click next to continue...
        `,
      }),
    when: {
      show() {
        tour.event$.next("set-private");
      },
    },
  },
  {
    id: "8",
    // For some reason selecting the compose post header directly doesn't
    // work, but selecting it's only child does work.
    attachTo: { element: getSelectorForStep(7), on: "bottom" },
    text: () =>
      renderStep({
        showComplete: true,
        content: (
          <>
            If the thread has a light colored header like this, that means it's shared. Only share sensitive information
            in Private threads. If you accidently forget to mark a sensitive message as Private, you can do so
            retroactively via the Command Bar ({PLATFORM_MODIFIER_KEY.name}
            +K), or by pressing {PLATFORM_MODIFIER_KEY.name}+{PLATFORM_ALT_KEY.name}+P while viewing the thread.
            <br />
            <br />
            <strong>This completes the tutorial.</strong>
          </>
        ),
      }),
    when: {
      show() {
        tour.navigationContext.nextIsComplete = true;
        tour.event$.next("set-shared");
      },
      hide() {
        tour.navigationContext.nextIsComplete = false;
      },
    },
  },
];

export const tour = createTour<
  TNewPrivateMessageWalkthroughEvent,
  {
    organizationName: string;
    sharedChannelName: string;
  }
>({
  lessonName: LESSON_NAME,
  lessonVersion: LESSON_VERSION,
  options: {
    steps,
  },
});

const makePrivateCommandListener = createKeybindingsHandler({
  "$mod+Alt+KeyP": (e) => {
    e.preventDefault();
    tour.event$.next("set-private");
    tour.next();
  },
});

tour.on("start", () => {
  // in case the user triggers the tour while already in private mode
  tour.event$.next("set-shared");
});

export function useConfigurePrivateMessageLesson() {
  const { currentUser } = useAuthGuardContext();
  const { recordLoader } = useClientEnvironment();

  // We need to configure the tour with the name of the
  // organization and shared channel
  useEffect(() => {
    const sub = recordLoader
      .observeGetRecord({
        table: "organization_profile",
        id: currentUser.owner_organization_id,
      })
      .subscribe(([profile]) => {
        if (!profile) return;

        tour.context = {
          organizationName: profile.name,
          sharedChannelName: `${profile.name_short} Shared`,
        };
      });

    return () => sub.unsubscribe();
  }, [currentUser.owner_organization_id, recordLoader]);
}
