import React, { useState, ReactNode, CSSProperties } from "react";
import { useSwipeable } from "react-swipeable";

type Props = {
  children: ReactNode;
  rightActionButtons: {
    content: ReactNode;
    onClick: () => void;
    role?: string;
  }[];
  leftActionButtons: {
    content: ReactNode;
    onClick: () => void;
    role?: string;
  }[];
  actionButtonMinWidth: number;
  height?: string;
  containerStyle?: CSSProperties;
  hideDotsButton?: boolean;
  dotsBtnAriaLabel?: string;
};

export const SwipeToRevealActions: React.FC<Props> = ({
  children,
  rightActionButtons,
  leftActionButtons,
  containerStyle,
  actionButtonMinWidth,
  height = "56px",
}: Props) => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [swipeXDelta, setSwipeXDelta] = useState<number>(0);
  const [swipeDirection, setSwipeDirection] = useState<string>("");
  const handlers = useSwipeable({
    onSwiped: () => handlePanEnd(),
    onSwipeStart: (eventData) => handlePanStart(eventData),
    // @ts-expect-error - TS doesn't know about onSwipeEnd
    onSwipeEnd: () => handlePanEnd(),
    onSwiping: (eventData) => handleSwipe(eventData),
    onTouchEndOrOnMouseUp: (eventData) => {
      eventData.event.stopPropagation();
    },
    onTouchStartOrOnMouseDown: (eventData) => {
      eventData.event.stopPropagation();
    },
  });

  function handlePanStart(e: any) {
    if (e.dir === "Down" || e.dir === "Up") {
      setIsScrolling(true);
    }
  }

  function handlePanEnd() {
    setIsScrolling(false);
    if (Math.abs(swipeXDelta) < actionButtonMinWidth) {
      setSwipeXDelta(0);
    }
  }

  function handleSwipe(e: any) {
    if (!isScrolling) {
      if (e.dir === "Left") {
        const minVal = Math.min(Math.abs(e.deltaX), leftActionButtons.length === 0 ? 0 : actionButtonMinWidth);
        if (swipeXDelta === 0 && leftActionButtons.length === 0) return;
        setSwipeXDelta(-minVal);
      } else if (e.dir === "Right") {
        const minVal = Math.min(Math.abs(e.deltaX), rightActionButtons.length === 0 ? 0 : actionButtonMinWidth);
        if (swipeXDelta === 0 && rightActionButtons.length === 0) return;
        setSwipeXDelta(minVal);
      }
      setSwipeDirection(e.dir);
    }
  }

  function handleActionClicked(callback: () => void) {
    if (Math.abs(swipeXDelta) >= actionButtonMinWidth) {
      callback();
    }
  }

  const actionButtons = swipeDirection === "Left" ? leftActionButtons : rightActionButtons;

  return (
    <div className="w-full relative overflow-hidden inline-flex box-border" style={{ height, ...containerStyle }}>
      <div {...handlers} className="w-full relative overflow-hidden inline-flex box-border">
        <div>
          <div
            className="w-full inline-flex items-center justify-between absolute transition-all duration-[0.25s] ease-[ease] bg-white box-border z-[2] pt-4 pb-[0.8rem] left-0 top-0"
            style={{
              height,
              transform: `translateX(${swipeXDelta}px`,
            }}
          >
            <div className="w-full">{children}</div>
          </div>
          <div
            className={`w-full absolute flex flex-row items-center ${
              swipeDirection === "Left" ? "justify-end" : "justify-start"
            } z-[1] right-0 top-0`}
            style={{ height: height, display: "flex" }}
            role="region"
          >
            {actionButtons.map((action, index) => (
              <div key={`actionKey_${index}`} style={{ height }}>
                <button
                  className="min-w-[50px] appearance-none box-border cursor-pointer select-none m-0 p-0 border-[none]"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleActionClicked(action.onClick);
                  }}
                  style={{
                    height,
                    minWidth: actionButtonMinWidth,
                  }}
                  role={action.role || "button"}
                >
                  {action.content}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
