import { ThreadResolvedThreadTagRecord, generateRecordId } from "libs/schema";
import useConstant from "use-constant";
import { useThreadTag } from "./useThreadTag";
import { UseRecordResultMeta } from "./useRecord";

export type UseThreadResolvedTagResult = [ThreadResolvedThreadTagRecord | null, UseRecordResultMeta];

export function useThreadResolvedTag(threadId: string | null | undefined) {
  const tagId = useConstant(() => generateRecordId("singleton_tag", { name: "resolved" }));

  return useThreadTag({ threadId, tagId }) as UseThreadResolvedTagResult;
}
