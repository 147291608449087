import { UnreachableCaseError } from "libs/errors";
import { parseStringToEmailAddress } from "libs/parseEmailAddress";
import { RecordValue } from "libs/schema";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useMessage } from "./useMessage";
import { useUserProfile } from "./useUserProfile";

export function useMessageSender(messageId?: string | null) {
  const { currentUserId } = useAuthGuardContext();
  const [message, { isLoading: isMessageLoading }] = useMessage(messageId);
  const [sender, { isLoading: isSenderLoading }] = useUserProfile(message?.sender_user_id);

  const result = {
    isLoading: isMessageLoading || isSenderLoading,
    label: null as string | null,
    name: sender?.name || null,
    firstName: sender?.first_name || null,
    middleName: sender?.middle_name || null,
    lastName: sender?.last_name || null,
    photoUrl: sender?.photo_url || null,
  };

  if (!message) return result;

  const label = getMessageSenderLabel({
    message,
    sender,
    currentUserId,
  });

  result.label = label || null;

  return result;
}

/**
 * If a currentUserId is provided and if the post was created by the current
 * user, then "Me" is returned.
 */
function getMessageSenderLabel(args: {
  message: RecordValue<"message">;
  sender?: RecordValue<"user_profile"> | null;
  currentUserId: string;
}): string | undefined {
  const { message, sender, currentUserId } = args;

  if (message.sender_user_id === currentUserId) {
    return "Me";
  }

  switch (message.type) {
    case "COMMS": {
      // Comms messages will always have a sender

      return sender?.name;
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      if (sender) {
        return sender.name;
      }

      if (!message.email_sender) return;

      const emailSender = parseStringToEmailAddress(message.email_sender);

      return emailSender?.label || emailSender?.address;
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}
