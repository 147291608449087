import { startWith } from "libs/rxjs-operators";
import { Observable, map } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export type ObserveBrowserPushNotificationSubscriptionResult = {
  pushSubscription: PushSubscription | null;
  isLoading: boolean;
};

/**
 * Returns an observable for the browser's native PushSubscription object.
 * `null` if this client doesn't have a native push subscription.
 */
export function observeBrowserPushNotificationSubscription(
  environment: Pick<ClientEnvironment, "serviceWorker">,
  options: { isLoading?: boolean } = {},
): Observable<ObserveBrowserPushNotificationSubscriptionResult> {
  return environment.serviceWorker.pushNotificationSubscription$.pipe(
    map((pushSubscription) => ({
      pushSubscription,
      isLoading: options.isLoading || false,
    })),
    startWith(() => ({ pushSubscription: null, isLoading: true })),
  );
}
