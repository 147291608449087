import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { ComponentType, useRef } from "react";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { ESCAPE_TO_INBOX_COMMAND } from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { SettingsForm } from "./SettingsForm";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";

/* -------------------------------------------------------------------------------------------------
 * SettingsView
 * -----------------------------------------------------------------------------------------------*/

export const SettingsView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const { settings } = useCurrentUserSettings();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      return commands;
    },
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl mr-3">Settings</h1>
      </MainLayout.Header>

      <div className="pt-4 px-12 py-20">{settings && <SettingsForm settings={settings} />}</div>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/
