import { CommandProps, ReactNodeViewRenderer } from "@tiptap/react";
import Image from "@tiptap/extension-image";
import { ImageNodeView } from "./ImageNodeView";
import { findChildren } from "prosemirror-utils";
import { IImageExtentionAttrs } from "./context";

export const ImageExtension = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      src: {
        default: null,
        parseHTML: (el) => {
          return el.getAttribute("src") || null;
        },
        renderHTML: (attrs) => {
          // TipTap will automatically delete any image nodes that don't have a src.
          // Strangely, it doesn't _always_ do this, but it does do it regularly.
          // Adding a blank string keeps TipTap from deleting it.
          return { src: attrs.src || "" };
        },
      },
      width: {
        default: null,
        parseHTML: (el) => {
          const width = el.getAttribute("width");
          return width ? parseInt(width) : null;
        },
        renderHTML: (attrs) => ({ width: attrs.width }),
      },
      height: {
        default: null,
        parseHTML: (el) => {
          const height = el.getAttribute("height");
          return height ? parseInt(height) : null;
        },
        renderHTML: (attrs) => ({ height: attrs.height }),
      },
      imageId: {
        default: null,
        parseHTML: (el) => el.getAttribute("data-imageid") || null,
        renderHTML: (attrs) => {
          return {
            "data-imageid": attrs.imageId || null,
          };
        },
      },
      contentType: {
        default: null,
        parseHTML: (el) => el.getAttribute("data-content-type") || null,
        renderHTML: (attrs) => {
          return {
            "data-content-type": attrs.contentType || null,
          };
        },
      },
      fileSize: {
        default: null,
        parseHTML: (el) => {
          const fileSize = el.getAttribute("data-file-size");
          return fileSize ? parseInt(fileSize) : null;
        },
        renderHTML: (attrs) => {
          return {
            "data-file-size": attrs.fileSize || null,
          };
        },
      },
      // // This value isn't persisted in the document and is instead used
      // // temporarily in memory.
      // signedUrlExpiresAt: {
      //   default: null,
      //   parseHTML: () => null,
      //   renderHTML: () => null,
      // },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(ImageNodeView);
  },
  addCommands() {
    return {
      ...this.parent?.(),

      updateImage: (imageId: string, attributes: Partial<IImageExtentionAttrs>) => (props: CommandProps) => {
        const images = findChildren(props.state.doc, (node) => {
          return node.type === this.type && node.attrs.imageId === imageId;
        });

        const [image] = images;

        if (!image) return false;

        if (images.length > 1) {
          alert(`Error: multiple images with imageId ${imageId} found.`);
          return false;
        }

        if (props.dispatch) {
          const newAttrs = { ...image.node.attrs, ...attributes };
          const tr = props.tr.setNodeMarkup(image.pos, this.type, newAttrs);
          props.dispatch(tr);
        }

        return true;
      },
    };
  },
}).configure({
  allowBase64: false,
});
