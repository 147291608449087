import { getPointer, generateRecordId, ThreadVisibility, GroupTagRecord } from "libs/schema";
import { op } from "libs/transaction";
import { toast } from "~/environment/toast-service";
import { UnreachableCaseError } from "libs/errors";
import { isTagPrivate } from "libs/schema/predicates";
import * as ops from "libs/actions";
import { GetOptions } from "~/environment/RecordLoader";
import { withTransaction, write } from "./write";

/* -------------------------------------------------------------------------------------------------
 * addGroupToThread
 * -------------------------------------------------------------------------------------------------
 */

export const addGroupToThread = withTransaction(
  "addGroupToThread",
  async (
    environment,
    transaction,
    props: {
      threadId: string;
      groupId: string;
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

    const [[thread], [group]] = await Promise.all([
      environment.recordLoader.getRecord("thread", props.threadId),
      environment.recordLoader.getRecord("tag", props.groupId),
    ]);

    if (!thread) {
      toast("vanilla", {
        subject: `Error adding group to thread.`,
        description: "Thread not found",
      });

      return;
    } else if (!group) {
      toast("vanilla", {
        subject: `Error adding group to thread.`,
        description: "Group not found.",
      });

      return;
    } else if (thread.visibility === "PRIVATE" ? !isTagPrivate(group) : isTagPrivate(group)) {
      toast("vanilla", {
        subject: `Error adding group to thread.`,
        description: `Cannot add ${
          thread.visibility === "PRIVATE" ? "shared" : "private"
        } to ${thread.visibility.toLowerCase()} thread.`,
      });

      return;
    }

    const pointer = getPointer("thread_group_permission", {
      thread_id: props.threadId,
      group_id: props.groupId,
    });

    transaction.operations.push(
      op.upsert(pointer, {
        onCreate: [
          op.set(pointer, {
            id: pointer.id,
            thread_id: props.threadId,
            group_id: props.groupId,
            creator_user_id: currentUserId,
            owner_organization_id: ownerOrganizationId,
            start_at: thread.first_message_timeline_order,
            thread_sent_at: thread.first_message_sent_at,
          }),
        ],
      }),
    );

    await write(environment, {
      transaction,
      onOptimisticWrite: () => {
        toast("vanilla", {
          subject: `Adding group to thread...`,
        });
      },
      onServerWrite: () => {
        toast("vanilla", {
          subject: `Adding group to thread...Done!`,
        });
      },
      onOptimisticUndo: () => {
        toast("vanilla", {
          subject: `Undoing adding group to thread...`,
        });
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 * removeGroupFromThread
 * -------------------------------------------------------------------------------------------------
 */

export const removeGroupFromThread = withTransaction(
  "removeGroupFromThread",
  async (
    environment,
    transaction,
    props: {
      threadId: string;
      groupId: string;
    },
  ) => {
    toast("vanilla", {
      subject: `Removing group from thread...`,
    });

    const [[thread], [group]] = await Promise.all([
      environment.recordLoader.getRecord("thread", props.threadId),
      environment.recordLoader.getRecord("tag", props.groupId),
    ]);

    if (!thread) {
      toast("vanilla", {
        subject: `Error removing group from thread.`,
        description: "Thread not found",
      });

      return;
    } else if (!group) {
      toast("vanilla", {
        subject: `Error removing group from thread.`,
        description: "Group not found. This could be because you don't have permission to access this group.",
      });

      return;
    } else if ((group as GroupTagRecord).data?.is_organization_group) {
      toast("vanilla", {
        subject: `Error removing group from thread.`,
        description: "Cannot manually remove an organization group. Instead, change the thread visibility.",
      });

      return;
    }

    const pointer = getPointer("thread_group_permission", {
      thread_id: props.threadId,
      group_id: props.groupId,
    });

    transaction.operations.push(op.delete(pointer.table, pointer));

    await write(environment, {
      transaction,
      onServerWrite: () => {
        toast("vanilla", {
          subject: `Removing group from thread...Done!`,
        });
      },
      onOptimisticUndo: () => {
        toast("vanilla", {
          subject: `Undoing removing group from thread...`,
        });
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 * updateThreadVisibility
 * -------------------------------------------------------------------------------------------------
 */

export const updateThreadVisibility = withTransaction(
  "updateThreadVisibility",
  async (
    environment,
    transaction,
    props: {
      threadId: string;
      visibility: ThreadVisibility;
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

    if (!environment.network.isOnline()) {
      toast("vanilla", {
        subject: "Not available offline",
        description: `
          Cannot change thread visibility while offline.
        `,
      });

      return;
    }

    toast("vanilla", {
      subject: `Making thread ${props.visibility.toLowerCase()}...`,
    });

    const [[thread], [groupPermissions]] = await Promise.all([
      environment.recordLoader.getRecord("thread", props.threadId),
      environment.recordLoader.getThreadGroupPermissions({
        thread_id: props.threadId,
      }),
    ]);

    if (!thread) {
      toast("vanilla", {
        subject: `Error making thread ${props.visibility}.`,
        description: "Thread not found",
      });

      return;
    }

    const currentUserPermissionPointer = getPointer("thread_user_permission", {
      thread_id: props.threadId,
      user_id: currentUserId,
    });

    // On visibility changes we make sure the current user has permission to access the thread
    // to prevent them from losing access to the thread after the visibility change.
    const permissionForCurrentUserUpsert = op.upsert(currentUserPermissionPointer, {
      onCreate: [
        op.set("thread_user_permission", {
          id: currentUserPermissionPointer.id,
          thread_id: props.threadId,
          user_id: currentUserId,
          creator_user_id: currentUserId,
          owner_organization_id: ownerOrganizationId,
          start_at: thread.first_message_timeline_order,
          thread_sent_at: thread.first_message_sent_at,
        }),
      ],
    });

    switch (props.visibility) {
      case "PRIVATE": {
        transaction.operations.push(
          op.update({ table: "thread", id: props.threadId }, { visibility: props.visibility }),
          permissionForCurrentUserUpsert,
          ...groupPermissions.map((permission) => {
            return op.delete("thread_group_permission", permission);
          }),
        );

        break;
      }
      case "SHARED": {
        transaction.operations.push(
          op.update({ table: "thread", id: props.threadId }, { visibility: props.visibility }),
          ...groupPermissions.map((permission) => {
            return op.delete("thread_group_permission", permission);
          }),
          permissionForCurrentUserUpsert,
          op.set("thread_group_permission", {
            id: generateRecordId("thread_group_permission", {
              thread_id: props.threadId,
              group_id: ownerOrganizationId,
            }),
            thread_id: props.threadId,
            group_id: ownerOrganizationId,
            creator_user_id: currentUserId,
            owner_organization_id: ownerOrganizationId,
            start_at: thread.first_message_timeline_order,
            thread_sent_at: thread.first_message_sent_at,
          }),
        );

        break;
      }
      default: {
        throw new UnreachableCaseError(props.visibility);
      }
    }

    await write(environment, {
      transaction,
      onServerWrite: () => {
        toast("vanilla", {
          subject: `Making thread ${props.visibility.toLowerCase()}...Done!`,
        });
      },
      onOptimisticUndo: () => {
        toast("vanilla", {
          subject: `Undoing making thread ${props.visibility.toLowerCase()}.`,
        });
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 * markThreadSeen
 * -------------------------------------------------------------------------------------------------
 */

export const markThreadSeen = withTransaction(
  "markThreadSeen",
  async (
    environment,
    transaction,
    props: {
      threadId: string;
      seen_to_timeline_id: string;
      seen_to_timeline_order: string;
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

    transaction.operations.push(
      op.set("thread_seen_receipt", {
        id: generateRecordId("thread_seen_receipt", {
          thread_id: props.threadId,
          user_id: currentUserId,
        }),
        thread_id: props.threadId,
        user_id: currentUserId,
        seen_to_timeline_id: props.seen_to_timeline_id,
        seen_to_timeline_order: props.seen_to_timeline_order,
        owner_organization_id: ownerOrganizationId,
      }),
    );

    await write(environment, { transaction, canUndo: false });
  },
);

/* -------------------------------------------------------------------------------------------------
 * markThreadResolved
 * -------------------------------------------------------------------------------------------------
 */

export const markThreadResolved = withTransaction(
  "markThreadResolved",
  async (
    environment,
    transaction,
    props: {
      threadId: string;
      resolvedByMessageId: string;
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();
    const fetchOptions: GetOptions = {
      fetchStrategy: "cache-first",
    };

    const [[threadTag], [message]] = await Promise.all([
      environment.recordLoader.getRecord(
        "thread_tag",
        generateRecordId("thread_tag", {
          thread_id: props.threadId,
          tag_id: generateRecordId("singleton_tag", { name: "resolved" }),
        }),
        fetchOptions,
      ),
      environment.recordLoader.getRecord("message", props.resolvedByMessageId, fetchOptions),
    ]);

    if (!message) return;

    if (message.sent_at > new Date().toISOString()) {
      toast("vanilla", {
        subject: `Cannot mark as resolved`,
        description: `
          The message that resolves the thread has not been sent yet.
          Try again after it has been sent.
        `,
      });

      return;
    }

    ops.applyOperationsToTransaction(
      transaction,
      ops.thread.markThreadResolved({
        threadId: props.threadId,
        resolvedByMessageId: props.resolvedByMessageId,
        markedResolvedByUserId: currentUserId,
        ownerOrganizationId: ownerOrganizationId,
      }),
    );

    try {
      await write(environment, {
        transaction,
        onOptimisticWrite: () => {
          toast("vanilla", {
            subject: `Thread resolved.`,
          });
        },
        onOptimisticUndo: () => {
          toast("vanilla", {
            subject: `Undoing marking thread resolved.`,
          });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: `Error marking thread resolved.`,
      });

      throw error;
    }
  },
);

/* -------------------------------------------------------------------------------------------------
 * markThreadNotResolved
 * -------------------------------------------------------------------------------------------------
 */

export const markThreadNotResolved = withTransaction(
  "markThreadNotResolved",
  async (
    environment,
    transaction,
    props: {
      threadId: string;
    },
  ) => {
    ops.applyOperationsToTransaction(
      transaction,
      ops.thread.markThreadNotResolved({
        threadId: props.threadId,
      }),
    );

    try {
      await write(environment, {
        transaction,
        onOptimisticWrite: () => {
          toast("vanilla", {
            subject: `Thread marked not resolved.`,
          });
        },
        onOptimisticUndo: () => {
          toast("vanilla", {
            subject: `Undoing marking thread not resolved.`,
          });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: `Error marking thread not resolved.`,
      });

      throw error;
    }
  },
);

/* -------------------------------------------------------------------------------------------------
 * deleteThread
 * -------------------------------------------------------------------------------------------------
 */

export const deleteThread = withTransaction(
  "deleteThread",
  async (
    environment,
    transaction,
    props: {
      threadId: string;
    },
  ) => {
    ops.applyOperationsToTransaction(
      transaction,
      ops.thread.deleteThread({
        threadId: props.threadId,
      }),
    );

    try {
      await write(environment, {
        transaction,
        onOptimisticWrite: () => {
          toast("vanilla", {
            subject: `Thread deleted.`,
          });
        },
        onOptimisticUndo: () => {
          toast("vanilla", {
            subject: `Thread restored.`,
          });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: `Error deleting thread.`,
      });

      throw error;
    }
  },
);

/* -------------------------------------------------------------------------------------------------
 * restoreThread
 * -------------------------------------------------------------------------------------------------
 */

export const restoreThread = withTransaction(
  "restoreThread",
  async (
    environment,
    transaction,
    props: {
      threadId: string;
    },
  ) => {
    ops.applyOperationsToTransaction(
      transaction,
      ops.thread.restoreThread({
        threadId: props.threadId,
      }),
    );

    try {
      await write(environment, {
        transaction,
        onOptimisticWrite: () => {
          toast("vanilla", {
            subject: `Thread restored.`,
          });
        },
        onOptimisticUndo: () => {
          toast("vanilla", {
            subject: `Thread deleted.`,
          });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: `Error restoring thread.`,
      });

      throw error;
    }
  },
);

/* -----------------------------------------------------------------------------------------------*/
