import * as d from "ts-decoders/decoders";
import { Decoder, DecoderReturnType } from "ts-decoders";
import { tableD } from "./schema";

export type ClientPubsubMessage =
  | { type: "LOGIN"; token: string }
  | { type: "SUBSCRIBE"; key: string }
  | { type: "UNSUBSCRIBE"; key: string };

export type ServerPubsubMessage = {
  key: string;
  value: string;
};

export type ParsedServerPubsubMessage = DecoderReturnType<typeof serverPubSubMessageD>;

export type RecordUpdateMessage = DecoderReturnType<typeof recordChangeMessageD>;

export type ChangeNotificationMessage = DecoderReturnType<typeof changeNotificationMessageD>;

export type LoginSuccessMessage = DecoderReturnType<typeof loginSuccessMessageD>;

export const clientPubSubMessageD: Decoder<ClientPubsubMessage> = d.anyOfD([
  d.objectD({ type: d.exactlyD("SUBSCRIBE"), key: d.stringD() }),
  d.objectD({ type: d.exactlyD("UNSUBSCRIBE"), key: d.stringD() }),
  d.objectD({ type: d.exactlyD("LOGIN"), token: d.stringD() }),
]);

export const recordChangeMessageD = d
  .objectD({
    key: d
      .stringD()
      .map((i) => i.split(":"))
      .chain(d.tupleD([tableD, d.stringD()])),
    value: d.stringD().map(Number).chain(d.integerD()),
  })
  .map(({ key, value }) => ({
    type: "RECORD_UPDATE" as const,
    table: key[0],
    id: key[1],
    version: value,
  }));

export const changeNotificationMessageD = d
  .objectD({
    key: d
      .stringD()
      .map((i) => i.split(":"))
      .chain(d.tupleD([d.exactlyD("change_notification"), d.stringD().map(Number).chain(d.integerD())])),
    value: d
      .stringD()
      .map(JSON.parse)
      .chain(
        d.objectD({
          row_table: tableD,
          row_id: d.stringD(),
          version: d.integerD(),
        }),
      ),
  })
  .map(({ key: [_, id], value: { row_table, row_id, version } }) => ({
    type: "CHANGE_NOTIFICATION" as const,
    id,
    row_table,
    row_id,
    version,
  }));

export const loginSuccessMessageD = d
  .objectD({
    key: d.exactlyD("LOGIN_SUCCESS"),
    value: d.anyD(),
  })
  .map(() => ({ type: "LOGIN_SUCCESS" as const }));

export const serverPubSubMessageD = d.anyOfD([recordChangeMessageD, changeNotificationMessageD, loginSuccessMessageD]);
