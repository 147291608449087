import { ComponentType, PropsWithChildren, useMemo } from "react";
import { useIsInitialSyncComplete } from "~/hooks/useIsInitialSyncComplete";
import { ClientEnvironmentProvider, useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { createRecordLoader } from "~/environment/RecordLoader";

export function withOfflineFirstIfSynced<P extends PropsWithChildren<unknown>>(
  Component: React.ComponentType<P>,
): ComponentType<P>;
export function withOfflineFirstIfSynced<P extends PropsWithChildren<unknown>>(props: {
  useIsOfflineFirst: () => boolean;
  Component: React.ComponentType<P>;
}): ComponentType<P>;
export function withOfflineFirstIfSynced<P extends PropsWithChildren<unknown>>(
  ComponentOrProps:
    | React.ComponentType<P>
    | {
        useIsOfflineFirst: () => boolean;
        Component: React.ComponentType<P>;
      },
): ComponentType<P> {
  const Component = "useIsOfflineFirst" in ComponentOrProps ? ComponentOrProps.Component : ComponentOrProps;

  const options = "useIsOfflineFirst" in ComponentOrProps ? ComponentOrProps : undefined;

  if (!options) {
    return function Wrapped(props: P) {
      return (
        <LoadChildrenFromCacheOnly>
          <Component {...props} />
        </LoadChildrenFromCacheOnly>
      );
    };
  }

  const { useIsOfflineFirst } = options;

  return function Wrapped(props: P) {
    const isOfflineFirst = useIsOfflineFirst();

    if (!isOfflineFirst) {
      return <Component {...props} />;
    }

    return (
      <LoadChildrenFromCacheOnly>
        <Component {...props} />
      </LoadChildrenFromCacheOnly>
    );
  };
}

const LoadChildrenFromCacheOnly: ComponentType = (props) => {
  const parentEnvironment = useClientEnvironment();
  const isInitialSyncComplete = useIsInitialSyncComplete();

  const environment = useMemo(() => {
    if (!isInitialSyncComplete) return parentEnvironment;

    return {
      ...parentEnvironment,
      recordLoader: createRecordLoader(parentEnvironment, { defaultFetchStrategy: "cache-and-server" }),
    };
  }, [isInitialSyncComplete, parentEnvironment]);

  return <ClientEnvironmentProvider environment={environment}>{props.children}</ClientEnvironmentProvider>;
};
