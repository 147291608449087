export function isNativeIOS() {
  if (isNative === undefined) {
    function getCookie(name: string): string | null {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        if (!c) return null;
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    isNative = getCookie("app-platform") === "iOS App Store";
  }

  return isNative;
}

let isNative: boolean | undefined;
