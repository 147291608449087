import { isEqual } from "libs/predicates";
import { throttleAsyncFn } from "libs/promise-utils";
import { createRecordMapFromPointersWithRecords } from "libs/schema";
import {
  applyOperation,
  applyTransaction,
  getOperationPointers,
  invertOperation,
  op,
  Operation,
  Transaction,
} from "libs/transaction";
import { RequiredParameters } from "libs/type-helpers";
import { uniqWith, pick } from "lodash-comms";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { GetRecordOptions } from "~/environment/RecordLoader";
import { EnqueueOptions } from "~/environment/TransactionQueue";

/* -------------------------------------------------------------------------------------------------
 *  withTransaction
 * -------------------------------------------------------------------------------------------------
 */

export function withTransaction<R>(
  label: string,
  fn: (environment: ClientEnvironment, transaction: Transaction, props: undefined) => Promise<R>,
): (environment: ClientEnvironment) => Promise<R>;
export function withTransaction<T extends Record<string, any> | undefined, R>(
  label: string,
  fn: (environment: ClientEnvironment, transaction: Transaction, props: T) => Promise<R>,
): (environment: ClientEnvironment, props: T) => Promise<R>;
export function withTransaction<T extends Record<string, any> | undefined = undefined>(
  label: string,
  fn: (environment: ClientEnvironment, transaction: Transaction, props: T) => Promise<any>,
) {
  return async (environment: ClientEnvironment, props: T) => {
    using disposable = environment.isPendingUpdate.add();

    const currentUserId = environment.auth.getAndAssertCurrentUserId();

    const transaction = op.transaction({
      authorId: currentUserId,
      label,
    });

    const logger = createTxLogger(environment, { transaction, data: props });

    return await fn({ ...environment, logger }, transaction, props);
  };
}

/* -------------------------------------------------------------------------------------------------
 *  write
 * -------------------------------------------------------------------------------------------------
 */

export async function write(
  environment: ClientEnvironment,
  props: {
    transaction: Transaction;
    canUndo?: boolean | ((props: { isRedo: boolean }) => boolean | Promise<boolean>);
    onOptimisticUndo?: () => void | Promise<void>;
    onServerUndo?: () => void | Promise<void>;
    onOptimisticWrite?: (props: { isRedo: boolean }) => void | Promise<void>;
    onServerWrite?: (props: { isRedo: boolean }) => void | Promise<void>;
  },
  /**
   * Internal use. Instead use the exported `debouncedWrite` function.
   */
  _debounce?: EnqueueOptions["debounce"],
): Promise<void> {
  const { undoRedo } = environment;
  const { transaction, canUndo = true } = props;

  const undoTransaction = invertTransaction(environment, transaction);

  if (undoTransaction && canUndo) {
    // Currently we only support undoing the most recent change. This is a user experience decision, as allowing undoing
    // more transactions can get confusing if you accidently undo an action which was taken on a previous page.
    undoRedo.undoStack = [
      {
        transaction: undoTransaction,
        canUndo: typeof canUndo === "function" ? canUndo : undefined,
        debounce: _debounce,
        onOptimisticUndo: props.onOptimisticUndo,
        onServerUndo: props.onServerUndo,
        onOptimisticRedo: props.onOptimisticWrite,
        onServerRedo: props.onServerWrite,
      },
    ];

    undoRedo.redoStack = [];
    undoRedo.resetUndoWindow();
  }

  optimisticWrite(environment, transaction);

  props.onOptimisticWrite?.({ isRedo: false });

  await enqueueTransaction(environment, transaction, { debounce: _debounce });

  await props.onServerWrite?.({ isRedo: false });
}

/* -------------------------------------------------------------------------------------------------
 *  debouncedWrite
 * -------------------------------------------------------------------------------------------------
 */

// The TransactionQueue already has logic to debounce enqueued transactions if we provide a debounce key.
// The problem is that, generally, when constructing a transaction we need to perform async operations to
// fetch documents. If we don't debounce these async operations, then potentially we call our function to
// construct the transaction multiple times, each time an async process starts to get the related documents,
// those processes can resolve out of order resulting in the transactions getting enqueued (with the
// debounce option) out of order. To fix this we throttle enqueuing debounced transactions until the async
// operation to fetch the documents has resolved. Notably, we don't want to wait for the debounced transaction
// to be committed on the server though.
//
// Note that `debouncedWrite` could have unexpected interactions with the TransactionQueue. E.g. If
// debouncedWriteInner is pending that means a transaction is still being constructed but hasn't been added
// to the transactionQueue. Calling TransactionQueue#flush would thus not affect that transaction. For this
// reason, the debouncedWrite function has a flush method that can be used to wait for all debounced transactions
// to finish processing.
export async function debouncedWrite<T extends Record<string, any> | undefined, R>(
  environment: ClientEnvironment,
  /** If debounce is undefined then this is basically the same as calling the `write` function. */
  debounce: EnqueueOptions["debounce"],
  fn: () => Promise<
    | {
        transaction: Transaction;
        canUndo?: boolean | ((props: { isRedo: boolean }) => boolean | Promise<boolean>);
        onOptimisticUndo?: () => void | Promise<void>;
        onServerUndo?: () => void | Promise<void>;
        onOptimisticWrite?: (props: { isRedo: boolean }) => void | Promise<void>;
        onServerWrite?: (props: { isRedo: boolean }) => void | Promise<void>;
      }
    | undefined
  >,
): Promise<void> {
  if (debounce) {
    const { wrappedPromise } = await debouncedWriteInner(environment, debounce, fn);
    return wrappedPromise;
  }

  const writeProps = await fn();
  if (!writeProps) return;
  return module.write(environment, writeProps);
}

const debouncedWriteInner = throttleAsyncFn(
  async (...args: RequiredParameters<typeof debouncedWrite>) => {
    const [environment, debounce, fn] = args;
    const writeProps = await fn();
    // The promise returned by `write` will resolve when the transaction has been committed on the server.
    // We only want to throttle enqueueing the debounced transaction. We don't want to wait for the
    // transaction to be committed. As such, we return the promise wrapped in an object so that
    // the throttle logic doesn't await it's resolution.
    const wrappedPromise = writeProps ? module.write(environment, writeProps, debounce) : Promise.resolve();
    return { wrappedPromise };
  },
  (_, debounce) => debounce.key,
);

debouncedWrite.promiseCache = debouncedWriteInner.promiseCache;

/**
 * Waits for the provided keys to finish being added to the TransactionQueue. If no keys are provided then it waits for all
 * debounced transactions.
 */
debouncedWrite.flush = async (keys?: string[]) => {
  if (!keys) {
    keys = Array.from(debouncedWrite.promiseCache.keys());
  }

  await Promise.all(
    keys.map(async (key) => {
      let cached = debouncedWrite.promiseCache.get(key);

      while (cached?.executingPromise) {
        await cached.executingPromise;
        cached = debouncedWrite.promiseCache.get(key);
      }
    }),
  );
};

/* -------------------------------------------------------------------------------------------------
 *  undo
 * -------------------------------------------------------------------------------------------------
 */

export async function undo(environment: ClientEnvironment) {
  const { undoRedo } = environment;

  if (undoRedo.running) return;

  const undoEntry = undoRedo.undoStack.pop();
  if (!undoEntry) return;

  try {
    undoRedo.running = true;

    const { transaction, canUndo, debounce } = undoEntry;

    if (canUndo && !(await canUndo({ isRedo: false }))) {
      return;
    }

    // It's possible that the records used by the original transaction have been garbage
    // collected from memory so we refetch them here.
    const pointers = uniqWith(transaction.operations.flatMap(getOperationPointers), isEqual);
    await environment.recordLoader.getRecords(pointers, { includeSoftDeletes: true, fetchStrategy: "cache-first" });

    // Optimistic update.
    const redoTransaction = invertTransaction(environment, transaction);
    if (!redoTransaction) throw new Error("Undo transactions should always be invertible.");
    undoRedo.redoStack.push({ ...undoEntry, transaction: redoTransaction });
    undoRedo.resetUndoWindow();

    optimisticWrite(environment, transaction);
    undoEntry.onOptimisticUndo?.();

    await enqueueTransaction(environment, transaction, { debounce });
    await undoEntry.onServerUndo?.();
  } finally {
    undoRedo.running = false;
  }
}

/* -------------------------------------------------------------------------------------------------
 *  redo
 * -------------------------------------------------------------------------------------------------
 */

export async function redo(environment: ClientEnvironment) {
  const { undoRedo } = environment;

  if (undoRedo.running) return;

  const redoEntry = undoRedo.redoStack.pop();
  if (!redoEntry) return;

  try {
    undoRedo.running = true;

    const { transaction, canUndo, debounce } = redoEntry;

    if (canUndo && !(await canUndo({ isRedo: true }))) {
      return;
    }

    // It's possible that the records used by the original transaction have been garbage
    // collected from memory so we refetch them here.
    const pointers = uniqWith(transaction.operations.flatMap(getOperationPointers), isEqual);
    await environment.recordLoader.getRecords(pointers, { includeSoftDeletes: true, fetchStrategy: "cache-first" });

    // Optimistic update.
    const undoTransaction = invertTransaction(environment, transaction);
    if (!undoTransaction) throw new Error("Redo transactions should always be invertible.");
    undoRedo.undoStack.push({ ...redoEntry, transaction: undoTransaction });
    undoRedo.resetUndoWindow();

    optimisticWrite(environment, transaction);
    redoEntry.onOptimisticRedo?.({ isRedo: true });

    await enqueueTransaction(environment, transaction, { debounce });
    await redoEntry.onServerRedo?.({ isRedo: true });
  } finally {
    undoRedo.running = false;
  }
}

/* -------------------------------------------------------------------------------------------------
 *  createTxLogger
 * -------------------------------------------------------------------------------------------------
 */

export function createTxLogger(
  environment: Pick<ClientEnvironment, "logger">,
  props?: {
    transaction?: Transaction;
    data?: Record<string, any> | Record<string, any>[];
  },
) {
  const data = Array.isArray(props?.data) ? props?.data.reduce(Object.assign, {}) : props?.data;

  const bindings = {
    ...data,
    transaction: pick(props?.transaction, ["txId", "label"]),
  };

  if (props?.transaction?.label) {
    bindings.name = props.transaction.label;
  }

  return environment.logger.child(bindings);
}

/* -------------------------------------------------------------------------------------------------
 *  utility functions
 * -------------------------------------------------------------------------------------------------
 */

function invertTransaction(environment: ClientEnvironment, transaction: Transaction) {
  const { db } = environment;
  const { operations, authorId } = transaction;

  // Get cached records for this write.
  const pointers = uniqWith(operations.flatMap(getOperationPointers), isEqual);
  const [pointerWithRecords] = db.getRecords(pointers, { includeSoftDeletes: true });
  const recordMap = createRecordMapFromPointersWithRecords(pointerWithRecords);

  const undoOperations: Operation[] = [];
  for (const operation of operations) {
    const invert = invertOperation({ recordMap, operation, authorId });
    if (invert) undoOperations.push(invert);
  }

  if (undoOperations.length) {
    const undoTransaction = op.transaction({
      label: transaction.label?.startsWith("Undo") ? `Redo ${transaction.label}` : `Undo ${transaction.label}`,
      authorId,
      operations: undoOperations,
    });

    return undoTransaction;
  }
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * This function syncronously writes the transaction to the in-memory database and then asynchronously
 * writes it to the persisted database. The returned promise will resolve when the transaction has finished
 * being applied to both databases.
 */
// Important! Should be a sync function. See comments below.
function optimisticWrite(
  environment: Pick<ClientEnvironment, "db" | "writeRecordMap" | "logger">,
  transaction: Transaction,
): Promise<void> {
  const { db, writeRecordMap } = environment;

  // Get cached records for this write.
  const pointers = uniqWith(transaction.operations.flatMap(getOperationPointers), isEqual);
  const [pointerWithRecords] = db.getRecords(pointers, { includeSoftDeletes: true });
  const recordMap = createRecordMapFromPointersWithRecords(pointerWithRecords);

  const currentTimestamp = new Date().toISOString();

  // Optimistic update.
  applyTransaction({
    recordMap,
    transaction,
    currentTimestamp,
    isServer: false,
  });

  environment.logger.debug({ recordMap, transaction }, `[${transaction.label}] optimisticWrite`);

  // Note that writeRecordMap itself is not an async function but it returns a promise.
  // It synchronously updates the in memory cache and async updates the persisted database.
  // Similarly, we want this optimistic write to be synchronous on the in-memory database
  // so we don't want to make this an async function.
  return writeRecordMap(recordMap).catch((error) => {
    environment.logger.error(
      { transaction, error },
      // The in-memory database will error synchronously so if we've caught a rejected promise here
      // that means it came from the persisted database.
      `[${transaction.label}] error applying optimistic write to persisted db`,
    );
  });
}

/* -----------------------------------------------------------------------------------------------*/

async function enqueueTransaction(
  environment: Pick<ClientEnvironment, "transactionQueue" | "logger">,
  transaction: Transaction,
  options?: { debounce?: EnqueueOptions["debounce"] },
) {
  const { transactionQueue, logger } = environment;

  try {
    await transactionQueue.enqueue(transaction, options);
  } catch (error) {
    logger.error({ ...transaction, error }, `[enqueueTransaction] error`);
  }
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * Gets the records associated with the transaction and applies the changes to them, in memory.
 * Returns a record map containing the changes. These changes are not committed anywhere.
 */
export async function getRecordsWithTransactionApplied(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { transaction: Transaction; currentTimestamp?: string },
  fetchOptions: GetRecordOptions,
) {
  const pointers = uniqWith(props.transaction.operations.flatMap(getOperationPointers), isEqual);

  const [pointersWithRecord] = await environment.recordLoader.getRecords(pointers, fetchOptions);

  const beforeRecordMap = createRecordMapFromPointersWithRecords(pointersWithRecord);

  const afterRecordMap = structuredClone(beforeRecordMap);

  const currentTimestamp = props.currentTimestamp || new Date().toISOString();

  // Apply the mutations.
  for (const operation of props.transaction.operations) {
    applyOperation({
      recordMap: afterRecordMap,
      operation,
      authorId: props.transaction.authorId,
      currentTimestamp,
      isServer: false,
    });
  }

  return afterRecordMap;
}

/* -----------------------------------------------------------------------------------------------*/

// Used for testing purposes
export const module = {
  write,
  debouncedWrite,
};
