import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { defaultUserSettings, NormalizedUserSettingsDoc } from "libs/constants/defaultUserSettings";
import { GetOptions } from "~/environment/RecordLoader";

export async function getNormalizedUserSettings(
  environment: Pick<ClientEnvironment, "auth" | "recordLoader">,
  options?: GetOptions,
): Promise<NormalizedUserSettingsDoc | null> {
  const currentUserId = environment.auth.getAndAssertCurrentUserId();

  const [userSettings] = await environment.recordLoader.getRecord("user_settings", currentUserId, options);

  const settings = userSettings?.settings;

  if (!settings) return null;

  const hasValidScheduledDeliverySettings =
    settings &&
    settings.scheduled_days &&
    settings.scheduled_times &&
    settings.scheduled_days.length > 0 &&
    settings.scheduled_times.length > 0;

  if (!hasValidScheduledDeliverySettings) {
    return defaultUserSettings();
  }

  return {
    ...defaultUserSettings(),
    ...settings,
  };
}
