import { htmlToText } from "libs/htmlToText";
import {
  DraftAttachmentDoc,
  DraftRecipientDoc,
  generateRecordId,
  getPointer,
  MessageRecipientDoc,
  RecordValue,
} from "libs/schema";
import { op, Operation } from "libs/transaction";
import { UnreachableCaseError } from "libs/errors";

/* -------------------------------------------------------------------------------------------------
 *  createEditDraft
 * -------------------------------------------------------------------------------------------------
 */

export interface CreateEditDraftProps {
  messageId: string;
  threadId: string;
  userId: string;
  ownerOrganizationId: string;
  /**
   * Note that drafts for replies only add recipients that are *new* to the thread to the `to`
   * field. If someone is mentioned in a reply who is already participating in the thread, they
   * will not be added to the `to` field. Because of this, we need to ensure that all mentioned
   * users and groups are included as recipients when constructing the message.
   */
  to: DraftRecipientDoc[];
  bodyHTML?: string;
  attachments?: DraftAttachmentDoc[];
}

export function createEditDraft(props: CreateEditDraftProps) {
  const draftPointer = getPointer("draft_edit", props.messageId);

  const operations: Operation[] = [
    op.upsert(draftPointer, {
      onCreate: [
        op.set(draftPointer, {
          id: props.messageId,
          user_id: props.userId,
          thread_id: props.threadId,
          body_html: props.bodyHTML ?? "",
          attachments: props.attachments ?? [],
          to: props.to ?? [],
          owner_organization_id: props.ownerOrganizationId,
        }),
      ],
      onUpdate: [
        op.update(draftPointer, {
          attachments: props.attachments,
          body_html: props.bodyHTML,
          to: props.to,
        }),
      ],
    }),
  ];

  return operations;
}

/* -------------------------------------------------------------------------------------------------
 *  setDraftEdit
 * -------------------------------------------------------------------------------------------------
 */

export interface SetDraftEditProps {
  draftId: string;
  userId: string;
  ownerOrganizationId: string;
  threadId: string;
  bodyHTML?: string;
  /**
   * Note that drafts for replies only add recipients that are *new* to the thread to the `to`
   * field. If someone is mentioned in a reply who is already participating in the thread, they
   * will not be added to the `to` field. Because of this, we need to ensure that all mentioned
   * users and groups are included as recipients when constructing the message.
   */
  to?: DraftRecipientDoc[];
  attachments?: DraftAttachmentDoc[];
}

export function setDraftEdit(props: SetDraftEditProps) {
  const operations = [
    op.set("draft_edit", {
      id: props.draftId,
      user_id: props.userId,
      thread_id: props.threadId,
      body_html: props.bodyHTML ?? "",
      attachments: props.attachments ?? [],
      to: props.to ?? [],
      owner_organization_id: props.ownerOrganizationId,
    }),
  ];

  return operations;
}

/* -------------------------------------------------------------------------------------------------
 *  saveMessageEdits
 * -------------------------------------------------------------------------------------------------
 */

export type ApplyEditsToMessageProps = {
  draft: RecordValue<"draft_edit">;
  message: RecordValue<"message">;
  shouldResendNotifications: boolean;
};

/**
 * Doesn't delete the draft records. That should be done separately.
 */
export function applyEditsToMessage(props: ApplyEditsToMessageProps) {
  const { draft, message } = props;
  const pointer = getPointer("message", draft.id);

  const operations: Operation[] = [
    op.update(pointer, {
      to: draft.to.map((recipient): MessageRecipientDoc => {
        switch (recipient.type) {
          case "GROUP": {
            return {
              id: generateRecordId("message_group_recipient_doc", {
                type: "GROUP",
                group_id: recipient.group_id,
              }),
              type: "GROUP",
              group_id: recipient.group_id,
              priority: recipient.priority,
              is_implicit: recipient.is_implicit,
              is_mentioned: recipient.is_mentioned,
            };
          }
          case "USER": {
            return {
              id: generateRecordId("message_user_recipient_doc", {
                type: "USER",
                user_id: recipient.user_id,
              }),
              type: "USER",
              user_id: recipient.user_id,
              priority: recipient.priority,
              is_implicit: recipient.is_implicit,
              is_mentioned: recipient.is_mentioned,
            };
          }
          default: {
            throw new UnreachableCaseError(recipient);
          }
        }
      }),
      body_text: htmlToText(draft.body_html),
      body_html: draft.body_html,
      attachments: draft.attachments,
      was_edited: true,
      last_edited_at: op.fieldValue.SERVER_TIMESTAMP(),
      is_delivered: false,
      delivered_at: null,
    }),
    op.update(pointer, "data", {
      resend_notifications: message.data?.resend_notifications || props.shouldResendNotifications,
    }),
  ];

  return operations;
}

/* -----------------------------------------------------------------------------------------------*/
