import { useEffect } from "react";
import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { createFormControl, useControl } from "solid-forms-react";
import { onSubmitFn, observable, useControlState } from "~/components/forms/utils";
import { useRegisterCommands } from "~/environment/command.service";
import { TextInput } from "~/components/forms/TextInput";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { closeDialogCommand } from "~/utils/common-commands";
import * as DialogLayout from "~/dialogs/DialogLayout";
import { toast } from "~/environment/toast-service";
import { updateUserSettings } from "~/actions/updateUserSettings";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { NormalizedUserSettingsDoc } from "libs/constants/defaultUserSettings";
import { getNormalizedUserSettings } from "~/queries/getNormalizedUserSettings";

export type IEditUndoSendWindowDialogData = {
  settings: NormalizedUserSettingsDoc;
};

export type IEditUndoSendWindowDialogReturnData = {
  success: boolean;
} | void;

export const EditUndoSendWindowDialogState = new DialogState<
  IEditUndoSendWindowDialogData,
  IEditUndoSendWindowDialogReturnData
>();

export const EditUndoSendWindowDialog = withModalDialog({
  dialogState: EditUndoSendWindowDialogState,
  async loadData({ environment, data }) {
    if (data?.settings) return data;

    const settings = await getNormalizedUserSettings(environment);

    return {
      settings,
    };
  },
  Component({ data }) {
    if (!data) {
      throw new Error("Missing required data");
    }

    const { settings } = data;

    if (!settings) {
      alert(`Could not find user settings data. Are you connected to the internet?`);

      EditUndoSendWindowDialogState.close();
      return null;
    }

    const environment = useClientEnvironment();

    const control = useControl(() => createFormControl(String(settings.seconds_for_undoing_sent_message)));

    useEffect(() => {
      observable(() => control.rawValue).subscribe((rawValue) => {
        const value = parseInt(rawValue, 10);

        const isInvalid = isNaN(value) || value < 0;

        if (isInvalid) {
          control.setErrors({ invalid: true });
        } else {
          control.setErrors(null);
        }
      });
    }, [control]);

    const hasErrors = useControlState(() => !!control.errors, [control]);

    useRegisterCommands({
      commands: () => {
        return [
          closeDialogCommand({
            callback: () => {
              EditUndoSendWindowDialogState.close();
            },
          }),
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>How many seconds do you want to have to undo sending a message?</h2>
        </DialogTitle>

        <div className={DIALOG_CONTENT_WRAPPER_CSS}>
          <form onSubmit={onSubmitFn({ control, environment, submit })} className="flex flex-col p-4">
            <label htmlFor="undo-send-window-value">
              After sending a message, how many seconds should Comms wait before actually sending that message? During
              this time, you will be able to undo the sending of the message. A value of 0 means that the message will
              be sent instantly and you won't be able to undo sending it.
            </label>

            <TextInput
              id="undo-send-window-value"
              name="Undo send duration"
              type="number"
              control={control}
              className="px-2 py-1 my-2 border focus-within:border-blue-9 rounded border-slate-8"
            />

            {hasErrors && <div className="text-red-10 font-medium">Must be greater than or equal to zero.</div>}
          </form>

          <DialogLayout.DialogFooter>
            <DialogLayout.DialogSubmitButton
              onClick={() => {
                if (!control.isValid) {
                  control.markTouched(true);
                  return;
                }

                submit(environment, control.value);
              }}
            />
          </DialogLayout.DialogFooter>
        </div>
      </>
    );
  },
});

const submit = onlyCallFnOnceWhilePreviousCallIsPending(async (environment: ClientEnvironment, value: string) => {
  using disposable = environment.isLoading.add();

  console.log("submitting...");

  EditUndoSendWindowDialogState.close();

  updateUserSettings(environment, {
    seconds_for_undoing_sent_message: parseInt(value, 10),
  });

  toast("vanilla", {
    subject: "Saved.",
  });
});
