import { getPointer } from "libs/schema";
import { op } from "libs/transaction";
import { withTransaction, write } from "./write";
import { toast } from "~/environment/toast-service";
import { GetOptions, GetRecordOptions } from "~/environment/RecordLoader";
import { promiseAllKeyed } from "libs/promise-utils";
import * as ops from "libs/actions";

/* -------------------------------------------------------------------------------------------------
 * toggleMessageReaction
 * -------------------------------------------------------------------------------------------------
 */

export const toggleMessageReaction = withTransaction(
  "toggleMessageReaction",
  async (
    environment,
    transaction,
    props: {
      messageId: string;
      reactionId: string;
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const pointer = getPointer("message_reactions", props.messageId);
    const [reaction] = await environment.recordLoader.getRecord(pointer);

    let usersReactions = reaction?.reactions?.[currentUserId] || [];

    if (usersReactions.includes(props.reactionId)) {
      usersReactions = usersReactions.filter((r) => r !== props.reactionId);
    } else {
      usersReactions.push(props.reactionId);
    }

    transaction.operations.push(op.set(pointer, ["reactions", currentUserId], usersReactions));

    await write(environment, {
      transaction,
      onOptimisticUndo: () => {
        toast("vanilla", { subject: "Undoing message reaction." });
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 * setMessageReactions
 * -------------------------------------------------------------------------------------------------
 */

export const setMessageReactions = withTransaction(
  "setMessageReactions",
  async (
    environment,
    transaction,
    props: {
      messageId: string;
      reactions: string[];
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const pointer = getPointer("message_reactions", props.messageId);
    transaction.operations.push(op.set(pointer, ["reactions", currentUserId], props.reactions));
    await write(environment, {
      transaction,
      onOptimisticUndo: () => {
        toast("vanilla", { subject: "Undoing message reaction." });
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  unscheduleMessageToBeSent
 * -------------------------------------------------------------------------------------------------
 */

export const unscheduleMessageToBeSent = withTransaction(
  "unscheduleMessageToBeSent",
  async (environment, transaction, props: { messageId: string }) => {
    const options: GetRecordOptions = { fetchStrategy: "cache-first", includeSoftDeletes: true };

    const [[message], [draft]] = await Promise.all([
      environment.recordLoader.getRecord("message", props.messageId, options),
      environment.recordLoader.getRecord("draft", props.messageId, options),
    ]);

    if (!message) {
      environment.logger.warn({ message }, "message not found");
      return;
    } else if (message.deleted_at) {
      environment.logger.notice({ message }, "message already deleted");
      return;
    } else if (message.sent_at <= new Date().toISOString()) {
      environment.logger.error({ message }, "message already sent");
      return;
    }

    if (!draft) {
      environment.logger.warn({ draft }, "draft not found");
      return;
    } else if (!draft.deleted_at) {
      environment.logger.notice({ draft }, "draft already unsent");
      return;
    } else if (draft.is_edit) {
      environment.logger.error({ draft }, "draft is an edit");
      return;
    }

    transaction.operations.push(
      op.update(
        { table: "draft", id: props.messageId },
        {
          deleted_at: null,
          deleted_by_user_id: null,
        },
      ),
      op.delete("message", message),
      op.delete("message_reactions", message),
    );

    if (!message.is_reply) {
      // Don't want to fetch deleted records
      const options: GetOptions = { fetchStrategy: "cache-first" };

      const {
        getMessages: [messages],
        getThreadGroupPermissions: [groupPermissions],
        getThreadSubscriptions: [subscriptions],
        getThreadTags: [threadTags],
        getThreadTimelineEntries: [timelineEntries],
        getThreadUserParticipants: [userParticipants],
        getThreadUserPermissions: [userPermissions],
      } = await promiseAllKeyed({
        getMessages: environment.recordLoader.getMessages({ thread_id: message.thread_id }, options),
        getThreadGroupPermissions: environment.recordLoader.getThreadGroupPermissions(
          { thread_id: message.thread_id },
          options,
        ),
        getThreadSubscriptions: environment.recordLoader.getThreadSubscriptions(
          { thread_id: message.thread_id },
          options,
        ),
        getThreadTags: environment.recordLoader.getThreadTags({ thread_id: message.thread_id }, options),
        getThreadTimelineEntries: environment.recordLoader.getThreadTimelineEntries(
          { thread_id: message.thread_id },
          options,
        ),
        getThreadUserParticipants: environment.recordLoader.getThreadUserParticipants(
          { thread_id: message.thread_id },
          options,
        ),
        getThreadUserPermissions: environment.recordLoader.getThreadUserPermissions(
          { thread_id: message.thread_id },
          options,
        ),
      });

      if (messages.length > 1) {
        throw new Error(`[unsendMessage] thread has more than one message: ${message.thread_id}`);
      }

      ops.applyOperationsToTransaction(
        transaction,
        groupPermissions.map((r) => op.delete("thread_group_permission", r)),
        subscriptions.map((r) => op.delete("thread_subscription", r)),
        threadTags.map((r) => op.delete("thread_tag", r)),
        timelineEntries.map((r) => op.delete("thread_timeline", r)),
        userParticipants.map((r) => op.delete("thread_user_participant", r)),
        userPermissions.map((r) => op.delete("thread_user_permission", r)),
      );
    }

    if (draft.branched_from_thread_id) {
      transaction.operations.push(
        op.update(
          getPointer("thread_timeline", {
            entry_id: message.id,
            thread_id: draft.branched_from_thread_id,
          }),
          { deleted_at: null, deleted_by_user_id: null },
        ),
      );
    }

    await write(environment, {
      transaction,
      onOptimisticWrite: () => {
        toast("vanilla", {
          subject: "Unsending message...",
        });
      },
      onServerWrite: () => {
        toast("vanilla", {
          subject: "Unsending message...Done!",
        });
      },
      onOptimisticUndo: () => {
        toast("vanilla", {
          subject: "Resending message...",
        });
      },
      onServerUndo: () => {
        toast("vanilla", {
          subject: "Resending message...Done!",
        });
      },
    });
  },
);

/* -----------------------------------------------------------------------------------------------*/
