import { useMemo } from "react";
import { useRecord } from "./useRecord";
import { getPointer } from "libs/schema";

export function useThreadUserParticipant(props: {
  threadId: string | null | undefined;
  userId: string | null | undefined;
}) {
  const pointer = useMemo(() => {
    if (!props.threadId || !props.userId) return null;

    return getPointer("thread_user_participant", {
      thread_id: props.threadId,
      user_id: props.userId,
    });
  }, [props.threadId, props.userId]);

  return useRecord(pointer, { name: "useThreadUserParticipant" });
}
