import { ComponentType, Suspense, useRef } from "react";
import { useParams } from "react-router";
import { useForceSidebarIntoMode } from "~/page-layouts/sidebar-layout";
import { Helmet } from "react-helmet-async";
import { NotFound } from "~/components/NotFound";
import { ListScrollbox } from "~/components/list";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { slate } from "@radix-ui/colors";
import { useIsOnline } from "~/hooks/useIsOnline";
import { closeViewCommand, deleteThreadCommand, restoreThreadCommand } from "~/utils/common-commands";
import { useSetWebpageBackgroundColor } from "~/hooks/useSetWebpageBackgroundColor";
import { ThreadTimeline } from "./ThreadTimeline";
import { closeThreadView } from "./utils";
import { ThreadHeader } from "./ThreadHeader";
import { ActionToolbar } from "./ActionToolbar";
import { useThread } from "~/hooks/useThread";
import { useThreadFound } from "~/hooks/useIsThreadFound";
import { deriveUUID, isUuid } from "libs/uuid";
import { Navigate } from "react-router-dom";
import { usePreloadThreadData } from "~/hooks/usePreloadThreadData";
import { withOfflineFirstIfSynced } from "~/components/withOfflineFirstIfSynced";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useNotification } from "~/hooks/useNotification";
import { ThreadLoading } from "./ThreadLoading";
import { ThreadPermissionDenied } from "./ThreadPermissionDenied";
import { cx } from "@emotion/css";
import { RecordValue } from "libs/schema";
import { useUserProfile } from "~/hooks/useUserProfile";
import { OutlineButton } from "~/components/OutlineButtons";
import { deleteThread, restoreThread } from "~/actions/thread";

/* -------------------------------------------------------------------------------------------------
 * ThreadView
 * -----------------------------------------------------------------------------------------------*/

export const ThreadView: ComponentType = withOfflineFirstIfSynced({
  useIsOfflineFirst: () => {
    const params = useParams();
    const [notification] = useNotification({ threadId: params.threadId, fetchStrategy: "cache" });
    return !!notification && !notification.is_done;
  },
  Component: () => {
    const params = useParams();
    const scrollboxRef = useRef<HTMLElement>(document.body);
    const headerRef = useRef<HTMLElement>(null);
    const isAppOnline = useIsOnline();
    const threadId = params.threadId;

    useSetWebpageBackgroundColor(slate.slate3);

    useRegisterThreadViewCommands({ threadId });

    useForceSidebarIntoMode("over");

    const { threadFound } = useThreadFound(threadId);
    const { isThreadLoading } = usePreloadThreadData({ threadId });
    const [thread] = useThread(threadId, { includeSoftDeletes: true });
    const isThreadDeleted = !!thread?.deleted_at;

    useTopScrollShadow({
      scrollboxRef,
      targetRef: headerRef,
      deps: [isThreadLoading, threadFound],
    });

    if (!threadId) {
      return <NotFound title="Thread Not Found" />;
    }

    // If a user is attempting to nagivate to a link for the old version of Comms,
    // redirect them to the correct url.
    if (threadId && !isUuid(threadId)) {
      return <Navigate to={`/threads/${deriveUUID(threadId)}`} replace />;
    }

    if (threadFound !== "found" && !isThreadLoading) {
      if (threadFound === "permission-denied") {
        return <ThreadPermissionDenied threadId={threadId} />;
      }

      if (!isAppOnline) {
        return <NotFound title="App Offline" />;
      }

      return <NotFound title="Thread Not Found" />;
    }

    return (
      <Suspense fallback={<ThreadLoading />}>
        <div className="MainPanel">
          <ThreadViewHeader threadId={threadId} headerRef={headerRef} />

          <div className="flex">
            <ThreadLayout.ActionPanel>
              <ActionToolbar threadId={threadId} />
            </ThreadLayout.ActionPanel>

            <ThreadLayout.ContentPanel>
              {isThreadLoading ?
                <ThreadLoading />
              : <ListScrollbox
                  isBodyElement
                  offsetHeaderEl={headerRef}
                  offsetTopPx={isThreadDeleted ? 42 : 0}
                  onlyOffsetHeaderElIfSticky
                >
                  <ThreadTimeline threadId={threadId} />
                </ListScrollbox>
              }
            </ThreadLayout.ContentPanel>

            <div className="flex-1" />
          </div>
        </div>
      </Suspense>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

const ThreadViewHeader: ComponentType<{
  threadId: string;
  headerRef: React.RefObject<HTMLElement>;
}> = (props) => {
  const [thread] = useThread(props.threadId, { includeSoftDeletes: true });

  return (
    <>
      <Helmet>
        <title>{thread?.subject || ""} | Thread | Comms</title>
      </Helmet>

      {thread?.deleted_at && <ThreadDeletedBanner thread={thread} />}

      <ThreadLayout.Header
        ref={props.headerRef}
        theme={thread?.visibility === "PRIVATE" ? "dark" : "light"}
        // "marginTop" ensures that child elements account for the parents position while "top" ensures that the
        // header itself accounts for the deleted banner if the header is sticking to the top.
        style={{ marginTop: thread?.deleted_at ? "42px" : undefined, top: thread?.deleted_at ? "42px" : undefined }}
      >
        <ThreadLayout.ActionPanel />

        <ThreadLayout.ContentPanel>
          <ThreadHeader threadId={props.threadId} />
        </ThreadLayout.ContentPanel>

        <div className="flex-1" />
      </ThreadLayout.Header>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ThreadDeletedBanner: ComponentType<{
  thread: Pick<RecordValue<"thread">, "id" | "deleted_at" | "deleted_by_user_id">;
}> = (props) => {
  const [user] = useUserProfile(props.thread.deleted_by_user_id);

  return (
    <div
      className={cx(
        "fixed top-0 w-full z-30 flex justify-center items-center transition-shadow duration-300 bg-red-9",
        "text-white font-medium py-1",
      )}
    >
      Thread deleted{user ? ` by ${user.name}` : ""}
      <OutlineButton onClick={() => restoreThreadCommand.trigger()} className="border-white ml-2 scale-75">
        Restore
      </OutlineButton>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

/**
 * These commands will be available to someone on the thread
 * view even if the thread hasn't loaded.
 */
function useRegisterThreadViewCommands(props: { threadId: string | null | undefined }) {
  const { threadId } = props;
  const environment = useClientEnvironment();
  const [thread] = useThread(threadId, { includeSoftDeletes: true });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        closeViewCommand({
          callback() {
            closeThreadView();
          },
        }),
      ];

      if (thread) {
        if (thread?.deleted_at) {
          commands.push(
            restoreThreadCommand({
              callback: () => {
                restoreThread(environment, { threadId: thread.id });
              },
            }),
          );
        } else {
          commands.push(
            deleteThreadCommand({
              callback: () => {
                deleteThread(environment, { threadId: thread.id });
              },
            }),
          );
        }
      }

      return commands;
    },
    deps: [thread],
  });
}

/* -----------------------------------------------------------------------------------------------*/
