import { PointerWithRecord, RecordTable, RecordValue, tableProps } from "libs/schema";
import { sql, Sql } from "libs/sql-statement";

/* -------------------------------------------------------------------------------------------------
 * getSqlToUpsertRecord
 * -------------------------------------------------------------------------------------------------
 */

/**
 * @returns a Sql object containing a SQL statement and params for upserting the
 *   given record to postgres.
 */
export function getSqlToUpsertRecord<T extends RecordTable>(
  /**
   * Note that this isn't typed as PointerWithRecord because
   * records upserted to the database may have properties mapped to
   * different types than then those expected by PointerWithRecord.
   */
  pointerWithRecord: { table: T; id: string; record: Record<string, unknown> },
  /**
   * Unless `true`, updates will be ignored unless the existing record version
   * is less than the incoming record version.
   */
  forceUpdate: boolean,
): Sql {
  return getUpsertFn(pointerWithRecord.table, pointerWithRecord.record, forceUpdate);
}

function getUpsertFn<T extends RecordTable>(table: T, record: Record<string, unknown>, forceUpdate: boolean) {
  const keys = tableProps[table] as Array<keyof RecordValue<T> & string>;

  const keyValueEntries = keys
    .filter((prop) => record[prop] !== undefined)
    .map((prop) => {
      let value = record[prop];

      if (typeof value === "object" && value !== null) {
        value = JSON.stringify(value);
      }

      return [prop, value] as const;
    });

  return sql`
    INSERT INTO "${sql.raw(table)}" 
      (${sql.join(
        keyValueEntries.map(([k]) => sql.raw(`"${k}"`)),
        ",\n",
      )}) 
    VALUES (
      ${sql.join(
        keyValueEntries.map(([, value]) => value),
        ",\n",
      )}
    )
    ON CONFLICT (id) 
    DO UPDATE
    SET
      ${sql.join(
        keyValueEntries.map(([key, value]) => sql`"${sql.raw(key)}" = ${value}`),
        ",\n",
      )}
    ${forceUpdate ? sql.EMPTY : sql`WHERE "${sql.raw(table)}".version < ${record.version}`};
  `;
}
