import { cx } from "@emotion/css";
import { forwardRef, memo, PropsWithChildren } from "react";
import { useAlwaysMakeHeaderStickySetting } from "~/hooks/useAlwaysMakeHeaderStickySetting";
// import { useAlwaysMakeHeaderStickySetting } from "~/pages/settings/SettingsForm";
import { SidebarIcon } from "../main-layout";

export const Header = memo(
  forwardRef<
    HTMLElement,
    PropsWithChildren<{
      className?: string | boolean | null;
      theme?: "light" | "dark";
      style?: React.CSSProperties;
    }>
  >((props, ref) => {
    const [alwaysMakeHeaderSticky] = useAlwaysMakeHeaderStickySetting();

    return (
      <header
        ref={ref}
        className={cx(
          "relative top-0 flex z-20 transition-shadow duration-300",
          props.theme === "dark" ? "bg-slateDark-5 text-white" : "bg-slate-3",
          alwaysMakeHeaderSticky ? "sticky" : "md-max-h:shadow-none md-h:sticky",
          props.className,
        )}
        style={props.style}
      >
        <SidebarIcon theme={props.theme} />

        {props.children}
      </header>
    );
  }),
);
