import { DraftAttachmentDoc, getMapRecords } from "libs/schema";
import { op } from "libs/transaction";
import { IEditorMention } from "~/components/forms/message-editor";
import { toast } from "~/environment/toast-service";
import * as ops from "libs/actions";
import { debouncedWrite, getRecordsWithTransactionApplied, withTransaction, write } from "./write";
import { GetRecordOptions } from "~/environment/RecordLoader";
import { EnqueueOptions } from "~/environment/TransactionQueue";
import { DraftRecipient, getDraftDebounceKey, getToRecipients } from "./draft";

/* -------------------------------------------------------------------------------------------------
 *  createEditDraft
 * -------------------------------------------------------------------------------------------------
 */

export interface CreateEditDraftProps {
  messageId: string;
  currentUserId: string;
  ownerOrganizationId: string;
  threadId: string;
  to?: DraftRecipient[];
  cc?: DraftRecipient[];
  bcc?: DraftRecipient[];
  bodyHTML?: string;
  userMentions?: IEditorMention[];
  groupMentions?: IEditorMention[];
  attachments?: DraftAttachmentDoc[];
}

/**
 * Updates a draft. If the draft is ready to be sent (scheduled_to_be_sent_at is set), it will be sent.
 */
export const createEditDraft = withTransaction(
  "createEditDraft",
  async (environment, transaction, props: CreateEditDraftProps) => {
    // If the message being edited was just optimistically created via a draft, it's possible
    // that there are still debounced writes associated with the draft. We wait for these
    // to be flushed.
    await debouncedWrite.flush([getDraftDebounceKey(props.messageId)]);

    // Can't create edits for a message that doesn't exist or was deleted.
    const [message] = environment.db.getRecord("message", props.messageId);
    if (!message || message.deleted_at) return;

    ops.applyOperationsToTransaction(
      transaction,
      ops.draftEdit.createEditDraft({
        messageId: props.messageId,
        userId: props.currentUserId,
        ownerOrganizationId: props.ownerOrganizationId,
        threadId: props.threadId,
        attachments: props.attachments,
        bodyHTML: props.bodyHTML,
        to: getToRecipients(props),
      }),
    );

    await write(environment, {
      transaction,
      canUndo: false,
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  setDraftEdit
 * -------------------------------------------------------------------------------------------------
 */

export interface SetDraftEditProps {
  draftId: string;
  currentUserId: string;
  ownerOrganizationId: string;
  threadId: string;
  bodyHTML?: string;
  to?: DraftRecipient[];
  cc?: DraftRecipient[];
  bcc?: DraftRecipient[];
  userMentions?: IEditorMention[];
  groupMentions?: IEditorMention[];
  attachments?: DraftAttachmentDoc[];
  noDebounce?: boolean;
}

/**
 * This is intended to be used for updates, but since it's implemented as a "set" I decided to name it that.
 * Prefer "createDraft" for creating a new draft. This function should not be used for "undeleting" a draft.
 * We attempt to guard against accidental undeletes by checking if the draft has been deleted before updating it.
 */
export const setDraftEdit = withTransaction(
  "setDraftEdit",
  async (environment, transaction, props: SetDraftEditProps) => {
    const debounceOptions: EnqueueOptions["debounce"] | undefined =
      props.noDebounce ? undefined : { key: getDraftEditDebounceKey(props.draftId), debounceMs: 1500, maxWaitMs: 4000 };

    await debouncedWrite(environment, debounceOptions, async () => {
      const [draftEdit] = environment.db.getRecord("draft_edit", props.draftId);
      if (draftEdit?.deleted_at) return;

      ops.applyOperationsToTransaction(
        transaction,
        ops.draftEdit.setDraftEdit({
          draftId: props.draftId,
          userId: props.currentUserId,
          ownerOrganizationId: props.ownerOrganizationId,
          threadId: props.threadId,
          attachments: props.attachments,
          bodyHTML: props.bodyHTML,
          to: getToRecipients(props),
        }),
      );

      return {
        transaction,
        canUndo: false,
      };
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  commitDraftEdits
 * -------------------------------------------------------------------------------------------------
 */

export const commitDraftEdits = withTransaction(
  "commitDraftEdits",
  async (
    environment,
    transaction,
    props: SetDraftEditProps & {
      shouldResendNotifications?: boolean;
      afterUndo?: () => void;
      noToast?: boolean;
    },
  ) => {
    const { recordLoader, logger } = environment;

    // Wait for any debounced writes associated with the draft to finish being added to the tx queue.
    await debouncedWrite.flush([getDraftEditDebounceKey(props.draftId)]);

    const fetchOptions: GetRecordOptions = { fetchStrategy: "cache-first" };

    // Check locally to make sure the draft exists
    const [existingDraft] = await environment.recordLoader.getRecord("draft_edit", props.draftId, fetchOptions);
    if (!existingDraft) return;

    ops.applyOperationsToTransaction(
      transaction,
      ops.draftEdit.setDraftEdit({
        draftId: props.draftId,
        userId: props.currentUserId,
        ownerOrganizationId: props.ownerOrganizationId,
        threadId: props.threadId,
        attachments: props.attachments,
        bodyHTML: props.bodyHTML,
        to: getToRecipients(props),
      }),
    );

    const afterRecordMap = await getRecordsWithTransactionApplied(environment, { transaction }, fetchOptions);

    const [draftEdit] = getMapRecords(afterRecordMap, "draft_edit");

    if (!draftEdit) {
      logger.error({ draftId: props.draftId }, `[commitDraftEdits] draft not found.`);
      throw new Error(`[commitDraftEdits] draft not found.`);
    }

    logger.notice({ draftId: draftEdit.id }, `[commitDraftEdits] applying edits ...`);

    const [message] = await recordLoader.getRecord("message", draftEdit.id, fetchOptions);

    if (!message) {
      logger.error(`[commitDraftEdits] message not find ${draftEdit.id}`);
      alert(`Count not find message to edit`);
      throw new Error(`[commitDraftEdits] message not found.`);
    }

    ops.applyOperationsToTransaction(
      transaction,
      ops.draftEdit.applyEditsToMessage({
        draft: draftEdit,
        message,
        shouldResendNotifications: !!props.shouldResendNotifications,
      }),
    );

    // Delete the draft now that it's been sent.
    transaction.operations.push(op.delete("draft_edit", draftEdit));

    await write(environment, {
      transaction,
      // If the edits added recipients to the thread, and if those changes were processed
      // by the server, then undoing them is more complicated than just undoing this tx.
      canUndo: false,
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  deleteDraftEdit
 * -------------------------------------------------------------------------------------------------
 */

export const deleteDraftEdit = withTransaction(
  "deleteDraftEdit",
  async (
    environment,
    transaction,
    props: {
      currentUserId: string;
      draftId: string;
      afterUndo?: () => void;
    },
  ) => {
    environment.logger.info({ draftId: props.draftId }, `[deleteDraftEdit] deleting draft edit ...`);

    // Wait for any debounced writes associated with the draft to finish being added to the tx queue.
    await debouncedWrite.flush([getDraftEditDebounceKey(props.draftId)]);

    transaction.operations.push(op.delete("draft_edit", props.draftId));

    try {
      await write(environment, {
        transaction,
        onOptimisticWrite: () => {
          toast("vanilla", {
            subject: "Edits discarded.",
          });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: "Failed to discard edits.",
      });

      throw error;
    }
  },
);

/* -----------------------------------------------------------------------------------------------*/

export function getDraftEditDebounceKey(draftId: string) {
  return `draft-edit-${draftId}`;
}

/* -----------------------------------------------------------------------------------------------*/
