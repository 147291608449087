import { ComponentType, MouseEventHandler, ReactNode } from "react";
import { IMessageEditorControl } from "./forms/message-editor";
import { DraftAttachmentDoc, MessageAttachmentDoc } from "libs/schema";
import {
  useMarkControlPendingDuringAttachmentUpload,
  usePendingUploadState,
  useSyncAttachmentUploadErrorToControl,
} from "./forms/message-editor/uploads";
import { Tooltip } from "./Tooltip";
import { cx } from "@emotion/css";
import { IoClose } from "react-icons/io5";
import { MdDownload } from "react-icons/md";
import { useControlState } from "./forms/utils";

/* -------------------------------------------------------------------------------------------------
 * MessageAttachment
 * -----------------------------------------------------------------------------------------------*/

export const MessageAttachment: ComponentType<{
  messageId: string;
  attachment: MessageAttachmentDoc;
}> = (props) => {
  const href = `/uploads/${props.attachment.id}?messageId=${props.messageId}`;

  return (
    <AttachmentBase
      attachmentUrl={href}
      fileName={props.attachment.fileName}
      fileContentType={props.attachment.contentType}
      actions={
        <div className={cx("flex shrink-0 items-center justify-center")}>
          <a
            aria-label="download"
            href={href}
            download={props.attachment.fileName}
            onClick={(e) => e.stopPropagation()}
            className="p-1 text-lg text-mauve-11 hover:text-black hover:no-underline"
          >
            <MdDownload />
          </a>
        </div>
      }
    />
  );
};

/* -------------------------------------------------------------------------------------------------
 * DraftAttachment
 * -----------------------------------------------------------------------------------------------*/

export const DraftAttachment: ComponentType<{
  control: IMessageEditorControl;
  attachment: DraftAttachmentDoc;
}> = (props) => {
  const { control, attachment } = props;
  const attachmentId = attachment.id;

  const isReadonly = useControlState(() => control.isDisabled || control.isReadonly, [control]);
  const messageId = useControlState(() => control.controls.messageId.rawValue, [control]);

  const pendingUpload = usePendingUploadState((state) => state.pendingUploads[attachmentId]);

  useMarkControlPendingDuringAttachmentUpload({
    control,
    pendingUpload,
  });

  useSyncAttachmentUploadErrorToControl({
    control,
    uploadId: attachmentId,
    pendingUpload,
  });

  const onDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    if (pendingUpload) {
      if (!pendingUpload.cancelled) {
        usePendingUploadState.getState().cancelPendingUpload(attachmentId);
      }

      usePendingUploadState.getState().removePendingUpload(attachmentId);
    }

    props.control.patchValue({
      attachments: control.controls.attachments.rawValue.filter((a) => a.id !== attachmentId),
    });
  };

  return (
    <AttachmentBase
      attachmentUrl={`/uploads/${props.attachment.id}?messageId=${messageId}`}
      fileName={props.attachment.fileName}
      fileContentType={props.attachment.contentType}
      errorMsg={attachment.errorMsg}
      uploadPercent={pendingUpload?.progress}
      actions={
        !isReadonly && (
          <div
            className={cx(
              "flex shrink-0 items-center justify-center text-lg text-mauve-11 w-6 h-6",
              "hover:cursor-pointer hover:text-black",
            )}
          >
            <button type="button" aria-label="delete" onClick={onDelete}>
              <IoClose />
            </button>
          </div>
        )
      }
    />
  );
};

/* -------------------------------------------------------------------------------------------------
 * AttachmentsContainer
 * -----------------------------------------------------------------------------------------------*/

export const AttachmentsContainer: ComponentType<{}> = (props) => {
  return (
    <div className="mt-3">
      <div className="flex gap-3 flex-wrap">{props.children}</div>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const AttachmentBase: ComponentType<{
  attachmentUrl: string;
  fileName: string | null;
  fileContentType: string | null;
  errorMsg?: string;
  uploadPercent?: number;
  actions?: ReactNode;
}> = (props) => {
  const fileType = usePrintContentType(props.fileContentType);

  const isUploading = typeof props.uploadPercent !== "undefined" && props.uploadPercent < 100;

  const tooltipMsg =
    props.errorMsg ? "Upload failed"
    : isUploading ? `Uploading ${props.uploadPercent}%`
    : props.fileName || "";

  // TODO:
  // In the iOS native build I'm not sure if it makes sense to open the attachment in a new tab
  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (isUploading) return;
    window.open(props.attachmentUrl, "_blank");
  };

  const backgroundColor =
    props.errorMsg ? "bg-redA-5"
    : isUploading ? "bg-white"
    : "bg-slateA-5";

  return (
    <Tooltip side="top" content={tooltipMsg}>
      <div
        className={cx(
          "relative max-w-[180px] rounded hover:cursor-pointer border overflow-hidden",
          backgroundColor,
          props.errorMsg ? "border-red-10"
          : isUploading ? "border-blue-10"
          : "border-slate-5",
        )}
        onClick={onClick}
      >
        {isUploading && (
          <div
            className={cx(
              "absolute top-0 left-0 h-full pointer-events-none z-0",
              props.errorMsg ? "bg-redA-5" : "bg-blueA-5",
            )}
            style={{
              width: `${props.uploadPercent}%`,
              transition: "width 0.2s ease",
            }}
          />
        )}

        <div className="p-2 flex items-center space-x-2">
          <div className="bg-white rounded px-1 text-red-10 text-sm uppercase">{fileType}</div>

          <div className="text-sm truncate">{props.fileName || "unknown"}</div>

          {props.actions}
        </div>
      </div>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function usePrintContentType(contentType: string | null | undefined) {
  const [primary, secondary] = (contentType || "").toLowerCase().split("/");

  if (primary === "application") {
    switch (secondary) {
      case "pdf": {
        return "PDF";
      }
      case "zip": {
        return "ZIP";
      }
      case "msword": {
        return "DOC";
      }
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "vnd.openxmlformats-officedocument.wordprocessingml.template": {
        return "DOCX";
      }
      case "vnd.ms-excel": {
        return "XLS";
      }
      case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "vnd.openxmlformats-officedocument.spreadsheetml.template": {
        return "XLSX";
      }
      case "vnd.ms-powerpoint": {
        return "PPT";
      }
      case "vnd.openxmlformats-officedocument.presentationml.presentation":
      case "vnd.openxmlformats-officedocument.presentationml.template":
      case "vnd.openxmlformats-officedocument.presentationml.slideshow": {
        return "PPTX";
      }
      default: {
        return "FILE";
      }
    }
  } else {
    return secondary;
  }
}

/* -----------------------------------------------------------------------------------------------*/
