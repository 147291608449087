import { Plugin, PluginKey, TextSelection } from "@tiptap/pm/state";
import { Extension } from "@tiptap/react";
import { SuggestPluginActionMeta, SuggestPluginState, TSuggestionDropdownContext } from "./context";
import { filterSuggestions } from "./SuggestionsDropdownProvider";
import { buildFilterSuggestionString } from "./utils";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    searchSuggestions: {
      /** Toggle the search suggestions dropdown */
      toggleSearchSuggestions: () => ReturnType;
      /** Open the search suggestions dropdown */
      showSearchSuggestions: () => ReturnType;
      /** Close the search suggestions dropdown */
      closeSearchSuggestions: () => ReturnType;
    };
  }
}

/*
 * This tiptap extension provides a dropdown for search filter suggestions.
 * The dropdown is implemented in react and it's state is held outside of tiptap. Meanwhile,
 * we need tiptap to manage a concept of suggestion "open/closed" because tiptap will highlight
 * text that is being suggested. Managing this state between tiptap and react is very annoying.
 *
 * My initial implementation wasn't the best, but I think the correct approach is to manage
 * the suggestion dropdown state in react as the source of truth. This being said, this
 * implementation is part-way refactored in that direction but not fully. I got things
 * working for the time being and needed to move on to other issues. Unfortunately, this
 * is pretty ugly still. I expect it can be simplified much more (or refactored entirely) but hopefully
 * we won't need to touch this for a while.
 * -- John 4/24/24
 */

export const SearchFilterSuggestions = Extension.create<{
  suggestionContext: TSuggestionDropdownContext;
}>({
  name: "SearchFilterSuggestions",
  addProseMirrorPlugins() {
    if (!this.options.suggestionContext) {
      // Provide the autocompleteContext via `SearchFilterSuggestions.configure({ autocompleteContext })`
      throw new Error("SearchFilterSuggestions: autocompleteContext is required.");
    }

    return [createPlugin(this.options.suggestionContext)];
  },
  addCommands() {
    return {
      toggleSearchSuggestions: () => {
        return ({ commands }) => {
          if (this.options.suggestionContext.getIsOpen()) {
            return commands.closeSearchSuggestions();
          } else {
            return commands.showSearchSuggestions();
          }
        };
      },
      showSearchSuggestions: () => {
        return ({ dispatch }) => {
          if (dispatch) {
            this.options.suggestionContext.setIsOpen(true);
          }

          return true;
        };
      },
      closeSearchSuggestions: () => {
        return ({ dispatch }) => {
          if (dispatch) {
            this.options.suggestionContext.setIsOpen(false);
          }

          return true;
        };
      },
    };
  },
});

const pluginKey = new PluginKey<SuggestPluginState>("search-suggestions");

function createPlugin(context: TSuggestionDropdownContext) {
  return new Plugin<SuggestPluginState>({
    key: pluginKey,
    state: {
      init() {
        return { isOpen: false };
      },
      apply(tr, state) {
        const isContentSelected = tr.selection.$anchor !== tr.selection.$head;
        const metadata = tr.getMeta(pluginKey) as SuggestPluginActionMeta | undefined;

        if (isContentSelected) {
          return { isOpen: false, isOpenChangedBy: metadata?.isOpenChangedBy };
        }

        if (metadata?.type === "close") {
          return {
            ...state,
            isOpen: false,
            isOpenChangedBy: metadata.isOpenChangedBy,
          };
        } else if (tr.docChanged || metadata?.type === "open" || metadata?.isOpenChangedBy) {
          const openedBy = metadata?.isOpenChangedBy;
          const pos = tr.selection.$head;

          // get text for the current paragraph. @mentions will be considered
          // empty leafText that takes up 1 position so we provide the option
          // to count them as an single " " else our relativePos index will be
          // off.
          const text = pos.doc.textBetween(pos.start(), pos.end(), " ", " ");
          // // get the current cursor index within the paragraph
          const relativePos = pos.pos - pos.start() - 1;

          const result = getWordAtIndex(text, relativePos);

          if (!result) {
            return {
              isOpen: metadata?.type === "open",
              isOpenChangedBy: openedBy,
              context: {
                word: "",
                from: pos.pos,
                to: pos.pos,
              },
            };
          }

          // close the dropdown if the word is a suggestion
          const matchesSuggestion = filterSuggestions.some((o) => o.name === result.word);
          if (matchesSuggestion) {
            return {
              isOpen: false,
              isOpenChangedBy: openedBy,
              context: {
                word: "",
                from: pos.pos,
                to: pos.pos,
              },
            };
          }

          return {
            isOpen: true,
            isOpenChangedBy: openedBy,
            context: {
              word: result.word,
              from: result.wordStartIndex + pos.start(),
              to: result.wordEndIndex + pos.start(),
            },
          };
        } else if (!state.isOpen || !state.context) {
          return state;
        } else {
          const pos = tr.selection.$head.pos;

          if (pos < state.context.from || pos > state.context.to) {
            return { ...state, isOpen: false, isOpenChangedBy: undefined };
          }

          return state;
        }
      },
    },
    props: {
      handleDOMEvents: {
        focus() {
          context.setIsSearchFocused(true);
        },
        blur(_, event) {
          const didUserClickOnSuggestion = context.floatingElRef.current?.contains(event.relatedTarget as HTMLElement);

          if (didUserClickOnSuggestion) return;

          context.setIsSearchFocused(false);
        },
      },
    },
    view() {
      return {
        update(view) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const state = pluginKey.getState(view.state)!;

          const pos = view.state.selection.$head.pos;

          const rect = view.coordsAtPos(pos);

          const onSelect = (suggestion: {
            name: string;
            hint?: string;
            receivesNestedQuery?: boolean;
            selectHint?: boolean;
          }) => {
            if (!state.isOpen || !state.context) return;

            const { tr } = view.state;

            const newText = buildFilterSuggestionString(suggestion);
            tr.insertText(newText, state.context.from, state.context.to);

            const isStartingPosition = state.context.from === 1 && state.context.to === 1;

            let from = suggestion.name.length;
            if (!isStartingPosition) {
              const offset = suggestion.receivesNestedQuery ? 2 : 0;
              from = tr.mapping.map(state.context.from) + suggestion.name.length + offset;
            }

            let to: number;
            if (isStartingPosition) {
              if (suggestion.receivesNestedQuery) {
                to = tr.mapping.map(1) - 2;
              } else {
                to = tr.mapping.map(1);
              }
            } else if (suggestion.receivesNestedQuery) {
              to = tr.mapping.map(state.context.to) - 2;
            } else {
              to = tr.mapping.map(state.context.to);
            }

            if (suggestion.hint && suggestion.selectHint) {
              tr.setSelection(TextSelection.create(tr.doc, from, to));
            } else {
              to = tr.mapping.map(state.context.to);
              tr.setSelection(TextSelection.create(tr.doc, to, to));
            }

            view.dispatch(tr);
          };

          if (state.isOpen) {
            context.setIsOpen(true);
          }

          context.setSuggestionDropdownProps({
            state,
            onSelect,
          });

          context.setPosition({ left: rect.left, top: rect.bottom });
        },
      };
    },
  });
}

function getWordAtIndex(text: string, index: number) {
  const char = text[index];

  if (!char || char === " ") return false;

  const before = text.slice(0, index);
  const after = text.slice(index);

  const startingSpaceIndex = before.lastIndexOf(" ");
  const endingSpaceIndex = after.indexOf(" ");

  const wordStartIndex = startingSpaceIndex < 0 ? 0 : startingSpaceIndex + 1;
  const wordEndIndex = endingSpaceIndex < 0 ? text.length : endingSpaceIndex + index;

  return {
    word: text.slice(wordStartIndex, wordEndIndex),
    wordStartIndex,
    wordEndIndex,
  };
}
