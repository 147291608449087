import { Editor } from "@tiptap/react";

/**
 * Builds a text representation of the filter suggestion
 */
export const buildFilterSuggestionString = (suggestion: {
  name: string;
  hint?: string;
  receivesNestedQuery?: boolean;
}) => {
  let newText: string;
  if (suggestion.receivesNestedQuery) {
    newText = `${suggestion.name}( ${suggestion.hint} )`;
  } else {
    newText = suggestion.hint ? `${suggestion.name}${suggestion.hint}` : `${suggestion.name} `;
  }

  return newText;
};

export const getTextSelectionRange = (
  suggestion: {
    name: string;
    hint: string;
    receivesNestedQuery?: boolean;
  },
  editor: Editor,
) => {
  const selection = editor.view.state.selection;
  let from = selection.$from.pos - suggestion.hint.length;
  if (suggestion.receivesNestedQuery) {
    from -= 2;
  }

  let to = selection.$from.pos;
  if (suggestion.receivesNestedQuery) {
    to -= 2;
  }

  return { from, to };
};
