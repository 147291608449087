import { ComponentType, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { usePrevious } from "react-use";
import useWindowSize from "react-use/lib/useWindowSize";
import { useInboxSectionIds } from "~/hooks/useInboxSectionIds";

export function useInboxZeroConfetti(isLoading: boolean, hasNotifications: boolean) {
  const prevHasNotifications = usePrevious(hasNotifications);

  const { width, height } = useWindowSize();

  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    if (!hasNotifications && prevHasNotifications) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 4000);
    }
  }, [hasNotifications, isLoading, prevHasNotifications]);

  return { showConfetti, windowWidth: width, windowHeight: height };
}

/* -----------------------------------------------------------------------------------------------*/

export const RedirectToDefaultInboxSection: ComponentType<{}> = () => {
  const [[defaultInboxSectionId]] = useInboxSectionIds();

  if (!defaultInboxSectionId) return null;

  return <Navigate to={`/inbox/${defaultInboxSectionId}`} replace />;
};
