import { generateRecordId, getPointer, RecordValue, SpecialTagTypeEnum, TagSubscriptionPreference } from "libs/schema";
import { op } from "libs/transaction";
import { toast } from "~/environment/toast-service";
import { difference } from "lodash-es";
import { isTagPrivate } from "libs/schema/predicates";
import { renderJsxToString } from "~/utils/renderJsxToString";
import * as ops from "libs/actions";
import { renderGroupName } from "~/utils/groups-utils";
import { withTransaction, write } from "./write";
import dayjs from "dayjs";

/* -------------------------------------------------------------------------------------------------
 * createGroup
 * -------------------------------------------------------------------------------------------------
 */

export const createGroup = withTransaction(
  "createGroup",
  async (
    environment,
    transaction,
    props: {
      groupId: string;
      icon: string | null;
      name: string;
      description: string | null;
      /** IDs of the tags which will act as folders for this tag */
      folderIds: string[];
      isPrivate: boolean;
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

    ops.applyOperationsToTransaction(
      transaction,
      ops.group.createGroup({
        creatorUserId: currentUserId,
        isPrivateGroup: props.isPrivate,
        icon: props.icon,
        name: props.name,
        ownerOrganizationId: ownerOrganizationId,
        description: props.description,
        groupId: props.groupId,
        nestedInGroupIds: props.folderIds,
      }),
      ops.tag.setTagSubscription({
        tagId: props.groupId,
        creatorUserId: currentUserId,
        ownerOrganizationId,
        preference: "all-new",
        userId: currentUserId,
      }),
    );

    await write(environment, {
      transaction,
      onOptimisticUndo: () => {
        toast("vanilla", { subject: "Undoing group creation." });
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 * updateTag
 * -------------------------------------------------------------------------------------------------
 */

export const updateTag = withTransaction(
  "updateTag",
  async (
    environment,
    transaction,
    props: {
      tagId: string;
      icon: string | null;
      name: string;
      description: string | null;
      /** IDs of the tags which will act as folders for this tag */
      folderIds: string[];
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

    const [[oldTag], [oldFolders]] = await Promise.all([
      environment.recordLoader.getRecord("tag", props.tagId),
      environment.recordLoader.getTagFolderMembers({
        tag_id: props.tagId,
      }),
    ]);

    if (!oldTag) {
      toast("vanilla", {
        subject: `Error updating tag.`,
      });

      environment.logger.error(`[updateTag] tag not found.`);
      return;
    }

    transaction.operations.push(
      op.update(
        { table: "tag", id: props.tagId },
        {
          name: props.name,
          icon: props.icon,
          description: props.description,
        },
      ),
    );

    const oldFolderIds = oldFolders.map((folder) => folder.folder_id);
    const newFolderIds = difference(props.folderIds, oldFolderIds);
    const removedFolderIds = difference(oldFolderIds, props.folderIds);

    removedFolderIds.forEach((folderId) => {
      transaction.operations.push(
        op.delete(
          getPointer("tag_folder_member", {
            tag_id: props.tagId,
            folder_id: folderId,
          }),
        ),
      );
    });

    newFolderIds.forEach((folderId) => {
      const pointer = getPointer("tag_folder_member", {
        tag_id: props.tagId,
        folder_id: folderId,
      });

      transaction.operations.push(
        op.set("tag_folder_member", {
          id: pointer.id,
          tag_id: props.tagId,
          folder_id: folderId,
          creator_user_id: currentUserId,
          owner_organization_id: ownerOrganizationId,
        }),
      );
    });

    await write(environment, {
      transaction,
      onOptimisticUndo: () => {
        toast("vanilla", { subject: "Undoing tag edit." });
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 * subscribeUsersToTag
 * -------------------------------------------------------------------------------------------------
 */

export const subscribeUsersToTag = withTransaction(
  "subscribeUsersToTag",
  async (
    environment,
    transaction,
    props: {
      tagId: string;
      userIds: string[];
      subscriptionPreference: TagSubscriptionPreference;
      notifyUsers: boolean;
    },
  ) => {
    toast("vanilla", {
      subject: `Subscribing users...`,
    });

    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();
    const userProfilePointers = props.userIds.map((userId) => getPointer("user_profile", userId));

    const [[tag], [userProfiles]] = await Promise.all([
      environment.recordLoader.getRecord("tag", props.tagId),
      environment.recordLoader.getRecords(userProfilePointers),
    ]);

    if (!tag) {
      toast("vanilla", {
        subject: `Error subscribing users.`,
      });

      environment.logger.error(`[subscribeUsersToTag] tag not found.`);
      return;
    }

    const isPrivate = isTagPrivate(tag);

    props.userIds.forEach((userId) => {
      const pointer = getPointer("tag_subscription", {
        tag_id: props.tagId,
        user_id: userId,
      });

      transaction.operations.push(
        op.upsert(pointer, {
          onCreate: [
            op.set(pointer.table, {
              id: pointer.id,
              tag_id: props.tagId,
              user_id: userId,
              creator_user_id: currentUserId,
              preference: props.subscriptionPreference,
              owner_organization_id: ownerOrganizationId,
            }),
          ],
          onUpdate: [
            {
              type: "upsert_on_update",
              where: {
                preference: { eq: "involved" },
              },
              operations: [
                op.update(pointer, {
                  preference: props.subscriptionPreference,
                }),
              ],
            },
          ],
        }),
      );

      if (isPrivate) {
        const pointer = getPointer("tag_user_member", {
          tag_id: props.tagId,
          user_id: userId,
        });

        transaction.operations.push(
          op.upsert(pointer, {
            onCreate: [
              op.set(pointer.table, {
                id: pointer.id,
                tag_id: props.tagId,
                user_id: userId,
                creator_user_id: currentUserId,
                owner_organization_id: ownerOrganizationId,
              }),
            ],
          }),
        );
      }
    });

    if (props.notifyUsers) {
      const now = dayjs().add(30, "seconds").toDate();

      props.userIds.forEach((userId) => {
        const userProfile = userProfiles.find((profile) => profile.id === userId)?.record;

        if (!userProfile) {
          environment.logger.error({ userId }, "subscribeUsersToTag: User profile not found.");
          return;
        }

        const subjectText = isPrivate
          ? `You've been subscribed to the private "#${tag.name}" group`
          : `You've been subscribed to the "#${tag.name}" group`;

        const bodyHtml = getMessageContentForSubscriptionNotification({
          user: userProfile,
          tag,
        });

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.sendNewThreadDraft({
            scheduledToBeSentAt: now,
            draft: {
              id: generateRecordId("draft"),
              type: "COMMS",
              to: [
                {
                  id: generateRecordId("draft_user_recipient_doc", {
                    type: "USER",
                    user_id: userId,
                  }),
                  type: "USER",
                  user_id: userId,
                  is_implicit: false,
                  is_mentioned: true,
                  priority: 300,
                },
              ],
              body_html: bodyHtml,
              attachments: [],
              branched_from_message_id: null,
              branched_from_thread_id: null,
              is_reply: false,
              is_edit: false,
              new_thread_subject: subjectText,
              new_thread_visibility: "PRIVATE",
              owner_organization_id: ownerOrganizationId,
              thread_id: generateRecordId("thread"),
              user_id: currentUserId,
            },
            branchedFromMessage: undefined,
          }),
        );
      });
    }

    try {
      await write(environment, {
        transaction,
        onOptimisticUndo: () => {
          toast("vanilla", { subject: "Undoing subscribing users." });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: `Error subscribing users.`,
      });

      throw error;
    }

    if (props.notifyUsers) {
      toast("vanilla", {
        subject: `Subscribing users...Done!`,
        description: `and notifications sent.`,
      });
    } else {
      toast("vanilla", {
        subject: `Subscribing users...Done!`,
      });
    }
  },
);

/* -------------------------------------------------------------------------------------------------
 * archiveTag
 * -------------------------------------------------------------------------------------------------
 */

export const archiveTag = withTransaction(
  "archiveTag",
  async (
    environment,
    transaction,
    props: {
      tagId: string;
    },
  ) => {
    transaction.operations.push(
      op.update({ table: "tag", id: props.tagId }, { archived_at: op.fieldValue.SERVER_TIMESTAMP() }),
    );

    try {
      await write(environment, {
        transaction,
        onOptimisticUndo: () => {
          toast("vanilla", { subject: "Undoing archiving group/tag." });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: `Error archiving group/tag.`,
      });

      throw error;
    }

    toast("vanilla", {
      subject: `Archived!`,
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 * unarchiveTag
 * -------------------------------------------------------------------------------------------------
 */

export const unarchiveTag = withTransaction(
  "unarchiveTag",
  async (
    environment,
    transaction,
    props: {
      tagId: string;
    },
  ) => {
    transaction.operations.push(op.update({ table: "tag", id: props.tagId }, { archived_at: null }));

    try {
      await write(environment, {
        transaction,
        onOptimisticUndo: () => {
          toast("vanilla", { subject: "Undoing unarchiving group/tag." });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: `Error unarchiving group/tag.`,
      });

      throw error;
    }

    toast("vanilla", {
      subject: `Unarchived!`,
    });
  },
);

/* -----------------------------------------------------------------------------------------------*/

function getMessageContentForSubscriptionNotification(props: {
  user: RecordValue<"user_profile">;
  tag: RecordValue<"tag">;
}) {
  const { user, tag } = props;

  const isPrivate = isTagPrivate(tag);
  const tagType = tag.type === SpecialTagTypeEnum.GROUP ? "group" : "tag";

  return renderJsxToString(
    <>
      <p>
        Hi{" "}
        <span data-type="mention" data-id={user.id} data-label={user.name} data-subject="user" data-priority="300">
          @{user.name}
        </span>
        ,
      </p>
      <p></p>
      <p>
        I've subscribed you to the{isPrivate ? " private " : " "}
        <a href={`/${tagType}s/${tag.id}`}>{renderGroupName(tag)}</a> {tagType}.
      </p>
    </>,
  );
}

/* -----------------------------------------------------------------------------------------------*/
