import { generateRecordId } from "libs/schema";
import { useMemo } from "react";
import { useRecord } from "./useRecord";

export function useThreadTag(props: { id?: string | null; threadId?: string | null; tagId?: string | null }) {
  const id = useMemo(() => {
    if (props.id) return props.id;
    if (props.threadId && props.tagId) {
      return generateRecordId("thread_tag", {
        thread_id: props.threadId,
        tag_id: props.tagId,
      });
    }

    return null;
  }, [props.id, props.threadId, props.tagId]);

  return useRecord("thread_tag", id, { name: "useThreadTag" });
}
