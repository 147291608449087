// Implementation taken from
// https://github.com/ueberdosis/tiptap/blob/b2bd909eaa687687b50fc8fa22330f042eaab4d1/packages/extension-list-item/src/list-item.ts

import { mergeAttributes, Node } from "@tiptap/core";
import { findParentNodeClosestToPos } from "prosemirror-utils";

export interface ListItemOptions {
  HTMLAttributes: Record<string, any>;
}

export const ListItem = Node.create<ListItemOptions>({
  name: "listItem",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: "paragraph block*",

  defining: true,

  parseHTML() {
    return [
      {
        tag: "li",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["li", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addKeyboardShortcuts() {
    const isValidTabEvent = () => {
      const { state } = this.editor;

      // If the cursor is at the start of a list item, then
      // selection.parent.parent.type === ListItem. Meanwhile,
      // selection.parent will be a paragraph node.

      const firstParentOfSelection = findParentNodeClosestToPos(state.selection.$head, () => true);

      const secondParentOfSelection =
        firstParentOfSelection && findParentNodeClosestToPos(state.doc.resolve(firstParentOfSelection.pos), () => true);

      const isSecondParentAListItem = secondParentOfSelection?.node.type === state.schema.nodes.listItem;

      if (!isSecondParentAListItem) return false;

      const isTextSelected = state.selection.to !== state.selection.from;
      const isCursorAtStartOfLine = state.selection.$head.parentOffset === 0;

      if (!isTextSelected && !isCursorAtStartOfLine) return false;

      return true;
    };

    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      "Mod-]": () => this.editor.commands.sinkListItem(this.name),
      "Mod-[": () => this.editor.commands.liftListItem(this.name),
      Tab: () => isValidTabEvent() && this.editor.commands.sinkListItem(this.name),
      "Shift-Tab": () => isValidTabEvent() && this.editor.commands.liftListItem(this.name),
    };
  },
});
