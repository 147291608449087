import { useInitialQueryLimit } from "./useInitialQueryLimit";
import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";

export type UseOrganizationUserIdsResult = UseRecordLoaderResult<string[]>;

export function useOrganizationUserIds(props: {
  organizationId: string | null | undefined;
  includeSoftDeletes?: boolean;
}): UseOrganizationUserIdsResult {
  const initialLimit = useInitialQueryLimit(50);

  return useRecordLoader({
    name: "useOrganizationUserIds",
    load({ loader, limit, currentUserId, deps: [organizationId] }) {
      if (!organizationId) {
        return loader.createObserveQueryResult<"user_profile">();
      }

      return loader.observeGetOrganizationUsers({
        currentUserId,
        organizationId: organizationId,
        includeSoftDeletes: props.includeSoftDeletes,
        limit,
      });
    },
    deps: [props.organizationId, props.includeSoftDeletes],
    initialLimit,
    limitStep: Math.ceil(initialLimit / 2),
    map: (records) => records.map((r) => r.id),
  });
}
