import { createContext } from "react";
import { IFormControl, IFormGroup } from "solid-forms-react";
import { DraftAttachmentDoc, ThreadVisibility } from "libs/schema";
import { createUseContextHook } from "~/utils/createUseContextHook";

export interface IEditorMention {
  type: "user" | "group";
  id: string;
  priority: number;
}

export type IMessageEditorControl = IFormGroup<{
  messageId: IFormControl<string>;
  type: IFormControl<"COMMS" | "EMAIL">;
  visibility: IFormControl<ThreadVisibility | null>;
  attachments: IFormControl<DraftAttachmentDoc[]>;
  body: IFormGroup<{
    content: IFormControl<string>;
    userMentions: IFormControl<IEditorMention[]>;
    groupMentions: IFormControl<IEditorMention[]>;
    /**
     * Count of the total number of mentions in this post.
     * At the moment, the only reason why we have this property
     * is so that form code can respond when someone adds
     * or removes an `@mention`. We
     * don't actually care about the total number of mentions in
     * a post. Because of this, at time of writing code in the
     * NewThreadForm and ComposePostReply components are
     * initializing this control with the possibly incorrect
     * value of `0`. So currently we cannot trust the value of
     * this control, but we can trust that it will update when
     * the mentions count updates.
     *
     * In the future if we refactor this to accurately display
     * the mentions count, we should rename this property.
     */
    possiblyIncorrectMentionsCount: IFormControl<number>;
  }>;
}>;

export interface IMessageEditorContext {
  control: IMessageEditorControl;
  saveDraftFn: () => Promise<void>;
}

export const MessageEditorContext = createContext<IMessageEditorContext | null>(null);

export const useMessageEditorContext = createUseContextHook(MessageEditorContext, "MessageEditorContext");
