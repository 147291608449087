import { ComponentType, memo } from "react";
import { cx } from "@emotion/css";
import { Tooltip } from "~/components/Tooltip";
import { isEqual } from "libs/predicates";
import { useInboxSubsectionIds } from "~/hooks/useInboxSubsectionIds";
import { useInboxSubsection } from "~/hooks/useInboxSubsection";
import { useBlockingLayoutInboxSubsection } from "~/hooks/useBlockingLayoutInboxSubsection";

export const BlockingInboxProgressBar = memo<{ inboxSectionId: string }>((props) => {
  const [inboxSubsectionIds] = useInboxSubsectionIds(props.inboxSectionId);

  const [inboxSubsection] = useBlockingLayoutInboxSubsection({
    inboxSectionId: props.inboxSectionId,
  });

  if (inboxSubsectionIds.length < 2) return null;

  const notificationCount = 0;
  // const notificationCount = inboxSubsection?.inbox_entries_count ?? 0;

  return (
    <div className="flex flex-1 text-[20px] hover:cursor-help">
      {inboxSubsectionIds.map((inboxSubsectionId, index) => {
        return (
          <ProgressEntry
            key={inboxSubsectionId}
            inboxSubsectionId={inboxSubsectionId}
            currentSubsectionIndex={inboxSubsection?.data.order ?? -1}
            relativeIndex={index}
            isLastSubsection={index === inboxSubsectionIds.length - 1}
            notificationCount={notificationCount}
          />
        );
      })}
    </div>
  );
}, isEqual);

const ProgressEntry: ComponentType<{
  inboxSubsectionId: string;
  currentSubsectionIndex: number;
  relativeIndex: number;
  isLastSubsection: boolean;
  notificationCount: number;
}> = (props) => {
  const [inboxSubsection] = useInboxSubsection(props.inboxSubsectionId);

  if (!inboxSubsection) return null;

  const isDone = props.relativeIndex < props.currentSubsectionIndex;
  const tooltip = isDone ? `${inboxSubsection.name} messages are done` : inboxSubsection.name;

  return (
    <Tooltip side="bottom" content={tooltip}>
      <div
        className={cx(
          "flex-1 py-[3px] flex justify-center items-center",
          props.relativeIndex === 0 ? "rounded-l" : "border-l",
          props.isLastSubsection ? "rounded-r" : "border-r",
          "text-black border-slate-8",
          props.currentSubsectionIndex === props.relativeIndex ? "bg-mint-5" : "bg-slate-5",
        )}
      >
        {props.currentSubsectionIndex === props.relativeIndex ? (
          `${inboxSubsection?.name} - (${props.notificationCount})`
        ) : isDone ? (
          <span className="text-xl">🎉</span>
        ) : null}
      </div>
    </Tooltip>
  );
};
