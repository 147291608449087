import { ComponentType, memo } from "react";
import { isNonNullable } from "libs/predicates";
import { isEqual } from "libs/predicates";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { EmptyListMessage } from "~/components/content-list/ContentList";
import { updateUserSettings } from "~/actions/updateUserSettings";
import { useNextScheduledDeliveryDatetime } from "~/hooks/scheduled-delivery";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export const EmptyInboxMessage: ComponentType<{}> = memo(() => {
  const { settings } = useCurrentUserSettings();

  if (settings?.enable_focus_mode && settings?.enable_scheduled_delivery) {
    return <FocusModeAndScheduledDeliveryEnabledMessage focusModeExceptions={settings.focus_mode_exceptions} />;
  } else if (settings?.enable_focus_mode) {
    return <FocusModeEnabledMessage focusModeExceptions={settings.focus_mode_exceptions} />;
  } else if (settings?.enable_scheduled_delivery) {
    return <ScheduledDeliveryEnabledMessage />;
  } else {
    return <EmptyListMessage text="Inbox Zero &nbsp; 🎉" />;
  }
}, isEqual);

const FocusModeEnabledMessage: ComponentType<{
  focusModeExceptions?: number[];
}> = memo((props) => {
  const environment = useClientEnvironment();

  const exceptions =
    props.focusModeExceptions
      ?.map((priority) => {
        switch (priority) {
          case 100:
            return "@@@interrupts";
          case 200:
            return "@@response-requested";
          case 300:
            return "@mentions";
        }
      })
      .filter(isNonNullable) || [];

  return (
    <Wrapper>
      <span className="text-2xl">Focus Mode is Enabled</span>

      <DescriptionWrapper>
        <div className="prose text-center">
          <p>
            You've enabled Focus Mode. All inbox notifications are hidden
            {exceptions.length > 0 && ` except ${exceptions.join(" and ")}`}.
          </p>
        </div>

        <div className="flex flex-1 justify-center mt-4">
          <button
            type="button"
            className="border rounded py-1 px-3 hover:cursor-pointer hover:bg-slate-5"
            onClick={() => {
              updateUserSettings(environment, { enable_focus_mode: false });
            }}
          >
            Disable Focus Mode
          </button>
        </div>
      </DescriptionWrapper>
    </Wrapper>
  );
}, isEqual);

const ScheduledDeliveryEnabledMessage: ComponentType<{}> = memo(() => {
  return (
    <Wrapper>
      <span className="text-2xl">Inbox Zero &nbsp; 🎉</span>

      <NextScheduledDelivery />

      <DescriptionWrapper>
        <div className="prose text-center">
          <p>
            Comms uses Scheduled Delivery. Messages only appear in your inbox at customizable times rather than
            immediately after the message is sent.
          </p>
        </div>

        <div className="flex flex-1 justify-center mt-4">
          <Link
            to="/settings#scheduled-delivery"
            className="border rounded py-1 px-3 hover:cursor-pointer hover:bg-slate-5"
          >
            Update Scheduled Delivery Settings
          </Link>
        </div>
      </DescriptionWrapper>
    </Wrapper>
  );
}, isEqual);

const FocusModeAndScheduledDeliveryEnabledMessage: ComponentType<{
  focusModeExceptions?: number[];
}> = memo((props) => {
  const environment = useClientEnvironment();

  const exceptions =
    props.focusModeExceptions
      ?.map((priority) => {
        switch (priority) {
          case 100:
            return "@@@interrupts";
          case 200:
            return "@@response-requested";
          case 300:
            return "@mentions";
        }
      })
      .filter(isNonNullable) || [];

  return (
    <Wrapper>
      <span className="text-2xl">Focus Mode and Scheduled Delivery are Enabled</span>

      <NextScheduledDelivery />

      <DescriptionWrapper>
        <div className="prose text-center">
          <p>
            You've enabled Focus Mode. Focus Mode is hiding all inbox notifications
            {exceptions.length > 0 && ` except ${exceptions.join(" and ")}`}. Separately, Comms uses Scheduled Delivery.
            Scheduled delivery causes inbox notifications to appear in your inbox at customizable times rather than
            immediately after the message is sent.
          </p>
        </div>

        <div className="flex flex-1 justify-center mt-4">
          <button
            type="button"
            className="border rounded py-1 px-3 hover:cursor-pointer hover:bg-slate-5"
            onClick={() => {
              updateUserSettings(environment, { enable_focus_mode: false });
            }}
          >
            Disable Focus Mode
          </button>

          <div className="w-8" />

          <Link
            to="/settings#scheduled-delivery"
            className="border rounded py-1 px-3 hover:cursor-pointer hover:bg-slate-5"
          >
            Update Scheduled Delivery Settings
          </Link>
        </div>
      </DescriptionWrapper>
    </Wrapper>
  );
}, isEqual);

const Wrapper: ComponentType<{}> = (props) => {
  return (
    <div className="mx-12 py-8 flex flex-col justify-center items-center bg-slate-2 rounded mb-20 text-slate-11">
      {props.children}
    </div>
  );
};

const DescriptionWrapper: ComponentType<{}> = (props) => {
  return <div className="mx-10 flex flex-col max-w-[600px] min-w-0 rounded p-4">{props.children}</div>;
};

const NextScheduledDelivery: ComponentType<{}> = () => {
  const nextDeliveryDate = useNextScheduledDeliveryDatetime();

  if (!nextDeliveryDate) return null;

  return (
    <div className="mb-4 mx-10 flex justify-center prose max-w-[600px] min-w-0 p-4 text-lg">
      Your next delivery will be {nextDeliveryDate.isSame(dayjs(), "date") ? "today" : nextDeliveryDate.format("dddd")}{" "}
      at {nextDeliveryDate.format("h:mm a")}.
    </div>
  );
};
