import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useLoadingObservable } from "./useLoadingObservable";
import { switchMap } from "rxjs";
import {
  ObserveUserPushNotificationSubscriptionForThisClientResult,
  observeUserPushNotificationSubscriptionForThisClient,
} from "~/observables/observeUserPushNotificationSubscriptionForThisClient";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";

export function useUserPushNotificationSubscriptionForThisClient() {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, currentUserId]) =>
          observeUserPushNotificationSubscriptionForThisClient(environment, { currentUserId }),
        ),
      );
    },
    deps: [environment, currentUserId],
  });
}

const DEFAULT_VALUE: ObserveUserPushNotificationSubscriptionForThisClientResult = [null, { isLoading: true }];
