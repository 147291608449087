import { ComponentType, RefObject, useMemo } from "react";
import { IListRef } from "~/components/list";
import { IRichTextEditorRef } from "~/components/forms/message-editor/MessageEditor";
import { Location, useLocation } from "react-router-dom";
import { firstValueFrom, map, Subject } from "rxjs";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { showNotImplementedToastMsg } from "~/environment/toast-service/toast.state";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { oneLine, stripIndents } from "common-tags";
import { toast } from "~/environment/toast-service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import { getSelectionAsHTMLString } from "./getSelectedPostHTMLString";
import { wait } from "libs/promise-utils";
import {
  getCurrentRouterLocation,
  navigateBackOrToInbox,
  navigateService,
  updateSearchParams,
} from "~/environment/navigate.service";
import {
  copyLinkToFocusedPostCommand,
  createBranchedReplyCommand,
  deleteDraftCommand,
  editMessageCommand,
  markDoneCommand,
  markNotDoneCommand,
  markThreadPrivateCommand,
  markThreadSharedCommand,
  reactToMessageCommand,
  removeThreadReminderCommand,
  replyToThreadCommand,
  setThreadReminderCommand,
  threadSubscriptionCommands,
  toggleThreadVisibilityCommand,
  addThreadToGroupCommand,
  markThreadResolvedCommand,
  markThreadNotResolvedCommand,
  focusThreadResolutionCommand,
} from "~/utils/common-commands";
import { getTimelineEntryElementId, writeToClipboard } from "~/utils/dom-helpers";
import { IEditorMention } from "~/components/forms/message-editor";
import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { getLastMessageEntry, getNearestMessageEntry } from "./utils";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { triageThread } from "~/actions/notification";
import {
  IThreadListNavigationArgs,
  ThreadListNavigationLocationStateKey,
  convertThreadViewPrevNextArgsToURL,
  getThreadViewPrevNextArgs,
  isThreadListNavigationArgsForGroupView,
  isThreadListNavigationArgsForInboxView,
  observeThreadViewPrevNextArgs,
} from "~/environment/thread-prev-next.service";
import { deleteDraft, createReplyDraft, createNewThreadDraft } from "~/actions/draft";
import { useNotification } from "~/hooks/useNotification";
import { useThread } from "~/hooks/useThread";
import { unsendAndFocusDraft } from "./actions/unsendAndFocusDraft";
import { MessageGroupRecipientDoc, MessageUserRecipientDoc, generateRecordId, getPointer } from "libs/schema";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { waitForCacheToContainRecord } from "~/utils/database-utils";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { MessageReactionPickerDialogState } from "~/dialogs/message-reaction-picker/MessageReactionPicker";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { getNormalizedUserSettings } from "~/queries/getNormalizedUserSettings";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { AddGroupToThreadDialogState } from "~/dialogs/thread-add-group/AddGroupToThreadDialog";
import { useThreadResolvedTag } from "~/hooks/useThreadResolvedTag";
import { markThreadNotResolved, markThreadResolved, updateThreadVisibility } from "~/actions/thread";
import { GetOptions } from "~/environment/RecordLoader";
import { createEditDraft } from "~/actions/draftEdit";

/* -------------------------------------------------------------------------------------------------
 * RegisterThreadTimelineCommands
 * -----------------------------------------------------------------------------------------------*/

export const RegisterThreadTimelineCommands: ComponentType<{
  threadId: string;
  useFocusedEntry: () => TThreadTimelineEntry | null;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  /** map of draftId to IRichTextEditorRef */
  editorRefStore: Map<string, IRichTextEditorRef>;
  collapseMessageEvents$: Subject<string>;
  // canEditFocusedEntry: false | "undo" | "edit";
  // setIsEditingPostId: (postId: string | null) => void;
}> = (props) => {
  const environment = useClientEnvironment();
  const { currentUserId, ownerOrganizationId } = useAuthGuardContext();
  const { settings } = useCurrentUserSettings();
  const reactRouterLocation = useLocation();
  const [thread] = useThread(props.threadId, { includeSoftDeletes: true });
  const [notification] = useNotification({ threadId: props.threadId });
  const [threadResolvedTag] = useThreadResolvedTag(props.threadId);

  const { threadId, listRef, editorRefStore, useFocusedEntry, collapseMessageEvents$ } = props;

  const focusedEntry = useFocusedEntry();

  const handleReplyCommand = useMemo(() => {
    return onlyCallFnOnceWhilePreviousCallIsPending(async () => {
      const selectedHTML = getSelectionAsHTMLString();

      const draftId = generateRecordId("draft");

      const createDraftPromise = createReplyDraft(environment, {
        type: "COMMS",
        currentUserId,
        ownerOrganizationId,
        threadId,
        draftId,
        bodyHTML: selectedHTML && `<blockquote>${selectedHTML}</blockquote><p></p><p></p>`,
      });

      const success = await waitForCacheToContainRecord(
        environment,
        { table: "draft", id: draftId },
        { relatedPromise: createDraftPromise },
      );

      if (success) {
        // tiptap handles its own rendering and it takes longer to initialize
        // than react does. Even after react has rendered the compose message
        // editor, tiptap's editor may not be ready to focus. In testing,
        // it takes about 30ms to initialize the tiptap editor on my laptop.
        // The "proper" way of doing this would be to get access to the editor
        // ref and use it to focus on the editor after it's initialized, but
        // so far this seems to work well and doesn't require passing the
        // editor ref around.
        await wait(50);
        editorRefStore.get(draftId)?.focus("end", { scrollIntoView: true });
      }
    });
  }, [threadId, currentUserId, ownerOrganizationId, environment, editorRefStore]);

  const handleBranchedReplyCommand = useMemo(() => {
    return onlyCallFnOnceWhilePreviousCallIsPending(async () => {
      if (!listRef.current || !thread) return;
      const selectedHTML = getSelectionAsHTMLString();

      const branchedFromMessage =
        getNearestMessageEntry({ focusedEntry, listRef: listRef.current }) ||
        getLastMessageEntry(listRef.current.entries);

      if (!branchedFromMessage) return;

      const draftId = generateRecordId("draft");
      const threadId = generateRecordId("thread");

      const createNewDraftPromise = createNewThreadDraft(environment, {
        type: "COMMS",
        draftId,
        threadId,
        currentUserId,
        ownerOrganizationId,
        visibility: thread.visibility,
        subject: `Branch: ${thread.subject}`,
        bodyHTML: selectedHTML && `<blockquote>${selectedHTML}</blockquote><p></p><p></p>`,
        branchedFrom: {
          threadId: branchedFromMessage.record.thread_id,
          messageId: branchedFromMessage.id,
        },
      });

      await waitForCacheToContainRecord(
        environment,
        { table: "draft", id: draftId },
        { relatedPromise: createNewDraftPromise },
      );

      // When the compose new thread dialog is closed we want to refocus the
      // correct message.
      updateSearchParams((searchParams) => searchParams.set("message", branchedFromMessage.id), { replace: true });

      openComposeNewThreadDialog(draftId);
    });
  }, [thread, focusedEntry, currentUserId, ownerOrganizationId, listRef, environment]);

  useRegisterCommands({
    commands: () => {
      if (!thread) return [];

      const commands: ICommandArgs[] = [
        markNotDoneCommand({
          callback: () => {
            navigateBackOrToInbox();

            triageThread(environment, {
              threadId,
              done: false,
            }).catch((e) => console.error("failed to mark thread as not done", e));
          },
        }),
        setThreadReminderCommand({
          callback: onlyCallFnOnceWhilePreviousCallIsPending(() =>
            openTriageDialog(environment, {
              threadId,
              currentUserId,
              location: reactRouterLocation,
            }),
          ),
        }),
        {
          label: "Forward (n/a)",
          hotkeys: ["f"],
          callback: () => {
            toast("vanilla", {
              subject: "Not yet implemented 😭",
              description: stripIndents`
                Forwarding a message isn't implemented.
                What you can do is reply to this thread and @mention someone
                to loop them into the conversation.
              `,
              durationMs: 10_000,
            });
          },
        },
        // expandAllMessagesCommand({
        //   callback: () => {
        //     collapseMessageEvents$.next("expand");
        //   },
        // }),
        // collapseAllMessagesCommand({
        //   callback: () => {
        //     collapseMessageEvents$.next("collapse");
        //   },
        // }),
        ...threadSubscriptionCommands({
          environment,
          threadId,
          notification,
        }),
      ];

      switch (thread.type) {
        case "COMMS": {
          commands.push(
            replyToThreadCommand({
              label: `Reply`,
              keywords: ["Focus reply"],
              callback: handleReplyCommand,
            }),
          );

          break;
        }
        case "EMAIL": {
          alert(`Branching from an email thread isn't currently supported.`);
          // if (settings?.linked_gmail_email_account) {
          //   commands.push(
          //     replyToThreadCommand({
          //       label: `Reply`,
          //       keywords: ["Focus reply"],
          //       callback: handleReplyCommand,
          //     }),
          //   );
          // } else {
          //   commands.push(
          //     replyToThreadCommand({
          //       label: `Reply`,
          //       callback: () => {
          //         handleBranchedReplyCommand({ includeMessageRecipients: true });

          //         toast("vanilla", {
          //           subject: "Branching email thread",
          //           description: `
          //             Creating a new Comms branch for this email thread.
          //             You need to link an email account to Comms in order to send emails.
          //           `,
          //           durationMs: 10_000,
          //         });
          //       },
          //     }),
          //   );
          // }

          break;
        }
        case "EMAIL_BCC": {
          throw new Error("not implemented");
        }
        default: {
          throw new UnreachableCaseError(thread.type);
        }
      }

      commands.push(
        createBranchedReplyCommand({
          callback: () => handleBranchedReplyCommand(),
        }),
        addThreadToGroupCommand({
          callback: () => {
            AddGroupToThreadDialogState.open({
              threadId: thread.id,
            });
          },
        }),
        toggleThreadVisibilityCommand({
          callback: () =>
            updateThreadVisibility(environment, {
              threadId: thread.id,
              visibility:
                thread.visibility === "PRIVATE" ? "SHARED"
                : thread.visibility === "SHARED" ? "PRIVATE"
                : throwUnreachableCaseError(thread.visibility),
            }),
        }),
        markThreadSharedCommand({
          callback: () =>
            updateThreadVisibility(environment, {
              threadId: thread.id,
              visibility: "SHARED",
            }),
        }),
        markThreadPrivateCommand({
          callback: () =>
            updateThreadVisibility(environment, {
              threadId: thread.id,
              visibility: "PRIVATE",
            }),
        }),
      );

      if (focusedEntry?.table === "message") {
        commands.push(
          reactToMessageCommand({
            callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
              using disposable = environment.isLoading.add();

              const [reaction] = await environment.recordLoader.getRecord("message_reactions", focusedEntry.id);

              MessageReactionPickerDialogState.open({
                messageId: focusedEntry.id,
                messageType: thread.type as "COMMS" | "EMAIL",
                currentUsersReactions: reaction?.reactions[currentUserId],
              });

              // We intentionally don't await this Promise so that it doesn't
              // impact `withPendingRequestBar`
              firstValueFrom(MessageReactionPickerDialogState.afterClose$).then((data) => {
                if (!data?.success) return;
                // this expands the post if it isn't already expanded
                collapseMessageEvents$.next(focusedEntry.id);
              });
            }),
          }),
          copyLinkToFocusedPostCommand({
            callback: async () => {
              if (!("clipboard" in navigator)) {
                toast("vanilla", {
                  subject: "Unsupported",
                  description: `
                    Your browser doesn't support the Clipboard API.
                    Update your browser or switch to a different one ¯\\_(ツ)_/¯
                  `,
                });

                return;
              }

              const urlPrefix =
                thread.type === "COMMS" ? "threads"
                : thread.type === "EMAIL" ? "emails"
                : thread.type === "EMAIL_BCC" ? "emails"
                : throwUnreachableCaseError(thread.type);

              const url = new URL(
                `/${urlPrefix}/${focusedEntry.record.thread_id}?message=${focusedEntry.id}`,
                location.href,
              );

              await writeToClipboard({
                type: "text/plain",
                value: url.toString(),
              });

              toast("vanilla", {
                subject: "Link copied to clipboard",
              });
            },
          }),
          markThreadResolvedCommand({
            callback: () => {
              markThreadResolved(environment, {
                threadId: thread.id,
                resolvedByMessageId: focusedEntry.record.id,
              });
            },
          }),
        );

        if (focusedEntry.record.sender_user_id === currentUserId) {
          const message = focusedEntry.record;

          if (message.type === "COMMS") {
            commands.push(
              editMessageCommand({
                callback() {
                  if (message.sent_at >= new Date().toISOString()) {
                    unsendAndFocusDraft({
                      environment,
                      draftId: message.id,
                      isReply: message.is_reply,
                    });
                  } else {
                    const userMentions = message.to
                      .filter((r): r is MessageUserRecipientDoc => r.is_mentioned && r.type === "USER")
                      .map((r): IEditorMention => {
                        return {
                          type: "user",
                          id: r.user_id,
                          priority: r.priority,
                        };
                      });

                    const groupMentions = message.to
                      .filter((r): r is MessageGroupRecipientDoc => r.is_mentioned && r.type === "GROUP")
                      .map((recipient): IEditorMention => {
                        return {
                          type: "group",
                          id: recipient.group_id,
                          priority: recipient.priority,
                        };
                      });

                    createEditDraft(environment, {
                      currentUserId,
                      ownerOrganizationId,
                      messageId: message.id,
                      threadId: message.thread_id,
                      bodyHTML: message.body_html,
                      attachments: message.attachments,
                      userMentions,
                      groupMentions,
                    });
                  }
                },
              }),
            );
          } else {
            commands.push(
              editMessageCommand({
                callback() {
                  if (message.sent_at >= new Date().toISOString()) {
                    unsendAndFocusDraft({
                      environment,
                      draftId: message.id,
                      isReply: message.is_reply,
                    });
                  } else {
                    showNotImplementedToastMsg(oneLine`
                      Editing email messages isn't supported.
                    `);
                  }
                },
              }),
            );
          }
        }
      }

      if (focusedEntry?.table === "draft") {
        const draft = focusedEntry;

        commands.push(
          deleteDraftCommand({
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              deleteDraft(environment, {
                draftId: draft.id,
                currentUserId,
              });
            },
          }),
        );
      }

      if (threadResolvedTag) {
        commands.push(
          markThreadNotResolvedCommand({
            // Adding this to more actions so that, on mobile, there's a button available to
            // mark a thread as not resolved.
            path: ["More actions"],
            callback: () => {
              markThreadNotResolved(environment, { threadId: thread.id });
            },
          }),
          focusThreadResolutionCommand({
            callback: () => {
              const elementId = getTimelineEntryElementId({
                thread_id: thread.id,
                entry_id: threadResolvedTag.data.message_id,
              });

              const element = document.getElementById(elementId);

              if (element) {
                element.focus();
              }
            },
          }),
        );
      } else if (focusedEntry?.table !== "message") {
        commands.push(
          markThreadResolvedCommand({
            callback: () => {
              toast("vanilla", {
                subject: "No message focused",
                description: "You need to first focus a message to mark the thread as resolved.",
              });
            },
          }),
        );
      }

      if (notification) {
        commands.push(
          removeThreadReminderCommand({
            callback: () => {
              triageThread(environment, {
                threadId,
                triagedUntil: null,
              });
            },
          }),
        );
      }

      return commands;
    },
    deps: [
      thread,
      threadResolvedTag,
      currentUserId,
      notification,
      focusedEntry,
      handleReplyCommand,
      handleBranchedReplyCommand,
      reactRouterLocation,
      collapseMessageEvents$,
      settings,
      environment,
    ],
  });

  useRegisterCommands({
    commands() {
      return observeThreadViewPrevNextArgs({
        environment,
        currentUserId,
        pointer: getPointer("thread", threadId),
        location: reactRouterLocation,
      }).pipe(
        map((prevNextState) => [
          markDoneCommand({
            async callback() {
              const settings = await getNormalizedUserSettings(environment, { fetchStrategy: "cache-first" });

              const location = getCurrentRouterLocation();

              await navigateOnDone({
                prevNextState,
                navBackOnThreadDone: settings?.enable_nav_back_on_thread_done,
              });

              triageThread(environment, {
                threadId,
                done: true,
                onOptimisticUndo() {
                  navigateService(location, { replace: true });
                },
              }).catch((error) =>
                environment.logger.error({ error }, "[markDoneCommand] failed to mark thread as done"),
              );
            },
          }),
        ]),
      );
    },
    deps: [threadId, thread, currentUserId, ownerOrganizationId, reactRouterLocation, environment],
  });

  return null;
};

/* -----------------------------------------------------------------------------------------------*/

async function openTriageDialog(
  environment: ClientEnvironment,
  props: {
    threadId: string | null | undefined;
    location: Location;
    currentUserId: string;
  },
  options?: GetOptions,
) {
  const { threadId, location, currentUserId } = props;

  if (!threadId) return;

  RemindMeDialogState.open({
    threadId: threadId,
    fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
    navigateIfReminderSet: async () => {
      const [prevNextState, settings] = await Promise.all([
        getThreadViewPrevNextArgs(
          {
            environment,
            pointer: getPointer("thread", threadId),
            location,
            currentUserId,
          },
          options,
        ),
        getNormalizedUserSettings(environment, options),
      ]);

      await navigateOnDone({
        prevNextState,
        navBackOnThreadDone: settings?.enable_nav_back_on_thread_done,
      });
    },
  });
}

/* -----------------------------------------------------------------------------------------------*/

export function navigateOnDone(props: {
  prevNextState: IThreadListNavigationArgs | null | undefined;
  navBackOnThreadDone?: boolean;
}) {
  const { navBackOnThreadDone, prevNextState } = props;

  if (navBackOnThreadDone) {
    // Here we want to navigate back to the previous location

    if (isThreadListNavigationArgsForInboxView(prevNextState)) {
      const notificationId = prevNextState!.nextEntry?.notification_id;

      const inboxSectionId = prevNextState.state[ThreadListNavigationLocationStateKey].inboxSectionId;

      if (notificationId && inboxSectionId) {
        return navigateService(`/inbox/${inboxSectionId}`, {
          state: { ["ContentList"]: notificationId },
        });
      }
    } else if (isThreadListNavigationArgsForGroupView(prevNextState)) {
      const threadId = prevNextState!.nextEntry?.id;

      const groupId = prevNextState.state[ThreadListNavigationLocationStateKey].groupId;

      if (threadId && groupId) {
        return navigateService(`/groups/${groupId}`, {
          state: { ["ContentList"]: threadId },
        });
      }
    }
  } else if (prevNextState?.nextEntry) {
    const nextUrl = convertThreadViewPrevNextArgsToURL(prevNextState, "next");

    if (nextUrl) {
      return navigateService(nextUrl, { state: prevNextState.state });
    }
  } else if (prevNextState) {
    // This indicates that we've been navigating a list but are at the end of
    // the list. We should try to navigate back to the list view now.

    if (isThreadListNavigationArgsForInboxView(prevNextState)) {
      const inboxSectionId = prevNextState.state[ThreadListNavigationLocationStateKey].inboxSectionId;

      if (inboxSectionId) {
        return navigateService(`/inbox/${inboxSectionId}`);
      }
    } else if (isThreadListNavigationArgsForGroupView(prevNextState)) {
      const groupId = prevNextState.state[ThreadListNavigationLocationStateKey].groupId;

      if (groupId) {
        return navigateService(`/groups/${groupId}`);
      }
    }
  }

  return navigateBackOrToInbox();
}
