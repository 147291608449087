import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import { RecordValue } from "libs/schema";
import { observeMentionableUserIds } from "./observeMentionableUserIds";
import { cacheReplayForTime } from "libs/rxjs-operators";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ObserveOptions } from "~/environment/RecordLoader";

export type ObserveMentionableUserRecordsResult = [MentionableUser[], { isLoading: boolean }];

export type MentionableUser = {
  type: "user";
  id: string;
  profile: RecordValue<"user_profile">;
  contact: RecordValue<"user_contact_info"> | null;
};

export function observeMentionableUsers(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    currentUserId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveMentionableUserRecordsResult> {
  const cacheKey = props.currentUserId + options?.fetchStrategy + options?.isLoading;

  const cachedQuery = queryCache.get(cacheKey);

  if (cachedQuery) return cachedQuery;

  const observable = innerObserveMentionableUserRecords(environment, props, options).pipe(
    cacheReplayForTime({
      timeMs: 100,
      onInit: () => {
        queryCache.set(cacheKey, observable);
      },
      onCleanup: () => {
        queryCache.delete(cacheKey);
      },
    }),
  );

  return observable;
}

const queryCache = new Map<string, Observable<ObserveMentionableUserRecordsResult>>();

export function innerObserveMentionableUserRecords(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    currentUserId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveMentionableUserRecordsResult> {
  return observeMentionableUserIds(environment, props, options).pipe(
    switchMap(([userIds, { isLoading: isMentionalableUserIdsLoading }]) => {
      if (userIds.length === 0) {
        return of([[], { isLoading: isMentionalableUserIdsLoading }] as ObserveMentionableUserRecordsResult);
      }

      return combineLatest(
        userIds.map((userId) =>
          combineLatest([
            environment.recordLoader.observeGetRecord(
              {
                table: "user_profile",
                id: userId,
              },
              {
                fetchStrategy: options?.fetchStrategy,
                isLoading: isMentionalableUserIdsLoading,
              },
            ),
            environment.recordLoader.observeGetRecord(
              {
                table: "user_contact_info",
                id: userId,
              },
              {
                fetchStrategy: options?.fetchStrategy,
                isLoading: isMentionalableUserIdsLoading,
              },
            ),
          ]),
        ),
      ).pipe(
        map((results): ObserveMentionableUserRecordsResult => {
          const { records, isLoading } = results.reduce(
            (store, [[profile, { isLoading: isProfileLoading }], [contact, { isLoading: isContactLoading }]]) => {
              if (isProfileLoading || isContactLoading) {
                store.isLoading = true;
              }

              if (profile) {
                store.records.push({
                  type: "user",
                  id: profile.id,
                  profile,
                  contact,
                });
              }

              return store;
            },
            {
              records: [] as MentionableUser[],
              isLoading: isMentionalableUserIdsLoading,
            },
          );

          return [records, { isLoading }];
        }),
      );
    }),
  );
}
