import { uuid } from "libs/uuid";
import { useEffect } from "react";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export function useTriggerIsLoading(isLoading: boolean) {
  const environment = useClientEnvironment();

  useEffect(() => {
    if (!isLoading) return;
    const options = { unique: uuid() };
    environment.isLoading.add(options);
    return () => environment.isLoading.remove(options);
  }, [isLoading, environment]);
}
