import {
  ICommandArgs,
  PLATFORM_MODIFIER_KEY,
  PLATFORM_ALT_KEY,
  useRegisterCommands,
} from "~/environment/command.service";
import {
  addAttachmentCommand,
  closeDraftCommand,
  deleteDraftCommand,
  getCommandFactory,
  sendMessageCommand,
} from "~/utils/common-commands";
import { closeComposeNewThreadDialog, openComposeNewThreadDialog } from "../page-dialog-state";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { handleSubmit, observable } from "~/components/forms/utils";
import { combineLatest, map, withLatestFrom } from "rxjs";
import { IComposeMessageForm, IComposeMessageFormValue } from "~/components/ComposeMessageContext";
import { ComponentType, forwardRef, useCallback, useEffect } from "react";
import { IFormControl } from "solid-forms-react";
import { TextInput } from "~/components/forms/TextInput";
import { cx } from "@emotion/css";
import { IGroupRecipientOption, IRecipientOption, buildRecipientOption } from "~/components/forms/ThreadRecipients";
import { AttachFileButton, DeleteDraftButton, SendDraftButton } from "~/components/ComposeReplyBase";
import { OutlineButton } from "~/components/OutlineButtons";
import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
import { Tooltip } from "~/components/Tooltip";
import { deleteDraft, setDraft } from "~/actions/draft";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { RecordValue, ThreadVisibility } from "libs/schema";
import { observeNormalizedUserSettings } from "~/observables/observeNormalizedUserSettings";
import { mapRecipientOptionToDraftRecipient } from "~/actions/draft";
import { SetOptional } from "type-fest";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { observeMentionableGroupRecords } from "~/observables/observeMentionableGroupRecords";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { addAndUploadAttachment } from "~/components/forms/message-editor/uploads";
import { useFileInputElement } from "~/hooks/useFileInputElement";

export const Subject = forwardRef<HTMLInputElement, { control: IFormControl<string>; autoFocus?: boolean }>(
  (props, ref) => {
    return (
      <div
        className={cx(
          // the transparent border exists so that this input has the
          // same height as the recipients input. The recipient chips
          // have a non-transparent border.
          "flex flex-1 py-1 border-y border-transparent outline-none",
          "font-bold placeholder:font-medium",
        )}
      >
        <TextInput ref={ref} name="subject" control={props.control} autoFocus={props.autoFocus} />
      </div>
    );
  },
);

export function useRegisterSharedComposeNewMessageCommands(args: {
  control: IComposeMessageForm;
  submit: (
    environment: ClientEnvironment,
    values: IComposeMessageFormValue,
    options?: { sendImmediately?: boolean },
  ) => Promise<void>;
}) {
  const { control, submit } = args;

  const environment = useClientEnvironment();
  const { currentUserId, ownerOrganizationId } = useAuthGuardContext();

  const { openFilePicker } = useFileInputElement({
    multiple: true,
    onFilesChange(files) {
      addAndUploadAttachment(environment, { control, files });
    },
  });

  useRegisterCommands({
    commands: () => {
      return [
        deleteDraftCommand({
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            const draftId = control.rawValue.messageId;

            deleteDraft(environment, {
              draftId,
              currentUserId,
              afterUndo: () => {
                openComposeNewThreadDialog(draftId);
              },
            });
          },
        }),
        closeDraftCommand({
          callback: () => {
            const values = control.rawValue;

            setDraft(environment, {
              currentUserId,
              ownerOrganizationId,
              is_edit: values.isEdit,
              is_reply: values.isReply,
              type: values.type,
              draftId: values.messageId,
              threadId: values.threadId,
              visibility: values.visibility,
              to: values.recipients.to.map(mapRecipientOptionToDraftRecipient),
              cc: values.recipients.cc.map(mapRecipientOptionToDraftRecipient),
              bcc: values.recipients.bcc.map(mapRecipientOptionToDraftRecipient),
              subject: values.subject || "",
              bodyHTML: values.body.content || "",
              groupMentions: values.body.groupMentions || [],
              userMentions: values.body.userMentions || [],
              attachments: values.attachments,
              noDebounce: true,
            })
              .then(() => console.debug("WIP draft saved successfully"))
              .catch((e) => console.error("updateNewDraft", e));

            closeComposeNewThreadDialog();
          },
        }),
        sendMessageCommand({
          callback: onlyCallFnOnceWhilePreviousCallIsPending(async (e?: KeyboardEvent) => {
            e?.preventDefault();
            e?.stopPropagation();
            await handleSubmit({ control, environment, submit });
          }),
        }),
        addAttachmentCommand({
          callback: (e) => {
            e?.preventDefault();

            if (!environment.network.isOnline()) {
              alert(`
                You are offline. Adding attachments to a message is currently 
                only supported when online.
              `);

              return;
            }

            openFilePicker();
          },
        }),
        {
          label: "Send message immediately",
          callback: onlyCallFnOnceWhilePreviousCallIsPending(async (e?: KeyboardEvent) => {
            e?.preventDefault();
            e?.stopPropagation();

            await handleSubmit({
              environment,
              control,
              submit: (environment, values) => submit(environment, values, { sendImmediately: true }),
            });
          }),
        },
      ];
    },
    deps: [deleteDraft, openFilePicker, currentUserId, ownerOrganizationId, environment],
  });

  useRegisterCommands({
    commands: () => {
      return observable(() => control.rawValue.visibility).pipe(
        map((visibility) => {
          const props =
            visibility === "PRIVATE" ?
              {
                label: "Mark shared",
                keywords: ["Mark public", "Mark private"],
              }
            : {
                label: "Mark private",
                keywords: ["Mark public", "Mark shared"],
              };

          return [
            toggleVisibilityCommand({
              ...props,
              hotkeys: [
                "$mod+Alt+p",
                // Note that, depending on the browser, the emitted KeyboardEvent#key
                // value may be "p" or "P" (chrome on windows seems to do "p" whereas
                // Firefox does "P"). For this reason, we also add a second shortcut
                // using "KeyP". The first shortcut will be shown in the kbar
                "$mod+Alt+KeyP",
              ],
              triggerHotkeysWhenInputFocused: true,
              callback: () => makePrivateCommandCallback(control),
            }),
          ];
        }),
      );
    },
    deps: [control],
  });

  useRegisterCommands({
    commands: () => {
      return combineLatest([
        observeNormalizedUserSettings(environment, { userId: currentUserId }),
        observable(() => control.rawValue.type),
      ]).pipe(
        map(([{ settings }, draftType]) => {
          const isEmailEnabled = settings?.linked_gmail_email_account === true;

          if (!isEmailEnabled) {
            return [];

            // TODO:
            // When email is out of beta and open to anyone, show users
            // a helpful message if they haven't linked their email.
            //
            // return [
            //   {
            //     id: TOGGLE_DRAFT_TYPE_COMMAND_ID,
            //     label: "Convert to email message",
            //     keywords: ["Make email", "Make comms message"],
            //     callback: () => {
            //       toast("vanilla", {
            //         subject: "You must link your email account to send emails",
            //       });
            //     },
            //   },
            // ];
          }

          const props = (
            draftType === "COMMS" || draftType === null ?
              {
                label: "Convert to email message",
                keywords: ["Make email", "Make comms message"],
              }
            : draftType === "EMAIL" ?
              {
                label: "Convert to comms message",
                keywords: ["Make email", "Make comms message"],
              }
            : throwUnreachableCaseError(draftType)) satisfies Partial<ICommandArgs>;

          return [
            toggleDraftTypeCommand({
              ...props,
              callback: () => toggleDraftTypeCommandCallback(control),
            }),
          ];
        }),
      );
    },
    deps: [environment, currentUserId, control],
  });
}

const toggleVisibilityCommand = getCommandFactory(
  "TOGGLE_VISIBILITY",
  (options: SetOptional<ICommandArgs, "hotkeys" | "triggerHotkeysWhenInputFocused">): ICommandArgs => ({
    hotkeys: [
      "$mod+Alt+p",
      // Note that, depending on the browser, the emitted KeyboardEvent#key
      // value may be "p" or "P" (chrome on windows seems to do "p" whereas
      // Firefox does "P"). For this reason, we also add a second shortcut
      // using "KeyP". The first shortcut will be shown in the kbar
      "$mod+Alt+KeyP",
    ],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

const toggleDraftTypeCommand = getCommandFactory(
  "TOGGLE_DRAFT_TYPE",
  (options): ICommandArgs => ({
    ...options,
  }),
);

export function makePrivateCommandCallback(control: IComposeMessageForm) {
  const newVisibility = control.rawValue.visibility === "PRIVATE" ? "SHARED" : "PRIVATE";

  const filterFn = (r: IRecipientOption) =>
    r.type === "user" ? true
    : r.type === "email" ? true
    : r.type === "group" ?
      newVisibility === "PRIVATE" ?
        r.isPrivate
      : !r.isPrivate
    : throwUnreachableCaseError(r);

  const newRecipients = {
    to: control.rawValue.recipients.to.filter(filterFn),
    cc: control.rawValue.recipients.cc.filter(filterFn),
    bcc: control.rawValue.recipients.bcc.filter(filterFn),
  };

  // TODO: fix me
  // Note that we intentionally first update the recipients and then
  // the visibility. This is because we have several hooks that respond
  // to control changes and separating out these two changes ensures
  // that the hooks respond in the correct order.
  control.patchValue({
    recipients: newRecipients,
  });

  control.patchValue({
    visibility: newVisibility,
  });
}

export function toggleDraftTypeCommandCallback(control: IComposeMessageForm) {
  const newDraftType =
    control.rawValue.type === "COMMS" ? "EMAIL"
    : control.rawValue.type === "EMAIL" ? "COMMS"
    : throwUnreachableCaseError(control.rawValue.type);

  const filterFn =
    newDraftType === "COMMS" ? (r: IRecipientOption) => r.type === "user" || r.type === "group"
    : newDraftType === "EMAIL" ? (r: IRecipientOption) => r.type === "user" || r.type === "email" || r.type === "group"
    : throwUnreachableCaseError(newDraftType);

  const to = control.rawValue.recipients.to.filter(filterFn);
  const cc = control.rawValue.recipients.cc.filter(filterFn);
  const bcc = control.rawValue.recipients.bcc.filter(filterFn);

  control.patchValue({
    type: newDraftType,
    recipients: {
      to,
      cc,
      bcc,
    },
  });
}

/* -------------------------------------------------------------------------------------------------
 * saveNewThreadDraft
 * -----------------------------------------------------------------------------------------------*/

export const saveNewThreadDraft = (
  environment: ClientEnvironment,
  values: IComposeMessageFormValue,
  options?: {
    immediate?: boolean;
  },
) => {
  return setDraft(environment, {
    currentUserId: environment.auth.getAndAssertCurrentUserId(),
    ownerOrganizationId: environment.auth.getAndAssertCurrentUserOwnerOrganizationId(),
    branchedFrom: values.branchedFrom,
    is_edit: values.isEdit,
    is_reply: values.isReply,
    type: values.type,
    draftId: values.messageId,
    threadId: values.threadId,
    visibility: values.visibility,
    to: values.recipients.to.map(mapRecipientOptionToDraftRecipient),
    cc: values.recipients.cc.map(mapRecipientOptionToDraftRecipient),
    bcc: values.recipients.bcc.map(mapRecipientOptionToDraftRecipient),
    subject: values.subject || "",
    bodyHTML: values.body.content || "",
    groupMentions: values.body.groupMentions || [],
    userMentions: values.body.userMentions || [],
    attachments: values.attachments,
    noDebounce: options?.immediate,
  });
};

function cloneJSON<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

export function useSaveDraftImmediatelyFn(control: IComposeMessageForm) {
  const environment = useClientEnvironment();

  return useCallback(() => {
    return saveNewThreadDraft(environment, control.rawValue, { immediate: true });
  }, [control, environment]);
}

export function useUpdateVisibilityOnRecipientChanges(control: IComposeMessageForm) {
  const { recordLoader, logger } = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  useEffect(() => {
    const sub = combineLatest([
      recordLoader.observeGetUserOrganizationProfiles({
        userId: currentUserId,
      }),
      observable(() => control.rawValue.recipients),
    ])
      .pipe(withLatestFrom(observable(() => control.rawValue.visibility)))
      .subscribe(([[[orgProfiles], _0], _1]) => {
        // If another subscription updates the control's values in it's response
        // handler, then this subscription might emit with state values. To work
        // around this, we refetch the latest values from the control.
        const recipients = control.rawValue.recipients;
        const visibility = control.rawValue.visibility;

        logger.debug(cloneJSON({ visibility, recipients }), "useUpdateVisibilityOnRecipientChanges");

        const allRecipients = [...recipients.to, ...recipients.cc, ...recipients.bcc];

        if (visibility) return;

        const isPrivate = allRecipients.some((r) => r.type === "group" && r.isPrivate);

        if (isPrivate) {
          control.patchValue({ visibility: "PRIVATE" });
        } else {
          control.patchValue({ visibility: "SHARED" });
        }
      });

    return () => sub.unsubscribe();
  }, [control, currentUserId, recordLoader, logger]);
}

export function useUpdateRecipientsOnVisibilityChanges(control: IComposeMessageForm) {
  const { currentUserId } = useAuthGuardContext();
  const environment = useClientEnvironment();

  useEffect(() => {
    const sub = observable(() => control.rawValue.visibility)
      .pipe(withLatestFrom(observeMentionableGroupRecords(environment, { currentUserId })))
      .subscribe(([_0, [mentionableGroups]]) => {
        // If another subscription updates the control's values in it's response
        // handler, then this subscription might emit with stale values. To work
        // around this, we refetch the latest values from the control.
        const threadType = control.rawValue.type;
        const recipients = control.rawValue.recipients;
        const visibility = control.rawValue.visibility;
        const groupMentions = control.rawValue.body.groupMentions;

        environment.logger.debug(
          cloneJSON({ visibility, recipients, groupMentions }),
          "useUpdateRecipientsOnVisibilityChanges",
        );

        switch (visibility) {
          case "PRIVATE": {
            const filterFn = (r: IRecipientOption) =>
              r.type === "user" || r.type === "email" || (r.type === "group" && r.isPrivate);

            const newRecipients = {
              to: recipients.to.filter(filterFn),
              cc: recipients.cc.filter(filterFn),
              bcc: recipients.bcc.filter(filterFn),
            };

            // If the message already mentions groups some private groups,
            // make sure they are included as recipients
            if (groupMentions.length > 0) {
              for (const mention of groupMentions) {
                if (newRecipients.to.some((r) => r.value === mention.id)) {
                  continue;
                }

                const mentionable = mentionableGroups.find((g) => g.id === mention.id);

                if (!mentionable) continue;
                if (!mentionable.isPrivate) continue;

                newRecipients.to.push(
                  buildRecipientOption({
                    threadType,
                    record: mentionable,
                    isThreadPrivate: true,
                  }),
                );
              }
            }

            environment.logger.debug(cloneJSON({ newRecipients }), "useUpdateRecipientsOnVisibilityChanges after");

            control.patchValue({ recipients: newRecipients });

            return;
          }
          case "SHARED": {
            const filterFn = (r: IRecipientOption) =>
              r.type === "user" || r.type === "email" || (r.type === "group" && !r.isPrivate);

            const newRecipients = {
              to: recipients.to.filter(filterFn),
              cc: recipients.cc.filter(filterFn),
              bcc: recipients.bcc.filter(filterFn),
            };

            // If the message already mentions groups some shared groups,
            // make sure they are included as recipients
            if (groupMentions.length > 0) {
              for (const mention of groupMentions) {
                if (newRecipients.to.some((r) => r.value === mention.id)) {
                  continue;
                }

                const mentionable = mentionableGroups.find((g) => g.id === mention.id);

                if (!mentionable) continue;
                if (mentionable.isPrivate) continue;

                newRecipients.to.push(
                  buildRecipientOption({
                    threadType,
                    record: mentionable,
                    isThreadPrivate: false,
                  }),
                );
              }
            }

            environment.logger.debug(cloneJSON({ newRecipients }), "useUpdateRecipientsOnVisibilityChanges after");

            control.patchValue({ recipients: newRecipients });

            return;
          }
          case null: {
            return;
          }
          default: {
            throw new UnreachableCaseError(visibility);
          }
        }
      });

    return () => sub.unsubscribe();
  }, [control, currentUserId, environment]);
}

/**
 * This effect handles adding the user's organization as a recipient
 * for shared threads when the recipients change as well as updating
 * the organization group option on walkthrough completion.
 *
 * Note, the AutocompleteSelect component accepts a custom Option
 * component which we use to display and style the organization group option
 * when a walkthrough is available. Annoyingly, this custom Option
 * component must be "pure" and does not support the usage of react context
 * within it. Because of this, we need to provide all state within the
 * option object itself. So when the `walkthroughNotCompleted` value
 * changes, we need to update the organization group option if it is present
 * in the recipients array.
 */
export function useAddOrganizationGroupOnRecipientAndVisibilityChanges(args: {
  control: IComposeMessageForm;
  walkthroughNotCompleted: boolean | undefined;
}) {
  const { control, walkthroughNotCompleted } = args;
  const { recordLoader, logger } = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  useEffect(() => {
    const sub = combineLatest([
      recordLoader.observeGetUserOrganizationProfiles({
        userId: currentUserId,
      }),
      observable(() => control.rawValue.recipients),
      observable(() => control.rawValue.visibility),
    ]).subscribe(([[orgProfiles], _0, _1]) => {
      // If another subscription updates the control's values in it's response
      // handler, then this subscription might emit with state values. To work
      // around this, we refetch the latest values from the control.
      const recipients = control.rawValue.recipients;
      const visibility = control.rawValue.visibility;

      logger.debug(cloneJSON({ visibility, recipients }), "useAddOrganizationGroupOnRecipientAndVisibilityChanges");

      if (visibility === "PRIVATE") return;

      const allRecipients = [...recipients.to, ...recipients.cc, ...recipients.bcc];

      if (allRecipients.length === 0) return;

      // const currentUser = getAndAssertCurrentUser();

      // const organization = orgUserMembers.find(
      //   (org) => org.id === currentUser.organizationId,
      // );

      // // TODO:
      // // Change this when we support multiple organizations
      // // and users who aren't part of an organization
      // if (!organization) return;

      const orgGroupCount = allRecipients.reduce((prev, curr) => {
        const isOrgGroup = curr.type === "group" && orgProfiles.some((o) => o.id === curr.value);

        return isOrgGroup ? prev + 1 : prev;
      }, 0);

      if (orgGroupCount === allRecipients.length) {
        // Clear organization groups if they are the only recipients

        logger.debug("Clearing organization groups");

        // We use setTimeout to ensure that any other subscriptions to
        // this control's value changes have a chance to respond to the
        // current change before we emit a new change.
        control.patchValue({
          recipients: {
            to: [],
            cc: [],
            bcc: [],
          },
        });
      } else if (orgGroupCount > 0) {
        // Make sure any shared channels present have the correct
        // "showWalkthrough" value.

        const areSharedChannelsAlreadyUpdated = allRecipients.every(
          (r) =>
            r.type !== "group" ||
            !r.organizationGroup ||
            r.organizationGroup.showWalkthrough === !!walkthroughNotCompleted,
        );

        if (areSharedChannelsAlreadyUpdated) return;

        const mapFn = <T extends IRecipientOption>(r: T): T => {
          if (r.type === "user" || r.type === "email" || (r.type === "group" && !r.organizationGroup)) {
            return r;
          }

          return {
            ...r,
            organizationGroup: {
              // we guarded against null in the if statement, above
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...r.organizationGroup!,
              showWalkthrough: !!walkthroughNotCompleted,
            },
          } satisfies IGroupRecipientOption as T;
        };

        // We use setTimeout to ensure that any other subscriptions to
        // this control's value changes have a chance to respond to the
        // current change before we emit a new change.
        control.patchValue({
          recipients: {
            to: recipients.to.map(mapFn),
            cc: recipients.cc.map(mapFn),
            bcc: recipients.bcc.map(mapFn),
          },
        });
      } else {
        // If the thread is shared and if there are no organization groups as recipients
        // then we need to add the organization group to the recipients.
        const newRecipients = {
          to: [
            ...orgProfiles.map(
              (org) =>
                ({
                  type: "group",
                  icon: null,
                  label: `${org.name} Organization`,
                  value: org.id,
                  folderPaths: [],
                  isPrivate: false,
                  isFixed: true,
                  organizationGroup: {
                    organizationName: org.name,
                    showWalkthrough: !!walkthroughNotCompleted,
                  },
                }) satisfies IGroupRecipientOption,
            ),
            ...recipients.to,
          ],
        };

        logger.debug(cloneJSON({ newRecipients }), "useAddSharedChannelsOnRecipientChanges after");

        // If the thread is shared and if there are no shared channels as recipients
        // then we need to add the shared channel to the recipients.
        control.patchValue({
          recipients: newRecipients,
        });
      }
    });

    return () => sub.unsubscribe();
  }, [control, walkthroughNotCompleted, currentUserId, recordLoader, logger]);
}

/**
 * Automatically converts a draft to an email draft
 * if an email recipient is added.
 */
export function useUpdateDraftTypeOnRecipientChanges(control: IComposeMessageForm) {
  const { logger } = useClientEnvironment();

  useEffect(() => {
    const sub = observable(() => control.rawValue.recipients)
      .pipe(withLatestFrom(observable(() => control.rawValue.type)))
      .subscribe(() => {
        // If another subscription updates the control's values in it's response
        // handler, then this subscription might emit with state values. To work
        // around this, we refetch the latest values from the control.
        const recipients = control.rawValue.recipients;
        const draftType = control.rawValue.type;

        logger.debug(cloneJSON({ draftType, recipients }), "useUpdateDraftTypeOnRecipientChanges");

        if (draftType === "EMAIL") return;

        const allRecipients = [...recipients.to, ...recipients.cc, ...recipients.bcc];

        const containsAnEmailRecipient = allRecipients.some((r) => r.type === "email");

        if (containsAnEmailRecipient) {
          const newType = "EMAIL";

          logger.debug(cloneJSON({ newType }), "useUpdateDraftTypeOnRecipientChanges after");

          control.patchValue({ type: newType });
          return;
        }
      });

    return () => sub.unsubscribe();
  }, [control]);
}

export function useUpdateRecipientsOnDraftTypeChanges(control: IComposeMessageForm) {
  const { logger } = useClientEnvironment();

  useEffect(() => {
    const sub = observable(() => control.rawValue.type)
      .pipe(withLatestFrom(observable(() => control.rawValue.recipients)))
      .subscribe(() => {
        // If another subscription updates the control's values in it's response
        // handler, then this subscription might emit with state values. To work
        // around this, we refetch the latest values from the control.
        const recipients = control.rawValue.recipients;
        const draftType = control.rawValue.type;

        logger.debug(cloneJSON({ draftType, recipients }), "useUpdateRecipientsOnDraftTypeChanges");

        const filterFn =
          draftType === "EMAIL" ? (r: IRecipientOption) => r.type === "user" || r.type === "email" || r.type === "group"
          : draftType === "COMMS" ? (r: IRecipientOption) => r.type === "user" || r.type === "group"
          : throwUnreachableCaseError(draftType);

        const newRecipients = {
          to: recipients.to.filter(filterFn),
          cc: recipients.cc.filter(filterFn),
          bcc: recipients.bcc.filter(filterFn),
        };

        logger.debug(cloneJSON({ recipients: newRecipients }), "useUpdateRecipientsOnDraftTypeChanges after");

        control.patchValue({
          recipients: newRecipients,
        });
      });

    return () => sub.unsubscribe();
  }, [control, logger]);
}

export const DraftActions: ComponentType<{
  visibility: ThreadVisibility | null;
  draftType: RecordValue<"draft">["type"];
}> = (props) => {
  const { settings } = useCurrentUserSettings();

  const isEmailEnabled = settings?.linked_gmail_email_account === true;

  const toggleVisibilityLabel =
    props.visibility === "SHARED" || props.visibility === null ? "Mark private"
    : props.visibility === "PRIVATE" ? "Mark shared"
    : throwUnreachableCaseError(props.visibility);

  const toggleDraftTypeLabel =
    props.draftType === "COMMS" || props.draftType === null ? "Convert to email"
    : props.draftType === "EMAIL" ? "Convert to Comms message"
    : throwUnreachableCaseError(props.draftType);

  return (
    <>
      <SendDraftButton />

      <Tooltip
        side="bottom"
        content={
          <>
            {toggleVisibilityLabel}{" "}
            <span className="flex items-center">
              (<PLATFORM_MODIFIER_KEY.symbol className="mr-1" />
              +
              <PLATFORM_ALT_KEY.symbol className="mx-1" /> + P)
            </span>
          </>
        }
      >
        <OutlineButton
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault();
            toggleVisibilityCommand.trigger();
          }}
        >
          <small>{toggleVisibilityLabel}</small>
        </OutlineButton>
      </Tooltip>

      {isEmailEnabled && (
        <Tooltip side="bottom" content={toggleDraftTypeLabel}>
          <OutlineButton
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              toggleDraftTypeCommand.trigger();
            }}
          >
            <small>{toggleDraftTypeLabel}</small>
          </OutlineButton>
        </Tooltip>
      )}

      <div className="flex-1" />

      <AttachFileButton />
      <DeleteDraftButton />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/
