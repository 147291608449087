import { Observable, map } from "rxjs";
import { MentionFrequencyMapDoc } from "libs/schema";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ClientRecordLoaderObserveGetRecordResultMeta, ObserveOptions } from "~/environment/RecordLoader";

export type ObserveCurrentUserMentionFrequencyResult = [
  MentionFrequencyMapDoc | null,
  ClientRecordLoaderObserveGetRecordResultMeta,
];

/**
 * This can return null if the user is currently offline.
 */
export function observeCurrentUserMentionFrequency(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    currentUserId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveCurrentUserMentionFrequencyResult> {
  return environment.recordLoader
    .observeGetRecord(
      {
        table: "user_settings",
        id: props.currentUserId,
      },
      options,
    )
    .pipe(
      map(([record, meta]) => {
        return [record?.mention_frequency || null, meta];
      }),
    );
}
