import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";

export type UseInboxSubsectionsResult = UseRecordLoaderResult<string[]>;

export function useInboxSubsectionIds(inboxSectionId: string | null | undefined): UseInboxSubsectionsResult {
  return useRecordLoader({
    name: "useInboxSubsectionIds",
    load({ loader, limit, currentUserId, deps: [inboxSectionId] }) {
      if (!inboxSectionId) {
        return loader.createObserveQueryResult<"tag">();
      }

      return loader.observeGetInboxSubsections({
        currentUserId: currentUserId,
        inboxSectionId,
        limit,
      });
    },
    initialLimit: 25,
    limitStep: 25,
    deps: [inboxSectionId],
    map: (records) => records.map((r) => r.id),
  });
}
