import { useMemo } from "react";
import { useRecord } from "./useRecord";

export function useMessageUserRecipient(props: {
  messageId: string | null | undefined;
  userId: string | null | undefined;
}) {
  const [message] = useRecord("message", props.messageId, { name: "useMessageUserRecipient" });

  return useMemo(() => {
    return message?.to.find((r) => r.type === "USER" && r.user_id === props.userId) || null;
  }, [message, props.userId]);
}
