import { DialogState, DIALOG_OVERLAY_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { css, cx } from "@emotion/css";
import { ComponentType } from "react";
import { PLATFORM_MODIFIER_KEY, useRegisterCommands } from "~/environment/command.service";
import { openHelpCommand } from "~/utils/common-commands";
import { showPWAInstallDialog } from "../pwa-install/PWAInstallDialog";
import { AlertDialogState } from "../alert/AlertDialog";
import { isPWASupported } from "~/utils/dom-helpers";

export const HelpDialogState = new DialogState();

const overlayCSS = cx(
  DIALOG_OVERLAY_CSS,
  css`
    backdrop-filter: blur(8px);
  `,
);

export const HelpDialog = withModalDialog({
  dialogState: HelpDialogState,
  overlayCSS,
  useOnDialogContainerRendered: () => {
    useRegisterCommands({
      commands: () => {
        return [
          openHelpCommand({
            callback: () => {
              HelpDialogState.open();
            },
          }),
        ];
      },
    });
  },
  Component: () => {
    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              HelpDialogState.close();
            },
          },
        ];
      },
    });

    return (
      <div className={cx("text-white bg-blackA-10 rounded-lg p-6", "overflow-y-auto")}>
        <h2 className="text-3xl mb-4">Help</h2>

        <p>
          Do you have a question, suggestion, or concern about a Comms feature? Check out our existing feature requests
          to upvote or submit a new one using{" "}
          <a
            href="https://comms.canny.io/comms-feature-requests"
            target="_blank"
            className="underline"
            rel="noreferrer"
          >
            this link
          </a>
          .
        </p>

        <h3 className="text-xl mb-4 mt-8">General Commands</h3>

        <table className="border border-white border-collapse w-full">
          <thead>
            <tr>
              <th className={cx(thCSS, "w-3/8")}>Shortcut</th>
              <th className={cx(thCSS, "w-5/8")}>Command</th>
            </tr>
          </thead>
          <tbody>
            <Row shortcut={"Escape"} command="Close dialog" />
            <Row shortcut={"←"} command="Open sidebar" />
            <Row shortcut={"G then I"} command="Go to Inbox" />
            <Row shortcut={"G then R"} command="Go to Starred" />
            <Row shortcut={"G then D"} command="Go to Drafts" />
            <Row shortcut={"G then T"} command="Go to Sent" />
            <Row shortcut={"G then E"} command="Go to Done" />
            <Row shortcut={"G then H"} command="Go to Reminders" />
            <Row shortcut={"/"} command="Go to Search" />
            <Row shortcut={"G then G"} command="Go to group..." />
            <Row shortcut={"C"} command="Compose new post" />
            <Row shortcut={"R"} command="Reply to thread" />
            <Row shortcut={"E"} command="Mark done (from Inbox view and from Thread view)" />
            <Row shortcut={"Shift + E"} command="Mark not done (from Done view and from Thread view)" />
            <Row shortcut={"H"} command="Set reminder (Inbox, Done, Reminder, and Thread views)" />
            <Row shortcut={"Shift + H"} command="Remove reminder (Inbox, Done, Reminder, and Thread views)" />
            <Row shortcut={`${PLATFORM_MODIFIER_KEY.shortName} + Shift + ,`} command="Delete draft" />
            <Row shortcut={"S"} command="Subscribe/unsubscribe from thread or group" />
            <Row shortcut={"H"} command="Star/unstar thread (i.e. search for 'Star' in reminders dialog)" />
            <Row shortcut={"Enter"} command="Expand/collapse post (Thread view only)" />
            <Row shortcut={`${PLATFORM_MODIFIER_KEY.shortName} + K`} command="Command Bar" />
            <Row shortcut={`${PLATFORM_MODIFIER_KEY.shortName} + Enter`} command="Submit form" />
            <Row shortcut={"?"} command="Open help menu" />
          </tbody>
        </table>

        <h3 className="text-xl mb-4 mt-12">Editor Shortcuts</h3>

        <p className="mb-2">
          The Comms editor is a "What You See is What You Get" editor that has builtin shortcuts that mimic some common
          markdown syntax. Unlike most text editors on the web, Comms does not provide any kind of toolbar.
        </p>

        <ul className="list-disc mb-4">
          <li className="ml-8">
            <a
              href="https://www.loom.com/share/27118dee919541cf8a40adeea6c16411"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              Comms message editor walkthrough
            </a>
          </li>
        </ul>

        <table className="border border-white border-collapse w-full">
          <thead>
            <tr>
              <th className={cx(thCSS, "w-3/8")}>Shortcut</th>
              <th className={cx(thCSS, "w-5/8")}>Effect</th>
            </tr>
          </thead>
          <tbody>
            <Row shortcut={"# then Space"} command="Format as Huge header (only available from start of line)" />
            <Row shortcut={"## then Space"} command="Format as Large header (only available from start of line)" />
            <Row shortcut={"### then Space"} command="Format as header (only available from start of line)" />
            <Row shortcut={`**some text** OR ${PLATFORM_MODIFIER_KEY.shortName} + B`} command="Format as bold" />
            <Row shortcut={`*some text* OR ${PLATFORM_MODIFIER_KEY.shortName} + I`} command="Format as italic" />
            <Row shortcut={"`some text`"} command="Format as inline code" />
            <Row shortcut={"~~some text~~"} command="Format as strikethrough" />
            <Row shortcut={"> then Space"} command="Format as blockquote (only available from start of line)" />
            <Row shortcut={"``` then Space"} command="Format as codeblock (only available from start of line)" />
            <Row shortcut={"- then Space"} command="Format as bulletted list (only available from start of line)" />
            <Row shortcut={"Select Text then Paste Hyperlink"} command="Make selected text a hyperlink" />
            <Row shortcut={`${PLATFORM_MODIFIER_KEY.shortName} + Enter`} command="Send message" />
          </tbody>
        </table>

        <h3 className="text-xl mb-4 mt-12">Search Tips</h3>

        <p className="mb-2">The Comms excels when you use simple filters to highlight message attributes.</p>
        <ul className="list-disc mb-4">
          <li className="ml-8">
            <a
              href="https://www.loom.com/share/003db6ad7ae84243bb66d67195fdadb3?sid=08eaf099-481a-4cf4-9377-8379aa07f043"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              Comms Search Video Walkthrough
            </a>
          </li>
        </ul>

        <table className="border border-white border-collapse w-full">
          <thead>
            <tr>
              <th className={cx(thCSS, "w-3/8")}>Shortcut</th>
              <th className={cx(thCSS, "w-5/8")}>Purpose</th>
              <th className={cx(thCSS, "w-5/8")}>Example</th>
            </tr>
          </thead>
          <tbody>
            <Row
              shortcut={"- (minus sign)"}
              command="Negative search"
              example="Use minus (-) to exclude words from search. For example, to return messages that contain the word 'master' but not 'plan': master -plan"
            />
            <Row
              // eslint-disable-next-line quotes
              shortcut={'" (quotes)'}
              command="Exact phrase"
              example={
                // eslint-disable-next-line quotes
                'Terms in quotes (") will only return messages containing those terms in the order they were given. For example, to return all messages that contain "master" and "plan" in that order: "master plan"'
              }
            />
            <Row
              shortcut={"Filters"}
              command="Filters"
              example="Filters allow you to narrow your search. Click on any of the filters above to add them to your search."
            />
            <Row
              shortcut={"after: and before:"}
              command="Date range"
              example="Use after: and before: filters to narrow your search. For example, to search for all the messages sent by Sally Smith on April 25, 2024: from:@Sally Smith after:2024-04-25 before:2024-04-26"
            />
            <Row
              shortcut={"not"}
              command="Not Filter"
              example="Use the not filter to negate other filters. For example, to search for all messages that are not replies and not from Sally Smith: not( is:reply ) not( from:@Sally Smith )"
            />
          </tbody>
        </table>

        <h3 className="text-xl mb-4 mt-12">Installation Instructions</h3>

        <p className="mb-2">
          <a
            className="text-blue-500 hover:text-blue-700 underline cursor-pointer"
            onClick={() => {
              if (isPWASupported()) {
                showPWAInstallDialog(true);
              } else {
                AlertDialogState.open({
                  content: `Unfortunately, your browser doesn't appear to support installation of progressive web apps.`,
                });
              }
            }}
          >
            Install Comms
          </a>{" "}
          to your device for a better experience.
        </p>

        <ul className="list-disc mb-4">
          <li className="ml-8">
            <a
              href="https://www.youtube.com/watch?v=cjvf4buG3Ec"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              Instructions for installing Comms
            </a>
          </li>
        </ul>
      </div>
    );
  },
});

const thCSS = "border border-whiteA-9 bg-whiteA-5 px-4 py-2 text-left";
const tdCSS = "border border-whiteA-9 px-4 py-2";

const Row: ComponentType<{
  shortcut: string;
  command: string;
  example?: string;
}> = (props) => {
  return (
    <tr>
      <td className={tdCSS}>{props.shortcut}</td>
      <td className={tdCSS}>{props.command}</td>
      {props.example && <td className={tdCSS}>{props.example}</td>}
    </tr>
  );
};
