import { isEqual } from "libs/predicates";
import { MessageRecipientDoc, RecordValue } from "libs/schema";
import { ComponentType, memo } from "react";
import { useMessageSender } from "~/hooks/useMessageSender";
import { useMessageUserRecipient } from "~/hooks/useMessageUserRecipient";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { EntryTimestamp, Recipients, Summary } from "../../content-list/layout";
import { LabelChip, LabelContainer, ThreadResolvedLabel } from "../../LabelChip";
import { MdAttachment } from "react-icons/md";
import { Tooltip } from "~/components/Tooltip";

export const CollapsedMessage: ComponentType<{
  message: RecordValue<"message">;
}> = memo((props) => {
  const { message } = props;
  const sender = useMessageSender(message.id);
  const senderLabel = sender.label || (sender.isLoading ? "loading..." : "unknown");
  const priorityLabel = useMentionLevelLabelForCurrentUser(message.id);

  const attachments = message.attachments.filter((a) => a.contentDisposition !== "inline");

  return (
    <>
      <Recipients
        className="font-bold"
        nonTruncatedPrefix={
          <ThreadResolvedLabel threadId={message.thread_id} messageId={message.id} className="mr-2 shrink-0" />
        }
      >
        {senderLabel}
      </Recipients>

      <Summary details={message.body_text} />

      <LabelContainer>
        {priorityLabel && (
          <div className="mx-2">
            <LabelChip tooltip={`You were ${priorityLabel}ed`}>{priorityLabel}</LabelChip>
          </div>
        )}

        {attachments.length > 0 && (
          <Tooltip side="bottom" content={`${attachments.length} attachment${attachments.length > 1 ? "s" : ""}`}>
            <div className="flex items-center justify-center">
              <MdAttachment size={20} className="text-slate-8" />
            </div>
          </Tooltip>
        )}
      </LabelContainer>

      <EntryTimestamp datetime={message.sent_at} />
    </>
  );
}, isEqual);

function useMentionLevelLabelForCurrentUser(messageId: string) {
  const { currentUser } = useAuthGuardContext();

  const recipient = useMessageUserRecipient({
    messageId,
    userId: currentUser.id,
  });

  const priorityLabel = getMessageMentionLabel(recipient);

  return priorityLabel;
}

function getMessageMentionLabel(recipient?: MessageRecipientDoc | null) {
  const priority = recipient?.is_mentioned ? recipient.priority : undefined;

  if (!priority) return;

  return (
    priority === 100 ? "@@@mention"
    : priority === 200 ? "@@mention"
    : priority === 300 ? "@mention"
    : undefined
  );
}
