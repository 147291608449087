import { RecordValue } from "libs/schema";

export function renderGroupName(group: RecordValue<"tag">): string {
  let name = `${group.icon ? `${group.icon} ` : ""}${group.name}`;

  if (group.archived_at) {
    name += " (Archived)";
  }

  return name;
}
