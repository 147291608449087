import { memo } from "react";
import { isEqual } from "libs/predicates";
import { IListOnEntryActionEvent, List } from "~/components/list";
import { Tooltip } from "~/components/Tooltip";
import { BsCheckCircleFill, BsLockFill } from "react-icons/bs";
import { PointerWithRecord } from "libs/schema";
import { useCurrentUserTagSubscription } from "~/hooks/useCurrentUserTagSubscription";
import { useGroup } from "~/hooks/useGroup";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import {
  EntryActions,
  OtherCommandEntryAction,
  SubscribeToTagEntryAction,
  UnsubscribeToTagEntryAction,
  entryCSSClasses,
} from "./layout";
import { isTagPrivate } from "libs/schema/predicates";
import { isModKeyActive } from "~/environment/command.service";
import { openLinkInNewTabOrWindow } from "~/environment/navigate.service";
import { navigateToEntry } from "./ContentList";
import { renderGroupName } from "~/utils/groups-utils";
import { cx } from "@emotion/css";

export function onGroupSelectNavigateToGroup({ entry, event }: IListOnEntryActionEvent<{ id: string }>) {
  const to = `/groups/${entry.id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(to);
  } else {
    navigateToEntry(entry.id, to);
  }
}

export type TOrderGroupsBy = "subscriber-count" | "recently-active" | "name";

export const GroupEntry = memo<{
  groupId: string;
  orderBy: TOrderGroupsBy;
  relativeOrder: number;
}>((props) => {
  const [group] = useGroup(props.groupId);

  const [subscription] = useCurrentUserTagSubscription({
    tagId: props.groupId,
  });

  const isSubscribed = subscription?.preference === "all" || subscription?.preference === "all-new";

  const entryData = useAsPointerWithRecord("tag", group);

  if (!group || !entryData) return null;

  const isPrivate = isTagPrivate(group);

  return (
    <List.Entry<PointerWithRecord<"tag">> id={props.groupId} data={entryData} relativeOrder={props.relativeOrder}>
      <div role="listitem" className={cx(entryCSSClasses, "GroupEntry")}>
        <div className="w-full flex items-center">
          <div className="flex items-center pr-6">
            {/* <span className="text-slate-9 mr-2">
              {props.entry.channelGroup.name} {">"}
            </span> */}

            <span>{renderGroupName(group)}</span>

            {isPrivate && (
              <Tooltip side="bottom" content="This is a private invite-only group">
                <span className="ml-2 hover:cursor-help">
                  <small>
                    <BsLockFill />
                  </small>
                </span>
              </Tooltip>
            )}

            {isSubscribed && (
              <Tooltip side="bottom" content="You are subscribed to this group">
                <span className="ml-2 text-slate-9 hover:cursor-help">
                  <BsCheckCircleFill size={14} />
                </span>
              </Tooltip>
            )}
          </div>

          <div className="flex-1" />

          {/* {props.orderBy === "subscriber-count" ? (
            <div className="text-slate-9">
              {props.entry.channel.subscriberCount} subscriber
              {props.entry.channel.subscriberCount === 1 ? "" : "s"}
            </div>
          ) : props.orderBy === "recently-active" ? (
            props.entry.channel.lastPostSentAt ? (
              <EntryTimestamp datetime={props.entry.channel.lastPostSentAt} />
            ) : (
              <div className="text-slate-9">n/a</div>
            )
          ) : (
            ""
          )} */}

          <EntryActions>
            {isSubscribed ? <UnsubscribeToTagEntryAction /> : <SubscribeToTagEntryAction />}
            <OtherCommandEntryAction />
          </EntryActions>
        </div>
      </div>
    </List.Entry>
  );
}, isEqual);
