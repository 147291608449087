import { cx } from "@emotion/css";
import { PointerWithRecord } from "libs/schema";
import { isNonNullable } from "libs/predicates";
import { ReactNode, Children } from "react";

export type TThreadTimelineEntry =
  | PointerWithRecord<"message">
  | PointerWithRecord<"thread">
  | PointerWithRecord<"draft">;

export const collapsedTimelineEntryCSS = cx(
  "TimelineEntry flex p-4 sm-w:px-8 sm-w:py-4 my-1",
  "border-l-[3px] border-transparent bg-transparent",
  "focus:outline-none focus:border-black focus:bg-white focus:shadow-lg",
  "hover:cursor-pointer hover:outline-none hover:bg-white hover:shadow-lg",
);

const LINK_REGEX = {
  /**
   * Takes either a loom share or embed link and returns the id of the video.
   */
  LOOM: /(?:http?s?:\/\/)?(?:www\.)?(?:loom\.com)\/(?:share|embed)\/?(.+)/g,
  /**
   * Takes either a youtube.com or youtu.be share or embed link and returns the id of the video.
   * https://stackoverflow.com/questions/22544625/javascript-convert-youtube-vimeo-urls-into-embed-versions-for-use-on-a-forum#comment62714866_22667308
   */
  YOUTUBE: /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/)?(.+)/g,
  /**
   * Takes either a vimeo share or embed link and returns the id of the video.
   * Note: Unlisted vimeo vidoes like https://vimeo.com/773370665/43b24307b3
   * will show an invalid embed code message. The vimeo api is required to get
   * the correct embed link.
   */
  VIMEO: /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com|player.vimeo.com)\/(?:video\/)?(.+)/g,
};

export function getEmbeddableVideoLink(url: string | undefined): string | null {
  if (!url) return null;

  if (url.match(LINK_REGEX.LOOM)) {
    return url.replace(LINK_REGEX.LOOM, "https://www.loom.com/embed/$1");
  }

  if (url.match(LINK_REGEX.YOUTUBE)) {
    return url.replace(LINK_REGEX.YOUTUBE, "https://www.youtube.com/embed/$1");
  }

  if (url.match(LINK_REGEX.VIMEO)) {
    return url.replace(LINK_REGEX.VIMEO, "https://player.vimeo.com/video/$1");
  }

  return null;
}

export function shouldURLBeUnfurled(
  url: string | undefined,
  /** children of the <a> tag */
  elementChildren: ReactNode,
) {
  type TChild = string | number | JSX.Element;

  const mapToStrings = (child: TChild) => {
    switch (typeof child) {
      case "string": {
        return child;
      }
      case "object": {
        if (!("type" in child)) return null;
        // If the link is bold or italiced then those tags are children
        // of the <a> tag.
        if (!(child.type === "strong" || child.type === "em")) {
          return null;
        }

        if (!Array.isArray(child.props.children)) return null;

        return child.props.children.flatMap((child: TChild) => mapToStrings(child)).join("");
      }
      default: {
        return null;
      }
    }
  };

  const children = Children.toArray(elementChildren).map((raw) => {
    const child = raw.valueOf() as TChild;
    return mapToStrings(child);
  });

  if (children.some((c) => !c)) return false;

  return children.filter(isNonNullable).join("") === url;
}
