import { ReactElement, useMemo } from "react";

/**
 * Helps with using `switch` statements inside JSX.
 *
 * @example
 *
 * ```ts
 * let variable = "one";
 *
 * return (
 *   <SwitchCase deps={[variable]}>
 *     {(value) => {
 *       switch (value) {
 *         case "COMMS": {
 *           return <CommsThreadActions value={variable} />;
 *         }
 *         case "EMAIL": {
 *           return <EmailThreadActions />;
 *         }
 *         default: {
 *           throw new UnreachableCaseError(value);
 *         }
 *       }
 *     }}
 *   </SwitchCase>
 * )
 * ```
 */
// Note, the <T,> comma is necessary hint to the compiler in .tsx files
// https://stackoverflow.com/a/56989122/5490505
export function SwitchCase<T extends [unknown, ...unknown[]]>(props: {
  deps: T;
  children: (value: T) => ReactElement | null;
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => props.children(props.deps), props.deps);
}
