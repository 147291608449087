import { useAsync } from "react-use";
import { useMentionExtensionSubscriptions } from "~/components/forms/message-editor/extensions/mention";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { getAllThreadViewData } from "~/queries/getAllThreadViewData";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";

export function usePreloadThreadData(props: { threadId: string | null | undefined }) {
  const { threadId } = props;
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  // Here we preload records used by the mention extension of the
  // compose message editor. This speeds up the rendering of the
  // compose reply editor. Note that we aren't blocking rendering
  // of the thread for this data.
  useMentionExtensionSubscriptions();

  const result = useAsync(async () => {
    if (!threadId) return;

    await getAllThreadViewData(environment, {
      threadId,
      getParentThreads: "first",
    });
  }, [threadId, currentUserId, environment]);

  return { isThreadLoading: result.loading };
}
