import { PointerWithRecord, RecordTable, RecordValue } from "libs/schema";
import { useMemo } from "react";

/**
 * Converts a record into a stable pointer with record value.
 */
export function useAsPointerWithRecord<T extends RecordTable>(
  table: T,
  record: RecordValue<T> | null | undefined,
): PointerWithRecord<T> | null {
  return useMemo(() => {
    if (!record) return null;
    return { table, id: record.id, record };
  }, [table, record]);
}
