import { differenceWith } from "lodash-comms";

export function removeOneFromArray<T>(array: T[], fn: (item: T) => boolean): T[] {
  const newArray = array.slice();

  const index = array.findIndex(fn);

  if (index < 0) return newArray;

  newArray.splice(index, 1);

  return newArray;
}

export function arrayChange<T>(newArray: T[], oldArray: T[], comparer: (a: T, b: T) => boolean = (a, b) => a === b) {
  const removed = differenceWith(oldArray, newArray, comparer);
  const added = differenceWith(newArray, oldArray, comparer);
  return { added, removed };
}
