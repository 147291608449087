import { RecordValue } from "libs/schema";
import { create } from "zustand";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

/**
 * State of the inbox page.
 */

export const inboxState = create<{
  inboxEntries: RecordValue<"inbox_entry">[];
  filteredInboxEntries: RecordValue<"inbox_entry">[];
  selectedGroups: string[];
  setInboxEntries: (environment: ClientEnvironment, inboxEntries: RecordValue<"inbox_entry">[]) => void;
  toggleGroup: (environment: ClientEnvironment, groupId: string) => void;
  reIndexEntries: (environment: ClientEnvironment) => void;
  clearSelectedGroups: () => void;
}>((set) => ({
  inboxEntries: [],
  filteredInboxEntries: [],
  selectedGroups: [],
  setInboxEntries(environment: ClientEnvironment, inboxEntries: RecordValue<"inbox_entry">[]) {
    set((state) => ({
      inboxEntries,
      filteredInboxEntries: inboxEntries.filter((entry) =>
        filterEntryByGroups(environment, state.selectedGroups, entry),
      ),
    }));
  },
  toggleGroup: (environment: ClientEnvironment, groupId: string) => {
    set((state) => {
      let selectedGroups = state.selectedGroups;
      selectedGroups = selectedGroups.includes(groupId)
        ? selectedGroups.filter((g) => g !== groupId)
        : [...selectedGroups, groupId];
      return {
        selectedGroups,
        filteredInboxEntries: state.inboxEntries.filter((entry) =>
          filterEntryByGroups(environment, selectedGroups, entry),
        ),
      };
    });
  },
  reIndexEntries: (environment: ClientEnvironment) => {
    set((state) => ({
      filteredInboxEntries: state.inboxEntries.filter((entry) =>
        filterEntryByGroups(environment, state.selectedGroups, entry),
      ),
    }));
  },
  clearSelectedGroups: () => {
    set((state) => ({
      selectedGroups: [],
      filteredInboxEntries: state.inboxEntries,
    }));
  },
}));

function filterEntryByGroups(
  environment: ClientEnvironment,
  selectedGroups: string[],
  entry: RecordValue<"inbox_entry">,
) {
  if (selectedGroups.length === 0) {
    return true;
  }

  if (entry.type === "notification") {
    const [permissions] = environment.db.getThreadGroupPermissions({
      thread_id: entry.thread_id,
    });
    return permissions.some((p) => selectedGroups.includes(p.group_id));
  }
  return true;
}
