import { ConvertHTMLToReactOptions, convertHTMLToReact } from "~/utils/parseHTMLProse";
// Note that we want to minimize our use of this hook since it's making use of the React suspense API
// which is still experimental and not yet stable. I.e. this hook is liable to break in future versions
// of React.
import usePromise from "react-promise-suspense";

/**
 * This hook accepts an HTML string and converts it to a React
 * component tree. This converstion is an async process and React
 * suspense is triggered while the promise is pending.
 */
export function useConvertHTMLToReact(text: string, components?: ConvertHTMLToReactOptions) {
  return usePromise(convertHTMLToReact, [text, components]);
}
