import { ComponentType } from "react";
import { LoadingText } from "~/components/LoadingText";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useTriggerIsLoading } from "~/hooks/useTriggerIsLoading";

export const ThreadLoading: ComponentType = () => {
  const { recordLoader } = useClientEnvironment();

  const isCacheFetchStrategy = recordLoader.options.defaultFetchStrategy.startsWith("cache");

  useTriggerIsLoading(!isCacheFetchStrategy);

  if (isCacheFetchStrategy) return null;

  return <LoadingText fullscreen={false} />;
};
